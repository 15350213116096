














































































































































@import 'Less/variables.less';

.toolbar {
  display: flex;
  align-items: center;
  flex: 0 1 66%;
  justify-content: center;

  &__item {
    display: inline-flex;
    padding-right: @spacing-2;
    padding-left: @spacing-2;

    &:hover {
      color: @theme-primary;
    }
  }
}

.icon {
  font-size: 20px;
  cursor: pointer;
}

iframe > img {
  width: 100%;
}

.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
