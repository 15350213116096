<template>
  <div>
    <div
      v-if="studentLogs.length"
      style="margin-top: 10px; text-align: center"
    >
      <transition name="fade">
        <div
          v-if="showStudentLogs"
          style="max-width: 600px; margin: 10px auto"
        >
          <div
            v-for="studentLog in firstLogs"
            :key="studentLog.id"
          >
            <StudentLogEvent :studentLog="studentLog" />
          </div>
        </div>
      </transition>

      <div v-if="studentLogs && studentLogs.length > 3 && showMore">
        <div
          v-for="studentLog in moreLogs"
          :key="studentLog.id"
        >
          <StudentLogEvent :studentLog="studentLog" />
        </div>
      </div>

      <div
        v-if="studentLogs && studentLogs.length > 3 && !showMore"
        class="text-center"
      >
        <text-button
          :title="__('Visa fler')"
          @click="showMore = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StudentLogEvent from './StudentLogEvent.vue'
export default {
  components: { StudentLogEvent },
  props: {
    studentLogs: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showStudentLogs: true,
      showMore: false,
    }
  },
  computed: {
    firstLogs() {
      return this.studentLogs.slice(0, 3)
    },
    moreLogs() {
      return this.studentLogs.slice(3)
    },
  },
  mounted() {
    window.K$.events.$on('chosen-student', () => {
      this.showMore = false
    })
  },
}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
