











































































































































































































































































































@import 'Less/variables.less';

.speech-synthesis {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: white;
  width: 200px;

  &__controls {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: @spacing-3;

    &__sliders {
      flex: 1;
      margin: @spacing-1;
      margin-bottom: @spacing-2;

      &__slider {
        padding-top: @spacing-1;
        padding-bottom: @spacing-2;

        &__label {
          font-size: @font-size-small;
          margin-bottom: @spacing-1;
        }
      }
    }

    &__speak-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__info p {
        margin-bottom: @spacing-3;
        font-size: @font-size-small;
        text-align: center;
      }
    }
  }
}
