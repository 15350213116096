








































































































.openChoiceCard {
  display: flex;
  width: 450px;
  margin: auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 20%);
  cursor: pointer;
  border-radius: 5px;

  &__title {
    padding: 5px;
    width: 100px;
    background: #1d2d50;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
    border-radius: 5px 0 0 5px;

    &--active {
      color: #1d2d50 !important;
    }
  }

  &__description {
    height: 120px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1e2d50;
    border-radius: 0 5px 5px 0;
  }
}

.openChoiceCard:hover {
  transition: 0.3s all;
  transform: scale(1.01);
}
