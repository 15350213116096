









@import 'Less/variables.less';

div.close--button {
  background: @theme-blue;
  height: 29px;
  width: 30px;
  position: fixed;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 100%;
  line-height: 2;
  cursor: pointer;
  z-index: 9;
  font-size: 20px;
  color: @theme-primary;

  i.close--button {
    line-height: 14px;
    font-size: 37px;
  }
}
