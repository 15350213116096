
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.fetch-exam-spinner {
  margin-left: 10px;
}

.exam-info-text {
  padding: 7px;
  font-size: small;
  color: #e6a000;
  width: 85%;
}

.cheat-information {
  padding-top: 15px;
  font-size: 12px;
  cursor: pointer;
  color: #8b95ab;
  display: flex;
  align-items: center;
}

.btn-exam-next {
  height: 46px;
  min-width: 100px;
  border-radius: 300px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: @theme-blue;
  border-color: @theme-blue;
  color: white;
  font-size: @font-size-medium;
  padding-left: @spacing-4;
  padding-right: @spacing-4;
  text-transform: uppercase;
  font-weight: var(--font-semibold);
  border: none;

  &:hover {
    background: @theme-primary;
  }
}

.loginBtn {
  cursor: pointer;
  display: flex;
  width: 240px;
  margin: auto;
  height: 41px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 63%);
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-family: 'Open Sans', Arial, sans-serif;
  background: #4285f4;
  border: 1px solid #4285f4;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-text {
    flex: 1;
    color: white;
    font-size: 14px;
  }

  &.microsoft {
    box-shadow: none;
    border: none;
    border-radius: 0;
    background: #2f2f2f;

    .icon {
      margin-left: 12px;
    }

    .login-text {
      text-align: center;
      // margin-left: 12px;
    }
  }

  &.google {
    .icon {
      width: 43px;
      height: 100%;
      background: white;
    }
  }

  &.skolfederation {
    background: white;
    border: 1px solid #ff7900;
    border-radius: 2px;
    box-shadow: 5px 5px 19px rgba(18, 9, 2, 20%);

    .login-text {
      color: black;
      text-align: center;
      // color: #ff7900;
      // margin-left: 12px;
    }

    .icon {
      margin-left: 6px;

      img {
        width: 30px;
      }
    }
  }
}

.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.launchAppCard {
  display: flex;
  width: 210px;
  margin: auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 20%);
  cursor: pointer;
  border-radius: 5px;
}

.launchAppCard:hover {
  transition: 0.3s all;
  transform: scale(1.1);
}

.sebInfo {
  &__footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__button {
    margin: @spacing-1;
  }
}
