















































































@import 'Less/variables.less';

.self-state-menu {
  display: flex;
  align-items: flex-end;

  &__icon {
    padding-right: @spacing-2;
    padding-left: @spacing-2;
    display: inline-flex;
    font-size: @font-size-small;
    cursor: pointer;

    &:hover {
      color: @theme-primary;
    }
  }
}
