






















































































































































































































































































@import 'Less/variables.less';

.exam-area {
  width: 100%;
  height: 100%;

  &__loader {
    max-height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--wrapper {
    width: 100%;
    height: 100%;
  }

  &__split {
    height: 100%;

    &--disabled {
      width: 100%;
    }
  }

  &__write-area-tools {
    display: flex;
  }

  &__question-area {
    display: flex;
    height: calc(100% - var(--resource-toolbar-height));
    width: 100%;

    &__pdf {
      background-color: @color-grey-super-light;
      height: 100%;
      width: 100%;
    }

    &__write {
      margin: @spacing-2 auto;
      width: 794px;
      max-width: 100%;
      min-height: 80%;
      border-radius: @radius-xs;
      box-shadow: @shadow-lg;

      &--overflow {
        width: 100%;
        overflow: auto;
        height: 100%;
      }
    }
  }
}

.display-mode-icon {
  cursor: pointer;
  padding-right: @spacing-2;
  padding-left: @spacing-2;
  display: inline-flex;
  font-size: @font-size-medium;
}

.display-mode-icon:hover {
  color: @theme-primary;
}

.display-mode-icon[data-active='true'] {
  color: @theme-primary;
}
