































































































































@import 'Less/variables.less';

.page {
  font-family: 'Times New Roman', Times, serif;
  max-width: 794px;
  min-height: 1123px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 20%);
  background-color: #fff;
  position: relative;
  font-size: 12pt;
  overflow: auto;

  /* Smooth iOS scroll */
  -webkit-overflow-scrolling: touch;

  &--wrapper {
    margin: auto;
    overflow: auto;
    height: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    height: 100px;
    box-sizing: border-box;

    img {
      max-width: 100px;
    }

    &__copyright-text {
      font-size: 14px;
      color: #333;
      text-align: right;
    }
  }

  &__body {
    margin: calc(3cm - 100px) 2.5cm 2.5cm 2.5cm;
    box-sizing: border-box;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  flex: 0 1 66%;
  justify-content: center;

  &__item {
    display: inline-flex;
    padding-right: @spacing-2;
    padding-left: @spacing-2;

    &:hover {
      color: @theme-primary;
    }
  }
}

.icon {
  font-size: 20px;
  cursor: pointer;
}

.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
