




























































































































































































































































































.StudentImageHandler {
  position: absolute;
  width: var(--width-60);
  min-width: var(--width-60);
  z-index: 1000;
  background: var(--blue-900);
  color: white;
  margin-top: 50px;
  padding: 0;
  text-align: center;
  transition: all var(--duration-500) ease-in-out;
  height: 100%;
  left: 0;
  font-size: var(--font-size-sm);
  max-height: calc(100vh - 50px); // Prevents top to be pushed out of view. 50 px is from id="student-info" height

  &__imageBeingAttached {
    margin: auto;
    margin-bottom: 8px;
  }

  &__cancel-attachment {
    margin-bottom: var(--spacing-4);
  }

  &--hidden {
    height: 0;
  }

  &__menu-item {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    cursor: pointer;
    padding-bottom: var(--spacing-2);
    padding-top: 10px;
    padding-left: 18px;
    padding-right: var(--spacing-1);
    margin-bottom: var(--spacing-2);
    color: #ddd;
    border-bottom: 1px solid white;

    &__icon {
      color: white;
      margin-right: var(--spacing-2);
      font-size: var(--font-size-sm);
    }

    .fa-paperclip {
      margin-right: 10px;
    }

    .fa-chevron-up {
      margin-right: var(--spacing-1);
    }
  }

  &__information {
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);

    &__text {
      margin-bottom: var(--spacing-4);
    }
  }

  &__scrollbar {
    overflow-y: auto;
    height: calc(100% - 100px);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: var(--spacing-2);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
      background-color: #1d2b51;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-100);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
    }
  }

  &__refresh {
    cursor: pointer;
    font-size: var(--font-size-sm);
    margin-top: var(--spacing-9);
  }
}

