




































































































































































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.ExamBuilderGrading {
  position: relative;
  width: 100%;

  &__question {
    margin-bottom: 70px;
    z-index: 1;

    &__subQuestion {
      margin-bottom: 30px;
    }
  }

  &__footer {
    position: sticky;
    padding: var(--spacing-2)  0;
    bottom: var(--spacing-3);
    border-radius: var(--radius-md);

    &--sticky {
      padding: var(--spacing-2)  var(--spacing-3);
      background-color: var(--gray-50);
      box-shadow: var(--shadow-default);
      z-index: var(--z-layer-2);
    }
  }
}

.Grading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: nowrap;
  margin: 10px 0;

  &__body {
    background-color: @color-warning-light;
    color: @color-warning-dark;
    border-left: 5px solid @color-warning-medium;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: @radius-small;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    &--info {
      background-color: @color-info-light;
      color: @color-info-dark;
      border-left: 5px solid @color-info-medium;
    }

    &--success {
      background-color: @color-success-light;
      color: @color-success-dark;
      border-left: 5px solid @color-success-medium;
    }

    &__label {
      margin-right: 15px;
    }
  }
}

.previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 30px;
  // margin-bottom: 30px;
  margin-right: 30px;
}

.star-marker {
  font-size: 22px;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: -3px;

  &--clickable {
    cursor: pointer;
  }
}

.wordCounter {
  text-align: center;
  margin-top: -8px;
  color: gray;
}
