



















































































































































































@import 'Less/variables.less';

.preview-scan {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #222;
    opacity: 0.8;
  }

  &__body {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 20%);
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    box-sizing: border-box;
    height: 100%;
  }

  &__canvas {
    background-color: #fff;
    margin: 0;
    margin-bottom: 20px;
    max-width: 80%;
    max-height: 80%;
  }

  &__rotate {
    bottom: 5px;
    right: 5px;
    background-color: @theme-blue;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
