





















































































































































































































































































































































































@import 'Less/variables.less';

.exam-table {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  color: @color-font-dark;

  &__actions {
    color: white;
    font-size: @font-size-large;
    padding: var(--spacing-2) var(--spacing-3);
    cursor: pointer;

    &--container {
      display: flex;
      justify-content: flex-start;
      margin: @spacing-1 9px;
      align-items: center;
    }
  }
}

.modal {
  &__footer {
    display: flex;
    justify-content: center;

    &__button {
      margin: 0 @spacing-1;
    }
  }
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}
