<template>
  <exam-modal
    size="lg"
  >
    <h3
      slot="title"
      class="modal-title"
    >
      {{ __('Skicka individuella provnycklar') }}
    </h3>
    <div
      slot="body"
      class="text-center"
    >
      <p>
        {{
          __('Är du säker på att du vill skicka ut individuella provnycklar så att eleverna kan återuppta provet?')
        }}
      </p>
      <p>{{ __('Detta förutsätter att eleven angav epost när provet påbörjades.') }}</p>

      <h4>{{ __('Skicka till alla elever utom följande') }}</h4>
      <select
        v-model="exceptedStudent"
        style="width: 200px; display: inline"
        class="form-control"
      >
        <option value="0">
          {{ __('Välj elev') }}
        </option>
        <option
          v-for="student in students"
          :key="student.id"
          :value="student.id"
        >
          {{ Student.displayName(student) }}
        </option>
      </select>
      <text-button
        style="margin: 4px"
        :title="__('Lägg till undantag')"
        @click="addException"
      />
      <h3>{{ __('Tillagda undantag') }}</h3>
      <div v-if="exceptions.length === 0">
        <p>{{ __('Inga undantag') }}</p>
      </div>
      <div v-else>
        <div
          v-for="student in exceptions"
          :key="student.id"
        >
          {{ Student.displayName(student) }} <i
            class="button-icon fa fa-times"
            @click="removeException(student)"
          />
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="popup-footer"
      style="max-width: 300px; margin: auto"
    >
      <text-button
        mode="secondary"
        data-dismiss="modal"
        :title="__('Stäng')"
      />
      <text-button
        mode="primary"
        :loading="isSending"
        :title="__('Skicka')"
        @click="send"
      />
    </div>
  </exam-modal>
</template>

<script>
import Student from 'Modules/Student.js'
import Flash from 'Modules/Flash.js'
import Modal from 'Modules/Modal.js'
import axios from 'axios'

export default {
  props: {
    examId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      Student,
      students: [],
      exceptions: [],
      exceptedStudent: 0,
      isSending: false,
    }
  },
  methods: {
    async show() {
      const response = await axios.get(`/api/students/exam/${this.examId}`)
      this.students = response.data
      Modal.show(this.$el)
    },
    send() {
      this.isSending = true

      axios.post(`/api/resume/${this.examId}`, { exceptions: this.exceptions }).then(response => {
        if (response.data.count === 0) {
          Flash.now(this.__('Ingen elev för detta prov har angett epostadress'))
          return
        }

        Flash.now(`${this.__('Individuella provnycklar har skickats till')} ${response.data.count} ${this.__('elever')}`)
      }).finally(() => {
        this.isSending = false
        Modal.hide(this.$el)
      })
    },
    addException() {
      if (!this.exceptedStudent) {
        return
      }

      if (this.exceptions.find(student => student.id === this.exceptedStudent)) {
        return
      }

      const student = this.students.find((student) => student.id === this.exceptedStudent)
      this.exceptions.push(student)
    },
    removeException(student) {
      this.exceptions = this.exceptions.filter((s) => s.id !== student.id)
    },
  },
}
</script>

<style scoped>
.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
</style>
