













































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.geogebra {
  &__loader {
    display: flex;
    flex-direction: column;
  }

  &__roundingChoice {
    margin: auto;
  }
}

.geogebra-wrapper {
  height: 100%;
  width: 100%;
}

.applet {
  &--container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  flex: 0 1 66%;
  justify-content: center;

  &__item {
    display: inline-flex;
    padding-right: @spacing-2;
    padding-left: @spacing-2;

    &:hover {
      color: @theme-primary;
    }
  }
}

.loading-container {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  color: @theme-primary;
}
