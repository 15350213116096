
































































@import 'Less/variables.less';

.powerButton {
  position: fixed;
  display: inline-block;
  top: 10px;
  left: auto;
  right: 10px;
  width: 45px;
  height: 45px;
  z-index: 10;
  background: url('https://static.exam.net/img/powerButton.svg') no-repeat;
  background-position: 7px 6px;
  background-color: @theme-blue;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}

.powerButton:hover {
  background-color: @theme-primary;
  box-shadow: 0 0 4px rgba(0, 0, 0, 50%);
}

.absolute {
  position: absolute;
}
