<template>
  <div
    class="delimiter"
    :class="{ delimiterShort: short === true }"
  >
    <div
      class="line"
      :class="{ lineShort: short === true }"
    />
  </div>
</template>

<script>
export default {
  props: { short: Boolean },
}
</script>

<style scoped>
.delimiter {
  position: relative;
  width: 100%;
  height: 60px;
}

.delimiterShort {
  height: 20px;
}

.line {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 35px;
  background-color: #eee;
}

.lineShort {
  top: 0;
}
</style>
