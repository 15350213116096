









































































































































































@import 'Less/variables.less';

.formula-area {
  width: 100%;
  height: 100%;

  &__loader-container {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__searchSelect {
    color: black;
    font-size: 16px;
    transform: scale(0.7);
    flex: 1;
  }

  &__document-container {
    width: 100%;
    height: calc(100% - var(--resource-toolbar-height));
    background-color: @color-grey-super-light;
    overflow: auto;
  }
}

.document {
  --zoom: 1;

  transform: scale(var(--zoom));
  transform-origin: top;
  margin: auto;
  max-width: 99%;
  max-height: stretch; // Fix Safari showing grey area below when zoomed out
  user-select: none;

  &__page:last-of-type {
    padding-bottom: @spacing-16;
  }

  &__item {
    cursor: grab;
    background-color: white;
    box-shadow: @shadow-md;
    margin: @spacing-4 auto;
    padding: @spacing-3 @spacing-5;
    width: 100%;
  }

  &--zoomed-in {
    transform-origin: top left;
  }
}

.draggingCursor {
  cursor: grabbing;
}
