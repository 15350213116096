































































































@activeUnderlineColor: var(--activeUnderlineColor);
@contentMarginTop: var(--contentMarginTop);
@tablet-width: 1024px;
@mobile-width: 480px;
@blackish: #373f41;

.el-tab {
  .left-align-header-items {
    margin-right: 15px;
    display: flex;
    flex-wrap: nowrap;
  }

  .right-align-header-items {
    margin-left: auto;
    display: flex;
    flex-wrap: nowrap;
  }

  .el-tab.nav {
    height: max-content;
    display: flex;
    flex-direction: row;
    position: relative;
    width: auto;
    max-width: 100%;
  }

  .el-tab.nav > div:last-child {
    margin-right: 0;
  }

  .el-tab.item {
    // padding: 2px 0px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    z-index: 1;
    color: @blackish;
    margin-right: 17px;
    white-space: nowrap;

    &::after {
      display: block;
      content: '';
      padding-bottom: 4px;
      border-bottom: 3px solid @activeUnderlineColor;
      transform: scaleX(0);
      transition: transform 100ms ease-in-out;
    }
  }

  @media (max-width: @mobile-width) {
    .subtitle1 {
      font-weight: 300;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.1px;
    }
  }

  .el-tab.active {
    z-index: 1;

    &::after {
      transform: scaleX(1);
      //  transform-origin:100% 50%; //right
      // transform-origin:  0% 50%; //left
    }

    @media (max-width: @mobile-width) {
      font-weight: 400;
    }
  }

  .content {
    // 110:heigh header ElPanel.vue, 40px:paddingen som ska vara i botten, 30px för tabs, +
    // contentMarginTop (brukar vara10px)
    // min-height: calc(var(--vh, 1vh) * 100 - 110px - 40px - 30px - var(--contentMarginTop));
    // padding: 25px 40px;
    // @media (max-width: @tablet-width) {
    //   //110:heigh header ElPanel.vue, 50px:navbar, 30px för tabs, + contentMarginTop (brukar vara10px)
    //   min-height: calc(var(--vh, 1vh) * 100 - 78px - 50px - 30px - var(--contentMarginTop));
    //   padding: 30px 30px 0 30px;
    // }
    // @media (max-width: @mobile-width) {
    //   min-height: calc(var(--vh, 1vh) * 100 - 58x - 50px - 28px - var(--contentMarginTop));
    //   padding: 10px 15px 0 15px;
    // }

    background-color: white;
    flex-grow: 1;
    width: 100%;
    z-index: 2;

    &.student-tab-control-height {
      max-height: 526px;

      /* height: 300px; */
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
