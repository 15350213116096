





























































































































































































































































































































































































@import 'Less/variables.less';

.pdf-page {
  margin: 0 auto;
  position: relative;
  box-shadow: @shadow-lg;
  margin-top: @spacing-5;
  background-color: white;

  * {
    height: 100%;
    width: 100%;
  }

  &:last-of-type {
    margin-bottom: @spacing-5;
  }

  &__text-layer {
    position: absolute;
    text-align: initial;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1;

    &::v-deep {
      span,
      br {
        color: transparent;
        position: absolute;
        white-space: pre;
        transform-origin: 0% 0%;
        // This makes the text elements taller so that selection is not interrupted when dragging between lines.
        height: 200px;
      }

      ::selection {
        color: transparent;
        text-shadow: none;
        background: @theme-blue;
      }

      /* Avoids https://github.com/mozilla/pdf.js/issues/13840 in Chrome */
      br::selection {
        background: transparent;
      }
    }
  }
}
