
















































































@import 'Less/variables.less';

.quit-bannerHolder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quit-banner {
  width: 100%;
  height: 300px;
}

.quit-banner-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 300px;
  background-color: #fff;
  opacity: 0.8;
}

.quit-studentName {
  height: 80px;
  font-size: 64px;
  text-align: center;
  margin-top: 40px;
  font-weight: 100;
  color: #20335d;
}

.quit-thanks {
  height: 24px;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  font-weight: 100;
  color: #20335d;
}

.quit-alternative {
  height: 12px;
  font-size: 16px;
  text-align: center;
  margin-top: -40px;
  font-weight: 100;
  color: #20335d;
}

.quit-button-holder {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}

.quit-button {
  height: 40px;
  width: 250px;
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: @theme-blue;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.quit-button:hover {
  color: #fff;
  text-decoration: none;
  background-color: @theme-primary;
}
