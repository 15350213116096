













































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

@divider-border: 1px solid @color-grey-light;
@orange-light: #ffe6bb;
@orange-dark: #613c00;
@orange-main: #ff9c00;
@blue-main: #1d2c52;
@red-main: #d84a39;
@inbox-width: 224px;

.animation {
  transition: @animation;
}

.teacher-chat {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);
  background-color: #fff;
  border-radius: @spacing-1;

  &--minimized {
    box-shadow: none;

    .teacher-chat__wrapper {
      height: 0;
      min-height: 0;
      max-height: 0;
    }

    &.beta {
      height: 100%;

      & > .teacher-chat__wrapper {
        width: 100%;
        min-height: 100%;
      }
    }
  }

  &__instruction-message {
    color: #757575;
    text-align: center;
    padding: @spacing-2 0;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 640px;
    max-width: 100%;
    height: 384px;
    max-height: 384px;
    border-bottom: @divider-border;

    &__toggle {
      position: absolute;
      bottom: 5px;
      left: 5px;
      width: @spacing-12;
      height: @spacing-12;
      border-radius: @radius-small;
      background-color: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);

      &--expanded {
        height: @spacing-5;
        top: -@spacing-5;
        border-radius: @radius-small @radius-small 0 0;

        &.km {
          visibility: hidden;
        }
      }

      &__icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__notification-badge {
        position: absolute;
        top: 0;
        right: 0;
        background-color: @red-main;
        height: @spacing-5;
        width: @spacing-5;
        border-radius: 50%;
        border: 1px solid #fff;
        color: #fff;
        transform: translate(50%, -50%);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }
    }

    &__inbox {
      position: relative;
      display: flex;
      flex-direction: column;
      width: @inbox-width;
      min-width: @inbox-width;
      max-width: @inbox-width;
      border-right: @divider-border;
      box-sizing: border-box;
      background-color: #fff;

      &__search-filter {
        position: relative;
        margin: @spacing-2 @spacing-2;
        height: @spacing-8;
        min-height: @spacing-8;
        background-color: #f9f9f9;
        border: 1px solid #cacaca;
        border-radius: @radius-xs;
        padding: 0 @spacing-2;
        box-sizing: border-box;
      }

      &__items {
        overflow-y: auto;
        border-top: @divider-border;
      }
    }
  }

  &__footer {
    background-color: @color-grey-super-light;

    &.beta {
      position: absolute;
      top: 0;
      background-color: #fff;
    }
  }
}

.chat {
  position: relative;
  height: @spacing-13;
  border-bottom: @divider-border;
  border-top: 1px solid #fff;
  cursor: pointer;
  padding: @spacing-1 @spacing-3;
  box-sizing: border-box;

  &--active {
    background-color: @orange-light;
    color: @orange-dark;
    border-bottom: 1px solid #fff;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: ' ';
      background-color: @orange-main;
      height: 100%;
      width: @spacing-1;
    }
  }

  &__badge {
    display: inline-block;
    height: @spacing-2;
    width: @spacing-2;
    border-radius: 50%;
    background-color: @blue-main;
  }

  &__name-and-timestamp {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: space-between;

    &__name {
      font-weight: 700;
      color: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__time {
      color: inherit;
      opacity: 0.5;
    }
  }

  &__message {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    color: inherit;
    opacity: 0.7;
    text-overflow: ellipsis;
  }
}
