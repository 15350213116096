











































































































































@modal-width: 900px;

.exam-builder-modal {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  background: rgba(0, 0, 0, 30%);
  top: 0;
  left: 0;
  width: 100vw;
  overflow-y: scroll;

  &__body {
    position: relative;
    margin: 10px 0;
    min-height: ~'Calc(100vh - 20px)';
    background: #fff;
    width: @modal-width;
    box-shadow: 0 0 10px rgba(0, 0, 0, 20%);
    max-width: 100%;
    border-radius: 5px;
    z-index: 1;
    padding: 30px 40px;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;

    @media (max-width: 900px) {
      top: 0;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    &__body {
      position: relative;
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 12%);
      bottom: 0;
      height: auto;
      width: 100%;
      max-width: @modal-width;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 5px 15px;
    }
  }

  &__spacer {
    height: 50px;
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
