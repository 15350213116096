<template>
  <div class="exam-toolbar">
    <span class="exam-toolbar__title noselect">{{ menuTitle }}</span>
    <div class="exam-toolbar__tools">
      <div class="exam-toolbar__tools__default">
        <slot />
      </div>
      <div class="exam-toolbar__tools____group">
        <slot name="splitView" />
      </div>
      <div class="exam-toolbar__tools__group">
        <SelfStateMenu :resourceName="resourceName" />
      </div>
      <div class="exam-toolbar__tools__toc">
        <slot name="toc" />
      </div>
    </div>
  </div>
</template>

<script>
import SelfStateMenu from 'VueStudent/exam/SelfStateMenu.vue'
export default {
  components: {
    SelfStateMenu,
  },
  props: {
    menuTitle: {
      type: String,
      default: '',
    },
    resourceName: {
      type: String,
      default: '',
    },
  },
}
</script>
<style>
:root {
  --resource-toolbar-height: 32px;
}
</style>
<style lang="less" scoped>
@import 'Less/variables.less';

.exam-toolbar {
  --icon-size: 20px;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 69;
  width: 100%;
  display: flex;
  height: var(--resource-toolbar-height);
  background: @theme-blue;
  color: @color-font-light;
  padding: @spacing-1 0;
  align-items: center;

  &__title {
    margin-left: @spacing-2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: var(--font-semibold);
    font-size: @font-size-medium;
    cursor: default;
    flex: 0 1 33%;
  }

  &__tools {
    display: flex;
    flex: 1 2 100%;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--icon-size);

    &__group {
      margin-left: @spacing-3;
    }

    &__toc {
      position: absolute;
      top: calc(var(--resource-toolbar-height) - 1px);
      right: 15px;
    }

    &__default {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      font-size: var(--icon-size);
    }
  }
}
</style>
