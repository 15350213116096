
















































































































a {
  color: white;
  text-decoration: underline;
}

a:hover {
  color: #1d2d50;
}

.information-bar {
  background: #f99830;
  margin-top: -10px;

  &__text-wrapper {
    padding: 10px 0 20px;
    color: white;
  }

  &__text {
    margin: 0;
    font-size: 22px;
    margin-left: 20px;
  }

  &__subtext {
    margin: 0;
    font-size: 16px;
    margin-left: 20px;
  }

  .warning-triangle {
    font-size: 28px;
  }
}
