<template>
  <div class="wrapper">
    <text-button
      style="margin-top: 10px; margin-bottom: 50px"
      title="Hämta internationella bokningar"
      @click="load"
    />

    <div
      class="flex flex-col"
      style="margin: auto"
    >
      <div class="lg:-mx-8 mt-8 overflow-x-auto sm:-mx-6">
        <div class="align-middle inline-block lg:px-8 py-2 sm:px-6">
          <div class="border-b border-gray-200 overflow-hidden shadow sm:rounded-lg">
            <table class="divide-gray-200 divide-y">
              <thead>
                <tr>
                  <th
                    class="bg-gray-50
                      font-medium
                      leading-4
                      px-6
                      py-3
                      text-gray-500
                      text-left
                      text-xs
                      tracking-wider
                      uppercase"
                  >
                    Organisation
                  </th>
                  <th
                    class="bg-gray-50
                      font-medium
                      leading-4
                      px-6
                      py-3
                      text-gray-500
                      text-left
                      text-xs
                      tracking-wider
                      uppercase"
                  >
                    Reference
                  </th>
                  <th
                    class="bg-gray-50
                      font-medium
                      leading-4
                      px-6
                      py-3
                      text-gray-500
                      text-left
                      text-xs
                      tracking-wider
                      uppercase"
                  >
                    Status
                  </th>
                  <th
                    class="bg-gray-50
                      font-medium
                      leading-4
                      px-6
                      py-3
                      text-gray-500
                      text-left
                      text-xs
                      tracking-wider
                      uppercase"
                  />
                </tr>
              </thead>
              <tbody>
                <!-- Odd row -->
                <tr
                  v-for="(booking, index) in bookings"
                  :key="booking.id"
                  class="bg-white"
                  :class="{ 'bg-gray-50': index % 2 }"
                >
                  <td class="font-medium leading-5 px-6 py-4 text-gray-900 text-sm whitespace-no-wrap">
                    {{ booking.organisation_name }}
                  </td>
                  <td class="leading-5 px-6 py-4 text-gray-500 text-sm whitespace-no-wrap">
                    {{ booking.reference_person }}
                  </td>
                  <td class="leading-5 px-6 py-4 text-gray-500 text-sm whitespace-no-wrap">
                    {{ booking.stripe_status }}
                  </td>
                  <td class="leading-5 px-6 py-4 text-gray-500 text-sm whitespace-no-wrap">
                    <button
                      class="hover:text-indigo-900 text-indigo-600"
                      @click="openPayment(booking)"
                    >
                      Öppna betalningslänk
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      bookings: [],
    }
  },
  methods: {
    async openPayment(booking) {
      const response = await axios.get(`/api/site-admin/international-bookings/payment-link/${booking.school_id}`)
      window.open(`/payment/perform/stripe/${response.data.jwt}?email=`)
    },
    load() {
      this.bookings = []
      setTimeout(() => {
        axios({
          url: '/api/site-admin/international-bookings',
        }).then(({ data }) => {
          this.bookings = data
        })
      }, 300)
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  text-align: center;
}
</style>
