


















































































































@import 'Less/variables.less';

.removeGroup {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__infoText {
    margin-bottom: @spacing-2;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__button {
      margin: @spacing-1;
    }
  }
}
