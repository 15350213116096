<template>
  <div
    v-sanitized-html="getIconWithHeight"
    class="svg-icons"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  props: {
    name: { type: String, default: '' },
    height: { type: String, default: '' },
  },
  data: function () {
    return {
      icons: {
        // exempel: <Icon :height="'21'" style="color: grey" :name="'magnifyingGlass'" />
        alert:
          '<svg viewBox="0 0 17 17" fill="none" > <path d="M8.3385 0.298828C3.9205 0.298828 0.338501 3.88083 0.338501 8.29883C0.338501 12.7168 3.9205 16.2988 8.3385 16.2988C12.7565 16.2988 16.3385 12.7168 16.3385 8.29883C16.3385 3.88083 12.7565 0.298828 8.3385 0.298828ZM7.3385 3.63216H9.3385L8.67183 10.2988H8.00517L7.3385 3.63216ZM8.3385 13.1322C7.8785 13.1322 7.50517 12.7588 7.50517 12.2988C7.50517 11.8388 7.8785 11.4655 8.3385 11.4655C8.7985 11.4655 9.17183 11.8388 9.17183 12.2988C9.17183 12.7588 8.7985 13.1322 8.3385 13.1322Z" fill="#F64C72"/> </svg> ',
        chat: '<svg viewBox="0 0 20 20"> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" > <g transform="translate(-470.000000, -508.000000)" fill="#1D2C51" fill-rule="nonzero" > <g transform="translate(470.000000, 508.000000)"> <path d="M18.3,10.04 L19.31,13.04 C19.4312717,13.3988929 19.3393314,13.7955007 19.0725202,14.0644294 C18.8057089,14.3333582 18.4098403,14.4284308 18.05,14.31 L15.04,13.31 C11.9644978,14.79092 8.26929494,13.8376047 6.29407291,11.0536622 C4.31885088,8.26971972 4.63964507,4.46703315 7.05333911,2.05333911 C9.46703315,-0.360354929 13.2697197,-0.681149123 16.0536622,1.29407291 C18.8376047,3.26929494 19.79092,6.96449778 18.31,10.04 L18.3,10.04 Z M9,8 C9.55228475,8 10,7.55228475 10,7 C10,6.44771525 9.55228475,6 9,6 C8.44771525,6 8,6.44771525 8,7 C8,7.55228475 8.44771525,8 9,8 Z M12,8 C12.5522847,8 13,7.55228475 13,7 C13,6.44771525 12.5522847,6 12,6 C11.4477153,6 11,6.44771525 11,7 C11,7.55228475 11.4477153,8 12,8 Z M15,8 C15.5522847,8 16,7.55228475 16,7 C16,6.44771525 15.5522847,6 15,6 C14.4477153,6 14,6.44771525 14,7 C14,7.55228475 14.4477153,8 15,8 Z" /> <path d="M13.88,15.8 C11.9621332,18.7629518 8.13839875,19.8346263 4.96,18.3 L1.96,19.31 C1.60110712,19.4312717 1.20449934,19.3393314 0.935570557,19.0725202 C0.666641778,18.8057089 0.571569155,18.4098403 0.69,18.05 L1.69,15.04 C0.400384196,12.383087 0.925250703,9.20183509 3,7.1 C3.03039133,9.79908009 4.2706109,12.3420109 6.37874998,14.0277472 C8.48688906,15.7134834 11.2403325,16.3640147 13.88,15.8 L13.88,15.8 Z" /> </g> </g> </g> </svg>',
        chat2:
          '<svg  viewBox="0 0 33 31" fill="none" > <path d="M16.5 0.375C7.78525 0.375 0 6.18575 0 14.1346C0 16.9534 1.01612 19.7213 2.81462 21.869C2.89025 24.3853 1.408 27.996 0.07425 30.625C3.652 29.9788 8.73812 28.5515 11.044 27.138C23.7435 30.2276 33 22.4561 33 14.1346C33 6.14313 25.1611 0.375 16.5 0.375ZM9.625 16.1875C8.48512 16.1875 7.5625 15.2649 7.5625 14.125C7.5625 12.9851 8.48512 12.0625 9.625 12.0625C10.7649 12.0625 11.6875 12.9851 11.6875 14.125C11.6875 15.2649 10.7649 16.1875 9.625 16.1875ZM16.5 16.1875C15.3601 16.1875 14.4375 15.2649 14.4375 14.125C14.4375 12.9851 15.3601 12.0625 16.5 12.0625C17.6399 12.0625 18.5625 12.9851 18.5625 14.125C18.5625 15.2649 17.6399 16.1875 16.5 16.1875ZM23.375 16.1875C22.2365 16.1875 21.3125 15.2649 21.3125 14.125C21.3125 12.9851 22.2365 12.0625 23.375 12.0625C24.5149 12.0625 25.4375 12.9851 25.4375 14.125C25.4375 15.2649 24.5149 16.1875 23.375 16.1875Z" fill="currentColor"/> </svg> ',
        attachment:
          '<svg viewBox="0 0 12 21" fill="currentColor"> <path d="M9.73475 4.95123V14.6545C9.73475 16.8838 7.92105 18.6975 5.69173 18.6975C3.4624 18.6975 1.6487 16.8838 1.6487 14.6545V4.95123C1.6487 3.61379 2.73708 2.52541 4.07452 2.52541C5.41195 2.52541 6.50033 3.61379 6.50033 4.95123V12.2287C6.50033 12.6742 6.13727 13.0373 5.69173 13.0373C5.24618 13.0373 4.88312 12.6742 4.88312 12.2287V5.75983H3.26591V12.2287C3.26591 13.5685 4.35187 14.6545 5.69173 14.6545C7.03158 14.6545 8.11754 13.5685 8.11754 12.2287V4.95123C8.11754 2.71867 6.30707 0.908203 4.07452 0.908203C1.84196 0.908203 0.0314941 2.71867 0.0314941 4.95123V14.6545C0.0314941 17.7805 2.56566 20.3147 5.69173 20.3147C8.81779 20.3147 11.352 17.7805 11.352 14.6545V4.95123H9.73475Z" fill="#A2A2A6"/> </svg> ',
        calculator:
          '<svg viewBox="0 0 24 24"> <path fill="currentColor" d="M7,2H17A2,2 0 0,1 19,4V20A2,2 0 0,1 17,22H7A2,2 0 0,1 5,20V4A2,2 0 0,1 7,2M7,4V8H17V4H7M7,10V12H9V10H7M11,10V12H13V10H11M15,10V12H17V10H15M7,14V16H9V14H7M11,14V16H13V14H11M15,14V16H17V14H15M7,18V20H9V18H7M11,18V20H13V18H11M15,18V20H17V18H15Z" /> </svg>',
        calculatorFalse:
          '<svg  viewBox="0 0 24 24"> <path fill="currentColor" d="M7 2C13 2 16.33 2 17 2C18.1 2 19 2.9 19 4C19 5.6 19 18.4 19 20C19 21.1 18.1 22 17 22C16 22 8 22 7 22C5.9 22 5 21.1 5 20C5 18.4 5 5.6 5 4C5 2.9 5.9 2 7 2M7 4L7 8L17 8L17 4L7 4M7 10L7 12L9 12L9 10L7 10M11 10L11 12L13 12L13 10L11 10M15 10L15 12L17 12L17 10L15 10M7 14L7 16L9 16L9 14L7 14M11 14L11 16L13 16L13 14L11 14M15 14L15 16L17 16L17 14L15 14M7 18L7 20L9 20L9 18L7 18M11 18L11 20L13 20L13 18L11 18M15 20L17 20L17 18L15 18L15 20Z" id="ctM1qJQ8"/><path d="M-4.5 -7.18L-1.87 -9.09L26.4 29.78L23.78 31.69L-4.5 -7.18Z" id="a1SI7O3iE"/>> <g><g><g><use xlink:href="#ctM1qJQ8" opacity="1" fill="#000000" fill-opacity="1"/><g><use xlink:href="#ctM1qJQ8" opacity="1" fill-opacity="0" stroke="#000000" stroke-width="1" stroke-opacity="0"/></g></g><g><use xlink:href="#a1SI7O3iE" opacity="1" fill="#000000" fill-opacity="0.56"/></g></g></g></svg>',
        checkmark:
          '<svg  viewBox="0 0 256 251" version="1.1"> /&gt; <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Question-part-Copy" transform="translate(-632.000000, -22.000000)" fill="#E5F3DF"> <path d="M669.251722 22L713.412258 22C682.315865 43.898083 662 80.0765291 662 121 662 187.826455 716.173544 242 782.999998 242 827.921332 242 867.125261 217.520886 888 181.174446L887.999997 231.190856C860.816068 257.096185 824.015301 273 783.499998 273 699.82886 273 632 205.17114 632 121.5 632 83.417669 646.051096 48.6171473 669.251722 22ZM852.587739 22L887.999997 22 887.999997 60.8255498C879.16226 45.4375581 867.039154 32.1766881 852.587739 22ZM797.999998 185L754.658645 185 705.942176 128.653536 745.999999 128.653536 771.421665 156.200094 812.999998 55 850.999998 55 797.999998 185Z" id="Combined-Shape"/> </g> </g> </svg>',
        checkmarkSimple:
          '<svg viewBox="0 0 67 50" fill="currentColor"> <path d="M25.2412 49.8448L0.491211 26.0903L8.16646 18.1978L25.0955 34.3513L58.673 0.152344L66.4912 7.89909L25.2412 49.8448Z" fill="currentColor"/> </svg> ',
        checkMarkCircle:
          '<svg  viewBox="0 0 17 16" fill="none" > <path d="M14.9092 7.05845C14.9665 7.42778 15.0052 7.80378 15.0052 8.18978C15.0052 12.2398 11.7218 15.5231 7.67183 15.5231C3.62183 15.5231 0.338501 12.2398 0.338501 8.18978C0.338501 4.13978 3.62183 0.856445 7.67183 0.856445C9.2365 0.856445 10.6838 1.35045 11.8745 2.18511L10.9265 3.15645C9.98783 2.54778 8.87183 2.18978 7.67183 2.18978C4.36383 2.18978 1.67183 4.88178 1.67183 8.18978C1.67183 11.4978 4.36383 14.1898 7.67183 14.1898C10.9345 14.1898 13.5912 11.5711 13.6652 8.32644L14.9092 7.05845ZM14.2745 1.63445L8.00517 8.06044L5.44317 5.65111L3.37983 7.71644L8.00517 12.1898L16.3385 3.69845L14.2745 1.63445Z" fill="#92DF2F"/> </svg> ',
        circle:
          '<svg viewBox="0 0 15 15" fill="currentColor" > <circle cx="7.49683" cy="7.08008" r="7" fill="currentColor"/> </svg> ',
        circleWithCross:
          '<svg  viewBox="0 0 16 16" fill="none" > <path d="M7.8385 1.93945C11.2848 1.93945 14.0885 4.7432 14.0885 8.18945C14.0885 11.6357 11.2848 14.4395 7.8385 14.4395C4.39225 14.4395 1.5885 11.6357 1.5885 8.18945C1.5885 4.7432 4.39225 1.93945 7.8385 1.93945ZM7.8385 0.689453C3.69663 0.689453 0.338501 4.04758 0.338501 8.18945C0.338501 12.3313 3.69663 15.6895 7.8385 15.6895C11.9804 15.6895 15.3385 12.3313 15.3385 8.18945C15.3385 4.04758 11.9804 0.689453 7.8385 0.689453ZM11.5885 10.7482L8.99038 8.1832L11.5529 5.59508L10.3973 4.43945L7.83163 7.03883L5.23413 4.47508L4.0885 5.62133L6.68913 8.19633L4.12413 10.7945L5.27038 11.9395L7.84413 9.34008L10.4335 11.9038L11.5885 10.7482Z" fill="#373F41"/> </svg> ',
        close:
          '<svg  viewBox="0 0 17 17" fill="currentColor"> <path d="M16.6191 14.9276L10.4965 8.86429L16.5578 2.74829L14.6698 0.876953L8.60981 6.99629L2.49247 0.93762L0.619141 2.81095L6.74314 8.88095L0.679807 15.0036L2.55314 16.877L8.62781 10.749L14.7478 16.8156L16.6191 14.9276Z" fill="currenColor"/> </svg> ',
        connection:
          '<svg viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9296 5.71043L12.8351 1.6951L13.9545 0.575684L19.1685 5.70964L13.9545 10.8674L12.8351 9.74793L16.9296 5.71043ZM2.40729 5.71043L6.50179 1.6951L5.38237 0.575684L0.168457 5.70964L5.38237 10.8674L6.50179 9.74793L2.40729 5.71043ZM6.10596 4.53402C6.76146 4.53402 7.29346 5.06602 7.29346 5.72152C7.29346 6.37702 6.76146 6.90902 6.10596 6.90902C5.45046 6.90902 4.91846 6.37702 4.91846 5.72152C4.91846 5.06602 5.45046 4.53402 6.10596 4.53402ZM13.231 4.53402C13.8865 4.53402 14.4185 5.06602 14.4185 5.72152C14.4185 6.37702 13.8865 6.90902 13.231 6.90902C12.5755 6.90902 12.0435 6.37702 12.0435 5.72152C12.0435 5.06602 12.5755 4.53402 13.231 4.53402ZM9.66846 4.53402C10.324 4.53402 10.856 5.06602 10.856 5.72152C10.856 6.37702 10.324 6.90902 9.66846 6.90902C9.01296 6.90902 8.48096 6.37702 8.48096 5.72152C8.48096 5.06602 9.01296 4.53402 9.66846 4.53402Z" fill="#EBA41B"/> </svg> ',
        exams:
          '<svg viewBox="0 0 20 20"> <path fill="currentColor" d="M5 18.3333V5H18.3333V11.2858C18.3333 14.7083 13.3333 13.3333 13.3333 13.3333C13.3333 13.3333 14.5983 18.3333 11.135 18.3333H5ZM20 11.9883V3.33333H3.33333V20H11.8242C14.46 20 20 13.9808 20 11.9883ZM11.6667 13.3333H7.5V12.5H11.6667V13.3333ZM15.8333 10H7.5V10.8333H15.8333V10ZM15.8333 7.5H7.5V8.33333H15.8333V7.5ZM17.5 1.66667H1.66667V17.5H0V0H17.5V1.66667Z" fill="white"/> </svg>',
        expandDown:
          '<svg viewBox="0 0 24 24"> <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /> </svg>',
        expandDownTriangle:
          '<svg viewBox="0 0 12 6"> <path fill="currentColor" d="M6.65079 5.44218C6.2763 5.76317 5.7237 5.76317 5.34921 5.44218L1.05247 1.75926C0.347568 1.15506 0.774851 2.38596e-07 1.70326 3.1976e-07L10.2967 1.07103e-06C11.2251 1.15219e-06 11.6524 1.15506 10.9475 1.75926L6.65079 5.44218Z" fill="none"/> </svg> ',
        expandUp:
          '<svg viewBox="0 0 24 24"> <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" /> </svg>',
        expandUpTriangle:
          '<svg viewBox="0 0 12 6"> <path fill="currentColor" d="M6.65079 0.557821C6.2763 0.23683 5.7237 0.236831 5.34921 0.557822L1.05247 4.24074C0.347568 4.84494 0.774851 6 1.70326 6L10.2967 6C11.2251 6 11.6524 4.84494 10.9475 4.24074L6.65079 0.557821Z" fill="none"/> </svg> ',
        image:
          '<svg viewBox="0 0 29 22" fill="currentColor"> <path d="M6.14925 6.93229C6.14925 5.96629 6.93325 5.18229 7.89925 5.18229C8.86525 5.18229 9.64925 5.96629 9.64925 6.93229C9.64925 7.89946 8.86525 8.68229 7.89925 8.68229C6.93325 8.68229 6.14925 7.89946 6.14925 6.93229ZM16.6493 7.51562L13.7104 12.1823L10.8159 9.89562L6.14925 16.849H22.4826L16.6493 7.51562ZM25.9826 2.84896V19.1823H2.64925V2.84896H25.9826ZM28.3159 0.515625H0.315918V21.5156H28.3159V0.515625Z" fill="currentColor"/> </svg> ',
        infoCircle:
          '<svg viewBox="0 0 21 20" fill="currentColor"> <path d="M10.6328 1.66667C15.2278 1.66667 18.9661 5.405 18.9661 10C18.9661 14.595 15.2278 18.3333 10.6328 18.3333C6.03781 18.3333 2.29948 14.595 2.29948 10C2.29948 5.405 6.03781 1.66667 10.6328 1.66667ZM10.6328 0C5.11031 0 0.632812 4.4775 0.632812 10C0.632812 15.5225 5.11031 20 10.6328 20C16.1553 20 20.6328 15.5225 20.6328 10C20.6328 4.4775 16.1553 0 10.6328 0ZM10.632 4.79167C11.207 4.79167 11.6745 5.25833 11.6745 5.83333C11.6745 6.40833 11.207 6.875 10.632 6.875C10.057 6.875 9.59115 6.40833 9.59115 5.83333C9.59115 5.25833 10.057 4.79167 10.632 4.79167ZM12.2995 15H8.96615V14.1667C9.36948 14.0175 9.79948 13.9992 9.79948 13.5542V9.83167C9.79948 9.38667 9.36948 9.31667 8.96615 9.1675V8.33417H11.4661V13.555C11.4661 14.0008 11.897 14.02 12.2995 14.1675V15Z" fill="currentColor"/> </svg> ',
        logout:
          '<svg viewBox="0 0 20 20"> <path fill="currentColor" d="M13.3333 7.50008V4.16675L20 10.0001L13.3333 15.8334V12.5001H6.66667V7.50008H13.3333ZM11.6667 15.8334V15.7642C10.685 16.3351 9.54833 16.6667 8.33333 16.6667C4.6575 16.6667 1.66667 13.6759 1.66667 10.0001C1.66667 6.32425 4.6575 3.33341 8.33333 3.33341C9.54833 3.33341 10.685 3.66508 11.6667 4.23591V2.36508C10.645 1.91841 9.51917 1.66675 8.33333 1.66675C3.73167 1.66675 0 5.39758 0 10.0001C0 14.6026 3.73167 18.3334 8.33333 18.3334C9.51917 18.3334 10.645 18.0817 11.6667 17.6351V15.8334Z" fill="white"/> </svg>',
        magnifyingGlass:
          '<svg  viewBox="0 0 21 20" > <path fill="currentColor" d="M20.3518 18.0383L15.181 12.8675C16.1535 11.53 16.7285 9.885 16.7285 8.10833C16.7285 3.6375 13.091 0 8.61932 0C4.14849 0 0.510986 3.6375 0.510986 8.10833C0.510986 12.58 4.14849 16.2167 8.61932 16.2167C10.3143 16.2167 11.8885 15.6942 13.1918 14.8017L18.3902 20L20.3518 18.0383ZM2.88932 8.10833C2.88932 4.94833 5.46015 2.3775 8.62015 2.3775C11.7802 2.3775 14.351 4.94833 14.351 8.10833C14.351 11.2683 11.7802 13.8392 8.62015 13.8392C5.45932 13.8392 2.88932 11.2683 2.88932 8.10833Z" fill="#E1E5E8"/> </svg> ',
        messageBell:
          '<svg viewBox="0 0 20 22" fill="none"> <path d="M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M11.73 20C11.5542 20.3031 11.3019 20.5547 10.9982 20.7295C10.6946 20.9044 10.3504 20.9965 10 20.9965C9.64964 20.9965 9.30541 20.9044 9.00179 20.7295C8.69818 20.5547 8.44583 20.3031 8.27002 20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg> ',
        messageBellInverted:
          '<svg viewBox="0 0 22 25" fill="none" > <path d="M17.5 7.7582C17.5 6.00777 16.8046 4.32904 15.5669 3.0913C14.3292 1.85356 12.6504 1.1582 10.9 1.1582C9.14957 1.1582 7.47083 1.85356 6.23309 3.0913C4.99535 4.32904 4.3 6.00777 4.3 7.7582C4.3 15.4582 1 17.6582 1 17.6582H20.8C20.8 17.6582 17.5 15.4582 17.5 7.7582Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12.8031 22.0586C12.6097 22.392 12.3321 22.6687 11.9981 22.8611C11.6641 23.0534 11.2855 23.1547 10.9001 23.1547C10.5147 23.1547 10.136 23.0534 9.80202 22.8611C9.46804 22.6687 9.19046 22.392 8.99707 22.0586" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg> ',
        play: '<svg viewBox="0 0 29 29" fill="currentColor"> <path d="M14.188 0.109375C6.45649 0.109375 0.187988 6.37787 0.187988 14.1094C0.187988 21.8409 6.45649 28.1094 14.188 28.1094C21.9195 28.1094 28.188 21.8409 28.188 14.1094C28.188 6.37787 21.9195 0.109375 14.188 0.109375ZM10.688 21.1094V7.10938L22.3547 14.1094L10.688 21.1094Z" fill="currentColor"/> </svg> ',
        playRound:
          '<svg viewBox="0 0 37 38" fill="currentColor"> <path d="M36.5535 19.1043C36.5535 29.1947 28.3736 37.3746 18.2832 37.3746C8.19283 37.3746 0.0129395 29.1947 0.0129395 19.1043C0.0129395 9.01387 8.19283 0.833984 18.2832 0.833984C28.3736 0.833984 36.5535 9.01387 36.5535 19.1043Z" fill="#369354"/> <path d="M25.3157 19.334L14.8157 26.834V11.834L25.3157 19.334Z" fill="white"/> </svg> ',
        playBackward:
          '<svg viewBox="0 0 22 22" fill="currentColor"> <path d="M11.052 0.0410156C8.15575 0.0410156 5.54738 1.22489 3.65038 3.12277L1.48038 0.952766L0.552002 7.37614L7.05938 6.53089L4.8815 4.35302C6.4635 2.77189 8.64313 1.79102 11.052 1.79102C15.8768 1.79102 19.802 5.71627 19.802 10.541C19.802 15.3658 15.8768 19.291 11.052 19.291C7.664 19.291 4.72663 17.352 3.27325 14.5284L1.77088 15.4436C3.53138 18.7713 7.02525 21.041 11.052 21.041C16.8506 21.041 21.552 16.3396 21.552 10.541C21.552 4.74239 16.8506 0.0410156 11.052 0.0410156Z" fill="#737B7D"/> </svg> ',
        playForward:
          '<svg viewBox="0 0 22 22" fill="currentColor"> <path d="M11.052 0.0410156C13.9483 0.0410156 16.5566 1.22489 18.4536 3.12277L20.6236 0.952766L21.552 7.37614L15.0446 6.53089L17.2225 4.35302C15.6405 2.77189 13.4609 1.79102 11.052 1.79102C6.22725 1.79102 2.302 5.71627 2.302 10.541C2.302 15.3658 6.22725 19.291 11.052 19.291C14.44 19.291 17.3774 17.352 18.8308 14.5284L20.3331 15.4436C18.5726 18.7713 15.0788 21.041 11.052 21.041C5.25338 21.041 0.552002 16.3396 0.552002 10.541C0.552002 4.74239 5.25338 0.0410156 11.052 0.0410156Z" fill="#737B7D"/> </svg> ',
        pauseRound:
          '<svg viewBox="0 0 37 38" fill="currentColor"> <path d="M36.5535 19.2644C36.5535 29.3548 28.3736 37.5347 18.2832 37.5347C8.19283 37.5347 0.0129395 29.3548 0.0129395 19.2644C0.0129395 9.17403 8.19283 0.994141 18.2832 0.994141C28.3736 0.994141 36.5535 9.17403 36.5535 19.2644Z" fill="#369354"/> <rect x="13.0657" y="10.9629" width="4.35565" height="15.0611" rx="2.17783" fill="white"/> <rect x="19.9691" y="10.9629" width="4.35565" height="15.0611" rx="2.17783" fill="white"/> </svg> ',
        progressBar:
          '<svg  viewBox="0 0 36 14" fill="none" > <path fill-rule="evenodd" clip-rule="evenodd" d="M4.69226 1.24414C3.03541 1.24414 1.69226 2.58729 1.69226 4.24414V9.82424C1.69226 11.4811 3.03541 12.8242 4.69226 12.8242H31.0012C32.6581 12.8242 34.0012 11.4811 34.0012 9.82424V4.24414C34.0012 2.58729 32.6581 1.24414 31.0012 1.24414H4.69226ZM0.692261 4.24414C0.692261 2.035 2.48312 0.244141 4.69226 0.244141H31.0012C33.2104 0.244141 35.0012 2.035 35.0012 4.24414V9.82424C35.0012 12.0334 33.2104 13.8242 31.0012 13.8242H4.69226C2.48312 13.8242 0.692261 12.0334 0.692261 9.82424V4.24414Z" fill="currentColor"/> <path d="M1.19226 4.69623C1.19226 2.643 2.96249 0.978516 5.14616 0.978516L18.9107 1.19784V12.8843L5.14616 13.0722C2.96249 13.0722 1.19226 11.4078 1.19226 9.35459V4.69623Z" fill="currentColor"/> </svg> ',
        questionmark:
          '<svg viewBox="0 0 21 20" fill="currentColor"> <path d="M10.7732 2.35612C15.3682 2.35612 19.1065 5.34945 19.1065 9.02862C19.1065 13.1261 14.819 15.6628 10.8482 15.6628C9.23403 15.6628 8.02903 15.332 7.18653 15.1261C6.35319 15.637 5.85736 15.9903 3.62653 16.6428C4.07236 15.4986 4.22903 14.3528 4.12819 13.0886C3.42986 12.2553 2.44069 11.0886 2.44069 9.02862C2.43986 5.34945 6.17819 2.35612 10.7732 2.35612ZM10.7732 0.689453C5.49153 0.689453 0.773193 4.21112 0.773193 9.02862C0.773193 10.737 1.38819 12.4145 2.47903 13.7161C2.52486 15.2411 1.62653 17.4295 0.818193 19.0228C2.98653 18.6311 6.06903 17.7661 7.46653 16.9095C8.64819 17.197 9.77903 17.3286 10.849 17.3286C16.7524 17.3286 20.774 13.2978 20.774 9.02779C20.7732 4.18529 16.0224 0.689453 10.7732 0.689453ZM11.6265 12.3353C11.6265 12.807 11.2449 13.1895 10.7732 13.1895C10.3024 13.1895 9.91986 12.807 9.91986 12.3353C9.91986 11.8645 10.3024 11.482 10.7732 11.482C11.2449 11.482 11.6265 11.8645 11.6265 12.3353ZM12.5774 5.50862C12.1624 5.08779 11.5432 4.85612 10.8357 4.85612C9.34736 4.85612 8.38486 5.91529 8.38486 7.55362H9.75736C9.75736 6.53945 10.324 6.17945 10.8082 6.17945C11.2407 6.17945 11.6999 6.46695 11.739 7.01612C11.7824 7.59445 11.4732 7.88779 11.0824 8.26029C10.1182 9.17695 10.0999 9.62112 10.1049 10.6286H11.474C11.4657 10.1753 11.4949 9.80695 12.1124 9.14112C12.5749 8.64279 13.1499 8.02279 13.1615 7.07695C13.1682 6.44529 12.9674 5.90279 12.5774 5.50862Z" fill="currentColor"/> </svg> ',
        questionmarkRound:
          '<svg viewBox="0 0 21 21" fill="none"> <path d="M10.4546 2.40885C15.0496 2.40885 18.7879 6.14719 18.7879 10.7422C18.7879 15.3372 15.0496 19.0755 10.4546 19.0755C5.85959 19.0755 2.12126 15.3372 2.12126 10.7422C2.12126 6.14719 5.85959 2.40885 10.4546 2.40885ZM10.4546 0.742188C4.93209 0.742188 0.45459 5.21969 0.45459 10.7422C0.45459 16.2647 4.93209 20.7422 10.4546 20.7422C15.9771 20.7422 20.4546 16.2647 20.4546 10.7422C20.4546 5.21969 15.9771 0.742188 10.4546 0.742188ZM11.4963 14.9089C11.4963 15.4839 11.0304 15.9505 10.4546 15.9505C9.88042 15.9505 9.41292 15.4839 9.41292 14.9089C9.41292 14.3339 9.88042 13.8672 10.4546 13.8672C11.0304 13.8672 11.4963 14.3339 11.4963 14.9089ZM12.6571 6.57719C12.1504 6.06385 11.3946 5.78135 10.5313 5.78135C8.71459 5.78135 7.53959 7.07302 7.53959 9.07302H9.21542C9.21542 7.83469 9.90626 7.39552 10.4971 7.39552C11.0254 7.39552 11.5863 7.74635 11.6338 8.41719C11.6854 9.12302 11.3088 9.48135 10.8321 9.93469C9.65542 11.0539 9.63376 11.5955 9.63876 12.8247H11.3096C11.2988 12.2714 11.3346 11.8222 12.0888 11.0097C12.6529 10.4014 13.3546 9.64469 13.3688 8.49135C13.3779 7.72135 13.1321 7.05885 12.6571 6.57719Z" fill="currentColor"/> </svg> ',
        resultat:
          '<svg viewBox="0 0 20 20" > <path fill="currentColor" d="M4.15303 15.8333H0.863027V12.5H4.15303V15.8333ZM9.08802 15.8333H5.79802V9.16666H9.08802V15.8333ZM14.023 15.8333H10.733V5H14.023V15.8333ZM18.958 15.8333H15.668V0H18.958V15.8333ZM19.7805 17.5H0.0405273V19.1667H19.7805V17.5Z" fill="#A2A2A6"/> </svg> ',
        trash:
          '<svg viewBox="0 0 32 32"> <path fill="currentColor" d="M4 10v20c0 1.1 0.9 2 2 2h18c1.1 0 2-0.9 2-2v-20h-22zM10 28h-2v-14h2v14zM14 28h-2v-14h2v14zM18 28h-2v-14h2v14zM22 28h-2v-14h2v14z"></path> <path fill="currentColor" d="M26.5 4h-6.5v-2.5c0-0.825-0.675-1.5-1.5-1.5h-7c-0.825 0-1.5 0.675-1.5 1.5v2.5h-6.5c-0.825 0-1.5 0.675-1.5 1.5v2.5h26v-2.5c0-0.825-0.675-1.5-1.5-1.5zM18 4h-6v-1.975h6v1.975z"></path></svg>',
        takeBack:
          '<svg  viewBox="0 0 20 15" fill="currentColor"> <path d="M19.2517 7.34525V14.2481H0.872803V7.34525L5.06703 0.463867H6.84136L2.66015 7.35597H6.23333L7.95635 9.65334H12.1682L13.8912 7.35597H17.4667L13.2801 0.463867H15.0751L19.2517 7.34525ZM11.5939 4.29281H13.8912L10.0623 0.463867L6.23333 4.29281H8.53069V8.12176H11.5939V4.29281Z" fill="#A2A2A6"/> </svg> ',
        volume:
          '<svg  viewBox="0 0 21 17" fill="currentColor" > <path d="M3.06763 5.99349V10.9935H2.23429V5.99349H3.06763ZM13.901 0.160156L6.40096 4.32682V6.23349L12.2343 2.99266V13.9943L6.40096 10.7535V12.6602L13.901 16.8268V0.160156ZM4.73429 4.32682H0.567627V12.6602H4.73429V4.32682ZM15.5743 6.07099C16.1926 6.69016 16.5743 7.54599 16.5726 8.49016C16.571 9.43432 16.1876 10.2885 15.5685 10.9068L16.5843 11.926C17.4651 11.0485 18.011 9.83349 18.0135 8.49182C18.0151 7.14932 17.4735 5.93516 16.5951 5.05432L15.5743 6.07099ZM17.3593 4.29182C18.4326 5.36849 19.0943 6.85266 19.0918 8.49266C19.0893 10.1327 18.4218 11.6177 17.3451 12.6918L18.3868 13.7368C19.7301 12.3968 20.5643 10.5418 20.5668 8.49432C20.571 6.44682 19.7451 4.59349 18.4043 3.25016L17.3593 4.29182Z" fill="currentColor"/> </svg> ',
        warning:
          '<svg  viewBox="0 0 16 16"> <g> <path d="M7.53027 1.94336C10.9765 1.94336 13.7803 4.74711 13.7803 8.19336C13.7803 11.6396 10.9765 14.4434 7.53027 14.4434C4.08402 14.4434 1.28027 11.6396 1.28027 8.19336C1.28027 4.74711 4.08402 1.94336 7.53027 1.94336ZM7.53027 0.693359C3.3884 0.693359 0.0302734 4.05148 0.0302734 8.19336C0.0302734 12.3352 3.3884 15.6934 7.53027 15.6934C11.6721 15.6934 15.0303 12.3352 15.0303 8.19336C15.0303 4.05148 11.6721 0.693359 7.53027 0.693359ZM6.71152 5.39711C6.64965 4.89273 7.04152 4.44336 7.55402 4.44336C8.0359 4.44336 8.41465 4.86586 8.35402 5.35023L7.8809 9.13336C7.85902 9.31023 7.7084 9.44336 7.53027 9.44336C7.35215 9.44336 7.20152 9.31023 7.17902 9.13336L6.71152 5.39711ZM7.53027 12.0996C7.09902 12.0996 6.74902 11.7496 6.74902 11.3184C6.74902 10.8871 7.09902 10.5371 7.53027 10.5371C7.96152 10.5371 8.31152 10.8871 8.31152 11.3184C8.31152 11.7496 7.96152 12.0996 7.53027 12.0996Z" fill="#F64C72"/> </g> <defs> <clipPath id="clip0"> <rect width="15" height="15" fill="white" transform="translate(0.0302734 0.693359)"/> </clipPath> </defs> </svg> ',
      },
    }
  },

  computed: {
    // Height needs to be set inline because of Safari, if no height is set from outside it is set to viewbox height
    getIconWithHeight() {
      const str = this.icons[this.name]
      const viewBox = str.substring(
        str.indexOf('viewBox="') + 9,
        str.indexOf('">'),
      )
      const viewBoxParameters = viewBox.split(' ')
      const heightOfViewBox = viewBoxParameters[viewBoxParameters.length - 1]
      const height = this.height !== '' ? this.height : heightOfViewBox
      const iconWithHeight =
        str.slice(0, 5) + 'height=' + height + ' ' + str.slice(5)
      return iconWithHeight
    },
  },
}
</script>
<style scoped>
div {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
