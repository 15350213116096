



























































































































































































































































































































































































































































































































@import 'Less/variables.less';

.audio {
  text-align: center;
  padding: 0;
  padding-top: 18px;

  &__button {
    width: 80px;
    font-weight: 100;
    text-transform: none;
    font-size: 13px;
    line-height: 1.5;
    padding: 4px 10px 5px;
    border: none;
    border-radius: 300px;
    white-space: nowrap;
    color: #fff;

    &--play {
      background: #35aa47;

      &:hover {
        background: #2d8f3c;
      }
    }

    &--pause {
      background: #c63927;
    }
  }
}

.header {
  margin-top: 0;
  padding-top: 10px;
}

.speak-button-wrapper {
  display: flex;
  margin: 16px;
  text-align: center;

  span {
    font-size: 10px;
    font-weight: 700;
  }
}

.speak-button {
  margin: auto;
  border: 2px solid black;
  box-shadow: 3px 5px 4px #010101;
  cursor: pointer;
  background: @theme-primary;
  border-radius: 10px;

  i {
    padding-top: 10px;
    font-size: 36px;
  }

  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: 1px 1px 1px #010101;
  }
}

.info {
  text-align: center;
  font-size: 10px;
  padding-bottom: 30px;
}

.wrapper {
  background: #ffb202;
  border-top: 1px solid black;
}

select {
  width: 80%;
  margin: auto;
}

audio {
  width: 100%;
}

.timer {
  font-size: 20px;
  margin-top: 2px;
}

.scrub {
  flex: 1;
  height: 26px;
  background: #ffb202;
  margin: 3px 10px;
  border: 1px solid #395ba8;

  &:hover {
    cursor: pointer;
  }

  div {
    background: #395ba8;
    width: 0;
    height: 100%;
  }
}

.audioSelect {
  background: #395ba8;
  width: 200px;
  color: white;
  border: none;
}

.usbButton {
  position: absolute;
  top: 37px;
  left: 5px;
}
