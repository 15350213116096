






























































































































.AttachmentPreview {
  position: relative;
  height: 140px;
  width: 130px;
  margin: var(--spacing-3);
  border-radius: var(--radius-default);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  font-size: var(--font-size-xs);
  background: white;
  cursor: pointer;

  &__name-input {
    background-color: #385ba8;
    position: absolute;
    bottom: 0;
    z-index: 4;
    border: 0;
    border-bottom-left-radius: var(--radius-default);
    border-bottom-right-radius: var(--radius-default);
    color: white;
    font-size: var(--font-size-xs);
    text-align: left;
    outline: none;
    width: 100%;
    height: 28px;
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &::placeholder {
      color: var(--gray-500);
    }
  }

  &__type-icon {
    position: absolute;
    top: calc(var(--spacing-1) * -1);
    left: calc(var(--spacing-1) * -1);
    z-index: 2;
    color: white;
    cursor: default;

    img {
      max-width: 30px;
      max-height: 30px;
    }
  }

  &__icon {
    font-size: var(--font-size-md);
    color: var(--blue-900);
    background: white;
    padding: var(--spacing-1);
    border-radius: var(--radius-default);
    cursor: pointer;
    box-shadow: var(--shadow-md);
    line-height: normal;
    pointer-events: auto;
  }

  &__thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--radius-default);
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--shadow-md);

    &:hover {
      box-shadow: var(--shadow-lg);

      img {
        width: 140px;
      }
    }

    img {
      width: 130px;
    }
  }

  &__name {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: left;
    padding: var(--spacing-1) var(--spacing-2);
    font-size: var(--font-size-xs);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: var(--radius-default);
    border-bottom-left-radius: var(--radius-default);
    background-color: #385ba8;
    color: white;
    line-height: 1;
    z-index: 4;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;

    span,
    i {
      position: relative;
    }
  }
}

.fa-trash {
  position: absolute;
  top: calc(var(--spacing-2) * -1);
  right: calc(var(--spacing-2) * -1);
  z-index: 2;
}

.animation {
  transition: var(--transition-all);
}
