
























































































































































































































































































































































































































































































































































































































































































































































































































































// @import '~style/main.less';
@height: 30px;
@tablet-width: 1024px;
@mobile-width: 480px;
@show-only-icon-width: 1300px;

.wantedPart {
  &__students {
    padding: 5px;
  }

  &__button {
    margin: 4px;
  }
}

.top-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // background-color: @blueberry;
  // color: white;
  // padding: 20px 40px;
  border: 1px solid @greyish;
  border-radius: 8px;
  margin-bottom: 20px;

  & > .header {
    .body2;

    height: 50px;
    background-color: #f2f2f2;
    width: 100%;
    display: flex;
    text-align: center;

    & > div.explanation {
      .caption();

      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > div {
        cursor: default;
      }
    }
  }

  & > .values {
    .headline2;

    height: 60px;
    width: 100%;
    display: flex;
    text-align: center;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.teacher-functions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-color: @lightGreen;
  color: @lightGreyDark;
  padding: 0;

  .hr {
    min-width: 100%;
    border-bottom: 1px solid @lightGreyDark;
  }

  & > .content {
    display: flex;
    flex-direction: column;

    .utility-wrapper {
      display: flex;
      margin: 20px 0;
    }
  }
}
////////////////************ */
.mulish() {
  font-family: Mulish, sans-serif;
  font-style: normal;
}

.headline3() {
  .mulish;

  font-weight: normal;
  font-size: 25px;
  line-height: 31px;
}

.headline3 {
  .headline3;
}

.headline1 {
  .mulish;

  font-weight: 100;
  font-size: 50px;
  line-height: 63px;

  @media (max-width: @mobile-width) {
    // headline3
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
  }
}
.headline2() {
  .mulish;

  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.25px;
}

.headline2 {
  .headline2();
}

.headline4 {
  .mulish;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

/* MULISH REGULAR 17/23px, SPACING 0.15px */
.subtitle1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
}

.subtitle1 {
  .subtitle1();
}

.subtitle2() {
  .mulish;

  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.subtitle2 {
  .subtitle2;
}

.body1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.body1 {
  .body1();
}
.body2() {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.body2 {
  .body2();
}

.button {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 1.25px;
}

.caption() {
  .mulish;

  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.caption {
  .caption;
}

.overline {
  .mulish;

  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}
@blackish: #373f41;
@blueberry: #2c415a;
@midnight-blue: #1b304a;
@km-green: #369354;
@km-green-hover: #48a466;
@navy-blue: #3cbeeb;
@lime: #92df2f;
@lightGreen: #f3fbdd;
@peach: #ff9292;
@sun-bleached: #fbeda0;
@greyish: #e1e5e8;
@lightGreyDark: #737b7d;
@lightGreyBetween: #c3cbcd;
@lightGrey: #dbdde0;
@light: #efefef;
@backgroundLight: #f5f7f9;
