
































































































.DeleteStudent {
  .deleteStudentModal {
    &__action {
      margin-bottom: var(--spacing-2);
    }
  }
}
