






























































































































































































































































































































































.StudentList {
  --student-list-offset: var(--spacing-3);

  display: flex;
  flex-direction: column;
  overflow: visible; // TODO - this is needed so icons are visible in the right

  .name-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: var(--spacing-4);
    margin-left: var(--spacing-2);

    &__button {
      margin-top: 0;
      margin-right: var(--spacing-2);
    }

    &__text {
      font-size: var(--font-size-xs);
    }
  }

  .chat-notification {
    font-size: 10px;
    flex: 1;
    z-index: var(--z-layer-2);
    animation: chat-notification 2s ease infinite;

    &__exclamation {
      color: white;
    }

    &__chat-bubble {
      color: var(--yellow-500);
    }
  }

  .students-by-status {
    flex: 1;

    &__container {
      overflow-y: auto;
      overscroll-behavior: contain;
      padding-left: var(--student-list-offset);
    }

    h4 {
      margin: 0;
      padding-top: var(--spacing-2);
      padding-bottom: var(--spacing-1);
      font-size: var(--font-size-normal);
      font-weight: var(--font-bold);
      position: sticky;
      top: 0;
      background-color: var(--gray-200);
      z-index: var(--z-layer-2);
    }

    &__students {
      &__student {
        --student-border: var(--border-width-1) solid var(--gray-300);

        z-index: var(--z-layer-1);
        border: none;
        position: relative;
        border-bottom: var(--student-border);
        width: 100%;
        display: flex;
        align-items: center;
        background: transparent;
        text-align: left;
        height: var(--height-8);
        padding: var(--spacing-1) 0;
        white-space: nowrap;

        &:first-of-type {
          border-top: var(--student-border);
        }

        &--previous {
          border-bottom: 1px solid transparent;
        }

        &--active {
          background-color: var(--gray-300);
          border-radius: var(--radius-default)
        }

        &__icon {
          position: absolute;
          z-index: var(--z-layer-2);
          left: calc(var(--student-list-offset) * -1);
          width: var(--width-3);
          height: var(--height-3);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }

        &__name {
          margin-left: var(--spacing-1);
        }
      }
    }
  }

  .list-button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    text-align: left;
    border-radius: var(--radius-lg);
    padding: var(--spacing-1) var(--student-list-offset);
    white-space: nowrap;

    &--active {
      background-color: var(--gray-300);
    }
  }

  .home-button.list-button {
    width: 100%;
    font-weight: var(--font-bold);
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-4);

    i {
      font-size: var(--font-size-xl);
      margin-right: var(--spacing-1);
    }
  }

  .student-search {
    margin-left: var(--student-list-offset)
  }
}

@keyframes chat-notification {
  50% { transform: scale(1.15); }
}

