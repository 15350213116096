



























































.exam-list-main {
  position: relative;
  background-color: #fff;
  margin-bottom: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
}

.exam-list-header {
  top: 0;
  height: 60px;
  background-color: rgb(31, 50, 92);
  color: #fff;
  line-height: 60px;
  font-size: 30px;
  margin-bottom: 0;
}

.exam-entry {
  padding: 20px;
  background: white;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
}
