









































































































































































































































































































































// @import '~style/main.less';

#audioplayer {
  display: flex;
  align-items: center;
  height: 60px;
  border: 1px solid #d7d8d8;
  border-radius: 30px;
  padding: 0 20px;
}

#currentTimeDisplayed {
  color: @blackish;
  font-family: Mulish, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
}

#timeline {
  position: relative;
  height: var(--heightTimeLine);
  border-radius: 15px;
  background: #f4f5f4;
}

#playheadbefore {
  position: absolute;
  top: calc(-1 * var(--heightTimeLine));
  height: var(--heightTimeLine);
  margin-top: var(--heightTimeLine);
  border-radius: 15px;
  background-color: #369354;
}

#playhead {
  position: absolute;
  width: var(--heightTimeLine);
  height: var(--heightTimeLine);
  border-radius: 50%;
  // margin-top: 1px;
  background: #369354;
}

#timeline:hover #playhead {
  background-color: #373f41;
  cursor: pointer;
  z-index: 1;
}

.number-inside-play-forward-backward {
  position: absolute;
  text-align: center;
  width: 26px;
  height: 26px;
  top: 7px;
  left: 0;
  font-size: 8px;
  cursor: pointer;
}

.volume {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 10px;
  width: 20px;
  margin-left: 20px;
  direction: rtl;

  .volume-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    height: 30px;
    width: 20px;

    & > .volume-bar {
      position: absolute;
      direction: ltr;
      // transform: translateX(-100px);

      opacity: 0;
      top: 12px;
      left: 5px;
      width: 0;
      height: 6px;
      background: #c7c6c6;
      border-radius: 4px;

      &.show {
        width: 50px;
        opacity: 1;
        transition: opacity 0.7s ease-in-out, width 0.1s;
      }
    }

    &.mouse-over {
      // padding-left: 60px;
      width: 100px;
      // transform: translateX(-60px);
      background-color: @greyish;
      border-radius: 15px;
      transition: width 0.5s;
    }
  }
}

////////////////************ */

@height: 30px;
@tablet-width: 1024px;
@mobile-width: 480px;
@show-only-icon-width: 1300px;
.mulish() {
  font-family: Mulish, sans-serif;
  font-style: normal;
}

.headline3() {
  .mulish;

  font-weight: normal;
  font-size: 25px;
  line-height: 31px;
}

.headline3 {
  .headline3;
}

.headline1 {
  .mulish;

  font-weight: 100;
  font-size: 50px;
  line-height: 63px;

  @media (max-width: @mobile-width) {
    // headline3
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
  }
}
.headline2() {
  .mulish;

  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.25px;
}

.headline2 {
  .headline2();
}

.headline4 {
  .mulish;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

/* MULISH REGULAR 17/23px, SPACING 0.15px */
.subtitle1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
}

.subtitle1 {
  .subtitle1();
}

.subtitle2() {
  .mulish;

  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.subtitle2 {
  .subtitle2;
}

.body1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.body1 {
  .body1();
}
.body2() {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.body2 {
  .body2();
}

.button {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 1.25px;
}

.caption() {
  .mulish;

  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.caption {
  .caption;
}

.overline {
  .mulish;

  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}
@blackish: #373f41;
@blueberry: #2c415a;
@midnight-blue: #1b304a;
@navy-blue: #1395be;
@lime: #92df2f;
@lightGreen: #f3fbdd;
@peach: #ff9292;
@sun-bleached: #fbeda0;
@greyish: #e1e5e8;
@lightGreyDark: #737b7d;
@lightGreyBetween: #c3cbcd;
@lightGrey: #dbdde0;
@light: #efefef;
@backgroundLight: #f5f7f9;
