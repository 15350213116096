



































@import 'Less/variables.less';

.radioButton {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: @spacing-5;
  margin-bottom: @spacing-3;

  &__input {
    height: @spacing-5;
    min-width: @spacing-5;
    border-radius: @radius-round;
    border: @border-thickness-thick solid @color-grey;
    box-sizing: border-box;
    cursor: pointer;
    margin-right: @spacing-4;
  }

  &__text {
    flex: 1;
  }
}

.selected {
  border: none;
  background-color: #5bde44;
}
