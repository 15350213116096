
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.Overview {
  &__TrainingVideos {
    width: 300px;

    &__title {
      font-weight: var(--font-semibold);
    }

    &__video {
      margin-top: @spacing-2;
    }

    &__icon {
      font-size: @font-size-x-large;
      cursor: pointer;
      margin-right: @spacing-3;
      margin-top: @spacing-6;
    }
  }
}

.wantedPart {
  &__students {
    padding: 5px;
  }

  &__button {
    margin: 4px;
  }
}

img.print-icon {
  margin-top: 8px;
  height: 50px;
}

.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.exam-key-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: none;
  padding: 8px 14px;
  background-color: #eee;
  border-radius: 5px;
  border: none;
  font-family: Verdana, sans-serif;
  font-weight: 100;
  margin-right: var(--spacing-5);

  a {
    margin-right: var(--spacing-3);
    font-size: var(--font-size-md);
    color: var(--font-gray-900);
  }
}

.discoverable {
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  color: #1d2d50;
}

.open-close-wrapper {
  display: flex;
  align-items: center;
}

.exam-key-settings__dropdown {
  position: relative;
  z-index: var(--z-layer-1);

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: var(--border-width-1) solid var(--gray-600);

    i {
      margin-left: var(--spacing-2);
    }

    padding: var(--spacing-1) var(--spacing-4);
    border-radius: var(--radius-round);
    background-color: var(--gray-100);
    transition: var(--transition-all);

    &--open,
    &:hover {
      background-color: #fff;
      border: var(--border-width-1) solid var(--yellow-500);
    }
  }

  &__options {
    position: absolute;
    top: calc(100% + var(--spacing-1));
    left: 0;
    width: var(--width-72);
    padding: var(--spacing-3) 0;
    border: var(--divider-border);
    border-radius: var(--radius-lg);
    background-color: #fff;
    box-shadow: var(--shadow-md);

    &__option {
      border: none;
      background-color: #fff;
      padding: var(--spacing-2) var(--spacing-4);
      width: 100%;
      cursor: pointer;
      text-align: left;

      &:hover {
        background-color: var(--gray-100);
      }

      i {
        text-align: center;
        width: var(--width-4);
        margin-right: var(--spacing-1);
      }
    }
  }
}

.exam-key-settings {
  display: inline-block;
  margin-left: 10px;
}

.archived-info {
  font-style: italic;
}

.status__status-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
