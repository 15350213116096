




















































@import 'Less/variables.less';

.mainButton {
  margin-top: 15px;
  margin-right: 15px;

  &__button {
    border-radius: 300px;
    font-weight: 600;
    line-height: 1.33;
  }
}

.flex {
  display: flex;
}

.centerButton {
  display: flex;
  justify-content: center;
}

.secondaryStyle {
  font-size: 14px;
  padding: 5px 40px;
  border: 1px solid @theme-blue;
  color: @theme-blue;
  text-decoration: bold;
  text-transform: none;
  background-color: transparent;
}

.lessPadding {
  padding: 5px 10px;
}

.secondaryStyle:hover {
  color: @theme-primary;
  border: 1px solid @theme-primary;
}

.primaryStyle {
  font-size: 14px;
  padding: 5px 40px;
  background-color: @theme-blue;
  text-decoration: bold;
  color: #fff;
  border: 1px solid #fff;
  text-transform: none;
}

.primaryStyle:hover {
  background-color: @theme-primary;
}

.dangerStyle {
  font-size: 14px;
  padding: 5px 40px;
  background-color: #d84a38;
  text-decoration: bold;
  color: #fff;
  text-transform: none;
}

.dangerStyle:hover {
  background-color: #9a1818;
}

.disabledStyle {
  background-color: #eee;
  color: #757575;
  cursor: not-allowed;

  &:hover {
    background-color: #eee;
    color: #757575;
  }
}

.animation {
  transition: all 0.2s ease-in-out;
}

.big {
  font-size: 22px;
}

.small {
  font-size: 12px;
}

.box {
  height: 50px;
  border-radius: 5px;
}
