<template>
  <div
    :class="{ disabled }"
    class="toggleButton"
    @click="toggle"
  >
    <div
      ref="background"
      class="animation toggleButton-background"
      :class="{
        backgroundActivated: checked,
      }"
    >
      <div
        ref="dot"
        class="animation toggleButton--dot"
        :class="{ dotActivated: checked }"
      />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    checked: {
      type: [Boolean, Number, String],
      default: null,
    },
    manual: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      if (this.disabled) {
        return
      }
      if (this.manual === true) {
        this.$emit('input', this.checked)
      } else {
        this.$emit('input', !this.checked)
      }

      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.toggleButton {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  min-height: 20px;
  min-width: 40px;
  margin-top: 15px;
  padding: none;
  cursor: pointer;
}

.toggleButton-background {
  position: relative;
  width: 51px;
  height: 26px;
  top: 0;
  left: 0;
  margin: none;
  margin-left: 0;
  background-color: #d8d8d8;
  border-radius: 13px;
  box-sizing: border-box;
}

.backgroundActivated {
  background-color: #5bde44;
}

.toggleButton--dot {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

.dotActivated {
  left: 27px;
}

.animation {
  transition: all 0.2s ease-in-out;
}

.disabled {
  filter: blur(1px);
  cursor: not-allowed;
}
</style>
