






























































@import 'Less/variables.less';

/* The container */
.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  user-select: none;
}

/* Hide the browser's default radio button */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  border: 1px solid @theme-blue;
}

/* When the radio button is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: #5bde44;
  border: 1px solid #5bde44;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark::after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark::after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  border-radius: 1px;
  transform: rotate(45deg);
}
