
































































@import 'Less/variables.less';

.student-chat {
  width: 250px;
  height: 320px;

  &__instruction-message {
    color: #757575;
    text-align: center;
    padding: @spacing-2 0;
  }
}
