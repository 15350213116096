

















































































































































































































































































































































































































































































@import 'Less/variables.less';

.thesaurus-window {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 520px;
  width: 480px;

  &__loader {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    display: flex;
    border-bottom: @divider-border;

    &__search {
      flex: 1;
      display: flex;
      align-items: center;
      padding: @spacing-2 @spacing-5;
    }
  }

  &__results {
    flex: 1;
    overflow-y: auto;

    &__no-results,
    &__no-results-suggestions {
      width: 20em;
      margin: 0 auto;
    }

    &__no-results {
      text-align: center;
    }

    &__no-results-suggestions {
      &__link {
        display: block;
        margin: 0;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .search-bar {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    &__input {
      width: 100%;
      min-height: @spacing-8;
      padding: @spacing-1;
      padding-left: @spacing-3;
      padding-right: @spacing-9;
      box-sizing: border-box;
      transition: @animation;
      background-color: @color-grey-light;
      border-radius: @radius-xl;
      border: 1px solid transparent;

      &:focus {
        outline: none;
        background-color: @color-grey-super-light;
        border: 1px solid @color-grey-light;
      }
    }

    &__button {
      position: absolute;
      right: @spacing-2;
    }
  }

  .result {
    counter-reset: lexeme;
    padding: @spacing-6;
    border-bottom: @divider-border;

    &:nth-child(odd) {
      background-color: @color-grey-super-light;
    }

    &__header {
      &__title {
        h3 {
          display: inline;
          font-size: @font-size-large;
          margin: 0;

          &::first-letter {
            text-transform: uppercase;
          }
        }

        &__type {
          font-weight: 700;
        }
      }

      &__versions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: @spacing-2;

        &__version:not(:last-of-type) {
          display: flex;
          align-items: center;

          &::after {
            content: '\00B7';
            font-weight: 700;
            padding: 0 @spacing-1;
          }
        }
      }
    }

    &__lexemes {
      &__lexeme {
        counter-increment: lexeme;
      }
    }

    &__header-info {
      padding: @spacing-4 0;

      &__item {
        padding: @spacing-1;
        font-size: @font-size-small;

        b {
          color: @color-grey-dark;
        }
      }
    }
  }
}
