







































































































































































.ChatWindow {
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__icon-loading {
    margin-left: var(--spacing-2);
    color: white;
  }

  &__icon-failed {
    margin-left: var(--spacing-2);
    color: var(--red-700);
  }

  &__messages {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    padding: var(--spacing-2);
  }

  &__message-container {
    display: flex;
    flex-direction: column;
  }

  &__message {
    padding: var(--spacing-1) var(--spacing-3);
    background-color: var(--gray-100);
    align-self: flex-start;
    width: auto;
    border-radius: var(--spacing-2);
    border-bottom-left-radius: 0;
    margin: var(--spacing-1) 0;
    word-break: break-word;

    &__timestamp {
      align-self: flex-start;
      font-size: 10px;
      color: var(--gray-500);

      &--from-user {
        align-self: flex-end;
      }
    }

    &--from-user {
      background-color: var(--blue-800);
      color: white;
      align-self: flex-end;
      border-radius: var(--spacing-2);
      border-bottom-right-radius: 0;
    }
  }

  &__input {
    margin: var(--spacing-2);
    padding: var(--spacing-1) var(--spacing-2);
  }
}

