






























@import 'Less/variables.less';

.wrapper {
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
  height: auto;
  min-height: 150px;
  width: 90%;
  background-color: #fff;
  color: #373737;
  margin-top: 20px;
}

.notification-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80px;
  background-color: #d84a38;
  color: #9a1818;
  text-align: center;
  line-height: 150px;
}

.warning {
  background-color: #fa991d;
  color: #bc7603;
}

.danger {
  background-color: #d84a38;
  color: #9a1818;
}

.info {
  background-color: #3a58a1;
  color: @theme-blue;
}

.success {
  background-color: #36cf4d;
  color: #35aa47;
}

.body {
  width: 85%;
  position: relative;
  display: inline-block;
  height: 100%;
  left: 80px;
  padding: 50px;
  padding-top: 0;
  padding-bottom: 10px;
}
