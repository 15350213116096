































































































































































































































































































































































































































@import 'Less/variables.less';

.moveTeacher {
  &__footer {
    display: flex;
    justify-content: center;

    &__button {
      margin: @spacing-1;
    }
  }
}

.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.popup-footer-vertical {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
}

.inputField {
  padding: 5px;
  border: 1px solid #d8d8d8;
  outline: none;
  width: 100%;
}

.inputField:focus {
  border: 1px solid @theme-blue;
}

.iconFont {
  color: #373737;
}

.contextMenu-background {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  height: auto;
  max-height: 300px;
  min-height: 20px;
  width: 300px;
  z-index: 100000;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
  border-radius: 5px;
  box-sizing: border-box;
  overflow: none;
  border: none;
  color: #373737;
  list-style-type: none;
}

.contextMenu-row {
  left: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 20px;
  margin: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.contextMenu-row:hover {
  background-color: #dcdcdc;
  border-left: 2px solid black;
}

.animation {
  transition: all 0.2s ease-in-out;
}

/* Modals */
.person {
  margin-left: 70px;

  /* margin-top: 6px; */
  display: flex;
  flex-direction: column;
  font-size: 10px;
  width: 300px;
}

.person > .email {
  font-size: 12px;
}

.person > .name {
  color: #35aa47;
}

.person > div {
  flex: 1;
}

h3 {
  margin: 12px;
  margin-bottom: 0;
}

h5 {
  margin: 12px;
}
