


















@import 'Less/variables.less';

.exam-popover__inner {
  background: white;
  color: @color-font-dark;
  padding: @spacing-4;
  border-radius: @radius-xs;
  box-shadow: @shadow-sm;
}
