
























































































































































































































































































































































































































































































































@import 'Less/variables.less';

.language {
  background-color: #fff;
  display: flex;
  width: 100%;
  margin-bottom: 0;
  height: 100%;

  & > * {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__column__from {
    border-right: @divider-border;

    &__toolbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: @spacing-1;
      color: @color-grey-medium;

      button {
        border-radius: 50%;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: 0;
        width: @spacing-13;
        height: @spacing-13;
        transition: @animation;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: @font-size-x-large;
          margin-top: 0;
        }

        &:hover {
          background-color: @color-grey-light;
        }

        &:focus {
          outline: none;
        }
      }

      &__word-count {
        padding: 0 @spacing-3;
        font-size: @font-size-medium;

        &--exceeded {
          color: @color-danger-dark;
        }
      }
    }
  }
}

.language-select {
  max-width: 100%;
}

textarea {
  width: 100%;
  height: 150px;
  resize: none;
  padding: 20px;
  font-size: 14px;
  color: black;
  background: white;
  border: none;
  flex: 1;

  &:focus {
    outline: none;
  }
}

.from {
  font-size: 20px;
}

.translated {
  text-align: left;
  width: 100%;
  height: 150px;
  resize: none;
  padding: @spacing-5;
  font-size: @font-size-medium;
  color: black;
  background: white;
  flex: 1;
  white-space: break-word;
  word-break: break-word;
  overflow-y: auto;

  &__error {
    color: @color-danger-dark;
  }

  &__placeholder {
    color: var(--gray-700);
  }
}
