









































































































@import 'Less/variables.less';

h1 {
  font-size: 14px;
  margin: 0;
}

.solution {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);
  border: 1px solid transparent;

  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 20%);
    border: 1px solid @theme-blue;
  }
}

.icon {
  height: 20px;
  margin-right: 10px;
}
