
























.wrapper {
  padding: 10px 40px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
  font-weight: 200;
  position: relative;
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  & > i {
    font-size: 20px;
  }
}
