<script>
export default {
  functional: true,
  render(createElement, { props, listeners, data }) {
    return createElement(
      'div',
      {
        class: {
          'toggle-button-container': true,
          titleStyle: typeof props.title === 'string' && props.title !== '',
        },
        on: listeners,
        ...data,
      },
      [
        props.hasTitle ? createElement('div', { class: 'titleContainer' }, props.title) : null,
        createElement(
          'div',
          {
            class: {
              'background animation': true,
              backgroundActivated: props.active,
              backgroundCenter: props.position === 'center' || props.position === 'middle',
            },
          },
          [
            createElement('div', {
              class: {
                'toggleButtonDot animation': true,
                dotActivated: typeof props.active === 'boolean' ? props.active : false,
              },
            }),
          ],
        ),
      ],
    )
  },
}
</script>

<style scoped>
.toggle-button-container {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  min-height: 20px;
  min-width: 40px;
  background-color: transparent;
}

.titleContainer {
  position: relative;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
}

.background {
  position: relative;
  width: 40px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: #d8d8d8;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.backgroundActivated {
  background-color: #5bde44;
}

.backgroundCenter {
  left: calc(50% - 20px);
}

.toggleButtonDot {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

.dotActivated {
  left: 22px;
}

.animation {
  transition: all 0.2s ease-in-out;
}
</style>
