<template>
  <div
    ref="KunskapsmatrisenExam"
    class="KunskapsmatrisenExam"
  >
    <div>
      <ResourceToolbar
        :menuTitle="subject"
        resourceName="kunskapsmatrisen"
      />
      <div
        id="kunskapsmatrisen-overflow"
        class="exam_part-list"
        @click.right="cancelSaveAttachment"
        @click="cancelSaveAttachment"
      >
        <div
          v-for="(exam_part, part_index) in exam_parts"
          v-show="exam_part.status.visible && exam_part.delsettings.editable !== 'hidden'"
          :key="part_index"
          class="exam_part"
        >
          <div
            v-for="(page, index) in exam_part.pages"
            :key="index"
            class="page"
          >
            <div
              v-if="index === 0"
              class="infoWrapper"
            >
              <span
                v-sanitized-html="partInfo(exam_part.deltype, part_index).html"
                class="partInfo"
                :class="{ noBorder: partInfo(exam_part.deltype, part_index).isCustom }"
              />
            </div>

            <div
              v-for="(question, questionIndex) in page.questions"
              :key="question.id"
            >
              <div
                v-if="newFormat(question)"
                class="newQuestionContainer"
              >
                <render-question
                  :id="question.id"
                  v-model="answerNewFormat[question.id]"
                  :froalaConfig="{ iconsTemplate: 'font_awesome', imagePaste: false, maximize: false }"
                  :katex="true"
                  :safariKatexBugFix="true"
                  :reRenderEqOnNoChangeUpdate="true"
                  :katexFroala="true"
                  :editable="!expectsPenAndPaperAnswer(exam_part.deltype) && exam_part.delsettings.editable === 'open'"
                  :autotypeCatcher="true"
                  :pasteProtection="usesPasteProtection"
                  :disableNumber="singleQuestionExam ? ['main'] : []"
                  :number="questionNumber(exam_part, page, questionIndex)"
                  :digital="!expectsPenAndPaperAnswer(exam_part.deltype)"
                  :answerArea="
                    expectsPenAndPaperAnswer(exam_part.deltype)
                      ? { answerRow: 'none', background: 'none', outline: false, size: 'auto' }
                      : null
                  "
                  :math="true"
                  :grade="false"
                  :hide="shouldHide"
                  :shuffle="shouldShuffle(exam_part.deltype)"
                  :question="Parser.json(question.htmlarr, 'array')"
                  :clearFormat="true"
                  :initAnswers="true"
                  :examSpellcheck="spellChecking !== null"
                  :examSpellcheckLanguage="spellChecking"
                  :disablePoint="hidePointsForStudents"
                  :showPointsLevel="showPointsLevel"
                  @autotype-detected="autotypeDetected"
                />
              </div>

              <div
                v-show="shouldShowAttachmentArea(question.id)"
                class="preview"
                @click="chooseQuestion(question)"
              >
                <div
                  v-if="attachmentToSave"
                  class="pickAttachmentQuestion text-center"
                >
                  <h2 class="pickAttachmentQuestion__info">
                    Spara ny bilaga på uppgift {{ questionNumber(exam_part, page, questionIndex) }}
                  </h2>
                </div>
                <Draggable
                  :list="attachments[question.id]"
                  :group="{ name: 'attachment', pull: true, put: ['attachment', 'image'] }"
                  :scrollSensitivity="200"
                  :forceFallback="true"
                  ghostClass="KunskapsmatrisenExam__attachment--ghost"
                  handle=".AttachmentPreview__thumbnail"
                  @change="draggableChange"
                  @start="isAttachmentMoving = true"
                  @end="isAttachmentMoving = false"
                >
                  <transition-group
                    name="group-list"
                    class="attachments"
                  >
                    <div
                      v-for="attachment in attachments[question.id]"
                      :key="attachment.identifier"
                    >
                      <attachment-preview
                        class="KunskapsmatrisenExam__attachment"
                        :type="attachment.type"
                        :thumbnail="attachment.thumbnail"
                        :name="attachment.name"
                        @click="editAttachment(attachment)"
                        @nameChange="(name) => attachmentChangeName(attachment, name)"
                        @delete="deleteAttachmentModal(attachment)"
                      />
                    </div>
                  </transition-group>
                </Draggable>
              </div>
            </div>

            <div style="margin-top: 100px" />
          </div>

          <div class="next-button">
            <text-button
              v-if="lockToGetTools(exam_part, part_index) && !isLastVisiblePart(part_index)"
              title="Lås fram hit för att fortsätta med miniräknare på nästa del"
              big
              noBorder
              @click="confirmLockModal(part_index + 1)"
            />

            <text-button
              v-if="lockToSeeNext(exam_part, part_index) && !isLastVisiblePart(part_index)"
              title="Lås fram hit för att visa nästa del utan miniräknare"
              big
              noBorder
              @click="confirmLockModal(part_index + 1)"
            />
          </div>

          <div style="margin-top: 100px" />
        </div>
      </div>
    </div>
    <DeleteAttachmentModal
      ref="deleteAttachmentModal"
      @confirm="confirmDeleteAttachment"
    />
    <CancelSaveAttachmentModal
      ref="cancelSaveAttachmentModal"
      @confirm="confirmCancelSaveAttachment"
    />

    <exam-modal
      ref="showDrawingModal"
      size="lg"
    >
      <h3 slot="title">
        {{ __('Visa bild') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <div v-if="drawingToShow">
          <img
            :src="drawingToShow"
            width="100%"
          >
        </div>
      </div>
      <div
        slot="footer"
        style="margin-right: -15px"
      >
        <el-button data-dismiss="modal">
          {{ __('Ok') }}
        </el-button>
      </div>
    </exam-modal>

    <exam-modal
      id="sureModal"
      size="sm"
    >
      <h3 slot="title">
        Är du helt säker?
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <h3>Är du helt säker på att du vill låsa provet fram hit?</h3>
        <br>
        <h4>Du kommer inte kunna gå tillbaka till dessa uppgifter igen.</h4>
      </div>
      <div
        slot="footer"
        class="sureModal__footer"
      >
        <text-button
          class="sureModal__button"
          mode="secondary"
          title="Nej"
          data-dismiss="modal"
        />
        <text-button
          class="sureModal__button"
          title="Ja, helt säker"
          data-dismiss="modal"
          @click="confirmLock"
        />
      </div>
    </exam-modal>

    <exam-modal
      id="sureModalTeacher"
      size="md"
    >
      <h3 slot="title">
        Detta kräver din lärares tillåtelse
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <h3>Är du helt säker på att du vill låsa provet fram hit?</h3>
        <br>
        <h4>Eftersom nuvarande delen lämnas in på papper måste din lärare godkänna detta.</h4>
        <p>Om läraren inte svarar på 30 sekunder kommer du kunna gå vidare ändå.</p>
      </div>
      <div slot="footer">
        <text-button
          mode="secondary"
          title="Nej"
          data-dismiss="modal"
        />

        <text-button
          title="Ja, helt säker"
          data-dismiss="modal"
          @click="askForNextPart"
        />
      </div>
    </exam-modal>

    <exam-modal
      id="waitForWantedPart"
      size="md"
    >
      <h3 slot="title">
        Väntar på lärarens godkännande
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <h3>Din lärare behöver godkänna att du får gå vidare till nästa del.</h3>
        <br>
        <h4>Se att du har lämnat in de papper du ska.</h4>
        <p>Om läraren inte svarar på 40 sekunder kommer du automatiskt komma vidare.</p>
      </div>
      <div slot="footer">
        <i
          style="font-size: 40px; margin-top: 10px"
          class="fa fa-spin fa-spinner fetch-exam-spinner"
        />
      </div>
    </exam-modal>

    <exam-modal
      id="notInClassModal"
      size="sm"
    >
      <h3 slot="title">
        Ej tillgängligt
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <h3>Detta prov är ej tillgängligt för dig.</h3>
        <br>
        <p style="font-size: 12px">
          Kontrollera med din lärare att provet är riktat till hela klassen eller till dig.
        </p>
        <p style="font-size: 12px">
          Om du tillhör flera grupper måste du först växla till rätt kurs (med hjälp av rullgardinsmenyn uppe till höger
          när du är inloggad på Kunskapsmatrisen) och därefter skriva in provnyckeln.
        </p>
      </div>
      <div slot="footer">
        <text-button
          title="Ok"
          data-dismiss="modal"
          @click="noAccessClose"
        />
      </div>
    </exam-modal>
    <exam-modal
      id="alreadyDoneModal"
      size="sm"
    >
      <h3 slot="title">
        Redan genomfört
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <h3>Du har redan genomfört detta prov.</h3>
      </div>
      <div slot="footer">
        <text-button
          title="Ok"
          data-dismiss="modal"
          @click="noAccessClose"
        />
      </div>
    </exam-modal>
  </div>
</template>

<script>
/* global $ axios */
import CancelSaveAttachmentModal from 'VueComponents/student/exam/CancelSaveAttachmentModal.vue'
import DeleteAttachmentModal from 'VueComponents/student/exam/DeleteAttachmentModal.vue'
import AttachmentPreview from 'VueComponents/student/exam/AttachmentPreview.vue'
import { updatePart } from '../../../student/KunskapsmatrisenExam.js'
import ResourceToolbar from 'VueStudent/exam/ResourceToolbar.vue'
import { RenderQuestion, init } from '@teachiq/render-question'
import FirebaseFileUpload from 'Modules/FirebaseFileUpload.js'
import PersistentStorage from 'Modules/PersistentStorage'
import ErrorHandler from 'Modules/ErrorHandler.ts'
import StudentModel from 'Modules/Models/Student'
import StringHandler from 'Modules/StringHandler'
import Firestore from 'Modules/Firestore.js'
import MathGrader from 'Modules/MathGrader'
import Student from 'Modules/Student.js'
import Parser from '@krisell/parser'
import Draggable from 'vuedraggable'
import Modal from 'Modules/Modal.js'
import Crypto from 'Modules/Crypto'
import grade from '@teachiq/grade'
import iOS from 'Modules/iOS.js'
import Vue from 'vue'

const calculatorDeltypes = [2, 4, 6, 8, 10]
const digitalAnswerDeltypes = [1, 2, 3, 4, 7, 8]
// const analogAnswerDeltypes = [5, 6, 9, 10]
const penAndPaperAnswersOnly = [9, 10]

export default {
  components: {
    RenderQuestion,
    AttachmentPreview,
    ResourceToolbar,
    Draggable,
    CancelSaveAttachmentModal,
    DeleteAttachmentModal,
  },
  props: {
    savedState: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      language: 'sv',
      spellChecking: null,
      drawingToShow: null,
      attachments: {},
      referenceCache: {},
      attachmentToSave: null,
      Parser,
      prev_part: null,
      prev_answer: null,
      titleOverrides: null,
      nextPart: 0,
      currentPart: 0,
      forceWantedPartTimeout: 0,
      exam_student: new StudentModel(window.K$.student),
      student: {
        token: null,
        studentid: 0,
        classid: 0,
      },
      examid: 0,
      advsettings: [],
      exam_parts: [],
      autocorrectOnly: false,
      questions: [],
      pages: [],
      notRendered: false,
      sha512: '',
      limitarr: null,
      deltypes: null,
      delsettings: null,
      coursetype: null,
      courseid: null,
      uppgifter: null,
      answerNewFormat: {},
      subQuestionNewFormat: {},
      singleQuestionExam: false,
      hidePointsForStudents: false,
      examAllowsProgrammingArea: false,
      subject: '',
      isAttachmentMoving: false,
      isStudentImageHandlerOpen: false,
    }
  },
  computed: {
    usesPasteProtection() {
      return Number(this.advsettings[6]) !== 0
    },
    shouldHide() {
      return !iOS.isNative() && window.innerWidth > 900
    },
    showPointsLevel() {
      if (Number(this.advsettings[8]) === 1) {
        return false
      }

      return true
    },
  },
  watch: {
    exam_parts() {
      this.setDefaultAnswerModel()
      this.notRendered = true
    },
  },
  mounted() {
    window.K$.events.$emit('register-image-toolbar', this.$el, '.render-question-formulation')

    init(Vue, {
      'froala-key': null,
      'font-awesome': null,
    })
    window.K$.events.$on('show-student-image-handler', () => {
      this.isStudentImageHandlerOpen = true
      this.scrollToClosestAttachmentArea()
    })
    window.K$.events.$on('hide-student-image-handler', () => {
      this.isStudentImageHandlerOpen = false
    })

    window.K$.events.$on('spellcheck-setLanguage', (language) => {
      if (Number(language) === 0) {
        this.spellChecking = null
        return
      }

      this.spellChecking = language.slice(0, 2)
    })

    window.K$.events.$on('cancel-save-image', () => {
      this.attachmentToSave = null
    })

    window.K$.testGrade = () => {
      console.log(JSON.stringify(this.generateSaveObject()))
      console.log(this.gradeNewFormat())
    }

    this.loadPreviousSaved()
    this.registerEventListeners()
    this.initiateGradeAndSend()

    window.K$.events.$on('get-new-answers', (fn) => {
      fn(this.answerNewFormat, this.attachmentsToAnswerFormat())
    })

    window.K$.events.$on('grade-new-answers', (fn) => {
      fn(this.gradeNewFormat())
    })

    window.K$.events.$on('save-km-attachment', (attachment, options = {}) => {
      attachment.name = ''
      this.attachmentToSave = attachment
      this.attachmentToSave.identifier = StringHandler.random(20)

      // Try to sync some attachment data now, and if/when successful, replace contents with a reference
      if (['GEOGEBRA', 'DESMOS'].includes(attachment.type)) {
        FirebaseFileUpload.studentUpload(
          { data: new window.Blob([JSON.stringify(attachment.data)], { type: 'application/json' }), attachmentType: attachment.type },
          'json',
        ).then((url) => {
          this.referenceCache[url] = attachment.data
          delete attachment.data
          attachment.reference = url
        })
      }

      if (options.thumbnail) {
        if (typeof options.thumbnail === 'string') {
          attachment.thumbnail = options.thumbnail
          this.$forceUpdate()
          return
        }

        FirebaseFileUpload.studentUpload({ data: options.thumbnail, attachmentType: attachment.type }, 'png')
          .then((url) => {
            attachment.thumbnail = url
            this.$forceUpdate()
          })
          .catch((err) => {
            throw err
          })
      }
      this.scrollToClosestAttachmentArea()
    })
  },
  methods: {
    scrollToClosestAttachmentArea() {
      const scrollYPosition = this.$refs.KunskapsmatrisenExam.scrollTop
      const attachmentAreas = document.getElementsByClassName('preview')
      if (attachmentAreas.length > 0) {
        setTimeout(() => {
          for (let i = 0; i < attachmentAreas.length; i++) {
            if (attachmentAreas.item(i).offsetTop > scrollYPosition) {
              attachmentAreas.item(i).scrollIntoView({ block: 'center' })
              return
            }
          }
        }, 100)
      }
    },
    unusedImages() {
      let unusedImagesRaw = []
      window.K$.events.$emit('student-unused-images', (images) => {
        unusedImagesRaw = images
      })
      const unusedImages = unusedImagesRaw.map((unusedImage) => {
        return { name: '', data: { src: unusedImage.url }, thumbnail: unusedImage.url, type: unusedImage.meta.customMetadata.attachmentType }
      })
      return unusedImages
    },
    questionNumber(examPart, page, questionIndex) {
      return examPart.partStart + page.pageStart + questionIndex + 1
    },
    shouldShowAttachmentArea(questionId) {
      return (this.attachments[questionId] && this.attachments[questionId].length > 0) ||
            this.isStudentImageHandlerOpen ||
            this.isAttachmentMoving ||
            this.attachmentToSave
    },
    attachmentsToAnswerFormat() {
      const attachmentsFormatted = []
      Object.entries(this.attachments).forEach(([questionId, attachments]) => {
        if (attachments.length > 0) {
          attachments.forEach((attachment) => {
            attachmentsFormatted.push({
              uid: questionId,
              attachment: attachment,
            })
          })
        }
      })

      return attachmentsFormatted
    },
    draggableChange() {
      window.K$.events.$emit('update-student-image-handler')
      this.$nextTick(() => this.$forceUpdate()) // This is needed to keep draggable and attachments in sync
    },
    attachmentChangeName(attachment, name) {
      attachment.name = name
    },
    cancelSaveAttachment() {
      if (!this.attachmentToSave) {
        return
      }
      if (['DRAWING', 'SCANNING'].includes(this.attachmentToSave.type)) {
        window.K$.events.$emit('cancel-save-image')
        this.attachmentToSave = null
      } else {
        Modal.show(this.$refs.cancelSaveAttachmentModal)
      }
    },
    confirmCancelSaveAttachment() {
      window.K$.events.$emit('cancel-save-image')
      this.attachmentToSave = null
    },
    deleteAttachmentModal(attachment) {
      this.attachmentToDelete = attachment
      Modal.show(this.$refs.deleteAttachmentModal)
    },
    confirmDeleteAttachment() {
      for (const key in this.attachments) {
        this.attachments[key] = (this.attachments[key] || []).filter(
          (saved) => saved.identifier !== this.attachmentToDelete.identifier,
        )
      }

      window.K$.events.$emit('update-student-image-handler')
      this.attachmentToDelete = null
      this.$forceUpdate()
    },
    finalizeAttachmentSave() {
      this.attachments[this.questionToSaveAttachmentTo] = [
        ...(this.attachments[this.questionToSaveAttachmentTo] || []),
        this.attachmentToSave,
      ]

      this.attachmentToSave = null
      window.K$.events.$emit('update-student-image-handler')
    },
    chooseQuestion(question) {
      if (!this.attachmentToSave) {
        return
      }

      this.questionToSaveAttachmentTo = question.id
      this.finalizeAttachmentSave()
    },
    editAttachment(attachment) {
      const attachmentToEdit = JSON.parse(JSON.stringify(attachment))
      if (['DRAWING', 'SCANNING'].includes(attachment.type)) {
        window.K$.drawingImageOnLoad = attachmentToEdit.data.src
        window.K$.events.$emit('add-image-to-drawing-area', attachmentToEdit.data.src)
        return
      }
      if (attachmentToEdit.reference) {
        if (this.referenceCache[attachmentToEdit.reference]) {
          attachmentToEdit.data = this.referenceCache[attachmentToEdit.reference]
        } else {
          axios.get(attachmentToEdit.reference).then((response) => {
            this.referenceCache[attachmentToEdit.reference] = response.data
            attachmentToEdit.data = response.data

            // The needed component may be lazy-loaded and not receive the message
            window.K$.attachmentToEdit = attachmentToEdit
            window.K$.events.$emit('edit-attachment', attachmentToEdit)
          })

          return
        }
      }

      window.K$.attachmentToEdit = attachmentToEdit
      window.K$.events.$emit('edit-attachment', attachmentToEdit)
    },
    isLastVisiblePart(partIndex) {
      // Return true if all following parts (higher index) are hidden
      return this.delsettings
        .filter((el, index) => index > partIndex + 1 && index < this.exam_parts.length + 1)
        .every((el) => el.editable === 'hidden')
    },
    autotypeDetected(lastText, text) {
      if (window.K$.isDemo) {
        return
      }

      $.ajax({
        url: '/api/cheat/autotype',
        method: 'post',
        data: {
          student_id: this.exam_student.id,
          token: window.K$.student_token,
          answer: text,
          previous: lastText,
        },
      })
    },
    expectsPenAndPaperAnswer(deltype) {
      return penAndPaperAnswersOnly.includes(Number(deltype))
    },
    gradeNewFormat() {
      const grading = {}

      for (const questionId in this.answerNewFormat) {
        grading[questionId] = []

        this.answerNewFormat[questionId].forEach((inputAnswer, index) => {
          const subQuestion = this.getSubQuestion(questionId, index)

          let points = []
          try {
            points = grade.subQuestion(subQuestion, inputAnswer)
          } catch (err) {
            ErrorHandler.report('Grading error' + JSON.stringify({ questionId, subQuestion, inputAnswer, err }))
          }

          grading[questionId].push(points)
        })
      }

      return grading
    },
    getSubQuestion(questionId, index) {
      return this.subQuestionNewFormat[questionId][index]
    },
    setDefaultAnswerModel() {
      this.exam_parts.forEach((examPart) => {
        examPart.pages.forEach((page) => {
          page.questions.forEach((question) => {
            if (!this.answerNewFormat[question.id] && Number(question.v) === 2) {
              this.answerNewFormat[question.id] = []
            }

            // Add object keys for all questions
            if (!this.attachments[question.id]) {
              this.attachments[question.id] = []
            }
            // Map questions for easier extraction
            if (!this.subQuestionNewFormat[question.id] && Number(question.v) === 2) {
              question.htmlarr = question.htmlarr.replace(
                /\/uppgiftsbilder/g,
                'https://www.kunskapsmatrisen.se/uppgiftsbilder',
              )
              this.subQuestionNewFormat[question.id] = [...JSON.parse(question.htmlarr)]
            }
          })
        })
      })
    },
    newFormat(question) {
      if (Number(question.v) === 1) {
        ErrorHandler.report('KM question v1' + JSON.stringify(question))
      }

      return Number(question.v) === 2
    },
    shouldAllowCalculator(deltype) {
      if (Number(this.advsettings[10].substring(1)) === 3) {
        return false
      }

      return this.shouldAllowDigitalTool(deltype)
    },
    shouldAllowGeogebra(deltype) {
      if (Number(this.advsettings[10].substring(1)) === 2) {
        return false
      }

      return this.shouldAllowDigitalTool(deltype)
    },
    shouldAllowDigitalTool(deltype) {
      if (Number(this.advsettings[10].substring(0, 1)) === 1) {
        return true
      }

      if (Number(this.advsettings[10].substring(0, 1)) === 0) {
        return false
      }

      return calculatorDeltypes.includes(Number(deltype))
    },
    shouldAllowProgramming(deltype) {
      // Limit programming tool access only if digital tools setting is "According to exam parts". Otherwise,
      // let programming setting define the access (even if "No" is chosen for digital tools).
      if (Number(this.advsettings[10].substring(0, 1)) === 2) {
        return this.examAllowsProgrammingArea && calculatorDeltypes.includes(Number(deltype))
      }

      return this.examAllowsProgrammingArea
    },
    noAccessClose() {
      window.K$.events.$emit('no-access')
    },
    setAnswerModel(answers = []) {
      this.attachments = {}
      answers.forEach((question) => {
        if (Number(question.version) !== 2) {
          return
        }
        if (question.attachments) {
          this.attachments[question.uid] = question.attachments
        }
        if (typeof question.boxarr === 'string') {
          question.boxarr = JSON.parse(question.boxarr)
        }

        this.answerNewFormat[Number(question.uid)] = question.boxarr
      })
    },
    loadPreviousSaved() {
      let previousAnswer = JSON.parse(this.savedState.saveList || '[]')
      let previousPart = this.savedState.part || 0

      this.prev_answer = previousAnswer
      this.prev_part = previousPart

      this.setAnswerModel(previousAnswer)

      // If there is anything in localstorage, it's the newest
      let previous = false
      PersistentStorage.getItem('temporary_answer_storage').then((stored) => {
        if (stored) {
          try {
            const { data } = JSON.parse(stored)
            const temporaryAnswerStorage = Crypto.wadjvawldbvoaiwldblaw(
              data,
              Crypto.jwahdbajlhwdbj2(this.exam_student.id),
            )
            previous = temporaryAnswerStorage.answer
          } catch (e) {
            previous = false
            PersistentStorage.removeItem('temporary_answer_storage')
          }
        }

        if (previous) {
          const savedState = JSON.parse(previous) || {}
          if (savedState.saveList) {
            previousAnswer = JSON.parse(savedState.saveList)
            previousPart = savedState.part
          }
        }

        this.prev_answer = previousAnswer
        this.prev_part = previousPart

        this.setAnswerModel(previousAnswer)
      })
    },
    confirmLockModal(index) {
      this.nextPart = index

      // If an analog part is about to be handed in, ask the teacher for permission.
      if (penAndPaperAnswersOnly.includes(+this.exam_parts[this.currentPart].deltype)) {
        Modal.show('sureModalTeacher')
        return
      }

      Modal.show('sureModal')
    },
    askForNextPart() {
      Firestore.sendToTeacher(this.exam_student.id, window.K$.bucket, {
        student_id: this.exam_student.id,
        type: 'wanted_part',
        part: this.nextPart,
      })
      $('#waitForWantedPart').modal({ keyboard: false, backdrop: 'static' })
      this.forceWantedPartTimeout = setTimeout(() => this.forceWantedPart(this.nextPart), 60000)
    },
    forceWantedPart(part) {
      $('#waitForWantedPart').modal('hide')
      this.nextPart = part
      this.setCurrentPart(part)
    },
    confirmLock() {
      this.setCurrentPart(this.nextPart)

      window.$.ajax({
        url: '/api/cheat/lock-part',
        method: 'post',
        data: {
          student_id: window.K$.student.id,
          token: window.K$.student_token,
          part_index: this.nextPart,
        },
      })
    },
    lockToGetTools(examPart, examIndex) {
      if (examIndex === this.exam_parts.length - 1) {
        return false
      }

      // Visa om denna del saknar räknare och nästa ska ha
      return !examPart.status.digital_tool && this.exam_parts[examIndex + 1].status.digital_tool
    },
    lockToSeeNext(examPart, examIndex) {
      if (examIndex === this.exam_parts.length - 1) {
        return false
      }

      // Visa om denna del har räknare och nästa inte
      return examPart.status.digital_tool && !this.exam_parts[examIndex + 1].status.digital_tool
    },
    setCurrentPart(partIndex) {
      this.currentPart = partIndex
      updatePart(partIndex) // For saving with part

      this.exam_parts.forEach((part, index) => {
        if (index < partIndex) {
          part.status.visible = false
        }
      })

      const part = this.exam_parts[partIndex]
      window.K$.events.$emit('digital_tool', part.status.calculator, part.status.geogebra, part.status.programming)

      // If calculator is not allowed, we may show all continuing
      // part without and then with calculator
      if (!part.status.digital_tool) {
        let state = 'no-calculator'
        this.exam_parts.forEach((part, index) => {
          if (index < partIndex) {
            return
          }

          if (state === 'no-calculator') {
            if (!part.status.digital_tool) {
              part.status.visible = true
            } else {
              state = 'calculator'
              part.status.visible = true
            }
          } else if (state === 'calculator') {
            if (part.status.digital_tool) {
              part.status.visible = true
            } else {
              part.status.visible = false
              state = 'done'
            }
          }
        })
      } else {
        let state = 'calculator'

        this.exam_parts.forEach((part, index) => {
          if (index < partIndex) {
            return
          }

          if (state === 'calculator') {
            if (part.status.digital_tool) {
              part.status.visible = true
            } else {
              state = 'done'
              part.status.visible = false
            }
          } else {
            part.status.visible = false
          }
        })
      }

      document.querySelector('.exam_part-list').scrollTop = 0
    },
    partInfo(deltype, partIndex) {
      const num = this.uppgifter
        .split('#')
        .map((questions) => questions.split(',').filter((question) => question.length).length)

      const skippedParts = num.reduce((acc, cur, index) => {
        if (index > partIndex || cur !== 0) {
          return acc
        }

        return acc + 1
      }, 0)

      if (this.titleOverrides[partIndex + skippedParts + 1] && this.titleOverrides[partIndex + skippedParts + 1][0]) {
        return {
          html: this.titleOverrides[partIndex + skippedParts + 1][0],
          isCustom: true,
        }
      }

      const snippets = {
        noTools: 'Digitala verktyg är <strong>inte</strong> tillåtna.',
        tools: 'Digitala verktyg är tillåtna.',
        answerBoxes: 'Svara i rutorna.',
        answerPaper: 'Svara på separat papper.',
        fullBoxes: 'Ge fullständiga lösningar i rutorna.',
        fullPaper: 'Ge fullständiga lösningar på separat papper.',
      }

      const toolsApplicable = !!+this.deltypes[0]

      const messages = {
        1: `${toolsApplicable ? snippets.noTools : ''} ${snippets.answerBoxes}`,
        3: `${toolsApplicable ? snippets.noTools : ''} ${snippets.answerBoxes}`,
        2: `${toolsApplicable ? snippets.tools : ''} ${snippets.answerBoxes}`,
        4: `${toolsApplicable ? snippets.tools : ''} ${snippets.answerBoxes}`,
        5: `${toolsApplicable ? snippets.noTools : ''} ${snippets.answerPaper}`,
        6: `${toolsApplicable ? snippets.tools : ''} ${snippets.answerPaper}`,
        7: `${toolsApplicable ? snippets.noTools : ''} ${snippets.fullBoxes}`,
        8: `${toolsApplicable ? snippets.tools : ''} ${snippets.fullBoxes}`,
        9: `${toolsApplicable ? snippets.noTools : ''} ${snippets.fullPaper}`,
        10: `${toolsApplicable ? snippets.tools : ''} ${snippets.fullPaper}`,
      }

      return {
        html: `Del ${this.numberToPart(partIndex)}: ${messages[deltype]}`,
        isCustom: false,
      }
    },

    numberToPart(number) {
      return {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
      }[number]
    },
    registerEventListeners() {
      window.K$.events.$on('register-kunskapsmatrisen-student', (student, examid) => {
        this.student = student
        this.examid = examid
      })

      window.K$.events.$on('kunskapsmatrisen-exam-render', () => {
        this.initiateRender()
      })

      window.K$.events.$on('open-part', (part) => {
        this.setCurrentPart(part)
      })

      window.K$.events.$on('grant-wanted-part', () => {
        this.setCurrentPart(this.nextPart)
        $('#waitForWantedPart').modal('hide')
        clearTimeout(this.forceWantedPartTimeout)
      })

      window.K$.events.$on('deny-wanted-part', () => {
        $('#waitForWantedPart').modal('hide')
        clearTimeout(this.forceWantedPartTimeout)
      })
    },
    initiateGradeAndSend() {
      window.K$.gradeAndSend = () => {
        if (window.K$.isDemo) {
          return new Promise((resolve) => resolve())
        }

        return new Promise((resolve, reject) => {
          $.ajax({
            method: 'post',
            url: `https://apikunskapsmatrisen1.azurewebsites.net/classes/${this.student.classid}/students/${this.student.studentid}/testsandresults/${this.examid}`,
            context: this,
            data: {
              sha512: this.sha512,
              saveobject: JSON.stringify(this.generateSaveObject()),
              onsafedevice: Student.isHighSecurityMode(this.student) ? 1 : 0,
              testlog: '',
            },
            headers: { Authorization: `Bearer ${this.student.token}` },
            success(response) {
              window.K$.events.$emit(
                'additional-handin-status',
                'Dina svar är sparade hos Kunskapsmatrisen, påbörjar rättning ...',
              )
              const gradingNewFormat = this.gradeNewFormat()
              let saveObject = this.generateSaveObject()
              let examObjectWithAnswers = response.sqldata

              saveObject = MathGrader.sortForGrading(saveObject, this.uppgifter, this.deltypes)
              examObjectWithAnswers = MathGrader.sortForGrading(examObjectWithAnswers, this.uppgifter, this.deltypes)
              const corrobject = MathGrader.autocorrect(
                saveObject,
                examObjectWithAnswers,
                this.limitarr,
                this.courseid,
                this.examid,
                gradingNewFormat,
              )

              $.ajax({
                method: 'post',
                url: `https://apikunskapsmatrisen1.azurewebsites.net/classes/${this.student.classid}/students/${this.student.studentid}/testsandresults/${this.examid}`,
                headers: { Authorization: `Bearer ${this.student.token}` },
                data: {
                  sha512: this.sha512,
                  corrobject: JSON.stringify(corrobject),
                  deltypes: JSON.stringify(this.deltypes),
                  autocorrectOnly: this.autocorrectOnly,
                  uppgifter: this.uppgifter,
                  coursetype: this.coursetype,
                  courseid: this.courseid,
                },
                success() {
                  window.K$.events.$emit('additional-handin-status', 'Dina svar är sparade och rättade, avslutar ...')
                  resolve()
                },
                error(err) {
                  reject(err)
                },
              })
            },
            error(err) {
              reject(err)
            },
          })
        })
      }
    },
    initiateRender() {
      let url = `https://apikunskapsmatrisen1.azurewebsites.net/classes/${this.student.classid}/students/${this.student.studentid}/testsandresults/${this.examid}`
      if (window.K$.isDemo) {
        url = `https://apikunskapsmatrisen1.azurewebsites.net/classes/0/students/0/testsandresults/${this.examid}`
      }

      $.ajax({
        url,
        data: { pagelist: 'digitest' },
        headers: { Authorization: `Bearer ${this.student.token}` },
        context: this,
        success(response) {
          if (response.error) {
            if (response.errormsg === 'allreadydone') {
              Modal.show('alreadyDoneModal')
              return
            }
          }

          this.processReceivedExam(response)
          this.setCurrentPart(this.prev_part)

          // Set the current part to the first visible part,
          // or the student's last part if that was higher.
          for (const [index, part] of Object.entries(this.exam_parts)) {
            if (part.delsettings.editable && part.delsettings.editable !== 'hidden') break
            this.setCurrentPart(Math.min(Math.max(this.prev_part, index + 1), this.exam_parts.length - 1))
          }

          if (this.exam_parts[this.exam_parts.length - 1].delsettings.editable === 'hidden') {
            window.K$.events.$emit('not-seeing-last-part')
          }
        },
      })
    },
    processReceivedExam(response) {
      const exam = response.pagelist[0]
      this.subject = exam.namn || this.__('Prov')
      this.language = exam.language || 'sv'
      this.autocorrectOnly = exam.uppgiftcontent.every((question) => Number(question.autocorr) === 1)

      if (!exam.classtest) {
        Modal.show('notInClassModal')
        return
      }

      this.advsettings = exam.advsettings.split('|')
      this.deltypes = JSON.parse(exam.deltypes)
      this.delsettings = JSON.parse(exam.delsettings)

      if (typeof this.delsettings[0] === 'object') {
        this.examAllowsProgrammingArea = this.delsettings[0].resources && this.delsettings[0].resources.programming

        if (this.delsettings[0].resources && this.delsettings[0].resources.hidePointsForStudents) {
          this.hidePointsForStudents = true
        }

        window.K$.events.$emit('set-km-exam-settings', this.delsettings[0])
      }

      window.K$.events.$emit('set-subject', this.subject)
      if (exam.formula && exam.formula.length && !this.isFormulaDisabled()) {
        window.K$.events.$emit('set-formula', exam.formula)
      }

      this.singleQuestionExam = exam.uppgiftcontent && exam.uppgiftcontent.length === 1

      this.exam_parts = exam.uppgifter
        .split('#')
        .map((uppgiftslista, index) => ({
          deltype: this.deltypes[index + 1],
          delsettings: this.delsettings[index + 1],
          status: {
            visible: false,
            done: false,
            digital_tool: this.shouldAllowDigitalTool(this.deltypes[index + 1]),
            geogebra: this.shouldAllowGeogebra(this.deltypes[index + 1]),
            calculator: this.shouldAllowCalculator(this.deltypes[index + 1]),
            programming: this.shouldAllowProgramming(this.deltypes[index + 1]),
          },
          partStart: 0,
          pages: [
            {
              pageStart: 0,
              questions: this.sortDifferently(
                this.deltypes[index + 1],
                uppgiftslista
                  .split(',')
                  .filter((uppgiftsId) => uppgiftsId.length)
                  .map((uppgiftsId) =>
                    exam.uppgiftcontent.find((uppgift) => Number(uppgift.id) === Number(uppgiftsId)),
                  ),
              ),
            },
          ],
        }))
        .filter((part) => part.pages[0].questions.length)

      // Allow for empty exams, which are probably created for testing, or
      // to give students access to the tools while answering with pen and paper.
      if (this.exam_parts.length === 0) {
        this.exam_parts.push({
          deltype: 1,
          delsettings: { editable: 'hidden' },
          status: {
            visible: false,
            done: false,
            digital_tool: this.shouldAllowDigitalTool(1),
            geogebra: this.shouldAllowGeogebra(1),
            calculator: this.shouldAllowCalculator(1),
            programming: this.shouldAllowProgramming(1),
          },
          partStart: 0,
          pages: [{ pageStart: 0, questions: [] }],
        })
      }

      this.exam_parts.reduce((acc, part) => {
        part.partStart = acc
        return acc + part.pages[0].questions.length
      }, 0)

      this.limitarr = JSON.parse(exam.limitarr)

      if (exam.literaturecontent && Array.isArray(exam.literaturecontent)) {
        exam.literaturecontent.forEach((literature) => {
          if (Number(literature.texttype) === 30) {
            return // Skip hörförståelse, provided by teacher
          }

          if (Parser.json(literature.html).audio) {
            return // Skip special audio file case
          }

          const content = this.parseLiteratureHtmlProperty(literature.html)

          if (content.text) {
            window.K$.events.$emit('add-literature', {
              name: literature.shorttitle,
              html: content.text,
              url: StringHandler.startsWith(content.text, 'https') ? content.text : false,
            })
          } else {
            window.K$.events.$emit('add-literature', {
              name: literature.shorttitle,
              html: literature.html,
              url: StringHandler.startsWith(literature.html, 'https') ? literature.html : false,
            })
          }
        })
      }

      this.titleOverrides = JSON.parse(exam.titleoverridearr)
      this.sha512 = response.sha512
      this.coursetype = exam.coursetype
      this.courseid = exam.courseid
      this.uppgifter = exam.uppgifter
    },
    parseLiteratureHtmlProperty(htmlProperty) {
      try {
        return JSON.parse(htmlProperty)
      } catch (e) {
        if (typeof htmlProperty === 'string') {
          return { text: htmlProperty, audio: '' }
        } else {
          console.log(e)
        }
      }
    },
    generateSaveObject() {
      const saveObject = Object.entries(this.answerNewFormat).map(([uid, boxarr]) => ({
        uid,
        boxarr,
        version: 2,
        attachments: this.attachments[uid],
      }))
      const unusedImages = this.unusedImages()
      if (unusedImages.length > 0) {
        saveObject.push({
          uid: -1,
          boxarr: [],
          version: 2,
          attachments: unusedImages,
        })
      }

      return saveObject
    },
    sortDifferently(deltype, arr) {
      if (!digitalAnswerDeltypes.includes(+deltype) || !this.shouldBeSorted()) {
        return arr
      }

      const sortType = (this.exam_student.id + 3) % 4

      return arr.sort((a, b) =>
        +a.apoints !== +b.apoints
          ? a.apoints - b.apoints
          : +a.cpoints !== +b.cpoints
              ? a.cpoints - b.cpoints
              : +a.epoints === +b.epoints
                  ? sortType >= 2
                    ? +b.id - +a.id
                    : +a.id - +b.id
                  : a.cpoints != 0 || a.apoints != 0 // eslint-disable-line eqeqeq
                    ? b.epoints - a.epoints
                    : [1, 3].includes(sortType)
                        ? +b.epoints - +a.epoints
                        : +a.epoints - +b.epoints,
      )
    },
    isFormulaDisabled() {
      return Number(this.advsettings[7]) === 1
    },
    shouldShuffle(deltype) {
      return this.shouldBeSorted() && digitalAnswerDeltypes.includes(Number(deltype))
    },
    shouldBeSorted() {
      return Number(this.advsettings[4]) === 1
    },
  },
}
</script>
<style>
.select-narrow-container-field {
  font-size: 12px;
}
</style>
<style scoped lang="less">
@import 'Less/variables.less';

.fetch-exam-spinner {
  margin-left: 10px;
}

.page {
  font-family: 'Times New Roman', Times, serif;
  width: 794px;
  max-width: 100%;
  min-height: 1123px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 20%);
  background-color: #fff;
  position: relative;
  font-size: 12pt;
  padding-top: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;

  /* Smooth iOS scroll */
  -webkit-overflow-scrolling: touch;
}

.KunskapsmatrisenExam {
  flex: 1 2 0;
  max-width: 100%;
  min-height: 100%;
  background-color: #f9f9f9;
  overflow: auto;

  &__attachment {
    cursor: grabbing;

    &--ghost {
      opacity: 0.4;
    }
  }
}

.infoWrapper {
  text-align: center;
}

.partInfo {
  margin-bottom: 30px;
  display: inline-block;
  padding: 5px 20px;
  border: 1px solid #373737;
  border-radius: 3px;
  color: #222;
  border-color: #373737;
  width: 90%;
  text-align: left;
}

.noBorder {
  border: none;
  box-shadow: none;
  padding: 0;
  margin-bottom: 0;
}

.newQuestionContainer {
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 5px;
  margin-bottom: 50px;
  position: relative;
}

.next-button {
  text-align: center;
  margin: 20px;
}

.handInButton {
  width: 100%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.pickAttachmentQuestion {
  opacity: 0.2;
  position: absolute;
  height: auto;
  width: auto;
  background: rgba(56, 91, 168, 80%);
  left: 0;
  top: -10px;
  right: 0;
  bottom: -10px;
  z-index: 9;
  display: flex;
  cursor: pointer;
  color: @color-font-light;
  justify-content: center;
  align-items: center;
  transition: all 0.1s;

  &__info {
    margin: 0;
  }

  &:hover {
    color: @color-font-light;
    opacity: 1;
  }
}

.pickAttachmentBar {
  position: fixed;
  left: -279px;
  top: 0;
  bottom: 0;
  width: 279px;
  height: auto;
  background: #2e3037;
  color: white;
  z-index: 10000;
  padding: 30px;
  transition: all 0.3s;
}

.pickAttachmentBar.show {
  left: 0;
}

.exam_part-list {
  overflow: auto;
  height: 100%;
}

.preview {
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 50px;
  margin-right: 30px;
  position: relative;

  .attachments {
    display: flex;
    flex-wrap: wrap;
    border-radius: @radius-xs;
    padding: @spacing-2;
    background: @color-grey-super-light;
    min-height: 175px;
  }
}

.sureModal {
  &__footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__button {
    margin: @spacing-1;
  }
}

.group-list-move {
  transition: @animation;
}
// This is to remove flickering when dragging attachments between questions
.group-list-leave-active {
  display: none;
}
</style>
