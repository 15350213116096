

























































































@import 'Less/variables.less';

.el-tab {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}

.tab-wrapper {
  display: flex;
  position: relative;
  width: auto;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tab-item:last-child {
    margin-right: 0;
  }
}

.tab-wrapper-border {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: @color-grey-light;
  z-index: -1;
}

.tab-item {
  padding: 5px 0;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 2px solid @color-grey-light;
  z-index: 2;
  color: @color-grey-medium;
  margin-right: 30px;

  &:hover {
    transform: scale(1.1);
    transition: all 0.1s;
  }
}

.active-tab-item {
  color: @color-font-dark;
  border-bottom: 2px solid @color-grey-dark;
  z-index: 2;
}

.contentArea {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding-bottom: @spacing-4;
  margin-top: @spacing-4;
}
