



















































































































































































.enThesaurus {
  &__lookup-button {
    border: none;
    border-radius: 300px;
    font-weight: 600;
    text-transform: uppercase;
    border-width: 0;
    padding: 7px 14px;
    font-size: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    margin-right: 20px;
  }
}

h1 {
  font-size: 50px;
}

.translated {
  text-align: left;
  width: 100%;
  height: 160px;
  margin-bottom: 10px;
  resize: none;
  padding: 20px;
  margin-top: 3px;
  border-radius: 10px;
  font-size: 20px;
  color: black;
  background: white;
  border: 1px solid #395ba8;
}

.translated > p {
  margin: auto;
  font-size: 14px;
}

#translateLoading {
  font-size: 40px;
}

.pil {
  font-size: 50px;
  margin: auto 20px;
  margin-top: 40px;
}

.errors {
  color: #ffb202;
  font-weight: 100;
  position: absolute;
  bottom: 0;
  z-index: 9;
  text-align: center;
  width: 100%;
  font-size: 16px;
}

.info {
  margin-top: 50px;
}
