

































































































































































































































































































































































































@import 'Less/variables.less';

.survey-overview {
  margin-top: 30px;
}

.overview-card {
  padding: 20px;

  /* border: 1px solid black; */
  margin: 10px;
}

.number {
  font-size: 40px;
  color: @theme-primary;
}

.highest {
  font-size: 15px;
  color: @theme-primary;
}

.number > i {
  font-size: 50px;
}

.title {
  font-size: 20px;
  color: #3a5ca9;
}

.overview {
  display: flex;
  margin-top: 10px;
}

.left {
  flex: 1;
}

.right {
  flex: 2;
}

.smaller {
  font-size: x-small;
}

.container h4 {
  line-height: 1;
}

.kunskapsmatrisen-logo {
  background: url('https://www.kunskapsmatrisen.se/verktyg/img/logo.png');
  width: 42px;
  height: 38px;
  margin: auto;
  display: inline-block;
  transform: scale(0.8);
  margin-right: -7px;
  margin-bottom: -8px;
}
