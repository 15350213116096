<template>
  <div>
    <div style="display: flex; justify-content: center">
      <el-button
        slot="title"
        style="margin: 0"
        :box="true"
        @click="pdfUpload"
      >
        <i class="fa fa-file-pdf-o" />&nbsp;&nbsp;Välj PDF-dokument
      </el-button>

      <el-button
        v-show="documentPages.length"
        slot="title"
        style="margin: 0"
        :box="true"
        @click="save"
      >
        Spara
      </el-button>
    </div>

    <div
      slot="body"
      class="text-center"
      style="margin-top: 10px"
    >
      <div
        id="presentUploadedPdf"
        class="text-center"
        style="max-height: 600px; overflow-y: scroll"
      />
      <exam-loader v-if="pdfIsLoading" />
      <h3 v-if="pdfIsLoading">
        Om dokumentet består av många sidor kan detta ta upp till 1 minut.
      </h3>
      <h4
        v-if="uploadTooLarge"
        style="color: red"
      >
        Filen du försöker använda är för stor.
      </h4>
    </div>

    <form
      style="display: none"
      enctype="multipart/form-data"
    >
      <input
        id="pdfUpload"
        type="file"
        name="file"
        accept="application/pdf"
      >
      <input type="submit">
    </form>
  </div>
</template>

<script>
import FirebaseFileUpload from 'Modules/FirebaseFileUpload.js'
import Document from 'Modules/Document.js'

export default {
  data() {
    return {
      pdfIsLoading: false,
      uploadTooLarge: false,
      documentPages: [],
      documentPDF: null,
    }
  },
  mounted() {
    window.addEventListener('message', () => {
      this.documentPages = []
      window.document.querySelector('#presentUploadedPdf').innerHTML = ''
    })

    window.$('#pdfUpload').change((event) => {
      const file = event.target.files[0]
      if (!file) {
        return
      }

      this.pdfIsLoading = true
      this.uploadTooLarge = false

      document.querySelector('#presentUploadedPdf').innerHTML = ''

      FirebaseFileUpload.kmUpload(file)
        .then(({ urls, documentPDF }) => {
          this.documentPages = urls
          this.documentPDF = documentPDF
          Document.view(document.querySelector('#presentUploadedPdf'), { documentPages: urls }).then(() => {
            this.pdfIsLoading = false
          })
        })
        .catch((err) => {
          console.log(err)
          this.uploadTooLarge = true
        })
    })
  },
  methods: {
    pdfUpload() {
      window.$('#pdfUpload').click()
    },
    save() {
      window.parent.postMessage({ documentPages: this.documentPages, documentPDF: this.documentPDF }, '*')
    },
  },
}
</script>

<style scoped>
div {
  color: black;
}
</style>
