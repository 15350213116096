
















































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

@modal-width: 1000px;

.print {
  width: @modal-width;
  max-width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: stretch;
  margin-bottom: 30px;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 23px #0000002e;

  @media (max-width: @modal-width) {
    border-radius: 0;
  }

  &__loader {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__error {
    flex: 1;
    padding: @spacing-10;
    text-align: center;
    font-size: @font-size-medium;
  }
}

.left {
  width: 300px;
  box-shadow: 5px 3px 7px #bdbdbd;
  z-index: 1;
  padding: 20px;
}

.right {
  flex-grow: 1;
  display: flex;
  background: #f2f2f2;

  @media (max-width: @modal-width) {
    overflow-x: scroll;
  }
}

.print-wrapper {
  z-index: 9999;
  height: 100vh;
  background: rgba(0, 0, 0, 20%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.page {
  width: 600px;
  margin: auto;
  min-height: 800px;
  margin-top: 10px;
  background: white;
  border-radius: 3px;
  box-shadow: 2px 3px 7px #c5c5c5;
  padding: 20px;
  position: relative;
}

.print-scroller {
  position: relative;
  overflow-y: scroll;
  overflow-x: none;
  height: ~'calc(100vh - 20px)';
  width: 100%;
  padding-bottom: 100px;
  font-size: 12px;
}

.performDate {
  font-size: 12px;
}

.ExamPrint {
  &__feedbacks {
    &__title {
      font-weight: bold;
    }

    &__text {
      padding: var(--spacing-3);
      margin-bottom: var(--spacing-1);
      background-color: var(--gray-100);
      border-radius: var(--radius-sm);
    }
  }
}

