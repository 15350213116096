



























































































































.GradingCommentEditor {
  &__text-area {
    display: flex;
    align-items: baseline;
    border: var(--border-width-1) solid var(--gray-300);
    border-radius: var(--radius-md);
    padding: var(--spacing-4);

    textarea {
      max-width: 100%;
      all: unset;
      margin-left: var(--spacing-2);
      flex: 1;
      overflow: hidden;
    }

    margin-bottom: var(--spacing-2);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-xs);

    &__info {
      color: var(--gray-700);
      font-size: var(--font-size-xs);
    }

    &__error {
      color: var(--red-700);
      font-size: var(--font-size-xs);
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      &__cancel {
        margin-right: var(--spacing-3);
        padding: 0 var(--spacing-1);
        color: var(--gray-700);
      }
    }
  }
}
