<template>
  <div
    v-show="show"
    class="image-toolbar image-toolbar-wrapper"
  >
    <div
      v-tooltip.left="__('Zooma in')"
      class="image-toolbar"
      @click="open"
    >
      <i
        class="fa fa-search-plus image-toolbar"
      />
    </div>
    <div
      v-show="showEdit"
      v-tooltip.left="__('Redigera')"
      class="image-toolbar"
      @click="edit"
    >
      <i class="fa fa-paint-brush image-toolbar" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

const HOVER_BOX_SHADOW = '1px 6px 30px #e7e7e7'

export default {
  props: {
    showEdit: Boolean,
  },
  data() {
    return {
      show: false,
      top: 0,
      right: 0,
      currentTarget: null,
    }
  },
  created() {
    window.K$.events.$on('register-image-toolbar', (root, selector) => {
      // Listen for scroll event on correct scrolling element to hide toolbar
      ;['#exam-builder-overflow', '#kunskapsmatrisen-overflow', '#question-area-write-overflow']
        .map((scrollSelector) => document.querySelector(scrollSelector))
        .filter((_) => _)
        .forEach((scroller) => {
          scroller.addEventListener('scroll', () => (this.show = false))
        })

      // We have to use classic event listeners here since exam images are inserted via raw HTML.
      // This uses jQuery delegated events to only register one single eventlistener that reacts
      // to mousemove events on images.
      window.$(root).on('mousemove', `${selector} img`, (e) => {
        if (!e.target) {
          return
        }

        // Images inside of MCQ or Match questions should not get a toolbar
        if (
          e.target &&
          e.target.closest &&
          (e.target.closest('.rq__match-items') || e.target.closest('.rq__multiple-choice'))
        ) {
          return
        }

        this.currentTarget = e.target

        // Set styles instead of a class to temporarily overwrite any set style
        this.currentTarget.style.boxShadow = HOVER_BOX_SHADOW
        const { top, left, width } = this.currentTarget.getBoundingClientRect()

        this.$el.style.top = `${top + 20}px`
        this.$el.style.left = `${left + Math.round(width) - 10}px`
        this.show = true
      })

      window.$(root).on('mouseleave', `${selector} img`, (e) => {
        if (!e.target) {
          return
        }

        // Hovering the toolbar triggers a mouseleave on the image
        if (e.relatedTarget && e.relatedTarget.classList.contains('image-toolbar')) {
          return
        }

        e.target.style.boxShadow = '' // Yes, this removes any initial box-shadow, which is never used
        this.show = false
      })
    })
  },
  methods: {
    edit() {
      this.show = false
      if (!this.currentTarget) {
        return
      }

      // The Same Origin Policy only allows canvas editing if CORS headers are set, so limit the toolbar
      // to images in our control (which should be all new images, but som KM questions might refer to other sources).
      if (this.currentTarget.src.slice(0, 41) === 'https://firebasestorage.googleapis.com/v0') {
        window.K$.drawingImageOnLoad = this.currentTarget.src
        window.K$.events.$emit('add-image-to-drawing-area', this.currentTarget.src)
        return
      }

      if (this.currentTarget.src.slice(0, 46) === 'https://www.kunskapsmatrisen.se/uppgiftsbilder') {
        // Force cache invalidation on the image URL to let Azure set CORS headers (which are only set on the XHR
        // request and not on the original image display request, which is otherwise cached resulting in a CORS issue.
        // Sounds like a misconfigured "Vary" header on Azure's end.)
        window.K$.drawingImageOnLoad = `${this.currentTarget.src}?invalidate-cache`
        window.K$.events.$emit('add-image-to-drawing-area', `${this.currentTarget.src}?invalidate-cache`)
        return
      }

      // Load other images via proxy to get CORS headers
      // const URL = 'http://localhost:5001/teachiq-converter/europe-west1/imageLoader'
      const URL = 'https://europe-west1-teachiq-converter.cloudfunctions.net/imageLoader'
      axios.post(URL, { url: this.currentTarget.src }, { responseType: 'arraybuffer' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png' }))
        window.K$.drawingImageOnLoad = url
        window.K$.events.$emit('add-image-to-drawing-area', url)
      })
    },
    open() {
      this.show = false
      window.K$.events.$emit('show-image-larger', this.currentTarget.src)
    },
  },
}
</script>

<style scoped>
div.image-toolbar-wrapper {
  z-index: 999;
  position: absolute;
  top: 0;
  text-align: center;
  background: white;
  box-shadow: 0 2px 17px #d6d6d6;
  width: 40px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: block;
}

i.fa {
  font-size: 25px;
  padding: var(--spacing-1);
  cursor: pointer;
}

i:hover {
  transform: scale(1.15);
}
</style>
