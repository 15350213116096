























































































.ExamRevealIdentity {
  .reveal-identities-modal {
    &__reveal-button {
      margin-bottom: var(--spacing-2);
    }

    &__title {
      display: flex;
      justify-content: center;
      user-select: none;

      i {
        margin-left: var(--spacing-1);
        color: var(--gray-500);
      }
    }
  }
}

