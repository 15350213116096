<template>
  <div>
    <text-button
      v-if="status !== 'loaded'"
      :loading="status === 'loading'"
      mode="secondary"
      :title="__('Visa nyligen raderad data')"
      @click="load"
    />
    <text-button
      v-if="status === 'loaded'"
      mode="secondary"
      :title="__('Dölj')"
      @click="hide"
    />
    <div>
      <div
        v-if="data.length === 0 && status === 'loaded'"
        style="margin-top: 10px"
      >
        {{ __('Du har ingen nyligen raderad data') }}
      </div>

      <div
        v-for="exam in data"
        :key="exam.id"
        class="restore-card"
      >
        <div style="display: flex">
          <div style="margin-right: 10px">
            <i class="fa fa-file-o" />
          </div>
          <div style="width: auto; flex: 1">
            <div>{{ exam.name }}</div>
            <div
              class="deleted-text"
            >
              {{ __('Skapad') }} {{ getDate(exam.created_at) }}
            </div>
            <div
              v-if="exam.deleted_at"
              class="deleted-text"
            >
              {{ __('Raderad') }} {{ getDate(exam.deleted_at) }}
            </div>
          </div>
          <div
            v-if="exam.deleted_at"
            class="restore"
            @click="restoreExam(exam)"
          >
            {{ __('Återskapa') }}
          </div>
        </div>

        <div
          v-for="student in exam.trashedStudents"
          :key="student.id"
          style="margin-top: 15px"
        >
          <hr>
          <div style="display: flex">
            <div style="margin-right: 10px">
              <i class="fa fa-user" />
            </div>
            <div style="width: auto; flex: 1">
              <div>{{ Student.displayName(student) }}</div>
              <div
                class="deleted-text"
              >
                {{ __('Skapad') }} {{ getDate(student.created_at) }}
              </div>
              <div
                v-if="student.deleted_at"
                class="deleted-text"
              >
                {{ __('Raderad') }} {{ getDate(student.deleted_at) }}
              </div>
            </div>
            <div
              v-if="student.deleted_at"
              class="restore"
              @click="restoreStudent(student)"
            >
              {{ __('Återskapa') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTime from 'Modules/DateTime.js'
import Student from 'Modules/Student.js'

export default {
  data() {
    return {
      Student,
      status: '',
      data: [],
    }
  },
  methods: {
    hide() {
      this.status = ''
      this.data = []
    },
    restoreExam(exam) {
      window.axios.post(`/api/profile/trash/restore-exam/${exam.id}`).then(() => {
        this.load()
      })
    },
    restoreStudent(student) {
      window.axios.post(`/api/profile/trash/restore-student/${student.id}`).then(() => {
        this.load()
      })
    },
    load() {
      this.status = 'loading'

      window.axios.get('/api/profile/trash').then(({ data }) => {
        this.status = 'loaded'

        this.data = data.exams
          .map((exam) => {
            return {
              ...exam,
              trashedStudents: data.trashedStudents.filter((student) => Number(student.exam_id) === Number(exam.id)),
            }
          })
          .filter((exam) => {
            return exam.trashedStudents.length > 0 || exam.deleted_at
          })
      })
    },
    getDate(datestring) {
      return DateTime.formatFromUTC(datestring, 'YYYY-MM-DD')
    },
  },
}
</script>

<style scoped>
hr {
  width: 100%;
  max-width: none;
}

.restore-card {
  margin-top: 15px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 20%);
}

.restore {
  width: auto;
  font-size: 12px;
  color: red;
  margin: auto;
  text-decoration: underline;
  cursor: pointer;
}

.deleted-text {
  font-size: 10px;
  color: #757575;
}
</style>
