<template>
  <div class="font-light">
    <div class="bg-white container h-full max-w-screen-md mb-72 mt-6 mx-auto px-6 rounded shadow">
      <div class="bg-white px-6 py-2">
        <div class="md:col-span-2 md:mt-0 mt-5">
          <div class="gap-3 grid grid-cols-6 m">
            <h3 class="col-span-6 font-medium leading-6 mt-8 text-2xl text-gray-800 text-left">
              {{ __('Testimonial') }}
            </h3>

            <div class="col-span-6">
              <label
                for="message"
                class="block font-normal leading-5 mt-2 text-gray-800 text-sm"
              >
                {{
                  __(
                    'How has Exam.net helped you as a teacher? Please tell us about some specific things you really like about Exam.net (e.g. ease-of-use, auto-marking, PDF upload, tools, student experience, etc).',
                  )
                }}
              </label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <pre>{{ message }}</pre>
              </div>
            </div>

            <div class="col-span-6">
              <label
                for="schoolName"
                class="block font-normal mt-6 text-gray-800"
              >{{
                __('School information (change if incorrect):')
              }}</label>
              {{ schoolName }}, {{ city }}, {{ country }}
            </div>

            <p class="col-span-6 font-normal mt-6 text-gray-800">
              {{ __('Your name and title (optional):') }}
            </p>

            <div class="col-span-6 sm:col-span-3">
              {{ title }} {{ name }}
            </div>

            <p class="col-span-6 font-normal mt-4 text-gray-800">
              {{ __('The subjects you teach in:') }}
            </p>

            <div class="col-span-5 flex justify-between">
              <div
                v-for="(subject, key) in subjects"
                :key="key"
                class="col-span-1"
              >
                <label
                  :for="key"
                  class="font-normal inline-block text-gray-800 text-sm"
                >{{ __(key) }}</label>
                <input
                  :id="key"
                  v-model="subject.teach"
                  type="checkbox"
                  :name="key"
                  class="align-middle inline-block sm:leading-5 sm:text-sm"
                >
              </div>
            </div>

            <div class="col-span-6 mt-2">
              <label
                for="othersubjects"
                class="font-normal inline-block text-gray-800 text-sm"
              >
                {{ __('Please specify subject(s):') }}
              </label>
              {{ othersubjects }}
            </div>

            <p class="col-span-6 font-normal mt-4 text-gray-800">
              {{ __('The level you primarily teach at (you can select several):') }}
            </p>

            <div class="col-span-6">
              <div
                v-for="(level, key) in levels"
                :key="key"
              >
                <div class="inline-block">
                  <label
                    :for="key"
                    class="font-normal ml-4 text-gray-800 text-sm"
                  >{{ __(key) }}</label>
                </div>
                <div class="float-left inline-block">
                  <input
                    :id="key"
                    v-model="level.teach"
                    type="checkbox"
                    :name="key"
                    class="sm:leading-5 sm:text-sm"
                  >
                </div>
              </div>
            </div>

            <p class="col-span-6 leading-5 mt-6 text-gray-800">
              {{
                __(
                  'When you have submitted your testimonial we will review it and potentially publish it in the testimonial section. Let us know what name you would like to appear alongside your testimonial:',
                )
              }}
            </p>

            <div class="col-span-6 mt-2">
              <div class="inline-block">
                <label
                  for="not_anonymous"
                  class="font-normal ml-4 text-gray-800"
                >{{
                  __('You can publish my testimonial along with my full name.')
                }}</label>
              </div>
              <div class="float-left inline-block">
                <input
                  id="not_anonymous"
                  v-model="anonymous"
                  type="radio"
                  name="not_anonymous"
                  :value="false"
                  class="inline-block sm:leading-5 sm:text-sm"
                >
              </div>
            </div>

            <div class="col-span-6">
              <div class="inline-block">
                <label
                  for="anonymous"
                  class="font-normal ml-4 text-gray-800"
                >{{
                  __('You can publish my testimonial but leave my surname out.')
                }}</label>
              </div>
              <div class="float-left inline-block">
                <input
                  id="anonymous"
                  v-model="anonymous"
                  type="radio"
                  name="anonymous"
                  :value="true"
                  class="inline-block sm:leading-5 sm:text-sm"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white px-6 py-2">
        <div class="md:col-span-2 md:mt-0 mt-5">
          <div class="gap-3 grid grid-cols-6">
            <h3 class="col-span-6 font-medium leading-6 mt-8 text-gray-800 text-left text-lg">
              {{ __('Submit your interest to work with us') }}
            </h3>

            <h3 class="col-span-6 font-medium leading-6 mt-4 text-base text-gray-800 text-left">
              {{ __('Teaching experience') }}
            </h3>

            <p class="col-span-6 leading-5 text-gray-800">
              {{
                __(
                  'What subjects do you have teaching experience in? Tick the boxes that apply to you. You can add more information in the text box below.',
                )
              }}
            </p>

            <div class="col-span-5 leading-5 mt-4 text-gray-800 text-sm">
              <div class="col-span-5 gap-2 grid grid-cols-5">
                <div />
                <div>{{ __('University') }}</div>
                <div>{{ __('Students age 16 or above') }}</div>
                <div>{{ __('Students age 11-15') }}</div>
                <div>{{ __('Students age 10 or below') }}</div>
              </div>
              <div
                v-for="(subjectslevel, subject) in subjectslevels"
                :key="subject"
                class="mt-2"
              >
                <div class="col-span-5 gap-2 grid grid-cols-5">
                  <div>{{ __(subject) }}</div>
                  <div
                    v-for="(_, level) in subjectslevel"
                    :key="level"
                  >
                    <input
                      v-model="subjectslevel[level]"
                      type="checkbox"
                      :name="subject"
                      class="duration-150 ease-in-out form-checkbox h-4 text-indigo-600 transition w-4"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-span-6 mt-6">
              <label
                for="experience"
                class="align-top font-normal inline-block pr-4 text-gray-800 text-sm"
              >{{
                __('Other teaching experience:')
              }}</label>
              <pre>{{ experience }}</pre>
            </div>

            <h3 class="col-span-6 font-medium leading-6 mt-4 text-gray-800 text-left text-sm">
              {{ __('Language competence') }}
            </h3>

            <p class="col-span-6 leading-5 mt-2 text-gray-800 text-sm">
              {{ __('What languages are you fluent in? You can select several.') }}
            </p>

            <div class="col-span-5 gap-1 grid grid-cols-4 mt-2">
              <div
                v-for="(language, key) in languages"
                :key="key"
              >
                <div class="inline-block">
                  <label
                    :for="key"
                    class="font-normal text-gray-800 text-sm"
                  >{{ __(key) }}</label>
                </div>
                <div class="float-left inline-block mr-4">
                  <input
                    :id="key"
                    v-model="language.competence"
                    type="checkbox"
                    :name="key"
                    class="align-middle sm:leading-5 sm:text-sm"
                  >
                </div>
              </div>
            </div>

            <div class="col-span-6 mt-2">
              <label
                for="additional_language"
                class="font-normal inline-block text-gray-800 text-sm"
              >{{
                __('Other languages:')
              }}</label>
              {{ otherlanguages }}
            </div>

            <h3 class="col-span-6 font-medium leading-6 mt-8 text-base text-gray-800 text-left">
              {{ __('System development competence') }}
            </h3>

            <p class="col-span-6 leading-5 text-gray-800">
              {{
                __(
                  'Apart from teaching skills, the other major area of expertise we are looking for is within system development.',
                )
              }}
            </p>

            <p class="col-span-6 leading-5 text-gray-800">
              {{ __('Describe your experience within system development by ticking the appropriate boxes below:') }}
            </p>

            <div class="col-span-5 mt-4 text-sm">
              <div class="col-span-5 gap-4 grid grid-cols-5 leading-5 text-gray-800 text-sm">
                <div />
                <div>{{ __('No experience') }}</div>
                <div>{{ __('Less than 1 year of experience') }}</div>
                <div>{{ __('1-3 years of experience') }}</div>
                <div>{{ __('4+ years of experience') }}</div>
              </div>
              <div
                v-for="(dev, key) in devs"
                :key="key"
                class="border-b border-gray-200 py-2"
              >
                <div class="col-span-5 gap-4 grid grid-cols-5">
                  <div>{{ __(key) }}</div>
                  <div
                    v-for="experienceValue in ['no', '<1', '13', '4+']"
                    :key="experienceValue"
                  >
                    <input
                      v-model="devs[key]"
                      :value="experienceValue"
                      type="radio"
                      :name="key"
                      class="duration-150 ease-in-out form-radio h-4 text-indigo-600 transition w-4"
                    >
                  </div>
                </div>
              </div>
            </div>

            <p class="col-span-6 leading-5 mt-6 text-gray-800">
              {{
                __(
                  'Please describe your programming experience in more detail (you can also include a GitHub profile or similar):',
                )
              }}
            </p>

            <div class="col-span-6">
              <pre>{{ devexperience }}</pre>
            </div>

            <h3 class="col-span-6 font-medium leading-6 mt-6 text-base text-gray-800 text-left">
              {{ __('Contact details') }}
            </h3>

            <div class="col-span-6 mb-32">
              {{ email }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacher: {
      type: Object,
      required: true,
    },
    testimonial: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      denied: false,
      showModal: false,
      message: '',
      schoolName: this.teacher?.school?.namn || '',
      city: this.teacher?.school.ort || '',
      country: this.teacher?.country,
      name: `${this.teacher?.firstName || ''} ${this.teacher?.lastName || ''}`.trim(),
      title: '',
      subjects: {
        Mathematics: { teach: '' },
        Sciences: { teach: '' },
        Languages: { teach: '' },
        'Social Sciences': { teach: '' },
      },
      othersubjects: '',
      otherlanguages: '',
      levels: {
        University: { teach: '' },
        College: { teach: '' },
        'School, students age 16 or above': { teach: '' },
        'School, students age 11-15': { teach: '' },
        'School, students age 10 or below': { teach: '' },
      },
      anonymous: false,
      subjectslevels: {
        Mathematics: { university: false, high: false, middle: false, low: false },
        Sciences: { university: false, high: false, middle: false, low: false },
        Languages: { university: false, high: false, middle: false, low: false },
        'Social Sciences': { university: false, high: false, middle: false, low: false },
      },
      experience: '',
      languages: {
        English: { competence: '' },
        Italian: { competence: '' },
        Spanish: { competence: '' },
        Portuguese: { competence: '' },
        Dutch: { competence: '' },
        Mandarin: { competence: '' },
        Arabic: { competence: '' },
        French: { competence: '' },
      },
      devs: {
        'Web Backend': false,
        'Web Frontend': false,
        'App development': false,
        'PHP (Laravel)': false,
        Docker: false,
        'SQL/Database': false,
        'Cloud (e.g. Azure)': false,
        'Firebase/GCP': false,
        'Vue.js': false,
        TypeScript: false,
        'Node.js': false,
        Linux: false,
      },
      devexperience: '',
      email: '',
      type: '',
      currentStep: 1,
      steps: [
        { number: 1, name: this.__('Submit a testimonial') },
        { number: 2, name: this.__('Submit your interest to work with us') },
      ],
    }
  },
  // watch: {},
  created() {
    if (this.testimonial) {
      const data = JSON.parse(this.testimonial.data)
      if (!data) {
        return
      }

      this.message = data.message
      this.schoolName = data.schoolName
      this.city = data.city
      this.country = data.country
      this.name = data.name
      this.title = data.title
      this.subjects = data.subjects
      this.othersubjects = data.othersubjects
      this.otherlanguages = data.otherlanguages
      this.levels = data.levels
      this.anonymous = data.anonymous
      this.subjectslevels = data.subjectslevels
      this.experience = data.experience
      this.languages = data.languages
      this.devs = data.devs
      this.devexperience = data.devexperience
      this.email = data.email
      this.type = data.type
      this.currentStep = data.currentStep
    }
  },
  methods: {
    close() {
      window.close()
    },
  },
}
</script>

<style scoped>
.user-terms > a {
  text-decoration: underline;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin-top: 20px;
  font-size: 14px;
  font-style: italic;
  font-family: Arial, sans-serif;
}
</style>
