



















































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.student-menu-wrapper {
  height: 100vh;
  background-color: rgb(46, 49, 55);
  width: 240px;
  min-width: 240px;

  &--folded {
    width: 100px;
    min-width: 100px;
  }
}

.student-menu {
  user-select: none;
  display: flex;
  height: 100%;
  flex-direction: column;

  &__student-info {
    display: flex;
    align-items: baseline;
    padding: @spacing-2 @spacing-2 0 @spacing-2;
    justify-content: center;
  }

  &__student-info-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: @theme-primary;
    height: 50px;
    width: 100%;

    &--shadow {
      box-shadow: 0 12px 20px 0 #111010;
    }
  }

  &__icon-lock {
    padding-top: @spacing-3;
    margin-right: @spacing-2;
  }

  &__icon-anonymous {
    margin-left: var(--spacing-2);
  }

  &__name {
    white-space: nowrap;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__nav {
    overflow-y: auto;

    &__ul {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0 -30px #000;
      background-color: rgb(59, 57, 57);
    }

    &::-webkit-scrollbar-thumb {
      background-color: @theme-primary;
    }

    &--shadow {
      box-shadow: inset 0 -12px 15px 0 #111010;
    }
  }

  &__item {
    display: flex;
  }

  &__item-icon {
    margin-right: @spacing-2;
    width: 17.5px;
  }

  &__item-image {
    margin-right: @spacing-2;
    width: 17.5px;

    &--folded {
      width: 30px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &__item-name {
    flex: 1;
  }
}

.status-bar-wrapper {
  margin-top: auto;
}

.status-bar {
  color: @theme-primary;
  bottom: 90px;
  width: 100%;
  font-weight: var(--font-thin);
  text-align: center;
  font-size: @font-size-large;
  height: 100%;

  &__timer {
    cursor: pointer;
    font-size: @font-size-medium;
  }

  &__clock {
    font-size: @font-size-medium;

    &--small {
      font-size: var(--font-size-sm);
    }
  }

  &__clock-icon {
    width: 100%;
    font-size: @font-size-medium;
  }

  &__exam-key {
    width: 100%;
    font-size: @font-size-small;
  }

  &__logo {
    width: 100%;
    text-align: center;
  }

  &__information {
    display: flex;
    line-height: 1;
  }
}

li {
  z-index: 1;
  margin-bottom: 0;
}

.animate-box-shadow {
  transition: box-shadow 0.2s ease-in;
}

.bottom-logo {
  width: 90px;
  margin-bottom: @spacing-1;
}

.student-settings {
  cursor: pointer;
  font-size: var(--font-size-default);
}
