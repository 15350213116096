















































































@import 'Less/variables.less';

.colorExam {
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &__info {
      font-size: @font-size-small;
    }

    &__colorPicker {
      margin: @spacing-5 0;
    }
  }
}
