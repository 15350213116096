<template>
  <div>
    <div style="height: 100vh; display: flex; flex-direction: column; align-items: center; justify-content: center">
      <img
        :alt="__('Logotyp för Exam.net')"
        style="position: fixed; top: 20px; width: 100px"
        src="https://static.exam.net/img/2021/light-logo.png"
      >

      <div style="display: flex">
        <div
          ref="choices"
          class="endMargin"
          style="display: flex; align-items: center; margin-left: 70px"
        >
          <img
            ref="image"
            :alt="__('Logotyp för Exam.net')"
            style="width: 250px"
            class="imageMarginEnd"
            src="https://static.exam.net/img/2021/logo-square-white.png"
          >

          <div
            ref="buttons"
            class="startButtons"
          >
            <text-button
              style="margin-top: 20px; margin-bottom: 30px"
              big
              :title="__('Download macOS app')"
              @click="downloadMacOS"
            />
            <p style="font-size: 12px; margin: 0; margin-top: -5px; padding: 0">
              Version {{ version }}
            </p>
            <p style="font-size: 12px">
              Requires macOS 10.13 or later.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import macOS from 'Modules/macOS.js'

export default {
  computed: {
    version() {
      return macOS.versions().latest
    },
  },
  mounted() {
    window.setTimeout(() => {
      this.$refs.buttons.classList.add('endButtons')
    }, 300)
  },
  methods: {
    downloadMacOS() {
      window.open(macOS.downloadURL())
    },
  },
}
</script>

<style scoped>
h1 {
  color: black;
}

.startButtons {
  opacity: 0;
}

.endButtons {
  opacity: 1;
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.endMargin {
  margin-right: 50px;
}

.imageMarginEnd {
  margin-top: 0;
}

.update {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 10px;
  padding: 5px;
  text-align: right;
}
</style>
