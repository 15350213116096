




































































































































































































































































































































































































.StudentResult {
  padding-bottom: var(--spacing-4);

  .topBar {
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacing-3);

    &__returnToOverview {
      color: var(--blue-600);
      display: flex;
      align-items: center;
      text-decoration: none;

      &__icon {
        font-weight: var(--font-semibold);
        font-size: var(--font-size-lg);
        margin-right: var(--spacing-1);
      }

      &__text {
        line-height: normal;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }

  &__answer {
    &__header {
      border-bottom: var(--divider-border);
      margin-top: var(--spacing-10);
      margin-bottom: var(--spacing-6);
      padding: var(--spacing-2) 0;

      &__heading {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h3 {
          font-size: var(--font-size-lg);
          font-weight: var(--font-semibold);
          margin: 0;
          margin-right: var(--spacing-5);
        }
      }
    }
  }

  &__actions {
    display: flex;

    & > *:not(:last-child) {
      margin-right: var(--spacing-3);
    }
  }

  &__SEBWarnings {
    margin-top: var(--spacing-3);
  }

  &__title {
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-6);
  }

  &__attachments-wrapper {
    margin-top: var(--spacing-3);
    display: flex;
    flex-wrap: wrap;
  }

  &__attachment {
    display: flex;
    flex-wrap: wrap;
  }

  &__student-answer {
    &::v-deep {
      .katex-display {
        display: inline-block;
      }

      img {
        max-width: 100%;
      }
    }
  }
}
