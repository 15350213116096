




















































@color-danger-light: #f2dede;
@color-danger-medium: #ebccd1;
@color-danger-dark: #a94442;
@color-warning-light: #fcf8e3;
@color-warning-medium: #faebcc;
@color-warning-dark: #805512;
@color-success-light: #dff0d8;
@color-success-medium: #d6e9c6;
@color-success-dark: #3c763d;
@color-info-light: #d9edf7;
@color-info-medium: #bce8f1;
@color-info-dark: #31708f;

.status-card {
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px 15px;
  border-radius: 5px;
  box-sizing: border-box;

  // Default as info
  background-color: @color-info-light;
  border: 1px solid @color-info-medium;
  color: @color-info-dark;

  &--warning {
    background-color: @color-warning-light;
    border: 1px solid @color-warning-medium;
    color: @color-warning-dark;
  }

  &--success {
    background-color: @color-success-light;
    border: 1px solid @color-success-medium;
    color: @color-success-dark;
  }

  &--info {
    background-color: @color-info-light;
    border: 1px solid @color-info-medium;
    color: @color-info-dark;
  }

  &--danger {
    background-color: @color-danger-light;
    border: 1px solid @color-danger-medium;
    color: @color-danger-dark;
  }

  &__icon {
    display: inline-block;

    &.icon {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}
