









































































.deleteExam {
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  &__footer {
    display: flex;
    justify-content: center;

    &__button {
      margin: 0 4px;
    }
  }
}
