






































































































































































@import 'Less/variables.less';

@animation-duration: 0.5s;
@animation-duration-2: 0.4s;
@tilt: perspective(900px) rotateY(-10deg) rotateX(20deg) rotateZ(1deg);
@tilt-2: perspective(900px) rotateY(10deg) rotateX(10deg) rotateZ(1deg);

.km-usp {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 400px;

  &__pagination {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px 0;
    justify-content: space-between;

    &__item {
      display: flex;
      align-items: center;
      border-radius: 0 5px 5px 0;
      color: #222;
      min-height: 50px;
      padding: 10px 20px;
      cursor: pointer;
      box-sizing: border-box;
      font-size: 14px;

      &--active {
        background-color: @theme-blue;
        color: #fff;
      }
    }
  }

  &__display {
    display: flex;
    flex-grow: 1;
    margin: 0;
    overflow: hidden;
    position: relative;
    margin-left: 20px;
  }
}

.showcase {
  &__types {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;

    &__card {
      position: absolute;
      top: 0;
      left: 0;
      height: 120px;
      width: 120px;
      overflow: hidden;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 20%);

      &--1 {
        transform: translateY(-130px) translateX(-120px);

        &--active {
          transform: translateY(40px) translateX(20px);
        }
      }

      &--2 {
        transform: translateY(-200px) translateX(160px);

        &--active {
          transform: translateY(110px) translateX(190px);
        }
      }

      &--3 {
        transform: translateY(410px) translateX(0);

        &--active {
          transform: translateY(240px) translateX(100px);
        }
      }

      &--4 {
        transform: translateY(-130px) translateX(400px);

        &--active {
          transform: translateY(60px) translateX(350px);
        }
      }

      &--5 {
        transform: translateY(450px) translateX(450px);

        &--active {
          transform: translateY(200px) translateX(400px);
        }
      }
    }
  }

  &__exam {
    position: relative;
    top: 10px;
    margin: auto;
    transform: translateY(400px);
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    overflow: hidden;

    &--active {
      transform: translateY(0) @tilt-2;
      box-shadow: -5px 10px 15px rgba(0, 0, 0, 10%);
    }

    &--scroll-down {
      transform: translateY(-200px) @tilt;
      box-shadow: 5px 5px 35px rgba(0, 0, 0, 12%);
    }

    &--scroll-right {
      transform: translateY(-200px) translateX(150px);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 12%);
    }

    &--scroll-middle {
      transform: translateY(0) translateX(0);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 12%);
    }
  }

  &__panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    transform: translateX(-100%);

    &--active {
      transform: translateX(0);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 20%);
    }
  }

  &__info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5px 20px;
    box-sizing: border-box;
    transform: translateY(30px);
    opacity: 0;
    z-index: 2;

    &--active {
      transform: translateY(20px);
      opacity: 1;
    }
  }

  &__logo {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  &__grade {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;

    &__comment {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(230px) translateX(30px) scale(1);
      opacity: 0;

      &--active {
        transform: translateY(210px) translateX(30px) scale(1);
        opacity: 1;
      }
    }

    &__check {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transform: translateY(-60px) translateX(370px) scale(1.5);

      &--active {
        transform: translateY(-30px) translateX(350px) scale(1);
        opacity: 1;
      }
    }
  }
}

.animation {
  transition: all @animation-duration ease-in-out;
}

.animation-2 {
  transition: all @animation-duration-2 ease-in-out;
}
