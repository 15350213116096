





































































































































































































































































































































































































































































































































































































































@green-light: #a1d767;
@green-main: #36a946;
@blue-main: #647eb8;
@red-main: #d84a39;

.mobile-scan {
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  min-height: 100vh;
  color: black;

  &__icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__frame {
      margin: 10px 5px;
    }
  }

  &__status {
    left: 0;
    right: 0;
    padding: 0 10px;
    min-height: 60px;
    border-radius: 10px;
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    &__icon {
      margin: 0 15px;
    }

    &--active {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 20%);
      color: @blue-main;
    }

    &--success {
      color: @green-main;
      border: 1px solid @green-main;
    }

    &--danger {
      color: @red-main;
      border: 1px solid @red-main;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
