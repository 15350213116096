



































































































.ExamEventLog {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: var(--spacing-2);
    font-weight: var(--font-semibold);
  }

  &__update {
    margin-bottom: var(--spacing-2);

    .time {
      color: var(--gray-700);
      margin-right: var(--spacing-2);
    }
  }

  &__show-more {
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    border-top: 1px solid var(--gray-300);

    i {
      font-size: var(--font-size-default);
    }
  }
}

