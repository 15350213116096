

































.UnderlinedInput {
  input {
    border: none;
    border-bottom: var(--border-width-1) solid var(--gray-700);
    border-top-left-radius: var(--radius-default);
    border-top-right-radius: var(--radius-default);

    &:focus {
      outline: 1px solid var(--gray-100);
      background-color: var(--gray-50);
      border-bottom: var(--border-width-1) solid var(--blue-500);
    }
  }
}
