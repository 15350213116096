<template>
  <div
    class="wrapper"
    :class="{ blur: status === 'loading' }"
  >
    <br>

    <div
      v-if="loaded"
      class="stat"
    >
      <div class="countries">
        <table
          class="table table-striped"
          style="font-size: 10px"
        >
          <tr>
            <th>Land</th>
            <th @click="sort('logins')">
              Antal lärare
            </th>
            <th @click="sort('logins')">
              Antal skolor
            </th>
            <th @click="sort('logins')">
              Inlogg (60 d.)
            </th>
            <th @click="sort('logins')">
              Inlogg (Totalt)
            </th>
            <th @click="sort('exams')">
              Prov (60 d.)
            </th>
            <th @click="sort('exams')">
              Prov (Totalt)
            </th>
            <th @click="sort('students')">
              Elevstarter (60 d.)
            </th>
            <th @click="sort('students')">
              Elevstarter (Totalt)
            </th>
          </tr>

          <tr
            v-for="country in countries"
            :key="country.name"
          >
            <td>
              <div
                class="clickable"
                @click="gotoCountry(country)"
              >
                {{ country }}
              </div>
            </td>
            <td>{{ countryStatisticsNumberOfUsers(country) }}</td>
            <td>{{ countrySchoolIds[country].length }}</td>
            <td>{{ countryStatisticsSixty(country, 'USER_LOGIN') }}</td>
            <td>{{ countryStatisticsTotal(country, 'USER_LOGIN') }}</td>
            <td>{{ countryStatisticsSixty(country, 'EXAM_CREATED') }}</td>
            <td>{{ countryStatisticsTotal(country, 'EXAM_CREATED') }}</td>
            <td>{{ countryStatisticsSixty(country, 'EXAM_STARTED') }}</td>
            <td>{{ countryStatisticsTotal(country, 'EXAM_STARTED') }}</td>
          </tr>
        </table>
      </div>
      <div class="schoolstat">
        <div>
          <h3>Internationell statistik</h3>
          <div class="schoolInfo">
            <b>{{ internationalStatisticsSixty('USER_JOINED') }} registrerade lärare senaste 60 dagarna</b>
            <br>({{ internationalStatisticsTotal('USER_JOINED') }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ internationalStatisticsSixty('EXAM_CREATED') }} skapade prov senaste 60 dagarna</b>
            <br>({{ internationalStatisticsTotal('EXAM_CREATED') }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ internationalStatisticsSixty('EXAM_STARTED') }} elevstarter senaste 60 dagarna</b>
            <br>({{ internationalStatisticsTotal('EXAM_STARTED') }} totalt)
          </div>
          <div>
            <div
              v-for="school in schoolsByTeachers"
              :key="school.id"
              class="clickable"
              @click="gotoSchool(school.id)"
            >
              {{ school.namn }} ({{ school.users }})
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loaded">
      <text-button
        title="Ladda statistik"
        @click="fetchInternationalStatistics"
      />
    </div>

    <div
      v-show="status === 'loading'"
      style="z-index: 9999; position: fixed; top: 300px; left: 40%"
    >
      <i
        style="font-size: 300px"
        class="fa fa-spin fa-spinner"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aggregated: [],
      chosenSchoolDisplay: '',
      internationalList: [],
      internationalStatisticsChart: null,
      ort: null,
      ortToSearch: '',
      schools: [],
      status: 'done',
      countries: [],
      countrySchoolIds: [],
      loaded: false,
    }
  },
  computed: {
    schoolsByTeachers() {
      let schools = []
      schools = this.schools
      return schools.sort((a, b) => Number(b.users) - Number(a.users))
    },
  },

  updated() {
    if (this.$root.international != null) {
      this.fetchInternationalStatistics(this.$root.international)
      this.$root.international = null
    }
  },
  methods: {
    sort(column) {
      if (['logins', 'exams', 'students'].includes(column)) {
        column = `num_${column}`

        this.users.sort((a, b) => {
          if (a.stat.length === 0) {
            return 1
          }

          if (b.stat.length === 0) {
            return -1
          }

          if (b.stat[0][column] - a.stat[0][column] === 0) {
            return b.firstName.localeCompare(a.firstName)
          }

          return b.stat[0][column] - a.stat[0][column]
        })
      }

      if (column === 'latest') {
        this.users.sort((a, b) => (a.login_at === b.login_at ? 0 : a.login_at < b.login_at ? 1 : -1))
      }
    },

    countryStatisticsNumberOfUsers(country) {
      return this.schools.reduce((acc, i) => {
        if (i.country === country) {
          return acc + parseInt(i.users)
        }

        return acc
      }, 0)
    },

    countryStatisticsSixty(country, statistics) {
      return this.aggregated.reduce((acc, i) => {
        if (i.group === 'sixty' && i.type === statistics && this.countrySchoolIds[country].includes(i.school_id)) {
          return acc + i.value
        }

        return acc
      }, 0)
    },

    countryStatisticsTotal(country, statistics) {
      return this.aggregated.reduce((acc, i) => {
        if (i.group === 'total' && i.type === statistics && this.countrySchoolIds[country].includes(i.school_id)) {
          return acc + i.value
        }

        return acc
      }, 0)
    },

    internationalStatisticsSixty(statistics) {
      return this.aggregated.reduce((acc, i) => {
        if (i.group === 'sixty' && i.type === statistics) {
          return acc + i.value
        }

        return acc
      }, 0)
    },

    internationalStatisticsTotal(statistics) {
      return this.aggregated.reduce((acc, i) => {
        if (i.group === 'total' && i.type === statistics) {
          return acc + i.value
        }

        return acc
      }, 0)
    },

    gotoCountry(country) {
      this.$root.country = country
      this.$root.choice = 'Landstat'
      this.$root.$children.forEach((child) => {
        child.$forceUpdate()
      })
    },

    gotoSchool(school) {
      this.$root.school = school
      this.$root.choice = 'Skolstat'
      this.$root.$children.forEach((child) => {
        child.$forceUpdate()
      })
    },

    fetchInternationalStatistics() {
      this.status = 'loading'

      fetch('/api/site-admin/statistics/international', { credentials: 'include' })
        .then((data) => data.json())
        .then((data) => {
          this.aggregated = data.aggregated
          this.schools = data.schools

          this.countries = []
          this.countrySchoolIds = []
          this.schools.forEach((school) => {
            if (this.countrySchoolIds[school.country] == null) {
              this.countries.push(school.country)
              this.countrySchoolIds[school.country] = []
            }
            this.countrySchoolIds[school.country].push(school.id)
          })
          this.countries = this.countries.sort()
          this.status = 'done'
          this.loaded = true
        })
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  text-align: center;
}

.stat {
  display: flex;
  text-align: left;
}

.users {
  flex: 2;
}

.schoolstat {
  flex: 1;
  border: 1px solid #ccc;
  text-align: center;
  box-shadow: 5px 5px 5px #00000085;
  margin: 0 15px;
}

td {
  border-bottom: 1px #ddd solid;
  padding-left: 10px;
}

tr:nth-child(even) {
  background: #ddd;
}

tr:nth-child(odd) {
  background: #eee;
}

td.login-green {
  color: green;
}

td.login-orange {
  color: orange;
}

td.login-red {
  color: red;
}

.schoolInfo {
  margin: 20px;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.blur {
  filter: blur(10px);
}
</style>
