














































































@import 'Less/variables.less';

.inputContainer {
  position: relative;
  width: 100%;
  height: 30px;
  font-size: 12px;
  text-align: left;
  margin-top: 10px;
  color: #373737;
  background-color: #eee;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  outline: none;
}

.inputContainer:focus {
  border: 1px solid @theme-blue;
}

.warningStyle {
  color: #a94442;
  border-color: #ebcccc;
  background-color: #f2dede;
}

.successStyle {
  color: #3c763d;
  border-color: #d0e9c6;
  background-color: #dff0d8;
}
