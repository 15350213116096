<template>
  <div class="cardContainer">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.cardContainer {
  position: relative;
  width: auto;
  min-width: 200px;
  height: auto;
  min-height: 30px;
  margin-top: 10px;
  background-color: #fff;
  padding: 15px 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
}
</style>
