<template>
  <transition name="fade">
    <div
      v-show="show"
      class="image-presenter__wrapper"
      @click="show = false"
    >
      <div class="image-presenter__content">
        <img
          v-if="imageUrl.length > 0"
          :src="imageUrl"
          class="image-presenter__image"
        >
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      imageUrl: '',
    }
  },
  created() {
    window.K$.events.$on('show-image-larger', (imageUrl) => {
      this.imageUrl = imageUrl
      this.show = true
    })
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.image-presenter__wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000a6;
}

.image-presenter__content {
  width: 70%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid black;
  background: white;
  padding: 10px;
  max-width: 1000px;
}

.image-presenter__image {
  max-width: 100%;
  max-height: 90vh;
}
</style>
