




























































































































































































































































































































@import 'Less/variables.less';

.exam-area {
  padding: 0;
  height: 100vh;
  display: flex;
  flex: 1;
  overflow: hidden;
  background: white;
}

.write-answer-area {
  order: 1;
  flex: 1;
}

.resources {
  flex: 1 2 0;
  display: flex;
  width: 100%;
  max-height: 100vh;
  overflow: auto;

  &__item {
    flex: 1 2 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    &--border-right {
      border-right: solid @border-thickness #d1d1d1;
    }

    &--first {
      order: -1;
    }
  }
}

.new-write-area {
  max-height: calc(100% - var(--resource-toolbar-height));
}
