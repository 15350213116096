<template>
  <div
    class="wrapper"
    :class="{ blur: status === 'loading' }"
  >
    <div style="display: flex">
      <div style="flex: 1">
        <h3>Sök skola</h3>

        <div class="form-group">
          <vue-simple-suggest
            v-model="chosenSchoolDisplay"
            :maxSuggestions="20"
            style="height: 40px; font-size: 14px; margin-right: 15px"
            :list="schoolList"
            displayAttribute="display"
            :placeholder="__('Ange skola')"
            :filterByQuery="true"
            @select="fetchSchoolStatistics"
          />
        </div>
      </div>
    </div>

    <div class="stat">
      <div class="users">
        <table
          class="table table-striped"
          style="font-size: 10px"
        >
          <tr>
            <th>Namn</th>
            <th @click="sort('latest')">
              Senaste inlogg
            </th>
            <th @click="sort('logins')">
              Inlogg (Idag)
            </th>
            <th @click="sort('logins')">
              Inlogg (Vecka)
            </th>
            <th @click="sort('logins')">
              Inlogg (60 d.)
            </th>
            <th @click="sort('logins')">
              Inlogg (Totalt)
            </th>
            <th @click="sort('exams')">
              Prov (Idag)
            </th>
            <th @click="sort('exams')">
              Prov (Vecka)
            </th>
            <th @click="sort('exams')">
              Prov (60 d.)
            </th>
            <th @click="sort('exams')">
              Prov (Totalt)
            </th>
            <th @click="sort('students')">
              Elevstarter (Vecka)
            </th>
            <th @click="sort('students')">
              Elevstarter (60 d.)
            </th>
            <th @click="sort('students')">
              Elevstarter (Totalt)
            </th>
          </tr>

          <tr
            v-for="user in activeUsers"
            :key="user.id"
          >
            <td>
              <a :href="loginLink(user)">{{ user.firstName }} {{ user.lastName }}</a>
            </td>
            <td :class="loginColor(user.login_at || user.created_at)">
              {{ user.login_at }}
            </td>
            <td>{{ userStatisticsToday(user, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsWeekly(user, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsSixty(user, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsTotal(user, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsToday(user, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsWeekly(user, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsSixty(user, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsTotal(user, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsWeekly(user, 'EXAM_STARTED') }}</td>
            <td>{{ userStatisticsSixty(user, 'EXAM_STARTED') }}</td>
            <td>{{ userStatisticsTotal(user, 'EXAM_STARTED') }}</td>
          </tr>

          <tr
            v-for="user in inactiveUsers"
            :key="user.id"
          >
            <td style="color: red">
              ! <a :href="loginLink(user)">{{ user.firstName }} {{ user.lastName }}</a>
            </td>
            <td :class="loginColor(user.login_at || user.created_at)">
              {{ user.login_at }}
            </td>
            <td>{{ userStatisticsToday(user, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsWeekly(user, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsSixty(user, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsTotal(user, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsToday(user, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsWeekly(user, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsSixty(user, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsTotal(user, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsWeekly(user, 'EXAM_STARTED') }}</td>
            <td>{{ userStatisticsSixty(user, 'EXAM_STARTED') }}</td>
            <td>{{ userStatisticsTotal(user, 'EXAM_STARTED') }}</td>
          </tr>
        </table>
      </div>
      <div class="schoolstat">
        <div v-if="school">
          <h3>{{ school.namn }}</h3>
          <h5
            class="clickable"
            @click="gotoDistrict(school.ort)"
          >
            {{ school.ort }}
          </h5>
          <h5
            class="clickable"
            @click="gotoOrganization(school.orgnummer)"
          >
            {{ school.orgnummer }}
          </h5>
          <div class="schoolInfo">
            <b>{{ schoolStatisticsSixty('USER_JOINED') }} registrerade lärare senaste 60 dagarna</b>
            <br>({{ schoolStatisticsTotal('USER_JOINED') }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ schoolStatisticsSixty('EXAM_CREATED') }} skapade prov senaste 60 dagarna</b>
            <br>({{ schoolStatisticsTotal('EXAM_CREATED') }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ schoolStatisticsSixty('EXAM_STARTED') }} elevstarter senaste 60 dagarna</b>
            <br>({{ schoolStatisticsTotal('EXAM_STARTED') }} totalt)
          </div>
        </div>
      </div>
    </div>

    <canvas id="schoolStatisticsChart" />

    <div
      v-show="status === 'loading'"
      style="z-index: 9999; position: fixed; top: 300px; left: 40%"
    >
      <i
        style="font-size: 300px"
        class="fa fa-spin fa-spinner"
      />
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import MyChart from '../../admin/SchoolStatisticsChart.js'

export default {
  components: { VueSimpleSuggest },
  data() {
    return {
      aggregated: [],
      chosenSchoolDisplay: '',
      orgnummer: null,
      orgSchools: [],
      ort: null,
      ortSchools: [],
      ortToSearch: '',
      school: null,
      schoolId: null,
      schoolList: [],
      schools: [],
      schoolStatisticsChart: null,
      status: 'done',
      users: [],
    }
  },
  computed: {
    activeUsers() {
      return this.users.filter((user) => {
        return user.school_id === this.schoolId
      }, this)
    },

    inactiveUsers() {
      return this.users.filter((user) => {
        return user.school_id !== this.schoolId
      }, this)
    },
  },

  mounted() {
    this.preloadSchools()
  },

  updated() {
    if (this.$root.school != null) {
      this.fetchSchoolStatistics(this.$root.school)
      this.$root.school = null
    }
  },

  methods: {
    preloadSchools() {
      window.$.ajax({
        url: '/api/site-admin/schools',
        success: (data) => {
          data.forEach((school) => {
            school.display = `${school.namn} (${school.ort})`
          })

          this.schoolList = data
        },
      })
    },
    sort(column) {
      if (['logins', 'exams', 'students'].includes(column)) {
        column = `num_${column}`

        this.users.sort((a, b) => {
          if (!a.stat || a.stat.length === 0) {
            return 1
          }

          if (!b.stat || b.stat.length === 0) {
            return -1
          }

          if (b.stat[0][column] - a.stat[0][column] === 0) {
            return b.firstName.localeCompare(a.firstName)
          }

          return b.stat[0][column] - a.stat[0][column]
        })
      }

      if (column === 'latest') {
        this.users.sort((a, b) => (a.login_at === b.login_at ? 0 : a.login_at < b.login_at ? 1 : -1))
      }
    },

    userStatisticsToday(user, statistics) {
      const after = new Date()
      after.setHours(0, 0, 0, 0)
      return this.userStatistics(user, statistics, after)
    },

    userStatisticsWeekly(user, statistics) {
      const after = new Date()
      after.setDate(after.getDate() - 6)
      after.setHours(0, 0, 0, 0)
      return this.userStatistics(user, statistics, after)
    },

    userStatistics(user, statistics, after) {
      if (!user || user.statistics[statistics] === undefined) {
        return 0
      }

      let total = 0

      for (const stat of user.statistics[statistics]) {
        if (after > Date.parse(stat.date)) {
          total += stat.value
        }
      }

      return total
    },

    userStatisticsSixty(user, statistics) {
      let total = 0

      for (const i of this.aggregated) {
        if (i.user_id === user.id && i.group === 'sixty' && i.type === statistics) {
          total += i.value
        }
      }

      return total
    },

    userStatisticsTotal(user, statistics) {
      let total = 0

      for (const i of this.aggregated) {
        if (i.user_id === user.id && i.group === 'total' && i.type === statistics) {
          total += i.value
        }
      }

      return total
    },

    schoolStatisticsSixty(statistics) {
      let total = 0

      for (const i of this.aggregated) {
        if (i.group === 'sixty' && i.type === statistics) {
          total += i.value
        }
      }

      return total
    },

    schoolStatisticsTotal(statistics) {
      let total = 0

      for (const i of this.aggregated) {
        if (i.group === 'total' && i.type === statistics) {
          total += i.value
        }
      }

      return total
    },

    loginColor(datetime) {
      const time = this.getTime(datetime)

      if (time.daysAgo < 30) {
        return 'login-green'
      }

      if (time.daysAgo < 90) {
        return 'login-orange'
      }

      return 'login-red'
    },

    getTime(datetime) {
      const date = new Date(`${datetime.substring(0, 19).replace(/-/g, '/')} UTC`)
      const localeString = new Date(`${datetime.substring(0, 19).replace(/-/g, '/')} UTC`).toLocaleString()

      const diff = new Date() - date
      const secondsAgo = Math.floor(diff / 1000)
      const minutesAgo = Math.floor(secondsAgo / 60)
      const hoursAgo = Math.floor(minutesAgo / 60)
      const daysAgo = Math.floor(hoursAgo / 24)

      return {
        toString() {
          return localeString
        },
        secondsAgo,
        minutesAgo,
        hoursAgo,
        daysAgo,
      }
    },

    loginLink(user) {
      return `/admin/impersonate/${user.email}`
    },

    gotoDistrict(district) {
      this.$root.district = district
      this.$root.choice = 'Ortstat'
      this.$root.$children.forEach((child) => {
        child.$forceUpdate()
      })
    },

    gotoOrganization(organization) {
      this.$root.organization = organization
      this.$root.choice = 'Orgstat'
      this.$root.$children.forEach((child) => {
        child.$forceUpdate()
      })
    },

    buildUsers(users, statistics) {
      const temp = []
      users.forEach(function (user) {
        temp[user.id] = user
        temp[user.id].statistics = {}
      }, this)

      statistics.forEach(function (statistic) {
        if (statistic.user_id != null) {
          if (!temp[statistic.user_id]) {
            return
          }

          if (temp[statistic.user_id].statistics[statistic.type] === undefined) {
            temp[statistic.user_id].statistics[statistic.type] = []
          }
          temp[statistic.user_id].statistics[statistic.type].push(statistic)
        }
      }, this)

      this.users = Object.values(temp)
      this.users.sort((a, b) => a.firstName.localeCompare(b.firstName))
    },

    fetchSchoolStatistics(schoolId) {
      setTimeout(() => {
        if (typeof schoolId === 'object') {
          schoolId = schoolId.id
        }

        if (!schoolId || typeof schoolId !== 'number') {
          schoolId = this.schoolList.find((school) => this.chosenSchoolDisplay === `${school.namn} (${school.ort})`).id
        }

        if (!schoolId) {
          return
        }

        this.status = 'loading'

        fetch(`/api/site-admin/statistics/school/${schoolId}`, { credentials: 'include' })
          .then((data) => data.json())
          .then((data) => {
            this.buildUsers(data.users, data.statistics)
            this.aggregated = data.aggregated
            this.school = data.school
            this.schoolId = data.id

            this.schoolStatisticsChart = MyChart.makeChart({
              id: 'schoolStatisticsChart',
              chart: this.schoolStatisticsChart,
              data: data.statistics,
            })

            this.status = 'done'
          })
      }, 100)
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  text-align: center;
}

.stat {
  display: flex;
  text-align: left;
}

.users {
  flex: 2;
}

.schoolstat {
  flex: 1;
  border: 1px solid #ccc;
  text-align: center;
  box-shadow: 5px 5px 5px #00000085;
  margin: 0 15px;
}

td {
  border-bottom: 1px #ddd solid;
  padding-left: 10px;
}

tr:nth-child(even) {
  background: #ddd;
}

tr:nth-child(odd) {
  background: #eee;
}

td.login-green {
  color: green;
}

td.login-orange {
  color: orange;
}

td.login-red {
  color: red;
}

.schoolInfo {
  margin: 20px;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.blur {
  filter: blur(10px);
}

#schoolStatisticsChart {
  padding-right: 10px;
}
</style>
