
























































































































.ExamAccessMenu {
  &__options {
    padding-left: var(--spacing-2);
    padding-top: var(--spacing-1);

    :last-child {
      margin-bottom: 0;
    }
  }

  &__option {
    display: flex;
    font-size: var(--font-size-md);

    &__input {
      margin-right: var(--spacing-2);
      cursor: pointer;

      &--open {
        filter: hue-rotate(270deg);
      }

      &--closed {
        filter: brightness(80%) saturate(90%) hue-rotate(159deg);
      }

      &--discoverable {
        filter: brightness(150%) saturate(200%) hue-rotate(220deg);
      }
    }

    &__label {
      display: flex;
      font-size: var(--font-size-sm);
      margin-bottom: 0;

      i {
        margin-left: var(--spacing-2);
        color: var(--gray-500);
        font-size: var(--font-size-xs);
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;

    .open {
      color: var(--green-500);
    }

    .closed {
      color: var(--red-700);
    }

    .discoverable {
      color: var(--yellow-500);
    }

    i {
      font-size: var(--font-size-sm);
      margin-right: var(--spacing-1);
    }
  }
}
