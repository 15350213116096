























































@import 'Less/variables.less';

.panel-help {
  color: #fff;
  float: right;
  margin-top: 22px !important;
  margin-right: 15px;
  font-size: 20px !important;
  cursor: pointer;
}

.sectionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  min-height: 20px;
  min-width: 40px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
  padding: 0;
  overflow: hidden;
  margin-bottom: 50px;
  margin-top: 20px;
}

.titleContainer {
  position: relative;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: @theme-blue;
  color: #fff;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  font-weight: 300;
}

.sectionBlock-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  height: auto;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 50px;
  text-align: left;

  p {
    font-size: 12px;
    text-align: left;
    margin-top: 10px;
  }

  &.wide {
    max-width: none;
    padding-right: 36px;
    padding-left: 36px
  }
}
