


































@import 'Less/variables.less';

.chip {
  display: inline-block;
  padding: 6px @spacing-3;
  border-radius: 32px;
  height: fit-content;
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: @color-font-dark;
  font-weight: var(--font-normal);
}

.notSelected {
  background: @color-grey-light;
  background-position: center;

  &:focus-visible {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 10%);
    outline: none;
  }

  &:active {
    box-shadow: inset 0 0 100px 100px @color-grey-light;
    background-color: #efffe8;
    background-size: 100%;
  }
}

.selected {
  transition: background 0.8s;
  background: @color-success-light radial-gradient(circle, transparent 1%, #dff0d8 1%) center/15000%;
  color: #417643;

  &:focus-visible {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 10%);
    outline: none;
  }

  &:active {
    background-color: #efffe8;
    background-size: 100%;
    transition: background 0s;
  }
}
