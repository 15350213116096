<template>
  <div class="main">
    {{ title }}
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.main {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 18px;
  text-align: left;
  margin-top: 15px;
  color: #373737;
}
</style>
