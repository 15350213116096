

















































.adminNav {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 600;
  background: #fa991d;
  padding: 10px;
  text-align: center;
  left: 10%;
  width: 80%;

  & a {
    color: #1b2b54;
    font-weight: bold;
  }

  & a:hover {
    color: #1b2b54;
  }
}
