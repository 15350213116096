






















































@import 'Less/variables.less';

.el-tab-item {
  width: 100%;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-enter {
  transform: translateY(-5px);
  opacity: 0;
}
