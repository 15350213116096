





























































































































































































































































































































































































































































@import 'Less/variables.less';

.ExamBuilderArea {
  min-height: calc(100% - var(--resource-toolbar-height));
  background-color: @color-grey-super-light;
  overflow: auto;

  &__page {
    position: relative;
    background-color: #fff;
    width: 794px;
    max-width: 99%;
    padding: @spacing-10 @spacing-5;
    margin: @spacing-1 auto @spacing-10;
    box-sizing: border-box;
    box-shadow: @shadow-md;
  }

  &__attachments {
    display: flex;
    flex-wrap: wrap;
    border-radius: @radius-xs;
    padding: @spacing-2;
    background: @color-grey-super-light;
    min-height: 175px;
  }

  &__attachment {
    cursor: grabbing;

    &--ghost {
      opacity: 0.4;
    }
  }
}

.pickAttachmentQuestion {
  position: absolute;
  height: auto;
  width: auto;
  background: rgba(56, 91, 168, 80%);
  left: 0;
  top: -10px;
  right: 0;
  bottom: -10px;
  z-index: 3;
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  cursor: pointer;
  color: @color-font-light;
  justify-content: center;
  align-items: center;
  transition: all 0.1s;
  opacity: 0.2;

  &__info {
    margin: 0;
  }

  &:hover {
    opacity: 1;
    z-index: 5;
  }
}

.questionContainer {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 22px;
}

.preview {
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 50px;
  margin-right: 30px;
  position: relative;
}

.group-list-move {
  transition: @animation;
}
// This is to remove flickering when dragging attachments between questions
.group-list-leave-active {
  display: none;
}
