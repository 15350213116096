











































































































































































































































































































.ExamSendMenu {
  .shareViaEmail {
    &__student-list {
      max-height: 60vh;
      overflow: overlay;
    }

    &__student-select {
      padding: var(--spacing-1);
    }

    &__delimiter {
      margin: var(--spacing-2) 0;
    }

    &__actions {
      display: flex;
      justify-content: space-around;
    }

    &__info {
      margin-bottom: var(--spacing-6);
    }

    &__expiration {
      display: flex;
      align-items: center;

      &__number {
        margin: 0;
        margin-right: var(--spacing-4);
      }

      &__unit {
        margin: 0;
      }
    }
  }
}
