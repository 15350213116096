



































































































.ExamBuilderPrintSummary {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 20%);
  margin: 20px auto;
  break-after: page;

  @media print {
    box-shadow: none;
    margin: 0;
    max-width: none;
  }

  &__delimiter {
    width: 100%;
    border-top: 1px solid #222;
    margin: 10px 0;
  }

  &__columns {
    @media screen and (max-width: 780px) {
      overflow-x: scroll;
    }

    &--too-many-columns {
      overflow-x: scroll;

      @media print {
        overflow-x: visible;
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 10px 0;

      thead {
        display: table-header-group;
      }

      th {
        cursor: pointer;
      }

      tr {
        vertical-align: top;
      }
    }
  }
}
