<template>
  <div
    class="caption"
    @click="click"
  >
    {{ text }}
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    click() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.caption {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 12px;
  text-align: left;
  margin-top: 5px;
  color: #373737;
  font-style: italic;
}
</style>
