





































.TrainingVideos {
  &__subscribe {
    min-height: var(--height-12);
  }

  &__movies {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    justify-items: center;
    width: 100%;
    margin-top: var(--spacing-4);
    column-gap: var(--spacing-4);
    grid-column-gap: var(--spacing-4);
  }
}
