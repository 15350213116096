
































































































































































































@media (max-width: 1200px) {
  .stackOnSmallScreen {
    flex-direction: column;
  }
}

.qr-button {
  margin-top: 10px;
}

img {
  box-shadow: var(--shadow-lg);
}
