















































// @import '~style/main.less';
.el-toggle-button {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 60px;
  height: auto;
  min-height: 30px;
  min-width: 60px;
  padding: none;

  &__background {
    position: relative;
    width: 60px;
    height: 30px;
    top: 0;
    left: 0;
    margin: none;
    margin-left: 0;
    background-color: var(--background-color-not-active);
    border-radius: 15px;
    box-sizing: border-box;
    cursor: pointer;
    // box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.18);

    &--active {
      background-color: var(--background-color-active);
      box-shadow: none;
    }
  }

  &__dot {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);

    &--active {
      left: 33px;
      background-color: white;
      box-shadow: none;
    }
  }
}
