


































@margin-x: 20px;
@margin-y: 40px;

.onboarding-step {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: @margin-y @margin-x;
  box-sizing: border-box;

  &__content {
    position: relative;
    margin-left: @margin-x;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-content: flex-start;
    align-items: flex-start;

    &__disabled {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #fff;
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  p,
  h3 {
    margin: 0;
  }

  i {
    font-size: 12px;
    color: #757575;
  }

  a {
    color: #4a90e2;
  }

  ul {
    padding-inline-start: 20px;
  }

  li {
    margin: 20px 0;
  }
}
