




























































































































































































































































@import 'Less/variables.less';

.menu-item {
  cursor: pointer;
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 100%;

  &__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: @theme-primary;
    }
  }

  &--activated {
    background-color: @theme-blue;
    border: @border-thickness-thick solid @theme-blue;
    border-left: outset @spacing-1 @theme-primary;
  }
}

.folded {
  display: inline-block;

  &:focus-visible {
    outline: auto;
  }

  .folded-icon {
    font-size: @font-size-large;
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    color: white;
  }
}

.icon {
  margin-right: 10px;
  width: 17.5px;

  &__action {
    position: absolute !important;
    font-size: @font-size-small;
    top: 0 !important;
    right: 0;
    color: white;
    height: 100%;
    padding-top: 13px;
    width: 30px !important;

    &:hover {
      color: @theme-primary;
    }

    &__restoreView {
      right: 30px;

      &--folded {
        left: 0;
      }
    }

    &:focus-visible {
      outline: auto;
    }
  }
}

a {
  color: #ddd;
  text-decoration: none;
  padding: 10px 15px;
  font-size: 15px;
  display: flex;
  background: unset;
  align-items: center;
  max-width: 83%;

  &:hover {
    color: #ddd;
  }

  &:focus-visible {
    outline: auto;
  }
}
