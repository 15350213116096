






















































































@import 'Less/variables.less';

.archiveExam {
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &__info {
      font-size: @font-size-small;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    &__button {
      margin: 0 @spacing-1;
    }
  }
}
