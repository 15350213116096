<template>
  <div
    data-testid="registration"
    class="bg-white max-w-full mx-auto rounded-2xl"
    style="width: 700px"
  >
    <div class="mb-4">
      <h2 class="font-semibold mt-8 p-6 pb-0 pt-10 text-center text-xl uppercase">
        {{ __('Välkommen till Exam.net') }}
      </h2>
      <h2
        v-if="!localeIs('sv')"
        class="text-center"
      >
        {{ __('Register an account or sign up for a free trial') }}
      </h2>
    </div>
    <div class="bg-white container h-full max-w-screen-md mx-auto rounded shadow">
      <nav>
        <ul class="divide-gray-300 divide-y md:divide-y-0 md:flex">
          <li
            v-for="step in steps"
            :key="step.number"
            class="md:flex md:flex-1 relative"
          >
            <div class="flex font-medium items-center leading-5 px-4 py-4 space-x-4 text-sm">
              <div
                class="duration-150
                  ease-in-out
                  flex
                  flex-shrink-0
                  h-10
                  items-center
                  justify-center
                  rounded-full
                  transition
                  w-10"
                :class="{ 'bg-exam': currentStep > step.number }"
              >
                <svg
                  v-if="currentStep > step.number"
                  class="h-6 text-white w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div
                  v-else
                  class="border-2 flex flex-shrink-0 h-10 items-center justify-center rounded-full w-10"
                  :class="{ 'border-exam': currentStep === step.number }"
                >
                  <p
                    class="text-gray"
                    :class="{ 'text-exam': currentStep === step.number }"
                  >
                    {{ step.number }}
                  </p>
                </div>
              </div>
              <p class="font-medium leading-5 text-gray-900 text-sm">
                {{ step.name }}
              </p>
            </div>

            <div
              v-if="step.number < steps.length"
              class="absolute h-full hidden md:block right-0 top-0 w-5"
            >
              <svg
                class="h-full text-gray-300 w-full"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div class="bg-white container h-full max-w-screen-md mb-72 mt-6 mx-auto rounded shadow">
      <div
        v-if="currentStep === 2"
        class="bg-white px-4 py-5 sm:px-6"
      >
        <h3 class="font-medium leading-6 mb-4 text-gray-900 text-lg">
          {{ __('Verifiera epost') }}
        </h3>
        <div>
          <div class="py-2">
            {{
              __(
                'Ange din personliga organisations-epost och klicka på verifiera. Ett e-post kommer skickas med en länk för att slutföra verifieringen.',
              )
            }}
          </div>

          <div
            v-if="emailStatus === 'non-organisation'"
            class="flex justify-center"
          >
            <warning :email="teacher.email">
              <p class="leading-5 text-gray-500 text-sm">
                {{
                  __(
                    'Du har angivit en generisk e-post. Om du har en personlig organisations-epost, vänligen ändra till den.',
                  )
                }}
              </p>
            </warning>
          </div>

          <div
            v-if="emailStatus === 'success'"
            class="flex justify-center"
          >
            <success :email="teacher.email" />
          </div>

          <div
            v-if="emailStatus !== 'success'"
            class="mt-1 relative rounded-md shadow-sm"
          >
            <div class="absolute flex inset-y-0 items-center left-0 pl-3 pointer-events-none">
              <svg
                class="h-5 text-gray-400 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </div>
            <input
              id="email"
              v-model="teacher.email"
              class="appearance-none
                block
                border
                border-gray-300
                duration-150
                ease-in-out
                focus:border-blue-300
                focus:outline-none
                focus:shadow-outline-blue
                pl-10
                placeholder-gray-400
                px-3
                py-2
                rounded-md
                sm:leading-5
                sm:text-sm
                transition
                w-full"
              placeholder="firstname.lastname@stockholmcollege.se"
            >
          </div>
          <div
            v-if="emailStatus !== 'success'"
            class="flex flex-end mt-4"
          >
            <div class="flex-1" />
            <button
              v-if="emailStatus !== 'pending'"
              type="button"
              class="active:bg-indigo-200
                bg-indigo-100
                border
                border-transparent
                duration-150
                ease-in-out
                focus:border-indigo-300
                focus:outline-none
                focus:shadow-outline-indigo
                font-medium
                hover:bg-indigo-50
                inline-flex
                items-center
                leading-5
                px-4
                py-2
                rounded-md
                text-indigo-700
                text-sm
                transition"
              @click="verifyEmail()"
            >
              {{ __('Verifiera') }}
            </button>
            <button
              v-if="emailStatus === 'pending'"
              type="button"
              disabled
              class="active:bg-indigo-200
                bg-indigo-100
                border
                border-transparent
                duration-150
                ease-in-out
                focus:border-indigo-300
                focus:outline-none
                focus:shadow-outline-indigo
                font-medium
                hover:bg-indigo-50
                inline-flex
                items-center
                leading-5
                px-4
                py-2
                rounded-md
                text-indigo-700
                text-sm
                transition"
            >
              {{ __('Verifierar') }} ...
            </button>
          </div>
          <div
            v-if="emailStatus === 'error'"
            class="mt-4 text-red-600"
          >
            {{
              __(
                'Angiven epost är ogiltig eller används redan. Om du har glömt ditt lösenord kan du begära en återställning vid inloggningen.',
              )
            }}
          </div>
          <SSOButtons
            v-if="forcedInternationalSchoolId == 0"
            @login="ssoLogin"
          />
        </div>
      </div>
      <div v-if="currentStep === 1">
        <div
          v-if="!reconnect"
          class="bg-white px-4 py-5 sm:px-6"
        >
          <h3 class="font-semibold mb-6 pb-0 text-xl uppercase">
            {{ __('Skapa nytt konto') }}
          </h3>
          <h3 class="font-medium leading-6 text-gray-900 text-lg">
            {{ __('Användare') }}
          </h3>
          <div class="flex items-center mt-4">
            <input
              id="usertype_teacher"
              v-model="userType"
              value="teacher"
              type="radio"
              class="duration-150 ease-in-out form-radio h-4 text-indigo-600 transition w-4"
            >
            <label
              for="usertype_teacher"
              class="ml-3"
            >
              <span class="block font-medium leading-5 text-gray-700 text-lg">{{ __('Jag är lärare') }}</span>
            </label>
          </div>

          <div class="flex items-center mt-4">
            <input
              id="usertype_student"
              v-model="userType"
              value="student"
              type="radio"
              class="duration-150 ease-in-out form-radio h-4 text-indigo-600 transition w-4"
            >
            <label
              for="usertype_student"
              class="ml-3"
            >
              <span class="block font-medium leading-5 text-gray-700 text-lg">{{ __('Jag är elev') }}</span>
            </label>
          </div>

          <div
            v-if="userType === 'student'"
            class="flex justify-center"
          >
            <div
              class="align-bottom
                bg-white
                inline-block
                overflow-hidden
                pb-4
                pt-5
                px-4
                rounded-lg
                shadow-xl
                sm:align-middle
                sm:max-w-lg
                sm:my-8
                sm:p-6
                sm:w-full
                text-left
                transform
                transition-all"
            >
              <div class="sm:flex sm:items-start">
                <div
                  class="bg-red-100
                    flex
                    flex-shrink-0
                    h-12
                    items-center
                    justify-center
                    mx-auto
                    rounded-full
                    sm:h-10
                    sm:mx-0
                    sm:w-10
                    w-12"
                >
                  <svg
                    class="h-6 text-red-600 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div class="mt-3 sm:ml-4 sm:mt-0 sm:text-left text-center">
                  <h3
                    id="modal-headline"
                    class="font-medium leading-6 text-gray-900 text-lg"
                  >
                    {{ __('Exam.net har inga elevkonton') }}
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm">
                      {{
                        __(
                          'Endast lärare behöver registrera ett konto. Som elev får du en provnyckel av läraren som du använder för att komma åt ditt prov.',
                        )
                      }}
                    </p>
                  </div>
                  <div class="mt-2 text-md underline">
                    <a href="/">{{ __('Till startsidan') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white px-4 py-5 sm:px-6">
          <h3
            v-if="!reconnect"
            class="font-medium leading-6 mt-8 text-gray-900 text-lg"
          >
            {{ __('Land') }}
          </h3>
          <h3
            v-if="reconnect"
            class="font-medium leading-6 mt-8 text-gray-900 text-lg"
          >
            {{ teacher.country }}
          </h3>
          <multiselect
            v-if="!reconnect"
            v-model="countrySelect"
            :options="countryOptions"
            :closeOnSelect="true"
            :clearOnSelect="false"
            :placeholder="__('Land')"
            trackBy="label"
            label="label"
            data-testid="country"
            :selectLabel="__('Tryck enter för att välja')"
            :selectedLabel="__('Vald')"
            deselectLabel=""
          >
            <template #noResult>
              {{ __('Inga resultat hittades.') }}
            </template>
          </multiselect>
        </div>

        <div
          v-if="teacher.country.length > 0 && regionOptions.length > 0"
          class="bg-white px-4 py-5 sm:px-6"
        >
          <h3 class="font-medium leading-6 text-gray-900 text-lg">
            {{ __('Region') }}
          </h3>
          <multiselect
            v-if="!reconnect"
            v-model="regionSelect"
            :options="regionOptions"
            :closeOnSelect="true"
            :clearOnSelect="false"
            :placeholder="__('Region')"
            trackBy="label"
            label="label"
            data-testid="region"
            :selectLabel="__('Tryck enter för att välja')"
            :selectedLabel="__('Vald')"
            deselectLabel=""
          >
            <template #noResult>
              {{ __('Inga resultat hittades.') }}
            </template>
          </multiselect>
        </div>
      </div>

      <div v-if="currentStep === 3">
        <div class="bg-white px-4 sm:px-6">
          <div class="flex justify-center">
            <div
              class="align-bottom
                bg-white
                inline-block
                max-w-lg
                overflow-hidden
                pb-4
                pt-5
                px-4
                rounded-lg
                shadow-xl
                sm:align-middle
                sm:my-8
                sm:p-6
                text-left
                transform
                transition-all
                w-full"
            >
              <div>
                <div class="bg-green-100 flex h-12 items-center justify-center mx-auto rounded-full w-12">
                  <svg
                    class="h-6 text-green-600 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div
                  v-if="teacher.country === 'Sweden'"
                  class="mt-3 sm:mt-5 text-center"
                >
                  <h3 class="font-medium leading-6 text-gray-900 text-lg">
                    {{ __('Din epost har verifierats.') }}
                  </h3>
                  <p class="leading-5 mb-4 mt-6 text-gray-500 text-sm">
                    {{ teacher.email }}
                  </p>
                  <multiselect
                    ref="multiselectSweden"
                    v-model="organisationSwedenSearch"
                    :optionsLimit="10000"
                    :options="schoolListSweden"
                    :closeOnSelect="true"
                    :clearOnSelect="false"
                    placeholder="Sök efter din skola"
                    label="title"
                    trackBy="title"
                    value="id"
                    data-testid="school_suggestion"
                    :selectLabel="__('Tryck enter för att välja')"
                    :selectedLabel="__('Vald')"
                    deselectLabel=""
                    @open="multiSelectSwedenOpen = true"
                    @close="multiSelectSwedenOpen = false"
                  >
                    <template #noResult>
                      {{ __('Inga resultat hittades.') }}
                    </template>
                  </multiselect>
                  <h4 class="mt-6 text-left underline">
                    Finns inte din organisation?
                  </h4>
                  <p class="mt-4 text-left">
                    Om du inte hittar din organisation i listan så kontakta oss på
                    info@exam.net så lägger vi till den snabbt.
                  </p>
                  <p class="text-left">
                    (Alla nya organisationer får en utvärderingsperiod då man kan testa full
                    funktionalitet helt gratis och utan förpliktelser.)
                  </p>
                  <div :class="{ 'mt-72': multiSelectSwedenOpen }" />
                </div>
                <div
                  v-if="teacher.country !== 'Sweden'"
                  class="mt-3 sm:mt-5 text-center"
                >
                  <h3 class="font-medium leading-6 text-gray-900 text-lg">
                    {{ __('Din epost har verifierats.') }}
                  </h3>
                  <p class="leading-5 mt-6 text-gray-500 text-sm">
                    {{ teacher.email }}
                  </p>
                  <div v-if="newOrganisation">
                    <div class="flex">
                      <button
                        class="mb-2 mt-4 underline"
                        href=""
                        @click="newOrganisation = false"
                      >
                        {{ __('Go back to search') }}
                      </button>
                    </div>
                    <h3 class="font-medium leading-6 mt-8 text-gray-900 text-left text-lg">
                      {{ __('Set up a new organization') }}
                    </h3>
                    <div class="flex items-center mt-4">
                      <input
                        id="school"
                        v-model="organisation.type"
                        value="school"
                        type="radio"
                        class="duration-150 ease-in-out form-radio h-4 text-indigo-600 transition w-4"
                      >
                      <label
                        for="school"
                        class="ml-3"
                      >
                        <span class="block font-medium leading-5 text-gray-700 text-sm">{{ __('School') }}</span>
                      </label>
                    </div>
                    <div class="flex items-center mt-4">
                      <input
                        id="university"
                        v-model="organisation.type"
                        value="university"
                        type="radio"
                        class="duration-150 ease-in-out form-radio h-4 text-indigo-600 transition w-4"
                      >
                      <label
                        for="university"
                        class="ml-3"
                      >
                        <span class="block font-medium leading-5 text-gray-700 text-sm">{{
                          __('University or college')
                        }}</span>
                      </label>
                    </div>
                    <div class="flex items-center mt-4">
                      <input
                        id="business"
                        v-model="organisation.type"
                        value="business"
                        type="radio"
                        class="duration-150 ease-in-out form-radio h-4 text-indigo-600 transition w-4"
                      >
                      <label
                        for="business"
                        class="ml-3"
                      >
                        <span class="block font-medium leading-5 text-gray-700 text-sm">{{
                          __('Business or private tutor')
                        }}</span>
                      </label>
                    </div>

                    <div
                      v-if="organisation.type.length > 0"
                      class="text-left"
                    >
                      <div class="mt-4">
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            :value="teacher.country"
                            autocomplete="no"
                            required
                            disabled
                            class="appearance-none
                              block
                              border
                              border-gray-300
                              duration-150
                              ease-in-out
                              focus:border-blue-300
                              focus:outline-none
                              focus:shadow-outline-blue
                              placeholder-gray-400
                              px-3
                              py-2
                              rounded-md
                              sm:leading-5
                              sm:text-sm
                              transition
                              w-full"
                          >
                        </div>
                      </div>

                      <div
                        v-if="teacher.region"
                        class="mt-4"
                      >
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            :value="teacher.region"
                            autocomplete="no"
                            required
                            disabled
                            class="appearance-none
                              block
                              border
                              border-gray-300
                              duration-150
                              ease-in-out
                              focus:border-blue-300
                              focus:outline-none
                              focus:shadow-outline-blue
                              placeholder-gray-400
                              px-3
                              py-2
                              rounded-md
                              sm:leading-5
                              sm:text-sm
                              transition
                              w-full"
                          >
                        </div>
                      </div>

                      <div
                        v-if="organisation.type !== 'tutor'"
                        class="mt-4"
                      >
                        <label class="block font-medium leading-5 text-gray-700 text-sm">{{
                          __('Organization name')
                        }}</label>
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            v-model="organisation.name"
                            :placeholder="__('Organization (or school) name')"
                            autocomplete="no"
                            required
                            class="appearance-none
                              block
                              border
                              border-gray-300
                              duration-150
                              ease-in-out
                              focus:border-blue-300
                              focus:outline-none
                              focus:shadow-outline-blue
                              placeholder-gray-400
                              px-3
                              py-2
                              rounded-md
                              sm:leading-5
                              sm:text-sm
                              transition
                              w-full"
                          >
                        </div>
                      </div>

                      <div
                        v-if="organisation.type !== 'tutor'"
                        class="mt-4"
                      >
                        <label class="block font-medium leading-5 text-gray-700 text-sm">{{ __('Stad') }}</label>
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            v-model="organisation.city"
                            :placeholder="__('Stad')"
                            autocomplete="no"
                            required
                            class="appearance-none
                              block
                              border
                              border-gray-300
                              duration-150
                              ease-in-out
                              focus:border-blue-300
                              focus:outline-none
                              focus:shadow-outline-blue
                              placeholder-gray-400
                              px-3
                              py-2
                              rounded-md
                              sm:leading-5
                              sm:text-sm
                              transition
                              w-full"
                          >
                        </div>
                      </div>

                      <div class="mt-4">
                        <label class="block font-medium leading-5 text-gray-700 text-sm">{{
                          __('Approximate number of students')
                        }}</label>
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            v-model="organisation.studentCount"
                            :placeholder="__('Approximate number of students attending')"
                            autocomplete="no"
                            required
                            class="appearance-none
                              block
                              border
                              border-gray-300
                              duration-150
                              ease-in-out
                              focus:border-blue-300
                              focus:outline-none
                              focus:shadow-outline-blue
                              placeholder-gray-400
                              px-3
                              py-2
                              rounded-md
                              sm:leading-5
                              sm:text-sm
                              transition
                              w-full"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <exam-loader v-if="suggestSchoolsStatus !== 'loaded'" />
                    <div v-else>
                      <h3 class="font-medium leading-6 mt-8 text-gray-900 text-left text-lg">
                        {{ __('Please choose your organization') }}
                      </h3>
                      <div
                        v-for="school in suggestedSchools"
                        :key="school.id"
                        class="flex items-center mt-4"
                      >
                        <input
                          :id="'international_school_' + school.id"
                          v-model="teacher.internationalSchool"
                          :value="school.id"
                          type="radio"
                          class="duration-150 ease-in-out form-radio h-4 text-indigo-600 transition w-4"
                        >
                        <label
                          :for="'international_school_' + school.id"
                          class="ml-3"
                        >
                          <span
                            class="block font-medium leading-5 text-gray-700 text-sm"
                          >{{ school.name }} ({{ school.city }})</span>
                        </label>
                      </div>
                      <div
                        v-show="suggestedSchools.length > 0"
                        class="flex items-center mt-4"
                      >
                        <input
                          id="none_of_the_above"
                          v-model="teacher.internationalSchool"
                          :value="0"
                          type="radio"
                          class="duration-150 ease-in-out form-radio h-4 text-indigo-600 transition w-4"
                        >
                        <label
                          for="none_of_the_above"
                          class="ml-3"
                        >
                          <span class="block font-medium leading-5 text-gray-700 text-sm">{{
                            __('None of the above')
                          }}</span>
                        </label>
                      </div>

                      <div
                        v-if="teacher.internationalSchool === 0"
                        class="mt-4"
                      >
                        <div v-if="schoolListStatus === 'loading'">
                          <exam-loader />
                          <p>{{ __('Loading school data') }}</p>
                        </div>

                        <multiselect
                          ref="multiselect"
                          v-model="organisationSearch"
                          :options="schoolList"
                          :closeOnSelect="true"
                          :clearOnSelect="false"
                          :placeholder="__('Search for your organization')"
                          label="title"
                          trackBy="title"
                          value="id"
                          data-testid="school_suggestion"
                          :selectLabel="__('Tryck enter för att välja')"
                          :selectedLabel="__('Vald')"
                          deselectLabel=""
                          @open="multiSelectOpen = true"
                          @close="multiSelectOpen = false"
                        >
                          <template #noResult>
                            {{ __('Inga resultat hittades.') }}
                          </template>
                        </multiselect>
                      </div>
                      <div :class="{ 'mt-72': multiSelectOpen }">
                        <!-- class="mt-72">-->
                        <button
                          v-if="teacher.internationalSchool === 0"
                          class="float-left mt-12 text-sm underline"
                          href=""
                          @click="showNewOrganisation"
                        >
                          {{ __("I can't find my school/organization when searching above") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentStep === 4">
        <div class="mt-8 sm:max-w-md sm:mx-auto sm:w-full">
          <div class="px-4 py-8">
            <div
              v-if="forcedInternationalSchool"
              class="mb-4"
            >
              <label class="block font-medium leading-5 text-gray-700 text-sm">{{ __('Skola') }}</label>
              <div>
                {{ forcedInternationalSchool.name }} ({{ forcedInternationalSchool.city }},
                {{ forcedInternationalSchool.country }})
              </div>
            </div>
            <div>
              <label class="block font-medium leading-5 text-gray-700 text-sm">{{ __('Förnamn') }}</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  v-model="teacher.firstName"
                  autocomplete="no"
                  required
                  class="appearance-none
                    block
                    border
                    border-gray-300
                    duration-150
                    ease-in-out
                    focus:border-blue-300
                    focus:outline-none
                    focus:shadow-outline-blue
                    placeholder-gray-400
                    px-3
                    py-2
                    rounded-md
                    sm:leading-5
                    sm:text-sm
                    transition
                    w-full"
                >
              </div>
            </div>

            <div class="mt-4">
              <label class="block font-medium leading-5 text-gray-700 text-sm">{{ __('Efternamn') }}</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  v-model="teacher.lastName"
                  autocomplete="no"
                  required
                  class="appearance-none
                    block
                    border
                    border-gray-300
                    duration-150
                    ease-in-out
                    focus:border-blue-300
                    focus:outline-none
                    focus:shadow-outline-blue
                    placeholder-gray-400
                    px-3
                    py-2
                    rounded-md
                    sm:leading-5
                    sm:text-sm
                    transition
                    w-full"
                >
              </div>
            </div>

            <div class="mt-4">
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  :value="teacher.email"
                  autocomplete="no"
                  required
                  disabled
                  class="appearance-none
                    block
                    border
                    border-gray-300
                    duration-150
                    ease-in-out
                    focus:border-blue-300
                    focus:outline-none
                    focus:shadow-outline-blue
                    placeholder-gray-400
                    px-3
                    py-2
                    rounded-md
                    sm:leading-5
                    sm:text-sm
                    transition
                    w-full"
                >
              </div>

              <div
                v-if="!definePassword"
                class="ml-2 mt-2 text-gray-500 text-xs"
              >
                {{ __('Inloggning via SSO') }}
              </div>
            </div>

            <div
              v-if="definePassword"
              class="mt-6"
            >
              <label
                for="password"
                class="block font-medium leading-5 text-gray-700 text-sm"
              >{{
                __('Lösenord')
              }}</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  v-model="teacher.password"
                  type="password"
                  :placeholder="__('Minst 8 tecken')"
                  required
                  class="appearance-none
                    block
                    border
                    border-gray-300
                    duration-150
                    ease-in-out
                    focus:border-blue-300
                    focus:outline-none
                    focus:shadow-outline-blue
                    placeholder-gray-400
                    px-3
                    py-2
                    rounded-md
                    sm:leading-5
                    sm:text-sm
                    transition
                    w-full"
                >
              </div>
            </div>

            <div
              v-if="definePassword"
              class="mt-6"
            >
              <label
                for="password_confirmation"
                class="block font-medium leading-5 text-gray-700 text-sm"
              >{{
                __('Bekräfta lösenord')
              }}</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="password_confirmation"
                  v-model="teacher.password_confirmation"
                  type="password"
                  required
                  class="appearance-none
                    block
                    border
                    border-gray-300
                    duration-150
                    ease-in-out
                    focus:border-blue-300
                    focus:outline-none
                    focus:shadow-outline-blue
                    placeholder-gray-400
                    px-3
                    py-2
                    rounded-md
                    sm:leading-5
                    sm:text-sm
                    transition
                    w-full"
                >
              </div>
            </div>

            <div class="flex items-center justify-between mt-6">
              <div class="flex items-center">
                <input
                  id="licensecheckbox"
                  v-model="teacher.accepted_agreement"
                  class="mr-2"
                  type="checkbox"
                >
                <label
                  v-sanitized-html="__(
                    `Godkänn <a href='/pdf/sv/anvandarvillkor.pdf' target='_blank' rel='noopener'>användarvillkoren</a> och vår <a href='/pdf/sv/personuppgiftspolicy.pdf' target='_blank' rel='noopener'>personuppgiftspolicy</a>`,
                  )"
                  for="licensecheckbox"
                  class="user-terms"
                />
              </div>
            </div>
          </div>
          <div>
            <span class="flex mt-6">
              <div class="flex-1" />
              <button
                type="button"
                class="active:bg-indigo-700
                    bg-exam
                    border
                    border-transparent
                    duration-150
                    ease-in-out
                    focus:border-indigo-700
                    focus:outline-none
                    focus:shadow-outline-indigo
                    font-medium
                    hover:bg-exam-orange
                    inline-flex
                    items-center
                    leading-5
                    px-4
                    py-2
                    rounded-md
                    text-sm
                    text-white
                    transition"
                @click="register"
              >
                {{ __('Börja använda Exam.net') }}
              </button>
            </span>
          </div>

          <div
            v-if="registrationStatus === 'error'"
            class="mt-2"
          >
            <div class="bg-red-50 p-4 rounded-md">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg
                    class="h-5 text-red-400 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <h3 class="font-medium leading-5 text-red-800 text-sm">
                    {{ __('Se till att ange all data') }}
                  </h3>
                  <div class="leading-5 mt-2 text-red-700 text-sm">
                    <ul class="list-disc pl-5">
                      <li
                        v-for="registrationError in errors"
                        :key="registrationError"
                      >
                        {{ registrationError }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex p-6 rounded-md shadow-sm">
        <button
          v-if="(currentStep === 2 && emailStatus === '') || (currentStep === 4 && !forcedInternationalSchool)"
          type="button"
          class="bg-exam
            border
            border-transparent
            duration-150
            ease-in-out
            focus:outline-none
            font-medium
            hover:bg-exam-orange
            inline-flex
            items-center
            leading-5
            p-8
            px-4
            py-2
            rounded-md
            text-sm
            text-white
            transition"
          @click="previousStep"
        >
          {{ __('Föregående') }}
        </button>
        <div class="flex-1" />
        <button
          v-if="stepCompleted"
          type="button"
          class="bg-exam
            border
            border-transparent
            duration-150
            ease-in-out
            focus:outline-none
            font-medium
            hover:bg-exam-orange
            inline-flex
            items-center
            leading-5
            p-8
            px-4
            py-2
            rounded-md
            text-sm
            text-white
            transition"
          @click="nextStep"
        >
          {{ __('Vidare') }}
        </button>
      </div>
    </div>

    <modal v-show="emailTaken">
      <div>
        <div class="mt-3 sm:mt-5 text-center">
          <h3
            id="modal-headline"
            class="font-medium leading-6 text-gray-900 text-lg"
          >
            {{ __('Account exists') }}
          </h3>
          <div class="mt-2">
            <p class="leading-5 text-gray-500 text-sm">
              {{ __('Det finns redan ett konto för denna epost, vänligen logga in istället.') }}
            </p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import QueryString from '@krisell/query-string'
import SSOButtons from '../../home/SSOButtons.vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Parser from '@krisell/parser'
import axios from 'axios'

import Modal from './Modal.vue'
import Success from './Success.vue'
import Warning from './Warning.vue'

export default {
  components: { Success, Warning, Modal, Multiselect, SSOButtons },
  props: {
    verifiedEmail: {
      type: String,
      default: '',
    },
    verifiedCountry: {
      type: String,
      default: '',
    },
    verifiedRegion: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    sso: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: '',
    },
    forcedInternationalSchool: {
      type: [Object, Number],
      default: 0,
    },
    forcedInternationalSchoolId: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    reconnect: Boolean,
  },
  data() {
    return {
      emailTaken: false,
      userType: 'teacher',
      multiSelectOpen: false,
      multiSelectSwedenOpen: false,
      newOrganisation: false,
      schoolList: [],
      schoolListSweden: [],
      organisationSearch: '',
      organisationSwedenSearch: '',
      emailStatus: '',
      registrationStatus: '',
      schoolListStatus: '',
      suggestSchoolsStatus: '',
      suggestedSchools: [],
      verificationEmailSent: false,
      emailWarning: false,
      currentStep: 1,
      steps: [
        { number: 1, name: this.__('Land') },
        { number: 2, name: this.__('Verifiera epost') },
        { number: 3, name: this.__('Organisation') },
        { number: 4, name: this.__('Detaljer') },
      ],
      regionOptions: [],
      countryOptions: [],
      organisation: {
        type: '',
        name: '',
        city: '',
        studentCount: '',
      },
      teacher: {
        firstName: this.firstName || '',
        lastName: this.lastName || '',
        country: this.verifiedCountry || '',
        email: this.verifiedEmail,
        password: '',
        password_confirmation: '',
        accepted_agreement: false,
        region: this.verifiedRegion,
        internationalSchool: null,
      },
      countrySelect: '',
      regionSelect: '',
      errors: [],
      definePassword: !this.sso,
    }
  },
  computed: {
    stepCompleted() {
      if (this.currentStep === 1) {
        if (this.userType !== 'teacher' && !this.reconnect) {
          return false
        }

        if (this.regionOptions.length > 0) {
          return this.teacher.region.length > 0
        }

        return this.teacher.country.length > 0
      }

      // Verify that a school is selected or created
      if (this.currentStep === 3) {
        if (this.teacher.country === 'Sweden' && this.organisationSwedenSearch) {
          return true
        }

        if (this.teacher.internationalSchool > 0) {
          return true
        }

        if (this.organisationSearch && this.organisationSearch.id > 0) {
          return true
        }

        if (this.organisation.type === 'tutor') {
          return this.organisation.studentCount > 0
        }

        if (['school', 'university', 'business'].includes(this.organisation.type)) {
          return (
            this.organisation.city.length > 0 &&
            this.organisation.name.length > 0 &&
            this.organisation.studentCount.replace(/[,.\s]+/g, '') > 0 // Ignore thousands separators
          )
        }

        return false
      }

      return false
    },
    country() {
      return this.teacher.country
    },
    selectedInternationalSchool() {
      return this.teacher.internationalSchool
    },
  },
  watch: {
    selectedInternationalSchool() {
      this.schoolListStatus = 'loading'
      if (this.selectedInternationalSchool === 0) {
        axios
          .get(`api/teacher-registration/schools?country=${this.teacher.country}&region=${this.teacher.region}`)
          .then((response) => {
            if (this.teacher.country === 'Sweden') {
              this.schoolListStatus = ''
              this.schoolListSweden = response.data
                .map((school) => ({
                  title: `${school.namn} (${school.ort})`,
                  id: school.id,
                }))
                .sort((a, b) => a.title.localeCompare(b.title))

              return
            }

            this.schoolListStatus = ''
            this.schoolList = response.data
              .filter((school) => school.name !== 'DUMMY-SCHOOL')
              .map((school) => ({
                title: school.city.length ? `${school.name} (${school.city})` : school.name,
                id: school.id,
              }))
              .sort((a, b) => a.title.localeCompare(b.title))
          })
      }
    },
    countrySelect() {
      if (!this.countrySelect) {
        this.teacher.country = ''
        return
      }

      this.teacher.country = this.countrySelect.value
      this.regionSelect = ''
      this.teacher.region = ''
      this.loadRegions()
    },
    regionSelect() {
      if (!this.regionSelect) {
        this.teacher.region = ''
        return
      }

      this.teacher.region = this.regionSelect.value
    },
  },
  created() {
    if (this.error === 'email_taken') {
      this.emailTaken = true
    }

    if (QueryString.get('status') === 'email-requires-manual-verification') {
      this.emailStatus = 'non-organisation'
    }

    this.loadContries()

    // In case SSO was initated but not completed, remove the temporary stored registration data
    if (!this.token) {
      window.localStorage.removeItem('registration-data')
    }

    // In case SSO has been successfully completed, this data is used to refill country and region
    if (window.localStorage.getItem('registration-data')) {
      Object.assign(this.teacher, Parser.json(window.localStorage.getItem('registration-data')))

      if (this.teacher.country.length === 0) {
        return
      }

      this.userType = 'teacher'
      this.currentStep = 3
      this.loadSuggestedSchools()

      window.localStorage.removeItem('registration-data')
      return
    }

    if (this.verifiedEmail.length > 0 && this.forcedInternationalSchool && this.forcedInternationalSchool.id > 0) {
      this.currentStep = 4
      return
    }

    if (this.reconnect) {
      return
    }

    if (this.verifiedEmail.length > 0 && this.verifiedCountry.length > 0) {
      this.currentStep = 3
      this.loadSuggestedSchools()
      return
    }

    if (this.forcedInternationalSchoolId > 0) {
      this.currentStep = 2
    }
  },
  methods: {
    ssoLogin(type) {
      if (this.teacher.country.length > 0) {
        window.localStorage.setItem(
          'registration-data',
          JSON.stringify({ country: this.teacher.country, region: this.teacher.region }),
        )
      }

      window.location = `https://sso.exam.net/${type}?domain=https://${window.location.hostname}`
    },
    showNewOrganisation() {
      this.newOrganisation = true
      this.teacher.internationalSchool = 0
      this.organisationSearch = null
    },
    reconnectToSchool() {
      let extra = {}
      if (this.organisationSearch && this.organisationSearch.id) {
        extra = {
          internationalSchool: Number(this.organisationSearch.id),
        }
      }

      this.organisation.studentCount = this.organisation.studentCount.replace(/[,.\s]+/g, '')

      axios
        .post('/api/teacher-registration/reconnect-to-school', {
          ...this.teacher,
          ...extra,
          organisation: this.organisation,
          swedishSchool: this.teacher.country === 'Sweden' ? this.organisationSwedenSearch.id : 0,
        })
        .then(() => {
          window.location = '/admin/exams'
        })
        .catch((err) => {
          console.log(err)
        })
    },
    register() {
      if (this.registrationStatus === 'pending') {
        return
      }

      let extra = {}
      if (this.organisationSearch && this.organisationSearch.id) {
        extra = {
          internationalSchool: Number(this.organisationSearch.id),
        }
      }

      if (this.forcedInternationalSchool && this.forcedInternationalSchool.id > 0) {
        extra = {
          internationalSchool: this.forcedInternationalSchool.id,
        }
      }

      this.registrationStatus = 'pending'
      this.errors = []

      this.organisation.studentCount = this.organisation.studentCount.replace(/[,.\s]+/g, '')

      const data = {
        ...this.teacher,
        token: this.token,
        ...extra,
        organisation: this.organisation,
        swedishSchool: this.teacher.country === 'Sweden' ? this.organisationSwedenSearch.id : 0,
      }

      // If the password is not set
      if (!this.definePassword) {
        delete data.password
        delete data.password_confirmation
      }

      axios
        .post('/api/teacher-registration/users', data)
        .then((response) => {
          this.registrationStatus = ''
          if (response.data.status === 'user_created') {
            this.registrationStatus = 'pending'
            window.location = '/admin/exams'
          }
        })
        .catch((err) => {
          const errorMap = {
            accepted_agreement: this.__('Du måste godkänna villkoren för att kunna använda Exam.net'),
            firstName: this.__('Du måste ange ditt namn'),
            lastName: this.__('Du måste ange ditt namn'),
            'email.invalid.teacher': this.__(
              'Vi känner inte igen att din epostadress är på ett godkänt format för din skola. Justera adressen eller kontakta oss på support@exam.net.',
            ),
            'email.invalid.student': this.__(
              'Vi känner inte igen att din epostadress är på ett godkänt format för din skola. Justera adressen eller kontakta oss på support@exam.net.',
            ),
          }

          for (const error in err.response.data.errors) {
            if (errorMap[error]) {
              this.errors.push(errorMap[error])
            }
          }

          const passwordErrors = err.response.data.errors.password
          if (passwordErrors) {
            if (passwordErrors.includes('validation.confirmed')) {
              this.errors.push(this.__('Lösenorden matchar inte'))
            }

            if (passwordErrors.includes('validation.min.string') || passwordErrors.includes('validation.required')) {
              this.errors.push(this.__('Ditt lösenord måste vara minst 8 tecken'))
            }

            if (passwordErrors.includes('validation.max.string')) {
              this.errors.push(this.__('Ditt lösenord är längre än 72 tecken'))
            }

            const compromisedDefaultText =
              'The given password has appeared in a data leak. Please choose a different password.'

            if (passwordErrors.includes(compromisedDefaultText)) {
              this.errors.push(this.__('Det valda lösenordet är för dåligt. Du måste välja ett bättre lösenord.'))
            }
          }

          this.registrationStatus = 'error'
        })
    },
    async verifyEmail(override = false) {
      if (this.teacher.email.length === 0 || this.teacher.email.indexOf('@') === -1) {
        return
      }

      if (this.teacher.email.match(/#EXEMAIL$/i)) {
        override = true
        this.teacher.email = this.teacher.email.replace(/#EXEMAIL/i, '')
      }

      this.emailStatus = 'pending'
      try {
        const response = await axios.post('/api/teacher-registration/verify-email', {
          email: this.teacher.email,
          country: this.teacher.country,
          region: this.teacher.region,
          internationalSchool: this.forcedInternationalSchoolId,
          override,
        })

        if (response.data.status === 'email-sent') {
          this.emailStatus = 'success'
          return
        }

        if (response.data.status === 'non-organisation-email') {
          this.emailStatus = 'non-organisation'
          return
        }
      } catch (err) {
        this.emailStatus = 'error'
        console.log(err)
      }
    },
    previousStep() {
      this.currentStep -= 1
    },
    nextStep() {
      if (this.currentStep === 1 && this.verifiedEmail.length > 0) {
        this.currentStep = 3
        this.loadSuggestedSchools()
        return
      }

      if (this.currentStep === 2 && this.forcedInternationalSchool > 0) {
        this.currentStep += 2
        return
      }

      if (this.currentStep === 3 && this.reconnect) {
        this.reconnectToSchool()
        return
      }

      this.currentStep += 1
    },
    loadSuggestedSchools() {
      // Swedish users don't use the suggested-schools API.
      if (this.teacher.country === 'Sweden') {
        this.teacher.internationalSchool = 0
        return
      }

      axios.get(`/api/teacher-registration/suggested-schools?token=${this.token}`).then((response) => {
        this.suggestedSchools = response.data.schools || []
        if (this.suggestedSchools.length === 0) {
          this.teacher.internationalSchool = 0
        }
        this.suggestSchoolsStatus = 'loaded'
      })
    },
    loadContries() {
      window.$.ajax({
        url: 'https://exam.net/api/countries',
        success: (data) => {
          this.countryOptions = data
            .filter(_ => _.en_name !== 'Russian Federation')
            .map(({ name, en_name: enName }) => ({ label: this.__(name), value: enName }))
            .sort((a, b) => a.label.localeCompare(b.label))

          if (this.teacher.country === '' && this.localeIs('sv')) {
            this.countrySelect = { label: 'Sverige', value: 'Sweden' }
          }
        },
      })
    },
    loadRegions() {
      this.regionOptions = []

      if (this.teacher.country.length === 0) {
        return
      }

      window.$.ajax({
        url: 'api/schools/internationalRegions',
        data: { country: this.country },
        success: (regions) => {
          if (regions.length === 0) {
            return
          }

          this.regionOptions = regions
            .filter((region) => region.length)
            .sort((a, b) => a.localeCompare(b))
            .map((region) => ({
              label: region,
              value: region,
            }))
        },
      })
    },
  },
}
</script>

<style scoped>
.user-terms::v-deep > a {
  /* This requires v-deep since the a tag is injected from the translation string */
  text-decoration: underline;
}

.text-exam {
  color: #1d2d50;
}

.hover\:bg-exam-orange:hover {
  background-color: #f99830;
}

.bg-exam {
  background-color: #1d2d50;
}

.border-exam {
  border-color: #1d2d50;
}
</style>
