





































































































































































































































































































































































































































































.MonitoringStudent {
  .topBar {
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacing-3);

    &__returnToOverview {
      color: var(--blue-600);
      display: flex;
      align-items: center;
      text-decoration: none;

      &__icon {
        font-weight: var(--font-semibold);
        font-size: var(--font-size-lg);
        margin-right: var(--spacing-1);
      }

      &__text {
        line-height: normal;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }

  &__SEBWarnings {
    margin-top: var(--spacing-3);
  }

  &__TabItem {
    margin-top: var(--spacing-3);
  }

  &__title {
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-6);
  }

  &__resume-key {
    margin-top: var(--spacing-4);

    .key {
      font-weight: var(--font-bold);
    }
  }

  &__student-info {
    margin-top: var(--spacing-2);
    color: var(--gray-700);

    .ip-address {
      margin-right: var(--spacing-2);
    }
  }

  .Overview {
    display: flex;
    justify-content: space-between;

    &__actions {
      display: flex;
      flex-direction: column;
    }

    &__action {
      margin-bottom: var(--spacing-3);
    }
  }

  &__attachments-wrapper {
    margin-top: var(--spacing-3);
    display: flex;
    flex-wrap: wrap;
  }

  &__attachment {
    display: flex;
    flex-wrap: wrap;
  }

  &__student-answer {
    &::v-deep {
      .katex-display {
        display: inline-block;
      }

      img {
        max-width: 100%;
      }
    }
  }

  &__settings-link {
    color: var(--blue-600);
    margin-top: var(--spacing-14);
    font-size: var(--font-size-sm);
    font-weight: var(--font-semibold);
    display: block;
    text-decoration: none;

    i {
      margin-left: var(--spacing-1);
      color: var(--gray-800);
    }
  }

  &__settings {
    margin: var(--spacing-2);
  }
}
