









































.el-tab-item {
  width: 100%;
}

.el-tab-item-header {
  padding-bottom: 10px;
}
