


















































































































































































































































































@import 'Less/variables.less';

.groupSettings {
  position: absolute;
  display: block;
  top: 10px;
  right: 0;
  color: #fff;
  cursor: pointer;
  width: 30px;
  padding-left: 10px;
}

.invisible {
  visibility: hidden;
}

.exam-list-main {
  position: relative;
  background-color: #fff;
  margin-bottom: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
}

.exam-list-header {
  top: 0;
  height: 60px;
  background-color: rgb(31, 50, 92);
  color: #fff;
  line-height: 60px;
  font-size: 30px;
  margin-bottom: 0;
}

.sort-list-move {
  transition: transform 0.4s;
}

.borderless td,
.borderless th {
  border: none;
}

.row-border {
  border-bottom: 1px solid #eee;
}

th {
  background-color: transparent !important;
}

.clickable-header {
  cursor: pointer;
}

.clickable-header:hover {
  text-decoration: underline;
}

.highlightStyle {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
  border-left: 5px solid @theme-blue;
}

.exam-list-table {
  background-color: green;
  border: none;
  overflow: show;
}

.table-icon {
  width: 20px;
}

.progressBar {
  border: 1px solid #111;
  background-color: #292929;
  width: 100%;
  height: 100%;
  text-align: center;
}

.progressBar div {
  height: 100%;
  color: #fff;
  text-align: right;
  line-height: 22px;
  width: 0;
  border-radius: 15px;
  background-color: rgb(255, 178, 0);
}

.more-icon {
  font-size: 14px;
  color: black !important;
  margin-left: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: relative;
  }
}

.error {
  color: red;
}

.info {
  color: green;
}

span.examKey {
  font-family: Verdana, sans-serif;
}

tr.own {
  background: rgba(255, 178, 0, 35%);
}

tr.own:hover {
  background: rgba(255, 178, 0);
}
