







































































@import 'Less/variables.less';

.zoom {
  display: flex;
  flex: 0 1 66%;
  justify-content: center;
  align-items: center;

  &__icon {
    padding-right: @spacing-2;
    padding-left: @spacing-2;
    display: inline-flex;
    font-size: @font-size-small;
    user-select: none;

    &:hover {
      color: @theme-primary;
    }
  }

  &__restore {
    min-width: 34px;
    text-align: right;
    cursor: pointer;
    font-size: @font-size-small;

    &:hover {
      color: @theme-primary;
    }
  }
}
