

















































@import 'Less/variables.less';

.selectContainer {
  position: relative;
  width: 100%;
  max-width: 200px;
  height: auto;
  font-size: 12px;
  text-align: left;
  margin-top: 15px;
  color: #373737;
  border-radius: 5px;
  box-sizing: border-box;
}

.selectContainer-field {
  height: 30px;
  width: 200px;
  background-color: #eee;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  padding-left: 10px;
}

.wide {
  width: 300px;
}

.fullWidth {
  width: 100%;
}

.high {
  height: 50px;
}

.selectContainer-field:focus {
  border: 1px solid @theme-blue;
}

.noMargin {
  margin-top: 0;
}

.narrow {
  width: 60px;
}

.transparent {
  background: none;
}
