













































































































































































.option {
  border: 1px solid black;
  background: black;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.option:hover {
  background: green;
}

.box {
  background: white;
  width: 700px;
  margin-top: -200px;
  text-align: center;
  border: 1 px solid black;
  padding: 10px 30px;
  max-width: 1000px;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 20%);
  border-radius: 5px;
  color: black;

  textarea {
    height: 500px;
    width: 600px;
    border: none;
    box-shadow: 2px 2px 33px #00000052;
    resize: none;
    outline: none;
    padding: 10px;
  }
}
