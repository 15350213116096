































































































































@import 'Less/variables.less';

.editGroup {
  &__group-title-field {
    display: flex;
    flex-direction: column;

    &__error {
      min-height: @spacing-7;
      color: @color-danger-dark;
      flex: 1;
    }

    &__warning {
      min-height: @spacing-7;
      color: @color-warning-dark;
      flex: 1;
    }

    &__input {
      margin-top: 0;
      border-radius: @radius-xs;

      &--wrapper {
        display: flex;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: @spacing-7;

    &__button {
      margin: 0 @spacing-1;
    }
  }
}
