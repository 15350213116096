

















































































































































































































































































































































































@import 'Less/variables.less';

.ExamGroups {
  margin-top: @spacing-7;
}

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 30px;
}

.bannerBackground {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
}

/* Standard elements */
.font-caption {
  font-size: 12px;
  font-style: italic;
  color: #373737;
  margin-bottom: 10px;
}

.animation {
  transition: all 0.2s ease-in-out;
}

p.small {
  font-size: 13px;
}

.searchWrapper {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 999;
}

.previous-school-exams {
  margin-top: 40px;
}

.group-list-move {
  transition: transform 0.5s;
}

.testimonial-card {
  margin-top: 30px;
  margin-bottom: 20px;

  h4 {
    margin-top: 20px;
    font-size: 20px;
  }

  &__add {
    margin-bottom: 10px;
    margin-top: 15px;
    display: flex;
  }

  &__buttons {
    display: flex;
    margin-bottom: 10px;
  }
}
