












































































































.moveExam {
  &__footer {
    display: flex;
    justify-content: center;

    &__button {
      margin: 0 4px;
    }
  }
}
