







































































































.Menu {
  &__label-icon {
    margin-right: var(--spacing-1);
  }

  .button {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition-all);
    border: unset;
    background: unset;
    border-radius: var(--radius-round);
    padding: var(--spacing-1) 0;

    &--bordered {
      padding: var(--spacing-1) var(--spacing-4);
      border-radius: var(--radius-round);
      background-color: white;
      border: var(--border-width-1) solid var(--gray-600);

      &--open,
      &:hover {
        color: var(--yellow-500);
        border: var(--border-width-1) solid var(--yellow-500);
      }
    }

    &__icon {
      margin-left: var(--spacing-2);
      cursor: pointer;
    }
  }

  .dropdown {
    position: absolute;
    max-width: var(--width-72);
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
    margin-top: var(--spacing-1);
    border: var(--divider-border);
    border-radius: var(--radius-lg);
    background-color: white;
    box-shadow: var(--shadow-sm);
    font-size: var(--font-size-sm);
    z-index: var(--z-layer-2);
    white-space: nowrap;

    .icon {
      margin-right: var(--spacing-2);
    }

    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      cursor: pointer;
      padding: var(--spacing-2);
      padding-right: var(--spacing-4);
      border-radius: var(--radius-default);

      &:hover {
        background-color: var(--gray-200);
        border-radius: 0;
      }

      &:active {
        box-shadow: var(--shadow-inner);
      }
    }
  }
}

