










































































































































































































.ExamEndExam {
  .throwOutStudentsModal {
    &__handle-exceptions {
      display: flex;
      justify-content: center;
      align-items: center;

      &__SearchSelect {
        margin-left: var(--spacing-2);
      }
    }

    &__excluded-students {
      margin-top: var(--spacing-2);
      background-color: var(--gray-100);
      border-radius: var(--radius-sm);
      cursor: pointer;
    }

    &__excluded-students-list {
      font-size: var(--font-size-md);
      list-style: none;
      padding: 0;

      li:hover {
        text-decoration-line: line-through;
        text-decoration-color: var(--red-700);
      }
    }

    &__action {
      margin-bottom: var(--spacing-2);
    }

    &__footer {
      justify-content: center;
    }
  }
}

