











































































































































































































































































































































































@min-height: 90px;
@width: 350px;
@color-button: #ce675c;
@color-record: #131415;
@shadow-1: 0px 5px 10px rgba(0, 0, 0, 0.2);
@animation: all 0.2s ease-in-out;

.audio-recorder {
  position: relative;
  width: @width;
  min-height: @min-height;
  background-color: #fff;

  &__audio {
    width: @width;
  }

  &__list {
    width: 100%;
    height: auto;
    max-height: 500px;
    overflow: auto;

    &--deactive {
      opacity: 0.6;
      // height: 30px;
    }
  }

  &__item {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    overflow-x: hidden;

    &--delimiter {
      border-top: 1px solid #f2f3f4;
    }

    &--active {
      overflow: hidden;
    }

    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 18px;
      flex-grow: 1;
    }

    &__icon {
      cursor: pointer;
      height: 20px;
      width: 20px;
      display: flex;
      flex-direction: center;
      align-items: center;
    }

    &__input {
      display: flex;
      flex-grow: 1;
      margin: 5px 0;
      font-size: 18px;
      border-radius: 3px;
      border: 1px solid #eee;
      outline: none;

      &:focus {
        border-color: @color-record;
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__record {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: @color-record;
    padding: 10px;
    border-radius: 0;
    min-height: 20px;

    &--large {
      height: @min-height;
    }

    &__button {
      position: relative;
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      border-radius: 50%;
      background-color: @color-button;
      border: none;
      outline: none;
      cursor: pointer;
      z-index: 2;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml;utf8,<svg width='16px' height='22px' viewBox='0 0 16 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'> <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'> <g transform='translate(-893.000000, -121.000000)' fill-rule='nonzero'> <g transform='translate(893.000000, 121.000000)'> <path d='M8,0 C10.209139,0 12,1.790861 12,4 L12,10 C12,12.209139 10.209139,14 8,14 C5.790861,14 4,12.209139 4,10 L4,4 C4,1.790861 5.790861,0 8,0 Z' fill='%23FFFFFF'></path> <path d='M8.99999952,17.94 L8.99999952,20 L11.9999995,20 C12.5522847,20 12.9999995,20.4477153 12.9999995,21 C12.9999995,21.5522847 12.5522847,22 11.9999995,22 L3.99999952,22 C3.44771525,22 2.99999952,21.5522847 2.99999952,21 C2.99999952,20.4477153 3.44771525,20 3.99999952,20 L6.99999952,20 L6.99999952,17.94 C2.99896186,17.4359168 -0.00138421682,14.0326671 -4.7876246e-07,10 C-4.7876246e-07,9.44771525 0.44771525,9 0.999999521,9 C1.55228475,9 1.99999952,9.44771525 1.99999952,10 C1.99999952,13.3137085 4.6862915,16 7.99999952,16 C11.3137085,16 13.9999995,13.3137085 13.9999995,10 C13.9999995,9.44771525 14.4477153,9 14.9999995,9 C15.5522847,9 15.9999995,9.44771525 15.9999995,10 C16.0013842,14.0326671 13.0010381,17.4359168 8.99999952,17.94 Z' fill='%23FFFFFF'></path> </g> </g> </g></svg>");

      &:hover {
        box-shadow: @shadow-1;
        transform: scale(1.1);
      }

      &--active {
        box-shadow: @shadow-1;

        &:hover {
          background-image: url("data:image/svg+xml;utf8,<svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'> <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'> <g transform='translate(-558.000000, -40.000000)' fill='%23FFFFFF' fill-rule='nonzero'> <rect x='558' y='40' width='14' height='14' rx='2'></rect> </g> </g></svg>");
          transform: scale(1.1);
        }

        &::after {
          position: absolute;
          content: ' ';
          background-color: transparent;
          border: 5px solid @color-button;
          box-sizing: border-box;
          height: 50px;
          width: 50px;
          top: -5px;
          left: -5px;
          z-index: -1;
          opacity: 0.5;
          border-radius: 50%;
          box-shadow: @shadow-1;
          animation: pulse 2s ease-in-out infinite;
          transform: scale(1);
        }
      }

      &--upload {
        background-color: #2f8ec9;
        background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'> <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'> <g transform='translate(-502.000000, -638.000000)' fill='%23FFFFFF' fill-rule='nonzero'> <g transform='translate(501.000000, 637.000000)'> <path d='M17,13.97 C17,13.21 16.7,12.46 16.12,11.87 L13.12,8.87 C11.9487298,7.70018649 10.0512702,7.70018649 8.88,8.87 L5.88,11.87 C5.31477501,12.4345172 4.99804252,13.2011539 5,14.0000399 C2.97691851,14.0089711 1.26565082,12.5059793 1.01346194,10.4986577 C0.761273064,8.49133611 2.04757177,6.61174652 4.01,6.12 C4.18951506,3.38683245 6.35278663,1.2023056 9.08406837,0.996076219 C11.8153501,0.789846842 14.2821165,2.62477598 14.87,5.3 C16.2554046,4.7563971 17.8201463,4.93458305 19.0478587,5.77575629 C20.275571,6.61692953 21.0067104,8.01177789 21.0000458,9.5 C20.9989125,11.7909802 19.2767947,13.7154469 17,13.97 L17,13.97 Z' ></path> <path d='M10,13.41 L10,20 C10,20.5522847 10.4477153,21 11,21 C11.5522847,21 12,20.5522847 12,20 L12,13.41 L13.3,14.71 C13.6991459,15.0471653 14.290578,15.0202154 14.6574061,14.6481468 C15.0242342,14.2760783 15.0427927,13.6843235 14.7,13.29 L11.7,10.29 C11.3111565,9.90885712 10.6888435,9.90885712 10.3,10.29 L7.3,13.29 C6.9572073,13.6843235 6.97576579,14.2760783 7.34259392,14.6481468 C7.70942204,15.0202154 8.3008541,15.0471653 8.7,14.71 L10,13.41 Z' ></path> </g> </g> </g></svg>");
        cursor: not-allowed;

        &:hover {
          box-shadow: @shadow-1;
          transform: scale(1);
        }

        &::after {
          position: absolute;
          content: ' ';
          background-color: transparent;
          border: 2px solid #2f8ec9;
          box-sizing: border-box;
          height: 50px;
          width: 50px;
          top: -5px;
          left: -5px;
          z-index: -1;
          opacity: 0.5;
          border-radius: 50%;
          animation: pulse 1s ease-in-out infinite;
          transform: scale(1);
        }
      }

      &--error {
        background-image: url("data:image/svg+xml;utf8,<svg width='16px' height='20px' viewBox='0 0 16 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'> <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'> <g transform='translate(-811.000000, -294.000000)' fill-rule='nonzero'> <g transform='translate(811.000000, 294.000000)'> <path d='M0,2 L8,0 L16,2 L16,11.06 C15.9983832,14.0877148 14.2876373,16.8550978 11.58,18.21 L8,20 L4.42,18.21 C1.71236267,16.8550978 0.00161680834,14.0877148 0,11.06 L0,2 Z' fill='%23ffffff'></path> <path d='M8,16 C7.17157288,16 6.5,15.3284271 6.5,14.5 C6.5,13.6715729 7.17157288,13 8,13 C8.82842712,13 9.5,13.6715729 9.5,14.5 C9.5,15.3284271 8.82842712,16 8,16 Z M9,10.1 C8.87,11.3 7.12,11.3 7,10.1 L6.5,5.1 C6.4716473,4.81793003 6.56434919,4.53708124 6.7550461,4.32731464 C6.94574302,4.11754803 7.21651222,3.99857862 7.5,3.99998741 L8.5,3.99998741 C8.78348778,3.99857862 9.05425698,4.11754803 9.2449539,4.32731464 C9.43565081,4.53708124 9.5283527,4.81793003 9.5,5.1 L9,10.1 Z' fill='%23CE675C'></path> </g> </g> </g></svg>");
      }
    }

    &__caption {
      color: #fff;
      font-size: 12px;
      margin: 5px;
    }

    &__fill {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      background-color: transparent;
    }

    &__error {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &__button {
        background-color: transparent;
        border: none;
        outline: none;
        margin: 0 10px;
        color: #5b9cd1;
        height: 20px;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &:focus {
          text-decoration: underline;
        }

        &--danger {
          color: #d84a39;
        }
      }
    }
  }
}

.animation {
  transition: @animation;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.4s;
}

.list-enter {
  opacity: 0;
  transform: translateY(30px);
}

.list-leave-to {
  transform: translateY(-30px);
  max-height: 0;
  margin: 0;
  padding: 0 10px;
  opacity: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
