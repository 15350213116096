<template>
  <div
    class="wrapper"
    :class="{ blur: status === 'loading' }"
    style="padding-right: 10px"
  >
    <div style="display: flex">
      <div style="flex: 1">
        <ul class="gap-6 grid grid-cols-2 lg:grid-cols-2 mb-14 md:grid-cols-2 sm:grid-cols-2">
          <li class="bg-white col-span-1 flex flex-col rounded-lg shadow text-center">
            <div class="flex flex-1 flex-col items-baseline p-2">
              <div class="w-full">
                <input
                  v-model="email"
                  placeholder="Ange lärarens epost address"
                  style="width: 50%; float: left"
                  class="p-8"
                  @keyup.enter="fetchUserByEmail"
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="hasUser"
      style="display: flex"
    >
      <div style="flex: 3">
        <h3>
          Lärarinfo (<a
            :href="loginLink()"
            target="_blank"
          >Logga in som</a>)
        </h3>
        <table>
          <tr v-if="user.school === null">
            <td>Skola</td>
            <td>Ingen</td>
          </tr>
          <tr v-if="hasSchool">
            <td>Ort</td>
            <td>{{ user.school.ort }}</td>
          </tr>
          <tr v-if="hasSchool">
            <td>Skola</td>
            <td>{{ user.school.namn }}</td>
          </tr>
          <tr>
            <td>Förnamn</td>
            <td>{{ user.firstName }}</td>
          </tr>
          <tr>
            <td>Efternamn</td>
            <td>{{ user.lastName }}</td>
          </tr>
          <tr>
            <td>Epost</td>
            <td>{{ user.email }}</td>
          </tr>
        </table>

        <h3>Flytta lärare</h3>
        <table style="width: 700px">
          <tr>
            <td>
              <vue-simple-suggest
                v-model="chosenSchool"
                :maxSuggestions="20"
                style="font-size: 14px"
                :list="schoolList"
                displayAttribute="display"
                mode="select"
                :placeholder="__('Ange skola')"
                :filterByQuery="true"
              />
            </td>
            <td>
              <text-button
                title="Flytta"
                :disabled="chosenSchool == null"
                @click="moveUser"
              />
            </td>
          </tr>
        </table>

        <text-button
          title="Radera läraren och all tillhörande data (prov, elever mm)"
          @click="deleteUser"
        />

        <h3>Prov</h3>
        <div
          v-for="exam in exams"
          :key="exam.id"
        >
          <h4>
            <b>{{ exam.examkey }}, {{ exam.name }}</b>
          </h4>
          <h6 v-if="exam.is_hidden == 1 || exam.archived_at != null || exam.deleted_at != null">
            <b>
              <span
                v-if="exam.archived_at != null"
                style="padding-right: 5px"
              >Arkiverad</span>
              <span
                v-if="exam.deleted_at != null"
                style="padding-right: 5px"
              >Borttagen</span>
              <span
                v-if="exam.is_hidden == 1"
                style="padding-right: 5px"
              >Dold</span>
            </b>
          </h6>
          <div
            v-for="examStudent in studentsForExam(exam.id)"
            :key="examStudent.id"
            style="float: left"
          >
            &nbsp;<a @click="showStudent(examStudent)">{{ formatStudentName(examStudent) }}</a>,
          </div>
          <div style="clear: both" />
          <hr style="border-top: 1px solid black">
        </div>
        <h3>Delningar</h3>
        {{ user.shares }}
      </div>

      <div style="flex: 2">
        <div v-if="student != null">
          <h3>Elevinfo</h3>
          <table>
            <tr v-if="student.firstName != ''">
              <td>Förnamn</td>
              <td>{{ student.firstName }}</td>
            </tr>
            <tr v-if="student.lastName != ''">
              <td>Efternamn</td>
              <td>{{ student.lastName }}</td>
            </tr>
            <tr v-if="student.email != ''">
              <td>Epost</td>
              <td>{{ student.email }}</td>
            </tr>
            <tr v-if="student.teacher != ''">
              <td>Lärare</td>
              <td>{{ student.teacher }}</td>
            </tr>
            <tr v-if="student.class != ''">
              <td>Klass</td>
              <td>{{ student.class }}</td>
            </tr>
            <tr v-if="student.answer != ''">
              <td>Svar</td>
              <td><a @click="showAnswerModal(student.answer)">Visa svar</a></td>
            </tr>
          </table>

          <h3>Logs</h3>
          <div
            v-for="log in studentLogs"
            :key="log.id"
          >
            {{ formatDate(log.created_at) }} - {{ log.type }}
          </div>

          <h3>Backups</h3>
          <div
            v-for="backup in studentBackups"
            :key="backup.id"
          >
            <a @click="showAnswerModal(backup.answer)">{{ formatDate(backup.created_at) }}</a>
          </div>

          <h3>Offline saves</h3>
          <div
            v-for="offlineSave in studentOfflineSaves"
            :key="offlineSave.id"
          >
            {{ formatDate(offlineSave.created_at) }} <a @click="showAnswerModal(offlineSave.new_answer)">Ny</a>
            <a @click="showAnswerModal(offlineSave.old_answer)">Gammal</a>
          </div>
        </div>
      </div>
    </div>

    <exam-modal
      ref="studentAnswerModal"
      size="lg"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Elevsvar') }}
      </h3>
      <div
        slot="body"
        class="text-center"
        style="display: flex; flex-direction: column; justify-content: space-around; align-items: center"
      >
        {{ answer }}
      </div>
      <div
        slot="footer"
        style="display: flex; flex-direction: column; justify-content: space-around; align-items: center"
      >
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Stäng')"
          position="middle"
        />
      </div>
    </exam-modal>

    <div
      v-show="status === 'loading'"
      style="z-index: 9999; position: fixed; top: 300px; left: 40%"
    >
      <i
        style="font-size: 200px"
        class="fa fa-spin fa-spinner"
      />
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import Modal from 'Modules/Modal.js'
import Student from 'Modules/Student.js'
import axios from 'axios'

export default {
  components: { VueSimpleSuggest },
  data() {
    return {
      deleteCount: 0,
      answer: 'Hello world',
      chosenSchool: null,
      email: null,
      key: null,
      schoolList: [],
      status: 'loaded',
      student: null,
      user: null,
    }
  },
  computed: {
    hasUser() {
      return this.user != null && this.user !== []
    },
    hasSchool() {
      return this.user == null ? false : this.user.school != null
    },
    exams() {
      const exams = this.user.exams
      return exams.sort((a, b) => b.id - a.id)
    },
    studentBackups() {
      const backups = this.student.backups
      return backups.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    studentLogs() {
      const studentLogs = this.student.student_logs
      return studentLogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    studentOfflineSaves() {
      const offlineSaves = this.student.offline_saves
      return offlineSaves.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
  },
  mounted() {
    this.preloadSchools()
  },
  methods: {
    loginLink() {
      return `/admin/impersonate/${this.user.email}`
    },
    preloadSchools() {
      window.$.ajax({
        url: '/api/site-admin/schools',
        success: (data) => {
          data.forEach((school) => {
            school.display = `${school.namn} (${school.ort})`
          })

          this.schoolList = data
        },
      })
    },
    fetchUserByEmail() {
      this.status = 'loading'
      fetch(`/api/site-admin/user?email=${window.encodeURIComponent(this.email)}`, { credentials: 'include' })
        .then((data) => data.json())
        .then((data) => {
          data.firstName == null ? (this.user = null) : (this.user = data)
          this.status = 'loaded'
        })
    },
    studentsForExam(examId) {
      return this.user.students
        .filter((student) => Number(student.exam_id) === examId)
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
    },
    formatStudentName(student) {
      return Student.displayName(student)
    },
    showStudent(student) {
      this.status = 'loading'
      fetch(`/api/site-admin/student/${student.id}`, { credentials: 'include' })
        .then((data) => data.json())
        .then((data) => {
          Array.isArray(data) && data.length === 0 ? (this.student = null) : (this.student = data)
          this.status = 'loaded'
        })
    },
    showAnswerModal(answer) {
      this.answer = answer
      Modal.show(this.$refs.studentAnswerModal)
    },
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      return new Date(date).toLocaleDateString('sv-SE', options)
    },
    moveUser() {
      this.status = 'loading'
      fetch(`/api/site-admin/user/${this.user.email}/move/${this.chosenSchool.id}`, { credentials: 'include' }).then(
        () => {
          this.fetchUserByEmail()
        },
      )
    },
    deleteUser() {
      this.deleteCount += 1

      if (this.deleteCount >= 3) {
        this.status = 'loading'
        axios.post(`/api/site-admin/delete-user/${this.user.id}`, { confirm: true }).then(() => {
          this.user = null
          this.status = ''
          this.deleteCount = 0
        })

        return
      }

      setTimeout(() => {
        this.deleteCount = Math.max(0, this.deleteCount - 1)
      }, 1000)
    },
  },
}
</script>

<style scoped>
.wrapper {
  padding: 10px;
}

.blur {
  filter: blur(10px);
}

td:nth-child(1) {
  padding-right: 10px;
  font-weight: bold;
}
</style>
