




























































































































































@import 'Less/variables.less';

.wrapper {
  width: 100%;
  text-align: center;
}

.pdp {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed @theme-blue;
  background: #efb4b4;
}

.green {
  background: #95ec95;
}
