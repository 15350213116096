



































































@import 'Less/variables.less';

div {
  flex: 1;
  margin: auto;
}

.status-icon {
  width: 100%;
  font-size: @font-size-medium;
  text-align: center;
}

.status-text {
  font-size: @font-size-medium;

  &.status-text--small {
    font-size: var(--font-size-sm);
  }
}
