

















































































@import 'Less/variables.less';

.text-button {
  --primary-background-color: @theme-blue;

  &__button {
    user-select: none;
    line-height: 1.33;
    cursor: pointer;
    border-radius: 300px;
    font-weight: var(--font-semibold);
    overflow: visible;
    white-space: pre-line;
    border: none;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;

    &--loading {
      opacity: 0.3;
    }
  }

  &__loading-indicator {
    position: absolute;
  }

  &__label-icon {
    margin-right: var(--spacing-1);
  }
}

.inlineStyle {
  display: inline-block;
}

i {
  font-size: @font-size-small;
}

.fullWidth {
  min-width: 100%;
  width: 100%;
}

.secondaryStyle {
  font-size: @font-size-small;
  padding: 5px 40px;
  border: 1px solid @theme-blue;
  color: @theme-blue;
  background-color: transparent;
}

.secondaryDangerStyle {
  font-size: @font-size-small;
  padding: 5px 40px;
  border: 1px solid #d84a38;
  color: #d84a38;
  background-color: transparent;
}

.secondaryDangerStyle:hover {
  border: 1px solid #9a1818;
  color: #9a1818;
}

.secondaryStyle:hover {
  color: @theme-primary;
  border: 1px solid @theme-primary;
}

.primaryStyle {
  font-size: @font-size-small;
  padding: 5px 40px;
  background-color: var(--primary-background-color);
  color: #fff;
  border: 1px solid #fff;
}

.dangerStyle {
  font-size: @font-size-small;
  padding: 5px 40px;
  background-color: #d84a38;
  color: #fff;
  text-transform: none;
}

.primaryStyle:hover {
  background-color: @theme-primary;
}

.primaryStyle[disabled]:hover {
  background-color: var(--primary-background-color);
}

.dangerStyle:hover {
  background-color: #9a1818;
}

.animation {
  transition: all 0.2s ease-in-out;
}

.capitalize {
  text-transform: capitalize;
}

// Should be after capitalize since Uppercase(Capitalize(text)) === Capitalize(Uppercase(text)) === Uppercase(text)
.uppercase {
  text-transform: uppercase;
}

.big {
  font-size: @font-size-medium;

  i {
    font-size: @font-size-medium;
  }
}

.noBorder {
  border: none;
}
