










































































































































































































































































































































































































































































































@import 'Less/variables.less';

@modal-width: 900px;

.google-classroom {
  &__title {
    h2 {
      margin-bottom: var(--spacing-2);
    }

    h4 {
      font-weight: var(--font-bold);
    }

    &--link {
      text-decoration: underline;

      &:hover {
        color: var(--gray-800);
        cursor: pointer;
      }
    }

    margin-bottom: var(--spacing-4)
  }

  &__modal-header,
  &__modal-footer {
    padding: var(--spacing-3);
  }

  &__modal-header {
    border-radius: var(--radius-lg) var(--radius-lg) 0 0;
    border-bottom: var(--divider-border);
  }

  &__modal-body {
    &__top-section {
      padding: 0 var(--spacing-5);
      text-align: center;
    }

    padding: var(--spacing-4) var(--spacing-16);
    overflow-y: auto;

    &__student-export {
      min-height: var(--height-96);

      h4 {
        font-weight: var(--font-bold);
        margin-bottom: var(--spacing-3);
      }

      &__ready-for-export-title {
        h4 {
          margin: 0;
          margin-right: var(--spacing-3);
        }

        margin-bottom: var(--spacing-3);
        display: flex;
        align-items: center;
      }

      &__checkbox {
        display: flex;
        padding: var(--spacing-1) 0;
        flex: 0 1 var(--width-third);

        &__pill {
          font-size: var(--font-size-xs);
          font-weight: var(--font-bold);
          margin-left: var(--spacing-1);
          border-radius: var(--radius-round);
          color: var(--gray-900);
          background-color: var(--gray-100);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 var(--spacing-2);
          line-height: var(--font-size-xs);
          cursor: help;
        }
      }

      &__exported {
        margin-top: var(--spacing-8);
      }

      &__selection {
        display: flex;
        flex-flow: row wrap;
        min-height: var(--height-30);
        margin-bottom: var(--spacing-6);
      }
    }

    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: var(--height-96);
      font-style: italic;
    }
  }

  &__modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    &__button:not(:last-child) {
      margin-right: var(--spacing-3);
    }
  }
}

.google-classroom__wrapper {
  z-index: 9999;
  height: 100vh;
  padding: var(--spacing-4);
  background: rgba(0, 0, 0, 20%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  .google-classroom-course {
    width: 200px;
    margin: auto;
    border: 1px solid #dadce0;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      box-shadow: @shadow-md;
      transform: scale(1.02);
    }

    &__title {
      font-size: 16px;
      text-align: center;
      height: 70px;
      border-bottom: 1px solid #dadce0;
      padding: 10px;
      background: #31a666;
      border-radius: 5px 5px 0 0;
    }

    &__content {
      padding: 20px;
    }
  }

  p {
    font-size: 14px;
  }
}
