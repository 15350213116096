<template>
  <div class="wrapper">
    <h3>Hantera BEK</h3>
    <el-input v-model="bek" />
    <br>
    <text-button
      :title="__('Skicka')"
      @click="send"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      bek: '',
    }
  },
  methods: {
    send() {
      window.$.ajax({
        url: '/api/sebhashes',
        method: 'post',
        data: {
          bek: this.bek,
        },
        success: (response) => {
          console.log(response)
          this.bek = ''
        },
      })
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  text-align: center;
}

textarea {
  border: 1px solid black;
  height: 200px;
  width: 600px;
  border-radius: 10px;
}

button {
  border-radius: 10px;
  padding: 20px;
}
</style>
