


















































.new-write-area-wrapper {
  position: relative;
  background: #f9f9f9;
  overflow: hidden;
  font-size: 18px;
  height: 100%;

  /**
  * Deep styles for text-editor
  */
  &::v-deep {
    .froala-editor__wrapper,
    .froala-editor {
      height: 100%;
    }

    .fr-box.fr-basic.fr-top .fr-wrapper {
      padding-bottom: 100px;
      box-sizing: border-box;
    }

    .fr-box.fr-basic .fr-element {
      font-size: 18px;
      color: black;
    }
  }
}
