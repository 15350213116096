



























































.el-inline {
  position: relative;
  width: auto;
  height: auto;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  &--center {
    justify-content: center;
  }

  &--nowrap {
    flex-wrap: nowrap !important;
  }

  &--stretch {
    width: 100%;
  }

  &--tight {
    min-height: 0;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--end {
    justify-content: flex-end;
  }
}
