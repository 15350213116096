<template>
  <span class="fitText">{{ text }}</span>
</template>

<script>
import fitty from 'fitty'

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fitty: undefined,
    }
  },
  destroyed() {
    this.fitty.unsubscribe()
  },
  mounted() {
    this.fitty = fitty(this.$el, this.$props)
  },
}
</script>

<style scoped>
.fitText {
  display: inline-block;
  white-space: nowrap;
}
</style>
