<template>
  <div class="container mt-4">
    <ul class="gap-6 grid grid-cols-2 lg:grid-cols-2 mb-14 md:grid-cols-2 sm:grid-cols-2">
      <li class="bg-white col-span-1 flex flex-col rounded-lg shadow text-center">
        <div class="flex flex-1 flex-col items-baseline p-8">
          <div class="w-full">
            <vue-simple-suggest
              v-model="chosenSchoolDisplay"
              :maxSuggestions="20"
              style="font-size: 18px"
              :list="schoolList"
              displayAttribute="display"
              :placeholder="__('Ange skola')"
              :filterByQuery="true"
            />
          </div>
          <el-button
            style="display: inline"
            @click="fetchSchoolStats"
          >
            Hämta skola
          </el-button>
        </div>
      </li>

      <li class="bg-white col-span-1 flex flex-col rounded-lg shadow text-center">
        <div class="flex flex-1 flex-col items-baseline p-8">
          <h4 class="font-medium leading-5 my-6 text-gray-900 text-sm">
            Hantera "Trial requests"
          </h4>
          <el-button
            style="display: inline"
            @click="loadTrialRequests"
          >
            Läs in
          </el-button>
        </div>
      </li>
    </ul>

    <div class="bg-white overflow-hidden shadow sm:rounded-lg">
      <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
        <div v-if="type === 'trial'">
          <div class="bg-white overflow-hidden shadow sm:rounded-md">
            <ul>
              <li
                v-for="trialRequest in trialRequests"
                :key="trialRequest.id"
              >
                <a
                  target="_blank"
                  :href="`/collect-school-information/${trialRequest.id}`"
                  class="block
                    duration-150
                    ease-in-out
                    focus:bg-gray-50
                    focus:outline-none
                    hover:bg-gray-50
                    no-underline
                    transition"
                >
                  <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <FormatDate :datetime="trialRequest.created_at" />
                        <div class="font-medium leading-5 text-indigo-600 text-xl">
                          <b>Provided contact information:</b> {{ trialRequest.contact }}
                        </div>
                        <div class="font-medium leading-5 text-indigo-600 text-xl">
                          <b>User name and email:</b> {{ trialRequest.user.firstName }}
                          {{ trialRequest.user.lastName }} ({{ trialRequest.user.email }})
                        </div>
                      </div>
                    </div>
                    <div class="flex-shrink-0 ml-5">
                      <svg
                        class="h-5 text-gray-400 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="type === 'existing'">
          <div v-if="school">
            <h1>{{ school.namn }}</h1>

            <a
              :href="'/admin/school/' + school.id"
              target="_blank"
            >
              <el-button>Öppna lärarlista som administratör</el-button>
            </a>

            <div>
              Skolan har access till:
              <el-input
                v-model="school.accessto"
                style="background: white; width: 200px"
              />
            </div>

            <div>
              Urkund-id:
              <el-input
                v-model="urkundId"
                style="background: white; width: 200px"
              />
            </div>

            <text-button
              style="margin-top: 10px"
              big
              :title="__('Spara')"
              @click="updateAccess"
            />
          </div>

          <div
            v-show="status === 'loading'"
            style="z-index: 9999; position: fixed; top: 300px; left: 40%"
          >
            <i
              style="font-size: 300px"
              class="fa fa-spin fa-spinner"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormatDate from 'VueDesignElements/FormatDate.vue'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'

export default {
  components: { VueSimpleSuggest, FormatDate },
  data() {
    return {
      type: '',
      chosenSchoolDisplay: '',
      school: null,
      urkundId: null,
      status: 'done',
      schoolList: [],
      trialRequests: [],
    }
  },
  mounted() {
    this.preloadSchools()
  },

  methods: {
    preloadSchools() {
      window.$.ajax({
        url: '/api/site-admin/schools',
        success: (data) => {
          data.forEach((school) => {
            school.display = `${school.namn} (${school.ort})`
          })

          this.schoolList = data
        },
      })
    },
    updateAccess() {
      window.$.ajax({
        method: 'post',
        url: `/api/licence/${this.school.id}`,
        context: this,
        data: {
          accessto: this.school.accessto,
          urkund_id: this.urkundId,
        },
        success() {
          this.fetchSchoolStats()
        },
      })
    },
    fetchSchoolStats() {
      this.type = 'existing'
      let skolid = null

      const skola = this.schoolList.find((school) => this.chosenSchoolDisplay === `${school.namn} (${school.ort})`)
      if (skola) {
        skolid = skola.id
      }

      if (!skolid) {
        return
      }

      this.status = 'loading'
      fetch(`/api/licence/${skolid}`, { credentials: 'include' })
        .then((data) => data.json())
        .then((data) => {
          console.log(data)
          this.status = 'done'
          this.school = data.school
          this.urkundId = data.urkundId
        })
    },
    loadTrialRequests() {
      this.type = 'trial'

      fetch('/api/trial-requests', { credentials: 'include' })
        .then((data) => data.json())
        .then((data) => {
          this.trialRequests = data
        })
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  text-align: center;
}

.teachers {
  flex: 2;
}

.schoolstat {
  flex: 1;
  border: 1px solid black;
  border-radius: 20px;
  text-align: center;
  box-shadow: 5px 5px 5px #00000085;
  margin: 10px;
}

td.login-green {
  color: green;
}

td.login-orange {
  color: orange;
}

td.login-red {
  color: red;
}

.schoolInfo {
  margin: 20px;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.blur {
  filter: blur(10px);
}
</style>
