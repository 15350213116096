<template>
  <div class="main">
    <div
      class="mainHeader"
      @click="isExpanded = !isExpanded"
    >
      <div class="more">
        <span class="more-circle">
          <span class="more-icon">
            <span
              ref="line1"
              class="animation more-line more-line-1"
              :class="{ transformed1: isExpanded }"
            />
            <span
              ref="line2"
              class="animation more-line more-line-2"
              :class="{ transformed2: isExpanded }"
            />
          </span>
        </span>
      </div>

      <div style="display: flex">
        <div style="margin-right: 40px;">
          <i
            v-if="icon"
            class="fa"
            :class="'fa-' + icon"
            style="font-size: 20px"
          />
          <span v-text="title" />
        </div>
        <div>
          <slot name="icons" />
        </div>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="optimize">
        <div
          v-if="isExpanded"
          class="collapseContent"
        >
          <slot />
        </div>
      </div>
      <div v-else>
        <div
          v-show="isExpanded"
          class="collapseContent"
        >
          <slot />
        </div>
      </div>
    </transition>

    <div
      v-show="isExpanded"
      class="extensionLine"
    />
  </div>
</template>

<script>
/* global $  */
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    open: Boolean,
    icon: {
      type: String,
      default: '',
    },
    optimize: Boolean,
    expand: Boolean,
  },
  data() {
    return {
      isExpanded: this.expand,
    }
  },

  mounted() {
    if (this.expand) {
      setTimeout(() => {
        const el = this.$el
        $('html, body').animate(
          {
            scrollTop: $(el).offset().top - 100,
          },
          200,
        )
      }, 300)
    }

    if (this.open === true) this.isExpanded = true
  },
}
</script>

<style scoped>
.main {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 18px;
  text-align: left;
  margin-top: 15px;
  color: #373737;
}

.mainHeader {
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
}

.collapseContent {
  margin-left: 30px;
  width: calc(100% - 30px);
  height: auto;
}

.extensionLine {
  position: absolute;
  top: 18px;
  left: 10px;
  height: calc(100% - 12px);
  width: 1px;
  background-color: #d8d8d8;
}

/* collapse icon */
.more {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.more-circle {
  background-color: transparent;
  top: 2px;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.more-icon {
  position: absolute;
  width: 15px;
  height: 10px;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: rotate(90deg);
}

.more-line {
  background-color: #373737;
  height: 2px;
  width: 12px;
  border-radius: 1px;
  position: absolute;
  left: 0;
}

.more-line-1 {
  top: 1px;
  transform: translateX(2px) rotate(40deg);
}

.more-line-2 {
  bottom: 1px;
  transform: translateX(2px) rotate(-40deg);
}

.transformed1 {
  transform: translateX(3px) rotate(140deg);
}

.transformed2 {
  transform: translateX(3px) rotate(-140deg);
}

.animation {
  transition: all 0.2s ease-in-out;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-enter {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
