















































@import 'Less/variables.less';

@button-height: 40px;
@animation: all 0.5s ease-in-out;

.el-animation {
  transition: @animation;
}

.el-expand-button {
  height: @button-height;
  cursor: pointer;
  box-sizing: border-box;
  background-color: transparent;
  position: absolute;
  width: 30px;
  z-index: 999;
  right: 0;
  bottom: 200px;

  &__icon {
    position: absolute;
    width: 16px;
    height: 13px;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &__line {
      background-color: @theme-primary;
      height: 3px;
      width: 18px;
      border-radius: 1px;
      position: absolute;
      left: 0;
    }

    &__line-1 {
      top: 0;
      transform: translateX(2px) rotate(40deg);

      &--open {
        transform: translateX(1.5px) rotate(140deg);
      }
    }

    &__line-2 {
      bottom: 0;
      transform: translateX(2px) rotate(-40deg);

      &--open {
        transform: translateX(1.5px) rotate(-140deg);
      }
    }
  }
}
