











































@animation: all 0.2s ease-in-out;
// @strawberry-light: #f64c72;
// @strawberry-light-hover: #ff6688;
@km-green: #369354;
@km-green-hover: #48a466;

@button-radius: 5px;
@button-font-size: 14px;
@button-padding: 20px;

.el-button {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  text-decoration: bold;
  text-transform: none;
  cursor: pointer;
  align-items: center;
  position: relative;
  padding: 8px 15px;
  min-width: 98px;
  width: max-content;
  height: 35px;
  min-height: 35px;
  border-radius: 4px;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 1.25px;

  &.uppercase {
    text-transform: uppercase;
  }
}

.el-button.spacey {
  padding: 10px;
  min-width: 192px;
  height: 52px;
  min-height: 52px;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.3px;
  border-radius: 8px;
}

.el-button.km-green {
  background-color: @km-green;
  border: 1px solid @km-green;
  color: white;

  &:hover {
    background-color: @km-green-hover;
    border: 1px solid @km-green-hover;
  }

  &.secondary {
    background: #fff;
    border: 1px solid @km-green;
    color: @km-green;

    &:hover {
      border: 1px solid @km-green-hover;
      color: @km-green-hover;
    }
  }
}

.el-button.selections {
  background-color: white;
  color: @km-green;
  height: 26px;
  width: max-content;
  min-width: max-content;
  min-height: 26px;
  border-radius: 4px;
  font-size: 13px;
  border: 1px solid @km-green;

  &:hover {
    color: @km-green-hover;
    border: 1px solid @km-green-hover;
  }
}

.el-animation {
  transition: @animation;
}
