









.question-body {
  position: relative;
  // height: auto;
  min-width: 500px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e1e5e8;
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
  //overflow: hidden; // kanske måste sätta tillbaka denna. Men ny audio så hamnar audio innanför
}

@media screen and (max-width: 800px) {
  .question-body {
    border-radius: 0;
  }
}
