









































.RoundedInput {
  input {
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-9);
    transition: var(--transition-all);
    background-color: var(--gray-50);
    border-radius: var(--radius-round);
    border: none;
    outline: transparent;

    &:focus {
      outline: 1px solid var(--blue-500);
      background-color: var(--gray-50);
    }
  }

  &__button {
    right: 8px;
  }
}
