<template>
  <div
    ref="modal"
    class="modal modal-no-body-scroll"
    tabindex="0"
    :class="{ 'is-closable-by-backdrop': isClosableByBackdrop, 'backdrop-is-grey': backdropIsGrey }"
    :style="`z-index:${zIndex}`"
    @keyup="shortcuts"
    @click="clickInsideModal"
  >
    <transition :name="animation ? 'slide-fade-long' : ''">
      <!-- *** OBS ÄNDRADE height:100% för att 10vh margin-top skulle slå igenom. 15 Oktober 2021  -->
      <div style="height: 100%">
        <div
          v-if="openWithDelay"
          class="modal-content"
          :style="`margin-top:50px;min-width: ${minWidth};max-width: ${maxWidth};
          ${minHeight !== '' ? 'min-height:' + minHeight : ''};
          padding: ${paddingContent}; max-height:${maxHeight};${height !== '' ? 'height:' + height : ''}`"
        >
          <div
            class="header"
            :style="`display:flex;align-items:center;
            background-color: ${hasHeaderSticky ? headerBgColor : ''};
            z-index: 100;height:${headerHeight}`"
          >
            <!-- If sticky header with closebutton! Do  <template #headersticky> hejhej </template> in parent element-->
            <div
              v-if="closeButton && closeButtonAlignment === 'left'"
              :class="`close-button`"
              :style="`margin-left:20px;background-color:${headerBgColor};color:${headerColor};display: flex;height:${headerHeight}`"
              @click="clickInsideModal"
            >
              <Icon
                :height="'12'"
                style="color: #dbdde0"
                :name="'close'"
              />
            </div>
            <div
              v-if="hasHeaderSticky"
              class="sticky-header"
              :style="`background-color:${headerBgColor};color:${headerColor};height:${headerHeight}`"
            >
              <slot name="headersticky" />
            </div>
            <div
              v-if="closeButton && closeButtonAlignment === 'right'"
              :class="`close-button ${closeButtonAlignment}`"
              :style="`background-color:${headerBgColor};color:${headerColor};display: flex;height:${headerHeight}`"
              @click="clickInsideModal"
            >
              <Icon
                :height="'16'"
                style="color: #dbdde0"
                :name="'close'"
              />
            </div>
          </div>

          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Icon from './utils/SvgIcons.vue'
export default {
  name: 'SimpleModal',
  components: {
    Icon,
  },
  props: {
    minWidth: { type: String, default: '500px' },
    maxWidth: { type: String, default: '90vw' },
    height: { type: String, default: '' },
    minHeight: { type: String, default: '' },
    maxHeight: { type: String, default: '95vh' },
    headerBgColor: { type: String, default: 'white' },
    headerColor: { type: String, default: 'black' },
    headerHeight: { type: String, default: '10px' },
    paddingContent: { type: String, default: '20px 20px 30px 20px' },
    isClosableByBackdrop: { type: Boolean, default: true },
    backdropIsGrey: { type: Boolean, default: true },
    closeButton: { type: Boolean, default: true },
    closeButtonAlignment: { type: String, default: 'right' },
    root: { type: Boolean, default: true },
    animation: { type: Boolean, default: false },
    zIndex: { type: Number, default: 100 },
  },
  data() {
    return {
      openWithDelay: false,
    }
  },
  computed: {
    hasHeaderSticky() {
      return this.$slots.headersticky
    },
  },
  mounted() {
    if (this.root === true) document.body.insertAdjacentElement('beforeend', this.$el)
    document.body.classList.add('document__body--no-scroll')
    this.$refs.modal.focus()
    // this.openWithDelay = true //switcha om vi ska ha animation
    setTimeout(() => (this.openWithDelay = true), 100)
  },
  destroyed() {
    const modalsOpen = document.querySelectorAll('.modal-no-body-scroll')
    const countModalsOpen = Array.from(modalsOpen).length
    if (countModalsOpen < 1) {
      document.body.classList.remove('document__body--no-scroll') // If there is no modals open then remove no-scroll
    }
  },

  methods: {
    clickInsideModal(ev) {
      if (ev.target.parentElement !== null) {
        if (
          (!ev.target?.closest('.modal-content') && this.isClosableByBackdrop) ||
          ev.target?.classList?.contains('close-button') ||
          ev.target?.closest('.close-button')?.classList?.contains('close-button')
        ) {
          this.$emit('close')
        } else {
        }
      }
    },
    shortcuts(e) {
      if (e.keyCode === 27 && this.isClosableByBackdrop) {
        this.$emit('close')
      }
    },
  },
}
</script>
<style>
.document__body--no-scroll {
  overflow: hidden !important;
}
</style>
<style lang="less" scoped>
// @import '~style/main.less';

/* The Modal (background) */
.modal {
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  &.backdrop-is-grey {
    background-color: rgba(0, 0, 0, 40%); /* Black w/ opacity */
  }

  &.is-closable-by-backdrop {
    cursor: pointer;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: 10vh auto;
  // max-height: 95vh; // fungerar bra för att scrolla i modalen om den är över 80vh
  // height: auto;
  flex: 1 1 auto;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 20%), 0 6px 20px 0 rgba(0, 0, 0, 19%);
  cursor: default;
  opacity: 1;

  .header {
    position: sticky;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;

    & > .sticky-header {
      width: max-content;
      padding: 0 10px;
      display: flex;
      justify-content: center;
    }

    & .close-button {
      &.right {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }

  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 500px) {
  .modal-content {
    width: 100%;
    min-width: 100%;
  }
}

.slide-fade-long-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-long-enter,
.slide-fade-long-leave-to {
  transform: translateY(100px);
  opacity: 0;
}

////////////////************ */

@height: 30px;
@tablet-width: 1024px;
@mobile-width: 480px;
@show-only-icon-width: 1300px;
.mulish() {
  font-family: Mulish, sans-serif;
  font-style: normal;
}

.headline3() {
  .mulish;

  font-weight: normal;
  font-size: 25px;
  line-height: 31px;
}

.headline3 {
  .headline3;
}

.headline1 {
  .mulish;

  font-weight: 100;
  font-size: 50px;
  line-height: 63px;

  @media (max-width: @mobile-width) {
    // headline3
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
  }
}
.headline2() {
  .mulish;

  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.25px;
}

.headline2 {
  .headline2();
}

.headline4 {
  .mulish;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

/* MULISH REGULAR 17/23px, SPACING 0.15px */
.subtitle1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
}

.subtitle1 {
  .subtitle1();
}

.subtitle2() {
  .mulish;

  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.subtitle2 {
  .subtitle2;
}

.body1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.body1 {
  .body1();
}
.body2() {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.body2 {
  .body2();
}

.button {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 1.25px;
}

.caption() {
  .mulish;

  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.caption {
  .caption;
}

.overline {
  .mulish;

  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}
@blackish: #373f41;
@blueberry: #2c415a;
@midnight-blue: #1b304a;
@km-green: #369354;
@km-green-hover: #48a466;
@navy-blue: #3CBEEB;
@lime: #92df2f;
@lightGreen: #f3fbdd;
@peach: #ff9292;
@sun-bleached: #fbeda0;
@greyish: #e1e5e8;
@lightGreyDark: #737b7d;
@lightGreyBetween: #c3cbcd;
@lightGrey: #dbdde0;
@light: #efefef;
@backgroundLight: #f5f7f9;
</style>
