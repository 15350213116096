

























































































































































































































































































































































































@import 'Less/variables.less';

.survey-overview {
  margin-top: 30px;
}

.overview-card {
  padding: 20px;

  /* border: 1px solid black; */
  margin: 10px;
}

.number {
  font-size: 30px;
  color: @theme-primary;
}

.number > i {
  font-size: 30px;
}

.title {
  font-size: 20px;
  color: #3a5ca9;
}

.overview {
  margin-top: 10px;
}
