
























































.notification {
  max-width: 700px;
  min-height: 45px;
  height: auto; //45px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-left: 20px;

  & .icon {
    width: 24px;
    height: 20px; // same as svg, to align the SVG
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  & :last-child {
    flex: 1;
  }

  &.alert-km {
    background-color: rgba(251, 237, 160, 40%); //sun-bleached
  }

  &.warning {
    background-color: #ffd3d3; // peach #FF9292
  }

  &.success {
    background-color: rgba(146, 223, 47, 10%); // lime
  }

  &.cursor {
    cursor: pointer;
  }
}
@blackish: #373f41;
@blueberry: #2c415a;
@midnight-blue: #1b304a;
@navy-blue: #1395be;
@lime: #92df2f;
@lightGreen: #f3fbdd;
@peach: #ff9292;
@sun-bleached: #fbeda0;
@greyish: #e1e5e8;
@lightGreyDark: #737b7d;
@lightGreyBetween: #c3cbcd;
@lightGrey: #dbdde0;
@light: #efefef;
@backgroundLight: #f5f7f9;
