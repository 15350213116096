
























































































.AccessInfoModal {
  &::v-deep {
    .modal-header,
    .modal-footer {
      border: none;
    }

    .modal-content {
      padding: var(--spacing-8);
      max-width: 800px;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: var(--spacing-4);
    right: var(--spacing-6);

    i {
      line-height: 14px;
      font-size: 37px;
      font-size: x-large;
    }
  }

  &__content {
    padding: var(--spacing-4) var(--spacing-8);
    max-width: var(--width-168);
    margin: auto;
    text-align: left;

    &__item {
      display: flex;

      &__label {
        flex: 0 0 25%;
        display: flex;
        font-weight: var(--font-semibold);

        .fa-circle {
          margin-right: var(--spacing-2);
          margin-top: 6px;
        }

        &--open {
          color: var(--green-500);
        }

        &--closed {
          color: var(--gray-900);
        }

        &--waiting-room {
          color: var(--yellow-500);
        }

        &--scheduled {
          color: var(--blue-600);
        }
      }

      &__text {
        flex: 0 0 75%;
        border-left: var(--border-width-1) solid var(--gray-500);
        padding-left: var(--spacing-8);
        margin-bottom: 0;
        padding-bottom: var(--spacing-5);
      }
    }
  }
}

