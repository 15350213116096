

















































































































































































































































@import 'Less/variables.less';

.exam-pdf-viewer {
  &--overflow-visible {
    overflow: visible;
  }

  &--overflow-auto {
    overflow: auto;
  }
}
