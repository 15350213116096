








































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';
@divider-border: 1px solid @color-grey-light;
@orange-light: #ffe6bb;
@orange-dark: #613c00;
@orange-main: #ff9c00;
@blue-main: #1d2c52;
@red-main: #d84a39;
@inbox-width: 224px;

@heightFirstRow: 220px;
@tablet-width: 1024px;
@mobile-width: 480px;

.examkey {
  font-family: Arial, sans-serif;
  letter-spacing: 2.5px;
}

.parts-open {
  .caption();

  color: @blackish;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-bottom: 10px;
}

.overview-button {
  border: 1px solid @lightGrey;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 25%);
  background-color: #f2f2f2;
  border-radius: 4px;
  height: 40px;
  padding: 0 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.box {
  position: relative;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 20%);
  border-radius: 5px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // flex-wrap: wrap;
  min-height: 200px;
  height: auto;
  margin-top: 8px;

  & .panel {
    width: 100%;
    display: flex;
    align-items: center;

    & .header {
      .headline2;
    }

    // justify-content: space-between;
    .exam-wrapper {
      margin-left: auto;
      display: flex;
      justify-content: space-between;
      height: 50px;

      .exam-key-wrapper {
        display: flex;
        // margin-right: 20px;
        justify-content: center;
        align-items: center;
        background-color: @lightGreen;
        color: @lightGreyDark;
        padding: 5px 20px;
        border-radius: 4px;
      }

      .open-exam-wrapper {
        display: flex;
        align-items: center;
        background-color: @lightGreen;
        color: @lightGreyDark;
        padding: 0 20px;
        border-radius: 4px;
        // margin-right: 20px;
        & > * {
          margin-right: 5px;
        }
      }

      .chat-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        background-color: @lightGreen;
        color: @lightGreyDark;
        padding: 0 20px;
        border-radius: 4px;
        cursor: pointer;

        & > .chat-notification-badge {
          position: absolute;
          top: 10px;
          right: 20px;
          z-index: 1;
          background-color: @red-main;
          height: @spacing-5;
          width: @spacing-5;
          border-radius: 50%;
          border: 1px solid #fff;
          color: #fff;
          transform: translate(50%, -50%);
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
        }
      }
    }
  }

  &.km {
    margin-top: -90px;
  }

  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    // & :not(:last-child) {
    //   margin-bottom: 20px;
    // }
  }

  .list-and-details-wrapper {
    display: flex;
    width: 100%;

    & > .left-side {
      width: 350px;
      max-width: 350px;
      min-width: 300px;
      margin-right: 20px;
      min-height: 200px;
      max-height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      overflow-y: auto;

      & > :first-child {
        // height: @heightFirstRow;
        // min-height: @heightFirstRow;
        &:not(.header) {
          .body2;

          color: @lightGreyDark;
        }
      }
    }

    & > .right-side {
      flex: 1;
      display: flex;
      flex-direction: column;

      & > :first-child {
        height: @heightFirstRow;
        min-height: @heightFirstRow;
      }
    }
  }

  // & > div {
  //   @media (max-width: 700px) {
  //     width: 80%;
  //   }
  // }
}

.left {
  position: fixed;
  width: 220px;
  margin-right: 10px;
  height: auto;
  top: 100px;
  bottom: 70px;

  &--top {
    top: 60px;
    bottom: 100px;
  }

  &--km {
    top: 10px;
  }
}

.right {
  flex: 1;
  padding: 10px;
  max-width: 800px;
}

.selectExam {
  margin-bottom: 5px;
}

.clickable {
  cursor: pointer;
  transition: all 0.1s;
}

.search {
  margin: 10px;
  width: ~'Calc(100% - 20px)';
  min-height: 30px;
}

.studentNavigationPanel {
  position: relative;
  height: auto;
  min-height: 200px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;

  &__list {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: auto;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px;
  }
}

.overviewBody {
  position: relative;
  padding: 15px 20px;
  height: auto;
  left: 230px;
  width: ~'Calc(100% - 230px)';
}

.studentButton {
  line-height: 1.6;
  min-height: 22px;
  display: flex;
  padding: 0 10px;
  border-radius: 10px;

  &:hover &__title {
    padding-left: 3px;
  }

  .studentButton__title {
    white-space: nowrap;
  }

  &--selected {
    background: rgba(255, 158, 2, 57%);
    border-left: 5px solid #ff9e02;
    color: #613c00;
    border-radius: 0;
    padding: 5px;

    .studentButton__title {
      padding-left: 3px;
    }
  }
}

.pretty-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.pretty-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
  background-color: #eee;
}

.pretty-scrollbar::-webkit-scrollbar-thumb {
  background-color: #1d2b51;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
}

.sort-list-move {
  transition: transform 0.1s;
}

.animation {
  transition: all 0.2s ease-in-out;
}

.student-icons {
  display: flex;
  align-items: center;

  i {
    margin-right: 3px;
  }
}

// .teacher-chat {
//   display: flex;
//   flex-direction: column;
//   // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//   background-color: #ffffff;
//   border-radius: @spacing-1;

//   &__instruction-message {
//     color: #757575;
//     text-align: center;
//     padding: @spacing-2 0;
//   }

// }

////////////////************ */
.mulish() {
  font-family: Mulish, sans-serif;
  font-style: normal;
}

.headline3() {
  .mulish;

  font-weight: normal;
  font-size: 25px;
  line-height: 31px;
}

.headline3 {
  .headline3;
}

.headline1 {
  .mulish;

  font-weight: 100;
  font-size: 50px;
  line-height: 63px;

  @media (max-width: @mobile-width) {
    // headline3
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
  }
}
.headline2() {
  .mulish;

  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.25px;
}

.headline2 {
  .headline2();
}

.headline4 {
  .mulish;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

/* MULISH REGULAR 17/23px, SPACING 0.15px */
.subtitle1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
}

.subtitle1 {
  .subtitle1();
}

.subtitle2() {
  .mulish;

  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.subtitle2 {
  .subtitle2;
}

.body1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.body1 {
  .body1();
}
.body2() {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.body2 {
  .body2();
}

.button {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 1.25px;
}

.caption() {
  .mulish;

  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.caption {
  .caption;
}

.overline {
  .mulish;

  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}
@blackish: #373f41;
@blueberry: #2c415a;
@midnight-blue: #1b304a;
@km-green: #369354;
@km-green-hover: #48a466;
@navy-blue: #3cbeeb;
@lime: #92df2f;
@lightGreen: #f3fbdd;
@peach: #ff9292;
@sun-bleached: #fbeda0;
@greyish: #e1e5e8;
@lightGreyDark: #737b7d;
@lightGreyBetween: #c3cbcd;
@lightGrey: #dbdde0;
@light: #efefef;
@backgroundLight: #f5f7f9;
