















































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.programming--wrapper {
  height: calc(100% - var(--resource-toolbar-height));
  width: 100%;
  overflow: auto;
}

.languages {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  height: 180px;
}

.output {
  overflow: hidden;
  width: 500px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

pre {
  border-radius: 0;
}

.status-pre {
  height: 100%;
  background: #2f3136;
  color: white;
  border: none;
}

.output-pre {
  background: #2f3136;
  height: 100%;
  color: white;
  border: none;
}

.error-pre {
  background: #2f3136;
  height: 100%;
  color: red;
  font-weight: var(--font-bold);
  border: none;
}

.pretty-scrollbar::-webkit-scrollbar {
  height: 5px;
  background-color: white;
}

.pretty-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
  background-color: #eee;
}

.pretty-scrollbar::-webkit-scrollbar-thumb {
  background-color: #1d2b51;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
}

.code-area {
  height: calc(100vh - 70px);
  min-width: 400px;
}

.image-icon {
  width: 28px;
  border-radius: 20px;
  cursor: pointer;
  margin: 8px;
}

.icon {
  font-size: @font-size-large;
  cursor: pointer;
  padding: @spacing-2;
}

.language-bar {
  height: 10px;
  background: white;
  width: 100%;

  .language-name {
    height: 100%;
    width: 80px;
    background: #e8e8e8;
    color: black;
  }
}

.historyMenu {
  width: 80px;
  position: absolute;
  background: #a7b3ba;
  z-index: 9;
  margin-top: 70px;
  overflow: auto;
  border-radius: 0 0 3px 3px;
}

.historyItem {
  cursor: pointer;
  margin: 3px;
  background: rgb(57, 91, 168);
  color: white;
  text-align: center;
  font-size: 12px;
}

.saved {
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 1px 1px 5px #e4e4e4;
  margin-left: 10px;
}

.saveInfo {
  font-size: 12px;
  color: rgb(35, 35, 35);
  display: flex;
  text-align: center;
  font-style: italic;
  font-weight: var(--font-thin);

  .gutter {
    background: #e8e8e8;
    width: 80px;
  }

  .text {
    font-size: 12px;
    color: #232323;
    padding: 5px;
    flex: 1;
  }
}

.inputArea {
  padding: 20px;
  outline: none;
  height: 25%;
  width: 100%;
  border-left: 0;
  border-right: 0;
}

.toolbar {
  display: flex;
  align-items: center;
  flex: 0 1 66%;
  justify-content: center;

  &__item {
    display: inline-flex;
    padding-right: @spacing-2;
    padding-left: @spacing-2;

    &:hover {
      color: @theme-primary;
    }
  }
}

.selected {
  color: @theme-primary;
}
