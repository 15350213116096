





















































































































.ExamOverviewHeader {
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-3);

  .grid {
    display: grid;
    grid-template-areas: 'exam-list tabs';
    grid-template-columns: 2fr 8fr;
  }

  &__notificationHandler {
    height: 1px;
  }

  &__heading-menu {
    grid-area: exam-list;
    display: flex;

    span {
      font-size: var(--font-size-md);
      font-weight: var(--font-bold);
    }
  }

  &__button-group {
    grid-area: tabs;
    display: flex;
    align-items: center;
    position: relative;

    &__monitoring,
    &__results {
      --button-background: white;
      --button-border-color: var(--gray-500);
      --button-color: var(--gray-900);

      position: relative;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 100%;
        height: 100%;
        fill: var(--button-background);
        stroke: var(--button-border-color);
      }

      span {
        position: absolute;
        color: var(--button-color);
      }

      &--active {
        --button-background: var(--blue-600);
        --button-border-color: var(--blue-600);
        --button-color: white;
      }
    }

    &__monitoring {
      z-index: var(--z-layer-1);
    }

    &__results {
      --tab-offset: var(--spacing-3);

      z-index: var(--z-layer-0);
      transform: translateX(calc(var(--tab-offset) * -1));
    }
  }
}
