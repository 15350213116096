


































































































.exam-language-select {
  cursor: pointer;
}

.language-list {
  margin-left: 0;
  margin-top: 5px;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  position: absolute;
  cursor: pointer;
  text-align: left;
  background: white;
  border-radius: 5px;

  &--in-application {
    right: 5px;
  }

  &__item {
    color: black;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    margin: 0;
    padding: 5px 20px;
    overflow: hidden;
    margin-bottom: 5px;
    transition: all 0.3s;
    font-weight: 300;

    &:hover {
      background: rgb(248, 152, 49);
      color: white;
    }
  }
}
