













































































































































































































































@import 'Less/variables.less';

.chartStudentsGlobal {
  &__intervalSelector-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__intervalSelector {
    margin-left: @spacing-4;
  }

  &__chart {
    width: 100%;
    height: 750px;
  }

  &__tools {
    width: 99%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
}
