

















































































































































































.offline-simulation-button {
  text-align: right;
  margin-bottom: -30px;

  span {
    opacity: 0;
  }
}
