




































































@import 'Less/variables.less';

.flash__wrapper {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 2147483647; // Max z-index value
}

.flash__content {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 30px;
  }

  &__text {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.flash__card {
  padding: @spacing-4;
  margin-bottom: @spacing-5;
  border-radius: @radius-xs;
  background: @theme-blue;
  color: white;
}

.flash__title {
  text-align: center;
}

.flash__text {
  color: @theme-primary;
  text-align: center;
}
