






















































































































































































































































































































































































































































































































































































































































.camera-wrapper {
  width: 100%;
  height: 100%;
}

.camera {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  background: #f9f9f9;
  width: 100%;
  height: calc(100% - var(--resource-toolbar-height));
  overflow: auto;
}

.solution-scan {
  position: relative;
  background-color: #f9f9f9;
  height: 100%;
  width: 100%;

  &__guide-line {
    position: absolute;
    top: 40px;
    left: 40px;
    bottom: auto;
    height: 40px;
    background-color: transparent;
    width: 0;
    z-index: 1;
    border: 1px dashed #ddd;

    &--top {
      top: 0;
    }

    &--bottom {
      bottom: 0;
      height: auto;
    }
  }

  &__row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;

    &__number {
      width: 40px;
      height: 40px;
      background-color: #eee;
      border-radius: 50%;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
      z-index: 2;
    }

    &__body {
      display: flex;
      align-items: flex-start;
      justify-content: stretch;
      margin: 20px 0;
      flex-grow: 1;
      flex-basis: 0;
      flex-flow: column;

      &__instruction {
        flex-grow: 1;
        flex-basis: 0;
        padding-right: 10px;
        box-sizing: border-box;
      }

      &__icon {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        &__frame {
          height: 150px;
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }
      }
    }
  }

  &__qr {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);
    border-radius: 5px;
  }
}
