
























































@import 'Less/variables.less';

.welcomeInformation {
  margin-top: @spacing-7;
  margin-bottom: @spacing-5;

  &__movies {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    justify-items: center;
    width: 100%;
    column-gap: @spacing-4;
    grid-column-gap: @spacing-4;
  }

  &__allMoviesButton-container {
    display: flex;
    justify-content: center;
    margin-top: @spacing-3
  }
}
