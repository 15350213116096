



















































































































@import 'Less/variables.less';

.TrainingVideo {
  --videoWidth: 288px;
  --aspectRatio: calc(9 / 16);

  text-align: center;
  width: var(--videoWidth);

  &__overlay {
    background-color: @theme-blue;
    height: calc(var(--videoWidth) * var(--aspectRatio));
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 1s ease-in-out;

    &__logo {
      width: 60px;
      position: absolute;
      bottom: @spacing-2;
      right: @spacing-2;
    }

    &__logo-square {
      width: 48px;
    }

    &__play-icon {
      font-size: @font-size-large;
      position: absolute;
      bottom: @spacing-2;
      left: @spacing-2;
      color: white;
      display: flex;
    }

    &__title {
      font-size: @font-size-small;
      font-weight: var(--font-semibold);
      margin-top: @spacing-1;
      margin-bottom: @spacing-6;
      margin-right: @spacing-3;
      margin-left: @spacing-3;
      word-break: break-word;
    }
  }

  h2 {
    font-size: @font-size-medium;
    font-weight: var(--font-semibold);
    margin-top: @spacing-3;
  }

  p {
    font-size: @font-size-xs;
  }

  &__iframe-wrapper {
    position: relative;
    height: 0;
    padding-bottom: calc(100% * var(--aspectRatio));
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
