

































































.student-settings {
  background: white;
  padding: var(--spacing-2);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.student-settings__button {
  padding: var(--spacing-1);
  margin: auto;
}
