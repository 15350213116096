
























































































@import 'Less/variables.less';

/* The container */
.radioContainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
  vertical-align: middle;
  user-select: none;
}

.textField {
  position: relative;
  margin-top: 10px;
  font-size: 12px;
  line-height: 18px;
}

.delimiterLine {
  position: relative;
  height: 40px;
}

.delimiterLine-line {
  position: absolute;
  top: 25px;
  height: 1px;
  width: 100%;
  background-color: #eee;
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .checkmark {
  border: 1px solid @theme-blue;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmark {
  background-color: #5bde44;
  border: 1px solid #5bde44;
}

/* Create the indicator (the dot/circle - hidden when not checked)
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .radioContainer input:checked ~ .checkmark:after {
        display: block;
    }
    .radioContainer .checkmark:after {
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #ffffff;
    }
    */
