<template>
  <div
    v-show="allPending.length > 0"
    class="exam-list-main"
  >
    <h1
      ref="title"
      class="exam-list-header text-center"
      style="background-color: #152234"
    >
      {{ __('Väntar på verifiering av skoladministratör') }}
    </h1>

    <div
      class="borderless exam-list-table table-responsive table-scrollable"
      style="margin-top: 0 !important; background-color: #152234"
    >
      <table
        id="testlist"
        ref="table"
        class="table table-hover"
        style="min-width: 800px"
      >
        <thead>
          <tr
            ref="tableHead"
            style="background-color: #152234"
          >
            <th style="width: 10px" />
            <th />
            <th
              class="clickable-header"
              style="width: 30%"
            >
              {{ __('Förnamn') }}
            </th>
            <th
              class="clickable-header"
              style="width: 30%"
            >
              {{ __('Efternamn') }}
            </th>
            <th
              class="clickable-header"
              style="width: 40%"
            >
              {{ __('Email') }}
            </th>
            <th
              class="clickable-header"
              style="width: 40%"
            >
              {{ __('Registreringsdatum') }}
            </th>
            <th />
          </tr>
        </thead>

        <transition-group
          id="testlist-rows"
          name="sort-list"
          tag="tbody"
        >
          <tr
            v-for="colleague in allPending"
            :key="colleague.id"
            class="animation row-border"
          >
            <td />
            <td />

            <td>{{ colleague.firstName }}</td>
            <td>{{ colleague.lastName }}</td>

            <td>
              {{ colleague.email }}
              <i
                v-if="!colleague.is_email_verified"
                v-tooltip="{
                  content:__('Epostadressen har ej bekräftats av användaren'),
                  theme: 'tooltipClickable',
                }"
                class="fa fa-exclamation-circle"
                style="margin-left: 1em"
              />
            </td>

            <td>
              <FormatDate
                format="YYYY-MM-DD"
                :datetime="colleague.created_at"
              />
            </td>

            <td
              class="text-center"
              style="width: 20px"
            >
              <text-button
                :title="__('Godkänn')"
                @click="accept(colleague)"
              />
              <text-button
                mode="secondary"
                :title="__('Avslå')"
                @click="deny(colleague)"
              />
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
import FormatDate from 'VueDesignElements/FormatDate.vue'
import Authorize from 'Modules/Authorize.ts'
import axios from 'axios'

export default {
  components: { FormatDate },
  props: {
    colleagues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allPending: [],
    }
  },
  mounted() {
    this.allPending = this.colleagues.filter((colleague) => Number(colleague.accepted_by) === 0)
  },
  methods: {
    accept(colleague) {
      axios
        .post(`/api/pending-verifications/${colleague.id}/accept`)
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          window.location.reload()
        })
    },
    deny(colleague) {
      axios
        .post(`/api/pending-verifications/${colleague.id}/deny`)
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          window.location.reload()
        })
    },
    isRole(teacherRole, role) {
      return Authorize.hasRole(teacherRole, role)
    },
  },
}
</script>

<style scoped>
.invisible {
  visibility: hidden;
}

.exam-list-main {
  position: relative;
  background-color: #fff;
  margin-bottom: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
}

.exam-list-header {
  top: 0;
  height: 60px;
  background-color: rgb(31, 50, 92);
  color: #fff;
  line-height: 60px;
  font-size: 30px;
  margin-bottom: 0;
}

.sort-list-move {
  transition: transform 0.4s;
}

.borderless td,
.borderless th {
  border: none;
}

.row-border {
  border-bottom: 1px solid #eee;
}

th {
  background-color: transparent !important;
}

.clickable-header {
  cursor: pointer;
}

.clickable-header:hover {
  text-decoration: underline;
}

.exam-list-table {
  background-color: green;
  border: none;
  overflow: show;
}

.table-icon {
  width: 20px;
}

.error {
  color: red;
}

.info {
  color: green;
}

span.examKey {
  font-family: Verdana, sans-serif;
}
</style>
