

























































































































































































































































































































@import 'Less/variables.less';

.desmos {
  width: 100%;
  height: 100%;

  &__loader {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  flex: 0 1 66%;
  justify-content: center;

  &__item {
    display: inline-flex;
    padding-right: @spacing-2;
    padding-left: @spacing-2;

    &:hover {
      color: @theme-primary;
    }
  }
}
