
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.CreateNewExam {
  &__delimiter {
    min-width: stretch;
    margin-right: @spacing-8;
  }

  &__AccessibilityTools {
    width: 100%;
  }

  &__itemWithTrainingVideo {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap-reverse;
    width: 100%;

    &__item {
      flex-shrink: 0;
      flex-basis: 67%;
      flex-grow: 1;
      padding-right: 16px;
    }

    &__video {
      flex-shrink: 0;
      flex-basis: 28%;
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
    }
  }

  &__radioButton {
    &__delimiter {
      min-width: stretch;
      margin-left: @spacing-8;
      margin-right: @spacing-8;
    }
  }
}

.helpModal {
  &__close-button {
    position: absolute;
    top: 0;
    left: -10px;
    z-index: 100;
    background: #fff;
    padding: 5px;
    font-size: 16px;
    border-radius: 100%;
    line-height: 16px;
    width: 25px;
    height: 25px;
    font-weight: 600;
    border: none;

    &:hover {
      background: @theme-blue;
      color: #fff;
    }
  }
}

.hovertrigger:hover {
  .hoverblack {
    color: black;
  }
}

.hoverblack {
  color: #d8d8d8;
}

.formula-icon {
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
  margin-right: 10px;
  float: right;
}

/* FADE */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* MODALS */
.progressBar {
  border: 1px solid #111;
  background-color: #292929;
  width: 100%;
  height: 100%;
  text-align: center;
}

.progressBar div {
  height: 100%;
  color: #fff;
  text-align: right;
  line-height: 22px; /* same as #progressBar height if we want text middle aligned */
  width: 0;
  border-radius: 15px;
  background-color: rgb(255, 178, 0);
}

.icons > i.fa {
  font-size: 30px;
  margin-right: 8px;
}

.icons {
  display: flex;
  align-items: center;
}

i.desmos {
  background: url('https://static.exam.net/myicons/desmosIcon.png');
  float: right;
  background-size: cover;
  width: 28px;
  height: 28px;
  margin: -5px 5px;
}

i.geogebra-icon {
  background: url('https://static.exam.net/myicons/gg-smaller.png');
  float: right;
  background-size: cover;
  width: 28px;
  height: 28px;
  margin: -5px 5px;
}

i.tool-icon {
  font-size: 18px;
}

.error {
  color: red;
  font-style: italic;
  font-size: 12px;
}

.warning {
  color: orange;
  font-style: italic;
  font-size: 12px;
}

.save-spinner {
  margin-right: 10px;
  font-size: 20px;
}

.small-text p {
  font-size: 14px;
}

.settingsTitle {
  text-decoration: underline;
  margin-right: 10px;
  font-size: 10px;
  right: 0;
  cursor: pointer;
}

.defineAllowedEmailsTitle {
  top: 100px;
  left: 100px;
  position: absolute;
  display: inline-block;
}

.automark-swedish-information {
  font-size: @font-size-large;
}

.printIcon {
  font-size: 30px;
  cursor: pointer;
}

.allowedEmailsArea {
  flex: 1;
}

.allowedEmailsInputArea {
  flex: 1;
  padding: 10px;
  resize: none;
}

.addAllowedEmailsArrow {
  cursor: pointer;
  font-size: 29px;
  padding: 10px;
  border-radius: 8px;
  background: #1d2d50;
  color: white;
  margin-left: 10px;
}

.allowedEmailsList {
  margin-left: 20px;
  overflow: auto;
  height: 350px;

  i {
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
  }
}

.clearAllowedEmailsList {
  height: 50px;
  align-items: bottom;
  float: right;
}

.google-classroom {
  margin-top: var(--spacing-6);
  display: flex;

  &__connect-exam {
    display: flex;
    align-items: center;

    &__button {
      position: relative;
      margin-right: var(--spacing-4);

      &::after {
        content: 'BETA';
        position: absolute;
        top: 0;
        right: var(--spacing-4);
        transform: translateY(-50%);
        background-color: var(--blue-700);
        color: #fff;
        border: var(--border-width-2) solid #fff;
        border-radius: var(--radius-round);
        font-size: 10px;
        padding: 0 var(--spacing-2);
        line-height: 1.5;
      }
    }

    a {
      color: var(--gray-900);
      text-decoration: underline;
      font-size: var(--font-size-xs)
    }
  }
}

.remove-classroom-connection {
  margin-left: 20px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.google-classroom-course-select {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
