<template>
  <div class="measure">
    <div class="number">
      {{ number }}
    </div>
    <div class="title">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.measure {
  margin-top: 30px;
  flex: 1;
  text-align: left;
}

.title {
  /* width: 105px; */
  font-style: italic;
}

.number {
  font-size: 50px;
}
</style>
