































































@import 'Less/variables.less';

.TrainingVideoPopOver {
  cursor: pointer;
  display: flex;
  align-items: center;

  &__play-icon {
    color: @theme-primary;
    font-size: 20px;
    display: flex;
    margin-right: @spacing-2;
  }

  &__text {
    color: black;
    font-size: @font-size-xs;
    text-decoration: underline;
  }

  &__video {
    display: flex;
  }
}
