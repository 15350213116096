




















































































































































































































.panel {
  border-radius: 5px;
  overflow: hidden;
}

.studentLog-panel {
  margin-bottom: 8px;
  margin-right: 15px;
}

.studentLog-panel-heading {
  padding: 8px;
}

.studentLog-panel-body {
  padding: 8px;
}

.studentLog-panel-body > p {
  margin-bottom: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
