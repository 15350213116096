<template>
  <div>
    <text-button
      v-if="status !== 'loaded'"
      :loading="status === 'loading'"
      mode="secondary"
      :title="__('Visa')"
      @click="load"
    />
    <text-button
      v-if="status === 'loaded'"
      mode="secondary"
      :title="__('Dölj')"
      @click="hide"
    />
    <div>
      <div
        v-for="student in students"
        :key="student.id"
        class="submission-card"
      >
        <div style="display: flex">
          <div style="margin-right: 10px">
            <i class="fa fa-user-o" />
          </div>
          <div style="width: auto; flex: 1">
            <div>{{ Student.displayName(student) }} ({{ student.exam.name }})</div>
            <div style="font-size: 12px; font-weight: 100">
              {{ humanDate(student.leave_time) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Student from 'Modules/Student.js'

export default {
  data() {
    return {
      Student,
      students: [],
      status: '',
    }
  },
  methods: {
    hide() {
      this.students = []
      this.status = ''
    },
    load() {
      this.status = 'loading'

      window.axios.get('/api/profile/latest-student-submissions').then(({ data }) => {
        this.students = data.students
        this.status = 'loaded'
      })
    },
    humanDate(datestring) {
      if (!datestring) {
        return ''
      }

      return window.moment.utc(datestring).local().format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>

<style scoped>
.submission-card {
  margin-top: 15px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 20%);
}
</style>
