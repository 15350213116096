




























































































































































































@modal-width: 1000px;

.teams {
  width: @modal-width;
  max-width: 100%;
  height: 95%;
  background: white;
  display: flex;
  justify-content: stretch;
  margin-bottom: 30px;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 23px #0000002e;

  @media (max-width: @modal-width) {
    border-radius: 0;
  }
}

.teams-wrapper {
  z-index: 9999;
  height: 100vh;
  background: rgba(0, 0, 0, 20%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
}
