






























































































































.ExamTimer {
  .timer-modal {
    &__start-button {
      margin-bottom: var(--spacing-2);
    }

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;

      i {
        margin-left: var(--spacing-1);
        color: var(--gray-500);
      }
    }
  }
}

