

























































































































































































































@import 'Less/variables.less';

.search-select {
  --input-height: @spacing-10;
  --border-open: 1px solid @color-grey-dark;
  --border-closed: 1px solid @color-grey;

  &--integrated {
    --input-height: 100%;
    --border-open: 1px solid @divider-border;

    .search-select__input {
      --border-open: none;
      --border-closed: none;
    }
  }

  &--border-top {
    border-top: @divider-border;
  }

  &--border-right {
    border-right: @divider-border;
  }

  &--border-bottom {
    border-bottom: @divider-border;
  }

  &--border-left {
    border-left: @divider-border;
  }

  position: relative;
  max-width: 200px;

  .options-slide-enter-active,
  .options-slide-leave-active {
    transform-origin: top;
    transition: all 0.2s ease 0.1s;
  }

  .options-slide-leave,
  .options-slide-enter-to {
    opacity: 1;
    transform: scaleY(1);
  }

  .options-slide-enter,
  .options-slide-leave-to {
    opacity: 0;
    transform: scaleY(0);
  }

  &__input {
    width: 100%;
    height: var(--input-height);
    padding: @spacing-1 @spacing-7 @spacing-1 @spacing-2;
    border: var(--border-closed);
    border-radius: @radius-xs;

    &:hover {
      border: var(--border-open);
    }

    &--open,
    &:focus {
      outline: none;
      border: var(--border-open);
      border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--open {
      border-bottom: none;

      &:hover {
        border-bottom: none;
      }
    }

    &--open ~ .search-select__caret i {
      transform: rotate(180deg);
    }

    &:focus ~ .search-select__selected-option-placeholder {
      color: @color-grey;
    }
  }

  &__selected-option-placeholder,
  &__caret {
    position: absolute;
    top: calc(var(--input-height) / 2);
    transform: translateY(-50%);
    color: @color-font-dark;
  }

  &__selected-option-placeholder {
    pointer-events: none;
    left: calc(@spacing-2 + 2px);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    max-height: 100%;
  }

  &__caret {
    right: @spacing-2;
    border-radius: 50%;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    width: @spacing-7;
    height: @spacing-7;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: @font-size-medium;
      margin-top: 0;
      line-height: normal;
    }

    &:hover {
      background-color: @color-grey-light;
    }

    &:focus {
      outline: none;
    }

    &--open {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  &__options {
    --max-height: 300px;

    position: absolute;
    top: var(--input-height);
    left: 0;
    transform-origin: top;
    background-color: white;
    width: 100%;
    z-index: 9000;
    box-shadow: @shadow-md;
    border-bottom-right-radius: @radius-xs;
    border-bottom-left-radius: @radius-xs;
    max-height: var(--max-height);
    overscroll-behavior: contain;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      border-radius: @radius-xs;
    }

    &--open {
      border: var(--border-open);
      border-top: none;
    }

    &__option {
      white-space: pre-wrap;
      padding: @spacing-2;
      border-top: @divider-border;
      cursor: pointer;
      z-index: 9100;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        background-color: @color-grey-super-light;
      }

      &--loading,
      &--empty {
        display: flex;
        align-items: center;
        color: @color-grey;
        cursor: auto;

        i {
          margin-left: var(--spacing-2);
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: inherit;
        }
      }
    }
  }
}
