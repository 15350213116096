<template>
  <div
    class="fade modal"
    :tabindex="-1"
    role="dialog"
    aria-hidden="true"
    style="display: none"
    :class="{ top }"
  >
    <div
      class="modal-dialog"
      :class="sizeClass"
    >
      <div
        class="modal-content modal-rounded"
        :class="{ 'less-rounded': lessRounded }"
      >
        <div
          v-if="!noHeader"
          class="modal-header text-center"
        >
          <slot name="title" />
        </div>

        <div class="modal-body">
          <slot name="body" />
        </div>

        <div
          v-if="!noFooter"
          class="modal-footer modal-footer--centered"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      validator: (size) => {
        return ['sm', 'md', 'lg'].includes(size)
      },
      required: true,
    },
    top: Boolean,
    lessRounded: Boolean,
    noHeader: Boolean,
    noFooter: Boolean,
  },
  computed: {
    sizeClass() {
      return `modal-${this.size}`
    },
  },
}
</script>

<style scoped>
.modal-rounded {
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
}

.modal-footer--centered {
  text-align: center;
}

.modal.fade.top {
  z-index: 999999;
}

.less-rounded {
  border-radius: 5px !important;
}
</style>
