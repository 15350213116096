












































































































































































































































































@import 'Less/variables.less';

.lexeme {
  display: flex;
  counter-increment: lexeme;

  &::before {
    content: counter(lexeme);
    font-size: @font-size-small;
    line-height: 1.7;
    padding-right: @spacing-2;
    color: @color-grey;
  }

  &__summary {
    padding-bottom: @spacing-2;
    flex: 1;

    &__translation {
      color: @color-grey-dark;
      margin-bottom: @spacing-1;
    }

    &__definition {
      margin-bottom: 0;

      &--comment {
        font-style: italic;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__show-more-button {
      font-size: @font-size-small;
    }

    &__more-info {
      padding-top: @spacing-3;

      > * {
        padding: @spacing-1;
      }

      p {
        margin-bottom: @spacing-2;
      }

      &__comment {
        font-size: @font-size-small;
        font-style: italic;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      &__title {
        color: @color-grey-dark;
      }

      ul {
        margin-top: @spacing-2;
        padding-left: 0;
        list-style: none;

        li {
          background-color: @color-grey-light;
          line-height: 1.5em;
          padding: @spacing-1 0;
          padding-left: @spacing-2;
          margin-bottom: @spacing-3;
          border-left: 6px solid var(--yellow-100);
          border-radius: @radius-small;

          .list__sub-item {
            font-weight: 600;
            color: @color-grey-dark;
            padding-top: @spacing-1;
          }
        }
      }

      &__child-lexemes {
        counter-reset: lexeme;

        .lexeme {
          counter-increment: lexeme;
        }
      }
    }
  }
}
