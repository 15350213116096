

























































































.exam-language-select {
  margin-right: 10px;
  position: fixed;
  top: 18px;
  left: 260px;
  cursor: pointer;
  text-align: left;
  z-index: 9;

  &__active-flag {
    width: 30px;
  }

  background: #1e2d51;
  padding: 7px;
  border-radius: 3px;
}

.language-list {
  margin-left: 0;
  margin-top: 20px;
  // position: absolute;
  cursor: pointer;
  text-align: center;

  &__item {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    color: white;
    // width: 50px;
    height: 27px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-bottom: 5px;

    &__flag {
      position: absolute;
      top: -10px;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }
}

.absolute {
  position: absolute;
}
