





































































































































































































































































































































































































































































































@import 'Less/variables.less';

.removeAccessModal {
  &__footer {
    display: flex;
    justify-content: center;
  }

  &__button {
    margin: @spacing-1;
  }
}

.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.popup-footer-vertical {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
}

.inputField {
  padding: 5px;
  border: 1px solid #d8d8d8;
  outline: none;
  width: 100%;
}

.inputField:focus {
  border: 1px solid @theme-blue;
}

.iconFont {
  color: #373737;
}

.contextMenu-row {
  left: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 20px;
  margin: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.contextMenu-row:hover {
  background-color: #dcdcdc;
  border-left: 2px solid black;
}

.animation {
  transition: all 0.2s ease-in-out;
}

.TemplateLink {
  display: flex;
  justify-content: center;
  align-items: center;

  & > i {
    cursor: pointer;
  }

  &__open-button {
    margin: var(--spacing-1) var(--spacing-2);
  }

  &__create-button {
    margin-top: var(--spacing-3);
  }

  &__remove-button {
    color: red;
    cursor: pointer;
  }
}

