









































































































































.wrapper {
  z-index: 9999;
  height: 100vh;
  background: rgba(0, 0, 0, 20%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.right {
  width: 200px;
  box-shadow: 5px 24px 24px #2f3236;
  z-index: 1;
  padding: 20px;
  background: #2f3236;
  border-left: 3px solid white;
}

.main {
  flex: 1;
  display: flex;
  background: white;

  & > div {
    width: 100%;
  }
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(300px);
  opacity: 0;
}
