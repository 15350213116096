




































































































































































@media (max-width: 1200px) {
  .stackOnSmallScreen {
    flex-direction: column;
  }
}

img {
  box-shadow: var(--shadow-lg);
}
