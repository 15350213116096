
























































































.ExamBuilderPrintSolution {
  &__question {
    margin-bottom: 50px;
    page-break-inside: avoid;

    &__subQuestion {
      margin-bottom: 10px;
      page-break-inside: avoid;
    }
  }

  .sub-question-answer {
    font-family: 'Times New Roman', Times, serif;
    font-size: var(--font-size-default);
    display: flex;
    margin-bottom: var(--spacing-2);

    &__number {
      font-weight: var(--font-bold);
      width: 50px; // Match render-question column width
      margin-right: 10px; // Match render-question column margin
      flex-shrink: 0;
    }

    &__sub-number {
      width: 30px; // Match render-question column width
      flex-shrink: 0;
    }

    &__answer-area {
      display: flex;

      &--no-answer {
        font-style: italic;
      }
    }
  }
}
