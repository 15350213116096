




































































.ExamSystemNotificationsSwitch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  i {
    margin-right: var(--spacing-2);
    font-size: var(--font-size-lg);
  }
}

