
















































































































































































































































































@import 'Less/variables.less';

@media print {
  pre {
    white-space: pre-wrap;
  }

  .print-avoid-break-inside {
    break-inside: avoid;
  }
}

pre::first-line {
  font-weight: bold;
  text-transform: uppercase;
}

.question {
  margin-bottom: 50px;

  &__sub-question {
    margin-bottom: 10px;
  }
}

.ExamBuilderPrintStudent {
  position: relative;
  width: 100%;

  &__attachment {
    text-align: center;

    &--scanning {
      width: 100%;
    }

    &--drawing {
      width: 500px;
    }

    &--programming {
      text-align: left;
    }
  }

  &__question {
    margin-bottom: 50px;
  }

  .render-sub-question {
    display: flex;
    flex-flow: column nowrap;
  }

  &__feedbacks {
    &__title {
      font-weight: bold;
    }

    &__text {
      padding: var(--spacing-3);
      margin-bottom: var(--spacing-1);
      background-color: var(--gray-100);
      border-radius: var(--radius-sm);
    }
  }
}

.Grading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: nowrap;

  &:last-of-type {
    margin-bottom: 10px;
  }

  &__body {
    box-sizing: border-box;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    color: @color-grey-dark;
    font-size: 12px;

    &__label {
      margin-right: 15px;
    }

    &__point {
      &--bold {
        font-weight: 700;
      }
    }
  }
}

.wordCounter {
  text-align: center;
  margin-top: -8px;
  color: gray;
}
