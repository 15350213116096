


















@import 'Less/variables.less';

.logotype {
  position: fixed;
  display: inline-block;
  top: 0;
  left: 0;
  width: 209px;
  height: 50px;
  z-index: 10;
  color: @theme-primary;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  margin: 8px 15px;
  background: url('https://static.exam.net/img/2021/light-logo.png') no-repeat;
  background-size: 190px;
  margin-top: 12px;
  background-position: left center;
}

.absolute {
  position: absolute;
}
