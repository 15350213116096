
















































































.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2px 16px;
  min-height: 30px;

  /* background-color: #5cb85c; */
  color: black;
}

/* The Close Button */
.close {
  margin-left: auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
