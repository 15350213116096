<template>
  <div>
    <div v-if="!links">
      <a href="/">
        <img
          src="https://cdn2.exam.net/website/wp-content/uploads/2021/09/examdotnet-logo.svg"
          class="logo-image mx-auto"
          :alt="__('Logotyp för Exam.net')"
        >
      </a>
    </div>
    <div
      v-if="links"
      class="bg-transparent flex items-center justify-between mt-4 mx-auto text-left"
    >
      <div>
        <a href="/">
          <img
            src="https://cdn2.exam.net/website/wp-content/uploads/2021/09/examdotnet-logo.svg"
            class="logo-image"
            :alt="__('Logotyp för Exam.net')"
          >
        </a>
      </div>
      <div>
        <div class="hidden items-center mr-14 mt-2 sm:flex">
          <div
            v-if="site !== 'sign-in'"
            class="border
              cursor-pointer
              flex
              hover:text-yellow-400
              items-center
              justify-center
              mr-2
              mt-1
              p-2
              px-8
              rounded-full
              text-sm
              text-white"
            @click="showSignInForm"
          >
            {{ __('Logga in') }}
          </div>
          <div
            v-if="site !== 'sign-up'"
            class="border
              cursor-pointer
              flex
              hover:text-yellow-400
              items-center
              justify-center
              mr-2
              mt-1
              p-2
              px-8
              rounded-full
              text-sm
              text-white"
            @click="showSignUpForm"
          >
            {{ __('Registrera konto') }}
          </div>
          <div class="hidden lg:flex mr-8 relative">
            <input
              v-model="examkey"
              class="bg-gray-100
                border
                cursor:pointer
                mt-1
                outline-none
                p-3
                placeholder-gray-600
                px-4
                rounded-full
                text-sm
                w-64"
              :placeholder="__('Provnyckel för elev')"
              style="height: 38px"
              @keypress.enter="studentLogin"
            >
            <div
              class="absolute
                border
                cursor-pointer
                duration-300
                flex
                hover:bg-white
                items-center
                justify-center
                next-button
                p-2
                px-8
                right-0
                rounded-full
                text-sm
                text-white
                transition-all"
              @click="studentLogin"
            >
              {{ __('Vidare') }}
            </div>
          </div>
          <div class="cursor-pointer flex items-center justify-center text-white">
            <LanguageSelect2021 />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="links"
      class="font-medium ml-14 mt-1 text-left text-white tracking-wider"
    >
      <a href="/">{{ __('Startsida') }}</a>
      <a
        v-if="site === 'sign-up'"
        href="/teacher-registration"
        class="border-b-2 border-white ml-6 pb-2"
      >{{ __('Registrera konto') }}</a>
      <a
        v-if="site === 'sign-in'"
        href="/teacher-login"
        class="border-b-2 border-white ml-6 pb-2"
      >{{ __('Logga in') }}</a>
    </div>
  </div>
</template>

<script>
import LanguageSelect2021 from './LanguageSelect2021.vue'
import HandleLocale from 'Modules/HandleLocale.js'

export default {
  components: { LanguageSelect2021 },
  props: {
    links: {
      type: Boolean,
      default: true,
    },
    site: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      examkey: '',
      language: this.__('sv'),
    }
  },
  watch: {
    language() {
      if (this.language !== this.__('sv')) {
        HandleLocale.set(this.language)
      }
    },
  },
  methods: {
    showSignUpForm() {
      window.location = '/teacher-registration'
    },
    showSignInForm() {
      window.location = '/teacher-login'
    },
    studentLogin() {
      if (this.examkey.length === 0) {
        return
      }

      window.location = `/student?examkey=${this.examkey}`
    },
  },
}
</script>

<style scoped>
.next-button {
  background: rgb(248, 152, 49);
  margin-top: 5px;
  height: 36px;
  right: 1px;
  width: 86px;
}

.next-button:hover {
  background: white;
  color: rgb(248, 152, 49);
  border-color: rgb(248, 152, 49);
}

.logo-image {
  margin-left: 53px;
  margin-top: -6px;
}
</style>
