










































































































































































































































































































































































































@import 'Less/variables.less';

.heatMapStudentsByCountry {
  &__heatMap {
    max-width: 100%;
    height: 750px;
  }

  &__gauge-container {
    width: 200px;
    height: 76px;
    display: flex;
    align-items: center;
    margin: @spacing-1;
  }

  &__gauge {
    width: 180px;
    height: 70px;
  }

  &__intervalSelector-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__intervalSelector {
    margin-left: @spacing-4;
  }

  &__tools {
    width: 99%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
}
