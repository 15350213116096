


























































.ar-line-control {
  position: relative;
  height: 8px;
  border-radius: 5px;
  background-color: #e6e6e6;

  &__head {
    position: absolute;
    height: inherit;
    background-color: #616161;
    border-radius: inherit;
  }
}
