





































































































































































































































































































































































































































































































































































































































































































































































































.side {
  display: flex;
  justify-content: space-between;
}

.licence-banner {
  width: 90%;
  margin: auto;
  padding: 10px;
  background: #1d2d50;
  color: white;
  border-left: 10px solid #f99830;
  font-size: 18px;
  text-align: center;
  border-right: 10px solid #f99830;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  & > div {
    font-size: 16px;
  }
}
