<template>
  <div
    v-show="show"
    class="write-content"
  >
    <transition name="slide-up">
      <div
        ref="contentArea"
        class="write-content__body"
      >
        <froala-editor
          v-model="text"
          iconsTemplate="font_awesome"
          :pasteProtection="false"
          :math="true"
          :katex="true"
          :safariKatexBugFix="true"
          :reRenderEqOnNoChangeUpdate="true"
          :table="true"
          :print="true"
          :color="true"
          :image="true"
          :indent="false"
          :font-size="true"
          :font-family="true"
          heightMin="600px"
          heightMax="Calc(100vh - 170px)"
          :skeuomorphism="true"
          :wordCount="false"
          :focus="true"
          :zIndex="9999"
          :maximize="false"
          @imageUploader="imageUploader"
        />
      </div>
    </transition>

    <div class="write-content__footer">
      <div class="write-content__footer__wrapper">
        <div class="write-content__footer__group">
          <text-button
            title="Ok"
            big
            style="margin-right: 10px"
            @click="save"
          />
          <text-button
            :title="__('Ångra och stäng')"
            big
            mode="secondary"
            @click="undoConfirm"
          />
        </div>

        <div class="write-content__footer__group">
          <el-dropdown
            v-model="prepareStudentArea"
            style="margin: 0; width: 300px; min-width: 300px"
            :wide="true"
            :options="prepareStudentAreaOptions"
          />
        </div>
      </div>
    </div>

    <exam-modal
      ref="undoModal"
      size="sm"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Ångra och stäng') }}
      </h3>
      <div
        slot="body"
        class="text-center"
        style="display: flex; flex-direction: column; justify-content: space-around; align-items: center"
      >
        {{ __('Är du säker på att du vill ångra redigeringen av innehållet?') }}
      </div>
      <div
        slot="footer"
        style="display: flex; flex-direction: row; justify-content: space-around; align-items: center"
      >
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Nej')"
          position="middle"
        />
        <text-button
          mode="primary"
          data-dismiss="modal"
          :title="__('Ja, ångra')"
          position="middle"
          @click="undo"
        />
      </div>
    </exam-modal>
  </div>
</template>

<script>
import FirebaseFileUpload from 'Modules/FirebaseFileUpload.js'
import ImageVerifier from 'Modules/ImageVerifier.js'
import FroalaEditor from '@teachiq/froala-editor'
import Modal from 'Modules/Modal.js'
import Vue from 'vue'

FroalaEditor.install(Vue)

export default {
  props: {
    bucket: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Modal,
      text: '',
      previousOverflowValue: '',
      show: false,
      prepareStudentArea: false,
      prepareStudentAreaOptions: [
        { value: false, label: this.__('Visa provfrågorna för eleven') },
        { value: true, label: this.__('Fyll i elevens skrivyta med provfrågorna vid start') },
      ],
    }
  },
  watch: {
    show() {
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
  },
  mounted() {
    window.K$.events.$on('show-content-area', () => {
      this.previousOverflowValue = document.body.style.overflow
      document.body.style.overflow = 'hidden'
      this.show = true
    })

    window.K$.events.$on('set-content', ({ text, prepareStudentArea }) => {
      this.text = this.sanitize(text)
      this.prepareStudentArea = prepareStudentArea
    })
  },
  methods: {
    async imageUploader(image) {
      if (!ImageVerifier.valid(image)) {
        return
      }

      try {
        const url = await FirebaseFileUpload.uploadCached(this.bucket, image, 'jpg')

        return url
      } catch (err) {
        //
      }
    },
    save() {
      this.close()
      this.$emit('update', {
        text: this.text,
        prepareStudentArea: String(this.prepareStudentArea) === 'true',
      })
    },
    undo() {
      this.$emit('undo-write-content')
      this.close()
    },
    undoConfirm() {
      if (!this.text || this.text.length === 0) {
        this.undo()
        return
      }

      Modal.show(this.$refs.undoModal)
    },
    close() {
      this.show = false
      document.body.style.overflow = this.previousOverflowValue
    },
  },
}
</script>

<style>
/** Global styles that target the froala editor */
.write-content__body img[src^='blob'] {
  opacity: 0.15;
  border: 3px dashed red;
  border-radius: 5px;
}

.write-content__body .fr-box.fr-basic .fr-element {
  font-size: 18px;
  color: black;
}
</style>

<style scoped lang="less">
.write-content {
  z-index: 9999;
  height: 100vh;
  background: rgba(0, 0, 0, 30%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;

  &__body {
    height: auto;
    top: 10px;
    bottom: 60px;
    background: #f9f9f9;
    width: 900px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 20%);
    max-width: 100%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    z-index: 1;

    @media (max-width: 900px) {
      top: 0;
    }
  }

  &__footer {
    background: transparent;
    position: absolute;
    bottom: 0;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;

    &__wrapper {
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 12%);
      display: flex;
      flex-wrap: nowrap;
      width: 900px;
      max-width: 100%;
      padding: 0 15px;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: center;
      height: 100%;
    }

    &__group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>
