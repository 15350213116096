<template>
  <div class="trial-cheat-wrapper">
    <div class="cheat-toggle-info">
      <p>{{ __('Underlying controls and reporting') }}:</p>
      <el-toggle-button
        class="cheat-toggle-button"
        :value="false"
        :disabled="true"
        @click="show = true"
      />
    </div>
    <notification-card
      v-show="show"
      type="warning"
      style="margin: 0; width: 100%"
    >
      <p style="margin-top: 20px">
        {{
          __(
            'In addition to our standard cheat protection, Exam.net has an extra layer of backend controls and logs for detection of advanced cheats with software, split-screens, integrity of variables, events, source code, settings or use of virtual machines and remote desktop solutions.',
          )
        }}
      </p>
      <p>
        {{
          __(
            'This extra layer applies to both browser and high-security mode, enhancing security in browser mode and making the high-security mode very secure. Should the backend controls detect anomalies, Exam.net emails a cheating report to the teacher, during or after an exam.',
          )
        }}
        See <a
          href="https://exam.net/cheat"
          target="_blank"
        >https://exam.net/cheat</a> for more information.
      </p>
      <p class="bold-paragraph">
        {{
          __('Backend cheat detection is enabled by default for all paid licenses but is not available during trials.')
        }}
      </p>
    </notification-card>
  </div>
</template>

<script>
import NotificationCard from 'VueComponents/NotificationCard.vue'

export default {
  components: { NotificationCard },
  data() {
    return {
      show: false,
    }
  },
}
</script>

<style scoped>
.trial-cheat-wrapper {
  margin-top: 50px;
}

.cheat-toggle-button {
  width: 70px;
  margin: 0;
  margin-left: 10px;
}

.cheat-toggle-info {
  display: flex;
  margin-top: 30px;
}

.bold-paragraph {
  font-weight: bold;
}
</style>
