





















































.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 600;

  &__bubble {
    margin-left: 12px;
    margin-right: 12px;
    text-transform: none;
    border: 1px solid black;
    color: black;
    background: none;
    cursor: default;
    border-radius: 5px;
    user-select: text;
    margin-bottom: 12px;
    width: 300px;
    padding: 12px;
    font-size: 14px;

    &.examFooter {
      color: white;
      border-color: white;
      margin-bottom: 30px;
      width: 260px;
    }
  }
}
