










































































@import 'Less/variables.less';

.search-bar {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 5px;
  transition: all 0.35s ease 0s;
  background: white;
  color: @theme-blue;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);

  &--expanded {
    width: 180px;
  }

  &__icon {
    font-size: 32px;
    margin-right: 10px;
    margin-left: 9px;
    margin-top: -1px;
    cursor: pointer;
  }
}
