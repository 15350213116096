<template>
  <div
    class="bg-white max-w-full mx-auto rounded-2xl text-center"
    style="width: 700px"
  >
    <div>
      <h2 class="font-semibold mt-8 p-6 pt-10 text-xl uppercase">
        {{ __('Lärarinlogg') }}
      </h2>
      <form>
        <input
          type="hidden"
          name="_token"
        >
        <div>
          <label
            for="email"
            class="sr-only"
          >{{ __('Epost') }}</label>
          <input
            id="teacherEmail"
            v-model="loginData.email"
            autofocus
            type="email"
            class="border m-2 max-w-full outline-none p-2 pl-6 rounded-full text-sm w-96"
            name="email"
            :placeholder="__('Epost')"
            data-testid="login-email"
            tabindex="1"
            @keydown.enter.prevent="tryToLogin"
          >
        </div>
        <div>
          <label
            for="password"
            class="sr-only"
          >{{ __('Lösenord') }}</label>
          <input
            v-model="loginData.password"
            class="border m-2 max-w-full mt-0 outline-none p-2 pl-6 rounded-full text-sm w-96"
            type="password"
            name="password"
            :placeholder="__('Lösenord')"
            data-testid="login-password"
            tabindex="2"
            @keydown.enter.prevent="tryToLogin"
          >
        </div>
        <div>
          <div>
            <button
              id="submit-teacher"
              type="button"
              name="log-me-in"
              tabindex="5"
              data-testid="sign-in-button"
              class="bg-exam-blue
                border
                border-white
                duration-300
                focus:outline-none
                font-light
                group
                hover:bg-white
                hover:border-exam-blue
                hover:text-exam-blue
                mb-2
                outline-none
                p-2
                rounded-full
                text-white
                transition-all
                w-64"
              @click="tryToLogin"
            >
              {{ __('Logga in') }}
              <i
                v-show="loginLoading"
                class="fa fa-spin fa-spinner fetch-exam-spinner group-hover:text-exam-blue text-white"
              />
            </button>
          </div>
        </div>
        <div
          v-if="loginFailed"
          class="font-light mt-2 mx-auto text-center text-yellow-500 w-96"
        >
          <span>{{
            __('Inloggning misslyckades, kontrollera inmatning och att du är registrerad som användare.')
          }}</span>
        </div>

        <div
          v-if="throttle"
          class="font-light mt-2 mx-auto text-center text-red text-red-500 w-96"
        >
          <span>{{ __('För många försök, vänligen vänta en stund innan du försöker igen.') }}</span>
        </div>
      </form>

      <div>
        <SSOButtons @login="ssoLogin" />
      </div>

      <div class="flex mt-2 mx-auto text-gray-400 text-left text-sm w-96">
        <div class="mb-8 mt-4 w-full">
          <div class="flex items-center justify-center w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              style="color: #f99731"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
            <a
              href="#"
              class="hover:text-exam-orange"
              @click="showLostPassword = true"
            >{{
              __('Glömt ditt lösenord?')
            }}</a>
          </div>
          <div
            v-show="showLostPassword"
            class="form-group pwdrestore"
          >
            <div class="flex items-center justify-center mt-4">
              <div>
                <label
                  for="lost_email"
                  class="sr-only"
                >{{ __('Epost') }}</label>
                <input
                  id="lost_email"
                  v-model="lostEmail"
                  class="border m-2 mt-2 outline-none p-2 pl-6 rounded-full text-sm w-64"
                  type="text"
                  name="email"
                  :placeholder="__('Epost')"
                  tabindex="1"
                  @keydown.enter.prevent="handleLostEmail"
                >
              </div>
              <div>
                <button
                  type="button"
                  name="send-me"
                  tabindex="5"
                  class="bg-exam-blue
                    border
                    border-white
                    duration-300
                    font-thin
                    hover:bg-white
                    hover:border-exam-blue
                    hover:text-exam-blue
                    outline-none
                    p-2
                    rounded-full
                    text-white
                    transition-all
                    w-32"
                  @click="handleLostEmail"
                >
                  {{ __('Skicka') }}
                  <i
                    v-show="lostEmailLoading"
                    class="fa fa-spin fa-spinner fetch-exam-spinner"
                  />
                </button>
              </div>
            </div>
            <div
              v-if="emailSent"
            >
              <span>{{ __('Ett epost för att återställa lösenordet har skickats iväg') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!localeIs('sv')"
      class="border-gray-300 border-t flex items-center justify-center p-4"
    >
      <div class="mb-2 mr-4">
        {{ __('Har du inget konto?') }}
      </div>
      <div>
        <button
          type="button"
          class="bg-exam-orange
            border
            border-white
            duration-300
            focus:outline-none
            font-light
            hover:bg-white
            hover:border-exam-orange
            hover:text-exam-orange
            outline-none
            p-2
            rounded-full
            text-white
            transition-all
            w-48"
          @click="showSignUpForm"
        >
          {{ __('Create an account') }}
        </button>
        <div class="text-gray-300 text-xs">
          {{ __('No payment or commitment required') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SSOButtons from './SSOButtons.vue'
import axios from 'axios'

export default {
  components: { SSOButtons },
  data() {
    return {
      SSO_fail: false,
      isTryingToLogin: false,
      loginData: {
        email: '',
        password: '',
        remember: false,
      },
      throttle: false,
      throttleInverval: null,
      waitingForLogin: false,
      lostEmail: '',
      emailSent: false,
      lostEmailLoading: false,
      loginLoading: false,
      loginFailed: false,
      showLostPassword: false,
    }
  },
  created() {
    // The old website used this information to differentiate between a teacher already signed in
    // and someone not signed in. This is no longer used and the code below removes old entries
    // in localStorage. This code can be removed after 2022-09-20.
    try {
      window.localStorage.removeItem('logged-in-email')
    } catch (err) {
      //
    }
  },
  methods: {
    ssoLogin(type) {
      window.location = `https://sso.exam.net/${type}?domain=https://${window.location.hostname}`
    },
    async tryToLogin() {
      if (this.isTryingToLogin) {
        return
      }

      window.clearTimeout(this.throttleInterval)
      this.isTryingToLogin = true

      if (this.loginData.email.length <= 2 || this.loginData.password.length === 0) {
        this.isTryingToLogin = false
        return
      }

      this.loginLoading = true
      this.loginFailed = false

      axios
        .post('/login', { ...this.loginData })
        .then(({ data }) => {
          this.loginLoading = false

          if (data.status === 'successful') {
            if (data.badPassword) {
              // Don't pass this information in the queryString since we don't want any record in the logs
              // or similar where this can be connected to a timestamp and a user via the login log. We, or
              // anyone with access to our system, should not be able to know which users have bad passwords.
              window.sessionStorage.setItem('recommend-password-update', true)
              window.location = '/admin/profile'
              return
            }

            window.location = '/admin/exams'
          }

          this.isTryingToLogin = false
        })
        .catch((error) => {
          this.loginLoading = false

          if (error.response.status === 419) {
            window.location.reload()
          }

          this.isTryingToLogin = false
          if (error.response.status === 422 || error.response.status === 401) {
            this.loginFailed = true
          }

          if (error.response.status === 429) {
            this.throttle = true
            this.throttleInterval = setTimeout(() => {
              this.throttle = false
            }, 2000)
          }
        })
    },
    async handleLostEmail() {
      if (this.lostEmail.length <= 2) {
        return
      }

      this.lostEmailLoading = true
      this.emailSent = false

      axios
        .post('/forgot-password', { email: this.lostEmail })
        .then(() => {
          this.lostEmailLoading = false
          this.emailSent = true
          this.lostEmail = ''
        })
        .catch((error) => {
          console.log(error)
          this.lostEmailLoading = false
        })
    },
    showSignUpForm() {
      window.location = '/teacher-registration'
    },
  },
}
</script>
<style scoped>
.fetch-exam-spinner {
  margin-left: 10px;
}
</style>
