














































































































































































































.drag-window {
  z-index: 9999;
  position: fixed;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 20%);
  overflow: hidden;
  background-color: #fff;

  &--rounded {
    border-radius: 5px;
  }

  &--active {
    box-shadow: 0 15px 35px rgba(0, 0, 0, 20%);
  }

  &__header {
    position: relative;
    width: 100%;
    cursor: move;
    user-select: none;
    touch-action: none;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;

    &__title {
      height: auto;
      font-size: 14px;
      margin: 0;
      padding: 0;
      font-weight: 700;
    }

    &__close {
      cursor: pointer;
    }
  }
}
