

































@import 'Less/variables.less';

.loader {
  --animation-speed: 3s;
  --x-axis-animation-settings: var(--animation-speed) infinite linear;
  --y-axis-animation-settings: var(--animation-speed) infinite;
  --dot-size: 20px;
  --traveler-move-distance: 140px;
  --dot-move-distance: 28px;
  --dot-move-distance-halfway: calc((var(--dot-move-distance) / 2) * -1);
  --dot-move-negative-distance: calc(var(--dot-move-distance) * -1);

  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: @spacing-8;
  margin-bottom: @spacing-8;

  &__dot {
    margin: @spacing-1;
    height: var(--dot-size);
    width: var(--dot-size);
    border-radius: @radius-round;
    background: #e3e3e3;

    &--traveler {
      margin-right: @spacing-5;
      animation: full-x-axis var(--x-axis-animation-settings);

      &--light {
        background: @theme-primary;
      }

      &--dark {
        background: @theme-blue;
      }
    }

    &--first {
      animation: x-axis-first var(--x-axis-animation-settings);
    }

    &--second {
      animation: x-axis-second var(--x-axis-animation-settings);
    }

    &--third {
      animation: x-axis-third var(--x-axis-animation-settings);
    }

    &--fourth {
      animation: x-axis-fourth var(--x-axis-animation-settings);
    }
  }

  &__y-axis {
    &--first {
      animation: y-axis-first var(--y-axis-animation-settings);
    }

    &--second {
      animation: y-axis-second var(--y-axis-animation-settings);
    }

    &--third {
      animation: y-axis-third var(--y-axis-animation-settings);
    }

    &--fourth {
      animation: y-axis-fourth var(--y-axis-animation-settings);
    }
  }
}

// Some of the timing values feels a bit arbitrary
// Would like the keyframes to be reusable for for all dots but can't get the timing right.
@keyframes x-axis-first {
  5% {
    transform: translateX(0); // Start moving left
  }

  15% {
    transform: translateX(var(--dot-move-distance-halfway)); // To reach halfway when traveler dot is beneath
  }

  20% {
    transform: translateX(var(--dot-move-negative-distance)); // Negative --dot-move-distance to move left
  }

  82% {
    transform: translateX(var(--dot-move-negative-distance)); // Start moving right
  }

  92% {
    transform: translateX(var(--dot-move-distance-halfway));
  }

  97% {
    transform: translateX(0); // Back to original position
  }
}

@keyframes y-axis-first {
  5% {
    transform: translateY(0); // Start to move down
  }

  15% {
    transform: translateY(var(--dot-move-negative-distance)); // --dot-move-negative-distance. Moves the dot up.
  }

  20% {
    transform: translateY(0); // Back to original y position
  }

  82% {
    transform: translateY(0); // Start to move up
  }

  92% {
    transform: translateY(var(--dot-move-distance));  // Positive --dot-move-distance. Moves the dot down (Top position)
  }

  97% {
    transform: translateY(0); // Back to original y position
  }
}

@keyframes x-axis-second {
  15% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(var(--dot-move-distance-halfway));
  }

  30% {
    transform: translateX(var(--dot-move-negative-distance));
  }

  72% {
    transform: translateX(var(--dot-move-negative-distance));
  }

  82% {
    transform: translateX(var(--dot-move-distance-halfway));
  }

  87% {
    transform: translateX(0);
  }
}

@keyframes y-axis-second {
  15% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(var(--dot-move-negative-distance));
  }

  30% {
    transform: translateY(0);
  }

  72% {
    transform: translateY(0);
  }

  82% {
    transform: translateY(var(--dot-move-distance));
  }

  87% {
    transform: translateY(0);
  }
}

@keyframes x-axis-third {
  25% {
    transform: translateX(0);
  }

  35% {
    transform: translateX(var(--dot-move-distance-halfway));
  }

  40% {
    transform: translateX(var(--dot-move-negative-distance));
  }

  62% {
    transform: translateX(var(--dot-move-negative-distance));
  }

  72% {
    transform: translateX(var(--dot-move-distance-halfway));
  }

  77% {
    transform: translateX(0);
  }
}

@keyframes y-axis-third {
  25% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(var(--dot-move-negative-distance));
  }

  40% {
    transform: translateY(0);
  }

  62% {
    transform: translateY(0);
  }

  72% {
    transform: translateY(var(--dot-move-distance));
  }

  77% {
    transform: translateY(0);
  }
}

@keyframes x-axis-fourth {
  35% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(var(--dot-move-distance-halfway));
  }

  50% {
    transform: translateX(var(--dot-move-negative-distance));
  }

  52% {
    transform: translateX(var(--dot-move-negative-distance));
  }

  62% {
    transform: translateX(var(--dot-move-distance-halfway));
  }

  67% {
    transform: translateX(0);
  }
}

@keyframes y-axis-fourth {
  35% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(var(--dot-move-negative-distance));
  }

  50% {
    transform: translateY(0);
  }

  52% {
    transform: translateY(0);
  }

  62% {
    transform: translateY(var(--dot-move-distance));
  }

  67% {
    transform: translateY(0);
  }
}

@keyframes full-x-axis {
  50% {
    transform: translateX(var(--traveler-move-distance));
  }
}
