








































.MenuItem {
  cursor: pointer;
  padding: var(--spacing-2);
  padding-right: var(--spacing-4);
  border-radius: var(--radius-default);

  &:hover {
    background-color: var(--gray-200);
    border-radius: 0;
  }

  &:active {
    box-shadow: var(--shadow-inner);
  }

  &__icon {
    margin-right: var(--spacing-2);
  }
}
