





































































































































































































@import 'Less/variables.less';

.warning {
  z-index: 999;
  position: sticky;
  bottom: 0;
  background: @color-warning-medium;

  &__message {
    font-size: @font-size-default;
    text-align: center;
    width: 100%;
    margin: 0;
    font-weight: var(--font-bold);
    color: @color-font-dark;
  }

  &__icon-close {
    position: absolute;
    top: @spacing-1;
    right: @spacing-2;
    cursor: pointer;
  }
}

.drawing-board-wrapper {
  width: 100%;
  height: calc(100% - var(--resource-toolbar-height));
  overflow: hidden;

  &--wrapper {
    width: 100%;
    height: 100%;
  }

  &--smaller {
    max-height: 600px;
    max-width: 1000px;
    border-right: @divider-border;
    border-bottom: @divider-border;
  }
}

.drawing-board {
  height: calc(100vh - var(--resource-toolbar-height));
}

.save-drawing-message {
  margin-top: @spacing-10;
}

.modal-footer {
  margin-right: -@spacing-4;
}

.screenshot-error-icon {
  margin: @spacing-9;
  color: @color-danger-dark;
}
