




























































































































.ExamExportMenu {
  &__menu {
    &__option {
      display: flex;
      align-items: center;

      &__icon {
        margin-right: var(--spacing-2);
        display: flex;
        align-items: center;

        &::v-deep {
          svg {
            width: var(--width-5);
            height: var(--height-5);
          }
        }
      }
    }
  }
}

