














































.wrapper {
  padding: 9px;
  background: #1d2d50;
  margin: auto;
  border-radius: 5px;
  top: -100px;
  position: fixed;
  left: calc(50% - 150px);
  transition: all 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  color: white;
}

.wrapper.visible {
  top: 10px;
}
