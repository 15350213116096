






























































































































@import 'Less/variables.less';
@width: 1em;
@height: 1em;

.checkbox-wrapper {
  cursor: pointer;
  position: relative;
  user-select: none;
  min-height: @height;
  min-width: @width;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  font-size: inherit;
  align-items: center;

  .input-wrapper {
    display: flex;
    align-items: center;
  }

  &__label:not(.sr-only) {
    margin-left: var(--spacing-3);
  }

  &::before {
    background-color: @color-grey-light;
    border-radius: 50%;
    border: none;
    content: '';
    position: absolute;
    height: @height;
    transition: @animation;
    width: @width;
  }

  &:not(.disabled):hover {
    &::before {
      transform: scale(2);
    }

    input ~ .checkmark {
      background-color: @color-grey-light;
    }
  }

  &:focus-visible {
    outline: none;
  }

  &:not(.disabled):focus {
    &::before {
      transform: scale(2);
    }

    input ~ .checkmark {
      background-color: @color-grey-light;
    }
  }

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked ~ .checkmark {
      background-color: white;
      border: 0.05em solid @color-info-dark;

      &::after {
        display: block;
      }
    }

    &:focus {
      outline: none;
      border: none;
    }

    &:indeterminate ~ .checkmark {
      background-color: white;

      &::after {
        transform: rotate(0deg);
        display: block;
        border-width: 0 0 0.2em;
        width: 0.5em;
        top: 0.34em;
        left: 0.2em;
        height: 0;
      }
    }
  }

  .checkmark {
    &::after {
      transform: rotate(35deg);
      border: solid @color-info-dark;
      border-width: 0 0.15em 0.2em 0;
      height: 0.8em;
      left: 0.28em;
      top: 0;
      width: 0.3em;
    }
  }
}

.checkmark {
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  height: @height;
  width: @width;
  position: relative;

  &::after {
    content: '';
    display: none;
    position: absolute;
  }
}

.disabled {
  color: var(--gray-500);
  cursor: not-allowed;
}

.inactive {
  color: var(--gray-500);
}
