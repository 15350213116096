
































































































































































































































































































/* Enter and leave animations can use different */

/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-800px);
  opacity: 0;
}
