








































































































































































































































































































.showOnLarge {
  display: none !important;

  @media (min-width: 1370px) {
    display: initial !important;
  }
}

.showLogoOnSmall {
  display: none !important;

  @media (max-width: 1371px) {
    display: initial !important;
    top: 16px;
    position: absolute;
    left: 19px;
  }
}

.showLogoOnSmall--minimized {
  top: 0;

  img {
    width: 31px;
    margin-top: 10px;
  }
}

.fa {
  font-size: 16px;
}

.notificationCounter {
  display: initial;
  position: absolute;
  top: 7px;
  left: 10px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #d84a38;
  text-align: center;
  line-height: 22px;
  box-sizing: content-box;
  color: #fff;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
}

.mobileMenu {
  position: absolute;
  top: 62px;
  width: 190px;
  border: none;
  display: flex;
  box-shadow: none;
  z-index: 1;
}

.teacher-navbar {
  &__wrapper {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.teacher-navbar__list {
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media (min-width: 768px) {
    flex-direction: row;
    max-width: 90%;
    white-space: nowrap;
  }
}

