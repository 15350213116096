
























































































@import 'Less/variables.less';

@shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
@orange-6: #bc7603;
@blue-4: #4a90e2;
@border-radius: 0px;

.refresh-notification {
  position: fixed;
  bottom: 15px;
  left: 10px;
  height: auto;
  width: auto;
  background-color: #fff;
  box-shadow: @shadow;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 250px;
  border-radius: @border-radius;
  z-index: 9999999;

  &__banner {
    width: 35px;
    min-width: 35px;
    background-color: @theme-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: @border-radius 0 0 @border-radius;
    padding-top: 15px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    padding: 10px;
    box-sizing: border-box;

    p {
      margin-bottom: 0;
    }
  }
}
