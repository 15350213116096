
















































































@import 'Less/variables.less';

.icon-button {
  border-radius: var(--radius-round);
  background-color: transparent;
  color: @color-grey-dark;
  border: 0;
  transition: @animation;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }

  &--labeled {
    border-radius: @radius-small;
    padding: @spacing-4;
  }

  &__label {
    font-size: @font-size-small;
    font-weight: var(--font-bold);
    margin-left: @spacing-2;
  }

  &:hover {
    background-color: var(--gray-100);
  }

  &:focus {
    outline: none;
  }

  &--active {
    background-color: @color-grey-light;
  }

  &--tiny {
    font-size: var(--font-size-sm);
    width: var(--spacing-6);
    height: var(--spacing-6);
  }

  &--small {
    font-size: @font-size-medium;
    width: @spacing-7;
    height: @spacing-7;
  }

  &--medium {
    font-size: @font-size-x-large;
  }

  &--fullWidth {
    width: 100%;
  }

  &__icon {
    &--tiny {
      font-size: var(--font-size-sm);
      margin-top: 0;
    }

    &--small {
      font-size: @font-size-large;
      margin-top: 0;
    }

    &--medium {
      font-size: 1em;
      margin-top: 0;
    }
  }
}
