<template>
  <div class="textContainer">
    {{ text }}
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.textContainer {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 12px;
  text-align: left;
  margin-top: 10px;
  color: #373737;
}
</style>
