







































.icon {
  min-height: 50px;
}

i.icon {
  align-items: center;
  justify-content: center;
  display: flex;
}

.action-button-card {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ff9e02;
  color: black;
  border: none;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 20%);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);
  }

  &.danger {
    border: 1px solid #d84a38;
    color: #d84a38;
  }
}

.action-button-card .description {
  margin-top: 20px;
  color: black;
  text-align: center;
}

.action-button-card i {
  font-size: 40px;
}

.action-button-card-activated {
  background: #fff;
  border: 1px solid #45c458;
  color: #45c458;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 20%);
  border-radius: 2px;
}

.action-button-card-danger {
  background: #fff;
  border: 1px solid #d84a38;
  color: #d84a38;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 20%);
  border-radius: 2px;
}
