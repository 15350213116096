<template>
  <exam-modal
    ref="turnitin"
    size="lg"
    :lessRounded="true"
  >
    <div slot="title">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
      >
        &times;
      </button>
      <div style="display: flex; align-items: center; justify-content: center">
        <img
          style="margin-top: 20px"
          width="200"
          src="https://cdn2.exam.net/static/images/turnitin.png"
          alt="Turnitin logo"
        >
      </div>
    </div>

    <div slot="body">
      <div
        v-if="state !== 'pending' && !schoolAccount"
        style="display: flex; align-items: center; justify-content: center; flex-direction: column"
      >
        <h4 style="margin-bottom: 20px">
          {{
            __('För att kunna använda integrationen mellan Turnitin och Exam.net behöver ni en licens hos Turnitin.')
          }}
        </h4>
        <p>
          <text-button
            mode="secondary"
            :title="__('Klicka här om du inte är kund hos Turnitin och vill veta mer')"
            @click="turnitinSales"
          />
        </p>
        <h4 style="margin-bottom: 20px; margin-top: 30px">
          {{ __('Skolans Turnitin-administratör måste konfigurera er integration innan den kan börja användas.') }}
        </h4>

        <div style="display: flex; align-items: center; justify-content: center;">
          <text-button
            :title="__('Visa länk för konfiguration')"
            @click="openConfigurationLink"
          />
        </div>
      </div>

      <div
        v-if="state !== 'pending' && schoolAccount && !userAccount"
        style="text-align: center"
      >
        <h4>{{ __('Du måste läsa och godkänna Turnitins End User License Agreement (EULA)') }}</h4>
        <iframe
          v-if="eulaURL"
          class="eula"
          :src="eulaURL"
        />

        <text-button
          style="margin-top: 20px"
          :title="__('Klicka här för att godkänna Turnitins EULA')"
          @click="createUser"
        />
      </div>

      <div v-if="schoolAccount && userAccount">
        <text-button
          style="text-align: center; margin-bottom: 10px"
          :title="__('Skicka samtliga ohanterade elevtexter till') + ' Turnitin'"
          @click="sendAllToTurnitin"
        />
        <p class="text-center">
          Processing and analysis may take between a few minutes and a couple of hours.
        </p>
        <p
          style="margin-top: -20px"
          class="text-center"
        >
          You can close this page while waiting.
        </p>
        <table
          id="testlist"
          ref="table"
          class="table table-hover"
          style="min-width: 800px"
        >
          <thead>
            <tr ref="tableHead">
              <th
                class="text-center"
                style="width: 200px"
              >
                {{ __('Elev') }}
              </th>
              <th class="text-center">
                {{ __('Status') }}
              </th>
              <th class="text-center">
                {{ __('Åtgärder') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <template
              v-for="student in students"
            >
              <tr
                v-if="student.is_demo == 0"
                :key="student.id"
                class="row-border"
              >
                <td>{{ Student.displayName(student) }}</td>
                <td style="text-align: center">
                  <span v-if="student.turnitin">
                    <span v-if="student.turnitin.state === 'NOT_SUBMITTED'">
                      <i class="fa fa-hourglass-end" />&nbsp;{{ __('Väntar på behandling') }}
                    </span>

                    <span v-if="['SUBMITTED', 'COMPLETE', 'SIMILARITY_PENDING'].includes(student.turnitin.state)">
                      <i class="fa fa-hourglass-end" />&nbsp;{{ __('Behandlas av') }} Turnitin
                    </span>

                    <span v-if="student.turnitin.state === 'SIMILARITY_COMPLETE' && significance(student.turnitin) > 0">
                      <span>
                        <i class="fa fa-inbox" />&nbsp;{{ __('Behandling klar') }} –
                        {{ significance(student.turnitin) }} % {{ __('likhet') }}
                      </span>

                      <div style="margin-top: 5px">
                        <text-button
                          mode="secondary"
                          :title="__('Se rapport')"
                          @click="seeReport(student)"
                        />
                      </div>
                    </span>

                    <span
                      v-if="student.turnitin.state === 'SIMILARITY_COMPLETE' && significance(student.turnitin) === 0"
                    >
                      <span><i class="fa fa-inbox" />&nbsp;{{ __('Behandling klar') }} –
                        {{ __('Ingen matchning') }}</span>
                    </span>

                    <span v-if="student.turnitin.state === 'ERROR'">
                      {{ __('Eleventexten är för kort för att genomgå plagiatanalys.') }}
                    </span>
                  </span>
                  <span v-else>
                    {{ __('Elevtext ej skickad för analys ännu') }}
                  </span>
                </td>

                <td style="text-align: center">
                  <i
                    v-if="!student.turnitin"
                    v-tooltip="__('Skicka elevtext för analys')"
                    class="fa fa-paper-plane-o"
                    style="cursor: pointer"
                    @click="sendToTurnitin(student)"
                  />
                  <i
                    v-else
                    v-tooltip="
                      __('Ta bort denna analys. Detta gör att du kan skicka in texten igen, ifall den har uppdaterats.')
                    "
                    class="fa fa-trash"
                    style="cursor: pointer"
                    @click="deleteTurnitin(student)"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <div
      slot="footer"
      class="popup-footer"
      style="width: 300px; margin: auto"
    >
      <text-button
        mode="secondary"
        data-dismiss="modal"
        :title="__('Stäng')"
      />
    </div>
  </exam-modal>
</template>

<script>
import Anonymous from 'Modules/Anonymous.ts'
import Student from 'Modules/Student.js'
import Modal from 'Modules/Modal.js'
import Parser from '@krisell/parser'
import axios from 'axios'
import { extractTextBasedStudentAnswersFromBuilderExamAsHTML } from 'Modules/ExportUtils.ts'

export default {
  props: {
    exam: {
      type: Object,
      required: true,
    },
    isBuilder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Modal,
      Student,
      unit: null,
      students: [],
      schoolAccount: null,
      userAccount: null,
      eulaURL: '',
      eulaLanguage: '',
      eulaVersion: '',
      state: 'pending',
      configurationLink: '',
      formLink: '',
    }
  },
  mounted() {
    window.K$.events.$on('show-turnitin-modal', () => {
      Modal.show(this.$refs.turnitin)
      this.fetchSchoolInfo()
    })
  },
  methods: {
    textBasedStudentAnswer(student) {
      if (this.isBuilder) {
        const answer = Parser.json(student.answer)

        return Object.keys(answer).length === 0
          ? ''
          : extractTextBasedStudentAnswersFromBuilderExamAsHTML(Parser.json(student.answer))
      }

      return student.answer || ''
    },
    openConfigurationLink() {
      window.open(this.configurationLink)
    },
    async seeReport(student) {
      const response = await axios.get(`/api/turnitin/students/${student.id}/similarity-url`)
      window.open(response.data.viewer_url)
    },
    significance(turnitin) {
      const similarityReport = Parser.json(turnitin.last_response)

      return Number(similarityReport.overall_match_percentage)
    },
    turnitinSales() {
      window.open(this.formLink)
    },
    fetchUser() {
      return axios
        .get('/api/turnitin/user')
        .then((response) => {
          if (response.data.user) {
            this.userAccount = response.data.user
            this.fetchExamData()
            return
          }

          const availableLanguages = response.data.eula.available_languages
          this.eulaLanguage = availableLanguages.find((lang) => lang.slice(0, 2) === this.__('sv'))
          if (!this.eulaLanguage) {
            this.eulaLanguage = availableLanguages[0]
          }

          this.eulaURL = `${response.data.eula.url}?lang=${this.eulaLanguage}`
          this.eulaVersion = response.data.eula.version
          this.state = ''
        })
        .catch((error) => {
          this.state = ''
          console.log(error)
        })
    },
    createUser() {
      return axios
        .post('/api/turnitin/user', {
          eula_version: this.eulaVersion,
          eula_language: this.eulaLanguage,
        })
        .then((response) => {
          this.userAccount = response.data
          this.fetchExamData()
        })
    },
    fetchSchoolInfo() {
      return axios
        .get('/api/turnitin')
        .then((response) => {
          if (response.data.status === 'found') {
            this.schoolAccount = response.data.turnitinTenant
            this.fetchUser()
            return
          }

          this.state = ''
          this.configurationLink = response.data.link
          this.formLink = response.data.formLink
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchExamData() {
      axios.get(`/api/turnitin/exams/${this.exam.id}`).then(({ data }) => {
        this.students = data.students

        if (Number(this.exam.anonymous) === 1) {
          this.students.forEach((student) => Anonymous.replaceName(student, this.exam, false))
        }

        setTimeout(this.fetchExamData, 2 * 60 * 1000)
      })
    },
    sendAllToTurnitin() {
      axios.post(`/api/turnitin/exams/${this.exam.id}`, {
        answers: this.students.reduce((studentIdAnswerMap, student) => {
          studentIdAnswerMap[student.id] = this.textBasedStudentAnswer(student)

          return studentIdAnswerMap
        }, {}),
      }).then(() => {
        this.fetchExamData()
      })
    },
    sendToTurnitin(student) {
      axios.post(`/api/turnitin/students/${student.id}`, {
        answer: this.textBasedStudentAnswer(student),
      }).then(() => {
        this.fetchExamData()
      })
    },
    deleteTurnitin(student) {
      axios.delete(`/api/turnitin/students/${student.id}`).then(() => {
        this.fetchExamData()
      })
    },
  },
}
</script>

<style scoped>
.eula {
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  box-shadow: 2px 2px 2px black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 50%);
  border: none;
}
</style>
