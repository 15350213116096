





























































































































































































































































































































































.default-text {
  //body2

  color: var(--colorInputText);
  .body2;

  &.disabled {
    color: @lightGrey;
  }
}

.selection {
  .caption();

  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  cursor: pointer;
  word-wrap: break-word;
  padding: 0 30px 0 12px; // input padding left,right. 30px because of absolute positioned icons
  background-color: var(--colorInputBackground);
  border-radius: var(--borderRadius);
  outline: 0;
  text-align: left;

  &.visible {
    &.below {
      border-radius: var(--borderRadius) var(--borderRadius) 0 0;
    }

    &.above {
      border-radius: 0 0 var(--borderRadius) var(--borderRadius);
    }

    & > .icon {
      opacity: '';
      z-index: 3;
    }
  }

  &.active {
    & > .icon {
      opacity: '';
      z-index: 3;
    }
  }

  transition: box-shadow 0.1s ease, width 0.1s ease;
  -webkit-tap-highlight-color: transparent;
}

.dropdown {
  position: absolute;
  display: none;
  z-index: 3;

  &.below {
    top: 100%;
    border-top-width: 0;
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
    background-color: var(--colorDropdownBackground);
  }

  &.above {
    bottom: 100%;
    border-bottom-width: 0;
    border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  }

  min-width: calc(100% + 2px);
  width: calc(100% + 2px);
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  margin: 0 -1px;
  padding: 0;
  background: #fff;
  text-shadow: none;
  text-align: left;
  outline: 0;

  & > * {
    white-space: nowrap;
  }

  & > .item {
    .caption();

    color: var(--colorDropdownText);
    border-top: 1px solid #fafafa;
    padding: 5px 10px;
    white-space: normal;
    word-wrap: normal;

    &:first-child {
      border-top-width: 0;
    }

    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    // color: rgba(0, 0, 0, 0.87); /* color på alternativen*/
    text-transform: none;
    box-shadow: none;
    -webkit-touch-callout: none;
  }

  :hover {
    background-color: var(--colorDropdownBackgroundHover);
  }

  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 15%);
  transition: opacity 0.1s ease;
  will-change: transform, opacity;
}

.icon-wrapper {
  display: flex;
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  top: calc((40px - 11px) / 2);
  right: 0;

  & .icon {
    width: auto;

    &.dropdown-arrow {
      color: var(--colorInputText);
      margin-right: 10px;
    }

    &.disabled {
      color: @lightGrey;
    }
  }
}

.pills {
  background-color: #eef3fa;
  user-select: none;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 0.8em;
  padding: 3px 6px;
  margin: 3px 3px 3px 0;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 15%) inset;
  transition: background 0.1s ease;

  &:hover {
    text-decoration: line-through;
    background-color: #ffbbbc;
  }
}

.single {
  .caption();

  color: var(--colorInputText);
}

.transition {
  height: auto;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;

  &.hidden {
    display: none;
    visibility: hidden;
  }
}

////////////////************ */
@height: 30px;
@tablet-width: 1024px;
@mobile-width: 480px;
.mulish() {
  font-family: Mulish, sans-serif;
  font-style: normal;
}

.headline3() {
  .mulish;

  font-weight: normal;
  font-size: 25px;
  line-height: 31px;
}

.headline3 {
  .headline3;
}

.headline1 {
  .mulish;

  font-weight: 100;
  font-size: 50px;
  line-height: 63px;

  @media (max-width: @mobile-width) {
    // headline3
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
  }
}
.headline2() {
  .mulish;

  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.25px;
}

.headline2 {
  .headline2();
}

.headline4 {
  .mulish;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

/* MULISH REGULAR 17/23px, SPACING 0.15px */
.subtitle1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
}

.subtitle1 {
  .subtitle1();
}

.subtitle2() {
  .mulish;

  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.subtitle2 {
  .subtitle2;
}

.body1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.body1 {
  .body1();
}
.body2() {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.body2 {
  .body2();
}

.button {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 1.25px;
}

.caption() {
  .mulish;

  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.caption {
  .caption;
}

.overline {
  .mulish;

  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}
@blackish: #373f41;
@blueberry: #2c415a;
@midnight-blue: #1b304a;
@km-green: #369354;
@km-green-hover: #48a466;
@navy-blue: #3cbeeb;
@lime: #92df2f;
@lightGreen: #f3fbdd;
@peach: #ff9292;
@sun-bleached: #fbeda0;
@greyish: #e1e5e8;
@lightGreyDark: #737b7d;
@lightGreyBetween: #c3cbcd;
@lightGrey: #dbdde0;
@light: #efefef;
@backgroundLight: #f5f7f9;
