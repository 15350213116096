



































































































































































































































.ExamMonitoring {
  &__title {
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-6);
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__access {
    display: flex;
    align-items: baseline;

    .text {
      margin-right: var(--spacing-1)
    }
  }

  &__key {
    display: flex;
    align-items: baseline;

    .text {
      margin-right: var(--spacing-1)
    }
  }

  &__information {
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__action {
    margin-bottom: var(--spacing-3);
  }

  .show-large__body {
    font-family: Verdana, sans-serif;
    font-size: 110px;
    text-align: center;
  }

  &__end-time {
    margin-top: var(--spacing-4);
    display: flex;

    .texts {
      display: flex;
      flex-direction: column;

      &__start {
        margin-bottom: var(--spacing-1);
      }
    }

    i {
      margin-right: var(--spacing-2);
      font-size: var(--font-size-lg);
      color: var(--yellow-500);
      width: var(--width-5);
    }
  }

  &__security-setting {
    margin-top: var(--spacing-4);
    display: flex;
    align-items: center;

    i {
      margin-right: var(--spacing-2);
      font-size: var(--font-size-lg);
      width: var(--width-5);
    }
  }
}

.vl {
  border-left: 1px solid var(--gray-600);
  margin-left: var(--spacing-2);
  margin-right: var(--spacing-2);
}

