
































































@import 'Less/variables.less';

.colorPicker {
  &__dot {
    display: inline-block;
    height: @spacing-5;
    width: @spacing-4;
    border-radius: @radius-xs;
    cursor: pointer;
    margin-right: @spacing-2;
    transition: @animation;
  }
}

.selected {
  width: @spacing-10;
}
