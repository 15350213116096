






































































































































































































.loader {
  transform: scale(0.8);
}
