




















































































































































































































































































































































































































































































































































































































































































































































.profile {
  margin-bottom: 50px;

  div {
    width: 100%;
  }
}

.info {
  font-size: 30px;
  font-weight: 100;
}

.smaller {
  font-size: 20px;
}

.small {
  font-size: 16px;
}

.hr {
  margin: 10px;
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid black;
}

input {
  margin-top: 3px;
}

.verified {
  margin-top: 10px;
  color: green;
  font-size: 12px;
}

.warning {
  margin-top: 10px;
  color: orange;
  font-size: 12px;
}

.error {
  font-size: 12px;
  margin-top: 10px;
  color: red;
}

.unverified {
  font-size: 12px;
  margin-top: 10px;
  color: red;
}

.emphasized {
  font-weight: bold;
}
