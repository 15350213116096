





















.GradingCommentIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--width-6);
  height: var(--height-6);
  border-radius: var(--radius-round);
  margin-right: var(--spacing-2);

  * {
    display: flex;
    justify-content: center;
    justify-items: center;
  }

  &--student {
    padding-left: 1px; // The student-cap icon is a bit off center, poor fix but works for now
    background-color: var(--yellow-500)
  }

  &--teacher {
    background-color: var(--blue-900)
  }
}
