
























































.ExamBuilderPrintEmpty {
  position: relative;
  width: 100%;

  &__question {
    margin-bottom: 50px;
    page-break-inside: avoid;

    &__subQuestion {
      margin-bottom: 10px;
    }
  }
}

.EBPE__question__subQuestion {
  page-break-inside: avoid;
}
