

































































































.ShareExamTemplate {
  text-align: center;
  margin: auto;

  &__exam-name {
    font-size: var(--font-size-lg);
    font-weight: bold;
    margin: var(--spacing-5);
  }

  &__information {
    font-size: var(--font-size-md);
  }

  &__create-button {
    margin: 20px;
  }

  &__link {
    color: var(--primary-color);
    margin-top: var(--spacing-5);
    font-size: var(--font-size-md);
    text-decoration: underline;
    cursor: pointer;
  }
}
