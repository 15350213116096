

































































































































































.loginBtn {
  cursor: pointer;
  display: flex;
  width: 240px;
  margin: auto;
  height: 41px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-family: 'Open Sans', Arial, sans-serif;
  background: #4285f4;
  border: 1px solid #4285f4;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-text {
    flex: 1;
    color: white;
    font-size: 14px;
  }

  &.microsoft {
    border: none;
    border-radius: 0;
    background: #2f2f2f;

    .icon {
      margin-left: 12px;
    }

    .login-text {
      text-align: center;
    }
  }

  &.google {
    .icon {
      width: 43px;
      height: 100%;
      background: white;
    }
  }

  &.skolfederation {
    background: white;
    border: 1px solid #ff7900;
    border-radius: 2px;

    .login-text {
      color: black;
      text-align: center;
    }

    .icon {
      margin-left: 6px;

      img {
        width: 30px;
      }
    }
  }

  .sso-spinner {
    font-size: 16px;
    margin-right: 10px;
    color: white;
  }
}
