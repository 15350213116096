

















































































































































































.write-content {
  z-index: 9999;
  height: 100vh;
  background: rgba(0, 0, 0, 30%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;

  &__body {
    height: auto;
    top: 10px;
    bottom: 60px;
    background: #f9f9f9;
    width: 900px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 20%);
    max-width: 100%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    z-index: 1;

    @media (max-width: 900px) {
      top: 0;
    }
  }

  &__footer {
    background: transparent;
    position: absolute;
    bottom: 0;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;

    &__wrapper {
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 12%);
      display: flex;
      flex-wrap: nowrap;
      width: 900px;
      max-width: 100%;
      padding: 0 15px;
      flex-direction: row;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &__group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
