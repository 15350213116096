





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.open-close-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.discoverable {
  cursor: pointer;
  margin-top: 5px;
  font-size: 12px;
  color: var(--gray-700);
}

.exam-list-main {
  position: relative;
  background-color: #fff;
  margin-bottom: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
}

.exam-list-header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 105%; //to avoid ellipsis when the text is fitted
  font-weight: 300;
  font-size: 30px; //original font size set to max
  &--wrapper {
    max-width: 65%;
    line-height: normal;
  }
}

.exam-list-info {
  margin: @spacing-4;
}

.exam-list-header {
  height: 60px;
  background-color: rgb(31, 50, 92);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-list-move {
  transition: transform 0.4s;
}

.borderless td,
.borderless th {
  border: none;
}

.row-border {
  border-bottom: 1px solid #eee;
}

.clickable-header {
  cursor: pointer;
}

.highlightStyle {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
  border-left: 5px solid @theme-blue;
}

.exam-name {
  margin-left: 0;
}

.exam-key-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.exam-key-link {
  text-decoration: none;
  text-transform: none;
  padding: 8px 14px;
  font-size: 17px;
  background-color: #eee;
  border-radius: 5px;
  border: none;
  color: #000;
  font-family: Verdana, sans-serif;
  font-weight: 100;
}

.exam-key-link:hover {
  background-color: @theme-blue;
  color: #fff;
}

.exam-key-settings {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 3px;
  width: auto;
  overflow: visible;
}

.table-icon {
  width: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: relative;
  }
}

.info {
  color: green;
}

span.examKey {
  font-family: Verdana, sans-serif;

  &--blurred {
    filter: blur(10px);
  }
}

.flex {
  display: flex;
  flex-direction: column;
}

.small {
  font-size: 10px;
}

tr .exam-key-settings i {
  opacity: 0;
}

tr:hover .exam-key-settings i {
  opacity: 1;
}

.willBeArchived {
  cursor: pointer;
}

.colorMark {
  margin-right: @spacing-3;
}

.teams {
  margin-left: -15px;
  margin-right: 5px;
}

.lowerOpacity {
  opacity: 0.7;
}

tr:hover .fullOpacityOnHover {
  opacity: 1;
}

.exam-table {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  &__checkbox {
    font-size: @font-size-default;
  }

  &__column {
    &__header {
      color: @color-info-dark;
      background: #fff;
      border-bottom: 2px solid @color-grey;
    }
  }

  &__header {
    color: #fff;
    font-weight: var(--font-semibold);

    @media only screen and (max-width: 970px) {
      display: none;
    }
  }

  &__body {
    display: flex;
    flex-flow: column nowrap;

    .exam-key-settings i {
      opacity: 0;
    }

    .hover {
      .exam-key-settings i {
        opacity: 1;
      }

      div[role='row'] {
        background-color: #f5f5f5;
      }
    }
  }

  &--blurred {
    filter: blur(10px);
  }

  div[role='row'] {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    div[role='cell'] {
      display: flex;
      flex-flow: row nowrap;
      flex: 0 1 calc(100% / 8);
      padding: 8px;
      min-width: 0;
      box-sizing: border-box;
    }

    div[role='cell'].exam-table__col_0 {
      flex: 0 0 var(--spacing-11);
      padding-left: var(--spacing-5);

      @media only screen and (max-width: 970px) {
        display: none;
      }
    }

    div[role='cell'].exam-table__col_1 {
      flex: 0 0 250px;
      font-weight: var(--font-normal);
    }

    div[role='cell'].exam-table__col_2 {
      flex: 0 0 130px;
      font-weight: var(--font-normal);
    }

    div[role='cell'].exam-table__col_3 {
      justify-content: center;
      flex: 0 0 107px;
      font-weight: var(--font-normal);
    }

    div[role='cell'].exam-table__col_4 {
      justify-content: center;
      flex: 0 0 100px;
      align-items: center;
      font-weight: var(--font-normal);
    }

    div[role='cell'].exam-table__col_5 {
      justify-content: center;
      flex: 0 0 127px;
      font-weight: var(--font-normal);
    }

    div[role='cell'].exam-table__col_6 {
      justify-content: flex-end;
      flex: 1 0 140px;
    }

    div[role='cell'].exam-table__col_7 {
      justify-content: flex-end;
      flex: 0 0 36px;
      padding-right: 20px;
    }

    @media only screen and (max-width: 970px) {
      padding: 0 10px;
      flex-flow: row wrap;

      div[role='cell'].exam-table__col_1 {
        padding-left: 8px;
        font-weight: 600;
      }

      div[role='cell'].exam-table__col_2 {
        justify-content: flex-end;
        flex: 0 0 40%;

        .exam-key-settings i {
          opacity: 1;
        }
      }

      div[role='cell'].exam-table__col_3 {
        justify-content: flex-start;
        text-align: left;
        flex: 0 0 50%;
      }

      div[role='cell'].exam-table__col_4 {
        flex: 0 0 50%;
        justify-content: flex-end;
      }

      div[role='cell'].exam-table__col_5 {
        justify-content: flex-start;
        flex: 0 0 30%;
      }

      div[role='cell'].exam-table__col_6 {
        justify-content: flex-end;
        flex: 1 1 59%; // Give it 1% leeway
      }

      div[role='cell'].exam-table__col_7 {
        justify-content: flex-end;
        flex: 0 1 10%;
        padding-right: 18px;
      }
    }
  }
}

.share-info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.sorting-icon {
  margin-left: @spacing-2;
}

.googleClassroomIcon {
  margin-right: 2px;
}

