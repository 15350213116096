





















































@import 'Less/variables.less';

.pdf-viewer {
  height: calc(100% - var(--resource-toolbar-height));
  background-color: @color-grey-super-light;
}
