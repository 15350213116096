<template>
  <div style="display: flex">
    <div class="teacherChart">
      <!-- <canvas id="teacherYearComparison"></canvas>
    <canvas id="studentYearComparison"></canvas> -->

      <canvas id="teacherAccChart" />
      <canvas id="studentsAccChart" />

      <canvas id="studentsChart" />
      <canvas id="studentsDayChart" />

      <canvas id="teacherLoginChart" />
      <canvas id="teacherLoginDayChart" />

      <canvas id="teacherChart" />
      <canvas id="teacherDayChart" />

      <canvas id="examChart" />
      <canvas id="examDayChart" />
      <!-- </div> -->
      <!-- <div class="studentsChart"> -->
    </div>
  </div>
</template>

<script>
import MyChart from '../../admin/MyChart.js'

export default {
  props: {
    stats: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      teacher_chart: null,
      students_chart: null,
      teacher_acc_chart: null,
      students_acc_chart: null,
      teacher_day_chart: null,
      students_day_chart: null,
      teacher_login_chart: null,
      teacher_login_day_chart: null,
    }
  },
  computed: {
    aggregatedTeacherCount() {
      const teacherCount = []
      this.stats.reduceRight((acc, day) => {
        teacherCount.push({
          date: day.date,
          accCount: acc + Number(day.teacher_create),
        })

        return acc + Number(day.teacher_create)
      }, 0)

      return teacherCount
    },
    aggregatedStudentsCount() {
      const studentsCount = []
      this.stats.reduceRight((acc, day) => {
        studentsCount.push({
          date: day.date,
          accCount: acc + Number(day.student_login) + Number(day.student_km_login),
        })

        return acc + Number(day.student_login) + Number(day.student_km_login)
      }, 0)

      return studentsCount
    },
  },
  watch: {
    stats() {
      this.drawCharts()
    },
  },
  methods: {
    drawCharts() {
      const that = this

      this.teacherAccChart = MyChart.makeChart({
        id: 'teacherAccChart',
        chart: that.teacher_acc_chart,
        labels: that.aggregatedTeacherCount.map((el) => el.date),
        label: 'Antal registrerade lärare',
        data: that.aggregatedTeacherCount.map((el) => el.accCount),
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      })

      this.studentsAccChart = MyChart.makeChart({
        id: 'studentsAccChart',
        chart: that.students_acc_chart,
        labels: that.aggregatedStudentsCount.map((el) => el.date),
        label: 'Totalt antal provstarter som elev',
        data: that.aggregatedStudentsCount.map((el) => el.accCount),
        backgroundColor: 'rgba(255, 99, 132, 0.4)',
      })

      this.studentsChart = MyChart.makeChart({
        id: 'studentsChart',
        chart: that.students_chart,
        labels: that.stats.map((day) => day.date).reverse(),
        label: 'Antal provstarter som elev (7-dagarsmedel)',
        data: this.sevenDayAverage(that.stats.map((day) => +day.student_login + +day.student_km_login).reverse()),
        backgroundColor: 'rgba(40, 99, 200, 0.9)',
      })

      this.teacherChart = MyChart.makeChart({
        id: 'teacherChart',
        chart: that.teacher_chart,
        labels: that.stats.map((day) => day.date).reverse(),
        label: 'Antal nya lärare (7-dagarsmedel)',
        data: this.sevenDayAverage(that.stats.map((day) => day.teacher_create).reverse()),
        backgroundColor: 'rgba(100, 200, 60, 0.8)',
      })

      this.teacherDayChart = MyChart.makeChart({
        id: 'teacherDayChart',
        chart: that.teacher_day_chart,
        labels: that.stats.map((day) => day.date).reverse(),
        label: 'Antal nya lärare per dag',
        data: that.stats.map((day) => day.teacher_create).reverse(),
        backgroundColor: 'rgba(100, 200, 60, 0.3)',
      })

      this.studentsDayChart = MyChart.makeChart({
        id: 'studentsDayChart',
        chart: that.students_day_chart,
        labels: that.stats.map((day) => day.date).reverse(),
        label: 'Antal dagliga provstarter som elev',
        data: that.stats.map((day) => +day.student_login + +day.student_km_login).reverse(),
        backgroundColor: 'rgba(40, 99, 200, 0.3)',
      })

      this.teacherLoginDayChart = MyChart.makeChart({
        id: 'teacherLoginDayChart',
        chart: that.teacher_login_day_chart,
        labels: that.stats.map((day) => day.date).reverse(),
        label: 'Antal unika lärarinloggningar per dag',
        data: that.stats.map((day) => day.teacher_login).reverse(),
        backgroundColor: 'rgba(300, 200, 60, 0.4)',
      })

      this.teacherLoginChart = MyChart.makeChart({
        id: 'teacherLoginChart',
        chart: that.teacher_login_chart,
        labels: that.stats.map((day) => day.date).reverse(),
        label: 'Antal unika lärarinloggningar (7-dagarsmedel)',
        data: that.sevenDayAverage(that.stats.map((day) => day.teacher_login).reverse()),
        backgroundColor: 'rgba(300, 200, 60, 0.8)',
      })

      this.examDayChart = MyChart.makeChart({
        id: 'examDayChart',
        chart: that.exam_day_chart,
        labels: that.stats.map((day) => day.date).reverse(),
        label: 'Antal skapade prov per dag',
        data: that.stats.map((day) => day.exam_create).reverse(),
        backgroundColor: 'rgba(222, 73, 224, 0.2)',
      })

      this.examChart = MyChart.makeChart({
        id: 'examChart',
        chart: that.exam_chart,
        labels: that.stats.map((day) => day.date).reverse(),
        label: 'Antal skapade prov (7-dagarsmedel)',
        data: that.sevenDayAverage(that.stats.map((day) => day.exam_create).reverse()),
        backgroundColor: 'rgba(222, 73, 224, 0.8)',
      })

      // let teacher2019Averages = that.sevenDayAverage(
      //   that.stats.filter(day => day.date.slice(0, 4) === '2019')
      //     .map(day => day.teacher_login)
      //     .reverse()
      // )

      // let teacher2018Averages = that.sevenDayAverage(
      //   that.stats.filter(day => day.date.slice(0, 4) === '2018')
      //     .slice(-teacher2019Averages.length)
      //     .map(day => day.teacher_login)
      //     .reverse()
      // )

      // let teacherComparisons = teacher2019Averages.map((value, index) => {
      //   let previousValue = teacher2018Averages[index]
      //   return Math.min((value / previousValue) * 100 - 100, 300)
      // })

      // MyChart.makeChart({
      //   id: 'teacherYearComparison',
      //   labels: that.stats.filter(day => day.date.slice(0, 4) === '2019').map(day => day.date).reverse(),
      //   label: 'Föränding lärarinloggningar',
      //   data: teacherComparisons,
      //   backgroundColor: 'rgba(222, 73, 224, 0.8)',
      // })

      // let student2019Averages = that.sevenDayAverage(
      //   that.stats.filter(day => day.date.slice(0, 4) === '2019')
      //     .map(day => +day.student_login + +day.student_km_login)
      //     .reverse()
      // )

      // let student2018Averages = that.sevenDayAverage(
      //   that.stats.filter(day => day.date.slice(0, 4) === '2018')
      //     .slice(-student2019Averages.length)
      //     .map(day => +day.student_login + +day.student_km_login)
      //     .reverse()
      // )

      // let studentComparisons = student2019Averages.map((value, index) => {
      //   let previousValue = student2018Averages[index]
      //   return Math.min((value / previousValue) * 100 - 100, 300)
      // })

      // MyChart.makeChart({
      //   id: 'studentYearComparison',
      //   labels: that.stats.filter(day => day.date.slice(0, 4) === '2019').map(day => day.date).reverse(),
      //   label: 'Förändring elevstarter',
      //   data: studentComparisons,
      //   backgroundColor: 'rgba(222, 73, 224, 0.8)',
      // })
    },
    sevenDayAverage(list) {
      return list.map((el, index, list) => {
        let total = 0
        for (let i = index; i > index - 7; --i) {
          total += i > 0 ? Number(list[i]) : Number(list[0])
        }

        return total / 7
      })
    },
  },
}
</script>

<style scoped>
.teacherChart {
  flex: 1;
}

.studentsChart {
  flex: 1;
}
</style>
