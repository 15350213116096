<template>
  <div class="wrapper">
    <text-button
      style="margin-top: 10px; margin-bottom: 50px"
      title="Hämta offertdata"
      @click="load"
    />

    <div
      class="flex flex-col"
      style="margin: auto"
    >
      <div class="lg:-mx-8 mt-8 overflow-x-auto sm:-mx-6">
        <div class="align-middle inline-block lg:px-8 py-2 sm:px-6">
          <div class="border-b border-gray-200 overflow-hidden shadow sm:rounded-lg">
            <table class="divide-gray-200 divide-y">
              <thead>
                <tr>
                  <th
                    class="bg-gray-50
                      font-medium
                      leading-4
                      px-6
                      py-3
                      text-gray-500
                      text-left
                      text-xs
                      tracking-wider
                      uppercase"
                  >
                    Organisation
                  </th>
                  <th
                    class="bg-gray-50
                      font-medium
                      leading-4
                      px-6
                      py-3
                      text-gray-500
                      text-left
                      text-xs
                      tracking-wider
                      uppercase"
                  >
                    Skapad
                  </th>
                  <th
                    class="bg-gray-50
                      font-medium
                      leading-4
                      px-6
                      py-3
                      text-gray-500
                      text-left
                      text-xs
                      tracking-wider
                      uppercase"
                  >
                    Senast uppdaterad
                  </th>
                  <th
                    class="bg-gray-50
                      font-medium
                      leading-4
                      px-6
                      py-3
                      text-gray-500
                      text-left
                      text-xs
                      tracking-wider
                      uppercase"
                  >
                    Hanterad
                  </th>
                  <th class="bg-gray-50 px-6 py-3" />
                </tr>
              </thead>
              <tbody>
                <!-- Odd row -->
                <tr
                  v-for="(quote, index) in quotes"
                  :key="quote.id"
                  class="bg-white"
                  :class="{ 'bg-gray-50': index % 2 }"
                >
                  <td class="font-medium leading-5 px-6 py-4 text-gray-900 text-sm whitespace-no-wrap">
                    {{ name(quote.data) }}
                  </td>
                  <td class="leading-5 px-6 py-4 text-gray-500 text-sm whitespace-no-wrap">
                    <FormatDate :datetime="quote.created_at" />
                  </td>
                  <td class="leading-5 px-6 py-4 text-gray-500 text-sm whitespace-no-wrap">
                    <FormatDate :datetime="quote.updated_at" />
                  </td>
                  <td class="leading-5 px-6 py-4 text-gray-500 text-sm whitespace-no-wrap">
                    <FormatDate :datetime="quote.handled_at" />
                    <button
                      class="hover:text-indigo-900 text-indigo-600"
                      @click="markHandled(quote)"
                    >
                      Markera som hanterad nu
                    </button>
                  </td>
                  <td class="font-medium leading-5 px-6 py-4 text-right text-sm whitespace-no-wrap">
                    <a
                      target="_blank"
                      :href="`/quote-information/${quote.id}`"
                      class="hover:text-indigo-900 text-indigo-600"
                    >Visa</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormatDate from 'VueDesignElements/FormatDate.vue'
import axios from 'axios'

export default {
  components: { FormatDate },
  data() {
    return {
      quotes: [],
    }
  },
  methods: {
    name(data) {
      if (data.type === 'school-group') {
        return data.organisation
      }

      return data.university
    },
    markHandled(quote) {
      axios.post(`/quote-information/${quote.id}/handled`).then(() => {
        this.load()
      })
    },
    load() {
      this.quotes = []
      setTimeout(() => {
        axios({
          url: '/quote-information/all',
        }).then(({ data }) => {
          console.log(data)
          this.quotes = data.map((quote) => {
            return {
              id: quote.id,
              data: JSON.parse(quote.data),
              created_at: quote.created_at,
              updated_at: quote.updated_at,
              handled_at: quote.handled_at,
            }
          })
        })
      }, 300)
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  text-align: center;
}
</style>
