












































































































@import 'Less/variables.less';

.mainContainer {
  display: inline-flex;
  height: 30px;
  margin-top: 10px;
  width: 100%;
}

.inputContainer {
  position: relative;
  flex-grow: 1;
  height: 30px;
  font-size: 12px;
  text-align: left;
  color: #373737;
  background-color: #eee;
  padding-left: 10px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #d8d8d8;
  outline: none;
}

.warningStyle {
  color: #a94442;
  border-color: #ebcccc;
  background-color: #f2dede;
}

.successStyle {
  color: #3c763d;
  border-color: #d0e9c6;
  background-color: #dff0d8;
}

.disabledStyle {
  background-color: #d8d8d8 !important;
  color: #373737 !important;
}

.inputContainer:focus {
  border: 1px solid @theme-blue;
}

/* Button */
.primaryStyle {
  font-size: 14px;
  padding: 5px 40px;
  background-color: @theme-blue;
  text-decoration: bold;
  color: #fff;
  border-radius: 0 15px 15px 0;
  text-transform: capitalize;
  border: none;
  font-weight: 600;
}

.primaryStyle:hover {
  background-color: @theme-primary;
}

.animation {
  transition: all 0.2s ease-in-out;
}
