<template>
  <div class="infoContainer">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.infoContainer {
  position: relative;
  width: 100%;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  text-align: left;
  margin-top: 10px;
  color: #373737;
  background-color: #eee;
  padding-left: 10px;
  border-radius: 5px;
  outline: none;
}
</style>
