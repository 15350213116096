



























































































































































































































.all {
  .keys {
    flex: 1;
    padding: 10px;

    & > p {
      text-align: center;
    }
  }

  .instructions {
    flex: 2;
    padding: 20px;
  }
}

li {
  margin-bottom: 20px;
}
