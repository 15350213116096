































































































































.BaseInput {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input {
    width: 100%;
    min-height: var(--spacing-8);
    padding: var(--spacing-1);
    padding-right: var(--spacing-9);
    box-sizing: border-box;
    transition: var(--transition-all);
    background-color: transparent;
    border: none;
    outline: transparent;
  }

  &__button {
    position: absolute;
    right: 0;
  }
}
