




































































































































@import 'Less/variables.less';

.custom.vue-tags-input {
  max-width: 800px;
}

.vue-tags-input .input {
  border-radius: 10px;
}

.vue-tags-input .tag.valid {
  background: @theme-primary;
}

.vue-tags-input .item.valid.selected-item {
  background: @theme-primary;
}

.auto {
  margin: auto;
}
