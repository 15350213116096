<template>
  <div
    class="wrapper"
    :class="{ blur: status === 'loading' }"
  >
    <div style="display: flex">
      <div style="flex: 1">
        <h3>Sök organisation</h3>

        <div class="form-group">
          <vue-simple-suggest
            v-model="chosenSchoolDisplay"
            :maxSuggestions="20"
            style="height: 40px; font-size: 14px; margin-right: 15px"
            :list="organizationList"
            displayAttribute="display"
            :placeholder="__('Ange organizationsnummer')"
            :filterByQuery="true"
            @select="fetchOrganizationStatistics"
          />
        </div>
      </div>
    </div>

    <div class="stat">
      <div class="users">
        <table
          class="table table-striped"
          style="font-size: 10px"
        >
          <tr>
            <th>Namn &amp; logga in som</th>
            <th @click="sort('latest')">
              Senaste inlogg
            </th>
            <th @click="sort('logins')">
              Inlogg (60 d.)
            </th>
            <th @click="sort('logins')">
              Inlogg (Totalt)
            </th>
            <th @click="sort('exams')">
              Prov (60 d.)
            </th>
            <th @click="sort('exams')">
              Prov (Totalt)
            </th>
            <th @click="sort('students')">
              Elevstarter (60 d.)
            </th>
            <th @click="sort('students')">
              Elevstarter (Totalt)
            </th>
          </tr>

          <tr
            v-for="teacher in activeUsers"
            :key="teacher.id"
          >
            <td>
              <a :href="loginLink(teacher)">{{ teacher.firstName }} {{ teacher.lastName }}</a>
            </td>
            <td :class="loginColor(teacher.login_at || teacher.created_at)">
              {{ teacher.login_at }}
            </td>
            <td>{{ userStatisticsSixty(teacher, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsTotal(teacher, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsSixty(teacher, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsTotal(teacher, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsSixty(teacher, 'EXAM_STARTED') }}</td>
            <td>{{ userStatisticsTotal(teacher, 'EXAM_STARTED') }}</td>
          </tr>

          <tr
            v-for="teacher in inactiveUsers"
            :key="teacher.id"
          >
            <td style="color: red">
              ! <a :href="loginLink(teacher)">{{ teacher.firstName }} {{ teacher.lastName }}</a>
            </td>
            <td :class="loginColor(teacher.login_at || teacher.created_at)">
              {{ teacher.login_at }}
            </td>
            <td>{{ userStatisticsSixty(teacher, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsTotal(teacher, 'USER_LOGIN') }}</td>
            <td>{{ userStatisticsSixty(teacher, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsTotal(teacher, 'EXAM_CREATED') }}</td>
            <td>{{ userStatisticsSixty(teacher, 'EXAM_STARTED') }}</td>
            <td>{{ userStatisticsTotal(teacher, 'EXAM_STARTED') }}</td>
          </tr>
        </table>
      </div>
      <div class="schoolstat">
        <div v-if="ort">
          <h3>{{ ort }}</h3>
          <div class="schoolInfo">
            <b>{{ organizationStatisticsSixty('USER_JOINED') }} registrerade lärare senaste 60 dagarna</b>
            <br>({{ organizationStatisticsTotal('USER_JOINED') }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ organizationStatisticsSixty('EXAM_CREATED') }} skapade prov senaste 60 dagarna</b>
            <br>({{ organizationStatisticsTotal('EXAM_CREATED') }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ organizationStatisticsSixty('EXAM_STARTED') }} elevstarter senaste 60 dagarna</b>
            <br>({{ organizationStatisticsTotal('EXAM_STARTED') }} totalt)
          </div>
          <div>
            <div
              v-for="school in schoolsByTeachers"
              :key="school.id"
              class="clickable"
              @click="gotoSchool(school.id)"
            >
              {{ school.namn }} ({{ school.users }})
            </div>
          </div>
        </div>
      </div>
    </div>

    <canvas id="organizationStatisticsChart" />

    <div
      v-show="status === 'loading'"
      style="z-index: 9999; position: fixed; top: 300px; left: 40%"
    >
      <i
        style="font-size: 300px"
        class="fa fa-spin fa-spinner"
      />
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import MyChart from '../../admin/SchoolStatisticsChart.js'

export default {
  components: { VueSimpleSuggest },
  data() {
    return {
      aggregated: [],
      chosenSchoolDisplay: '',
      organizationList: [],
      organizationStatisticsChart: null,
      ort: null,
      ortToSearch: '',
      schools: [],
      status: 'done',
      users: [],
    }
  },
  computed: {
    activeUsers() {
      return this.users.filter((teacher) => {
        return this.school_ids.includes(teacher.school_id)
      }, this)
    },

    inactiveUsers() {
      return this.users.filter((teacher) => {
        return !this.school_ids.includes(teacher.school_id)
      }, this)
    },

    schoolsByTeachers() {
      let schools = []
      const schoolHasTeachers = {}
      this.users.forEach((teacher) => {
        if (schoolHasTeachers[teacher.school_id] === undefined) {
          schoolHasTeachers[teacher.school_id] = 1
        } else {
          schoolHasTeachers[teacher.school_id] += 1
        }
      })
      schools = this.schools
      schools.forEach((school) => {
        if (schoolHasTeachers[school.id] === undefined) {
          school.users = 0
        } else {
          school.users = schoolHasTeachers[school.id]
        }
      })
      schools.sort((a, b) => a.users > b.users)

      return schools
    },
  },

  mounted() {
    this.preloadOrganizations()
  },
  updated() {
    if (this.$root.organization != null) {
      this.fetchOrganizationStatistics(this.$root.organization)
      this.$root.organization = null
    }
  },
  methods: {
    preloadOrganizations() {
      window.$.ajax({
        url: '/api/site-admin/statistics/organization',
        success: (data) => {
          data.forEach((organization) => {
            organization.id = `${organization.orgnummer}`
            organization.display = `${organization.orgnummer}`
          })

          this.organizationList = data
          this.organizationList.sort((a, b) => a.orgnummer.localeCompare(b.ort))
        },
      })
    },
    sort(column) {
      if (['logins', 'exams', 'students'].includes(column)) {
        column = `num_${column}`

        this.users.sort((a, b) => {
          if (a.stat.length === 0) {
            return 1
          }

          if (b.stat.length === 0) {
            return -1
          }

          if (b.stat[0][column] - a.stat[0][column] === 0) {
            return b.firstName.localeCompare(a.firstName)
          }

          return b.stat[0][column] - a.stat[0][column]
        })
      }

      if (column === 'latest') {
        this.users.sort((a, b) => (a.login_at === b.login_at ? 0 : a.login_at < b.login_at ? 1 : -1))
      }
    },

    userStatisticsSixty(teacher, statistics) {
      return this.aggregated.reduce((acc, i) => {
        if (i.user_id === teacher.id && i.group === 'sixty' && i.type === statistics) {
          return acc + i.value
        }

        return acc
      }, 0)
    },

    userStatisticsTotal(teacher, statistics) {
      return this.aggregated.reduce((acc, i) => {
        if (i.user_id === teacher.id && i.group === 'total' && i.type === statistics) {
          return acc + i.value
        }

        return acc
      }, 0)
    },

    organizationStatisticsSixty(statistics) {
      return this.aggregated.reduce((acc, i) => {
        if (i.group === 'sixty' && i.type === statistics) {
          return acc + i.value
        }

        return acc
      }, 0)
    },

    organizationStatisticsTotal(statistics) {
      return this.aggregated.reduce((acc, i) => {
        if (i.group === 'total' && i.type === statistics) {
          return acc + i.value
        }

        return acc
      }, 0)
    },

    loginColor(datetime) {
      const time = this.getTime(datetime)

      if (time.daysAgo < 30) {
        return 'login-green'
      }

      if (time.daysAgo < 90) {
        return 'login-orange'
      }

      return 'login-red'
    },

    getTime(datetime) {
      const date = new Date(`${datetime.substring(0, 19).replace(/-/g, '/')} UTC`)
      const localeString = new Date(`${datetime.substring(0, 19).replace(/-/g, '/')} UTC`).toLocaleString()

      const diff = new Date() - date
      const secondsAgo = Math.floor(diff / 1000)
      const minutesAgo = Math.floor(secondsAgo / 60)
      const hoursAgo = Math.floor(minutesAgo / 60)
      const daysAgo = Math.floor(hoursAgo / 24)

      return {
        toString() {
          return localeString
        },
        secondsAgo,
        minutesAgo,
        hoursAgo,
        daysAgo,
      }
    },

    loginLink(teacher) {
      return `/admin/impersonate/${teacher.email}`
    },

    gotoSchool(school) {
      this.$root.school = school
      this.$root.choice = 'Skolstat'
      this.$root.$children.forEach((child) => {
        child.$forceUpdate()
      })
    },

    fetchOrganizationStatistics(organization) {
      this.status = 'loading'

      if (typeof organization === 'object') {
        organization = organization.id
      }

      this.ort = organization

      fetch(`/api/site-admin/statistics/organization/${organization}`, { credentials: 'include' })
        .then((data) => data.json())
        .then((data) => {
          this.aggregated = data.aggregated
          this.users = data.users
          this.users.sort((a, b) => a.firstName.localeCompare(b.firstName))
          this.schools = data.schools
          this.school_ids = data.schools.map((school) => {
            return school.id
          })
          this.status = 'done'

          this.organizationStatisticsChart = MyChart.makeChart({
            id: 'organizationStatisticsChart',
            chart: this.organizationStatisticsChart,
            data: data.statistics,
          })
        })
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  text-align: center;
}

.stat {
  display: flex;
  text-align: left;
}

.users {
  flex: 2;
}

.schoolstat {
  flex: 1;
  border: 1px solid #ccc;
  text-align: center;
  box-shadow: 5px 5px 5px #00000085;
  margin: 0 15px;
}

td {
  border-bottom: 1px #ddd solid;
  padding-left: 10px;
}

tr:nth-child(even) {
  background: #ddd;
}

tr:nth-child(odd) {
  background: #eee;
}

td.login-green {
  color: green;
}

td.login-orange {
  color: orange;
}

td.login-red {
  color: red;
}

.schoolInfo {
  margin: 20px;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.blur {
  filter: blur(10px);
}
</style>
