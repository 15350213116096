<template>
  <div>
    <study-attachments :geogebraType="geogebraType ? geogebraType : null" />

    <exam-modal
      id="overrideAnswerModal"
      size="sm"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Återställ till historisk punkt') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        {{
          __(
            'Är du säker på att du vill använda denna tidpunkt som elevens svar? Elevens nuvarande svar kommer placeras överst i historiken, så att du kan återställa till den senare om du önskar.',
          )
        }}
      </div>
      <div
        slot="footer"
        class="popup-footer"
      >
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Nej')"
        />
        <text-button
          mode="primary"
          data-dismiss="modal"
          :title="__('Återställ')"
          @click="overrideAnswer"
        />
      </div>
    </exam-modal>

    <exam-modal
      id="wordIndividualModal"
      size="md"
    >
      <div slot="title">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
        <h3
          id="activate-modal-title"
          class="modal-title text-center"
        >
          {{ __('Ladda hem word-filer') }}
        </h3>
      </div>

      <div
        slot="body"
        class="text-center"
      >
        <p
          v-if="isBuilder"
          style="color: red"
        >
          {{
            __(
              'In the case of auto-marked exams, the files will only contain text-based student answers, and not the complete exam. To get the complete exam, use the PDF export or printing feature.',
            )
          }}
        </p>
        <p>
          {{
            __(
              'Detta kommer ladda ner Word-filer direkt till din dator. De hamnar i ordinare mapp för "Nedladdade filer". En fil för varje elev skapas.',
            )
          }}
        </p>
      </div>

      <div
        slot="footer"
        class="popup-footer"
        style="width: 300px; margin: auto"
      >
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Stäng')"
        />
        <text-button
          mode="primary"
          data-dismiss="modal"
          :title="__('Ladda ner')"
          @click="downloadWord"
        />
      </div>
    </exam-modal>

    <exam-modal
      ref="openPartModal"
      size="sm"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Är du säker?') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <p>
          {{ __('Är du säker på att du vill öppna upp en tidigare provdel för eleven?') }}
        </p>
        <p>
          {{ __('Eleven måste vara inne i provet för att detta ska kunna genomföras') }}
        </p>
      </div>
      <div
        slot="footer"
        class="openPartModal__footer"
      >
        <text-button
          class="openPartModal__button"
          :title="__('Nej')"
          mode="secondary"
          data-dismiss="modal"
        />

        <text-button
          class="openPartModal__button"
          :title="__('Ja')"
          data-dismiss="modal"
          @click="openPart"
        />
      </div>
    </exam-modal>

    <exam-modal
      ref="deleteStudentModal"
      size="sm"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Radera elev') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <p>
          {{ __('Är du helt säker på att du vill radera eleven? Eleven och all dess information kommer att raderas.') }}
        </p>
        <p>{{ __('Bekräfta detta genom att skriva "radera" i fältet nedan.') }}</p>
        <el-input
          v-model="deleteConfirmation"
          :placeholder="__('Skriv radera')"
        />
      </div>

      <div
        slot="footer"
        class="popup-footer"
      >
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Nej')"
        />
        <text-button
          mode="primary"
          :title="__('Radera')"
          @click="deleteStudent"
        />
      </div>
    </exam-modal>

    <exam-modal
      ref="shareViaEmail"
      size="sm"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Skicka kopia till elev') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <div v-if="isBuilder">
          <p v-if="student && student.email && student.email.length > 0">
            {{
              __('Detta kommer att skicka en länk till eleven som kan användas för att se provsvaren samt rättningen.')
            }}
          </p>
          <p v-else>
            {{
              __(
                'Eftersom eleven inte har angivit epost kan länken inte skickas automatiskt, men du kan fortfarande skicka den manuellt.',
              )
            }}
          </p>
          <p>
            {{ __('Välj här hur länge länken ska vara giltig.') }}
          </p>
          <div style="display: flex">
            <el-input
              v-model="link.expirationNumber"
              style="width: 50px; margin: 0; margin-right: 10px"
            />
            <el-dropdown
              v-model="link.expirationUnit"
              style="margin: 0"
              :options="link.expirationUnitOptions"
            />
          </div>
        </div>
        <div v-else>
          <p>
            {{
              __(
                'Detta kommer skicka en kopia av elevens text samt eventuella bilder, GeoGebra/Desmos-konstruktioner samt programkod som ett epost till eleven.',
              )
            }}
          </p>
        </div>
      </div>

      <div
        slot="footer"
        class="popup-footer"
        style="flex-direction: column"
      >
        <div style="display: flex; margin-bottom: 10px">
          <text-button
            v-if="isBuilder"
            :style="student && student.email && student.email.length > 0 ? 'margin-right: 10px' : ''"
            mode="secondary"
            :title="__('Öppna')"
            @click="openLink"
          />
          <text-button
            v-if="student && student.email && student.email.length > 0"
            mode="primary"
            :title="__('Skicka')"
            @click="shareViaEmail"
          />
        </div>
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Stäng')"
        />
      </div>
    </exam-modal>

    <exam-modal
      ref="throwOutStudentModal"
      size="sm"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Avsluta elevens prov') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        {{ __('Elevens prov kommer avslutas och lämnas in. Är du säker?') }}
      </div>
      <div
        slot="footer"
        class="popup-footer"
      >
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Nej')"
        />
        <text-button
          mode="primary"
          data-dismiss="modal"
          :title="__('Ja, avsluta')"
          @click="throwOutStudent"
        />
      </div>
    </exam-modal>

    <exam-modal
      ref="sendOneIndividualModal"
      size="sm"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Skicka individuell provnyckel via epost.') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        {{ __('Eleven kan återuppta provet med denna provnyckel. Är du säker på att du vill skicka?') }}
      </div>
      <div
        slot="footer"
        class="popup-footer"
      >
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Stäng')"
        />
        <text-button
          mode="primary"
          data-dismiss="modal"
          :title="__('Skicka')"
          @click="sendIndividualResumeEmail"
        />
      </div>
    </exam-modal>

    <exam-modal
      ref="missingConnectionInfoModal"
      size="md"
      :lessRounded="true"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Eleven saknar anslutning till Exam.net') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <p>
          {{
            __(
              'Exam.net har för tillfället inte kontakt med eleven, men eleven kan fortsätta skriva som vanligt. När anslutning återupprättas kommer elevens text synkas.',
            )
          }}
        </p>
        <p>
          {{
            __(
              'Om anslutning fortfarande skulle saknas när det är dags att lämna in kommer eleven få information om hur man kan hantera det.',
            )
          }}
        </p>
        <p v-if="student && site === 'exam'">
          {{
            __(
              'Om eleven behöver starta om, exempelvis genom att byta enhet, så kan följande kod användas för att fortsätta provet',
            )
          }}:
        </p>
        <h3 v-if="student && site === 'exam'">
          {{ exam.examkey }}#{{ student.resume_pin }}
        </h3>
      </div>
      <div slot="footer">
        <text-button
          data-dismiss="modal"
          title="Ok"
        />
      </div>
    </exam-modal>

    <exam-modal
      ref="wordModal"
      size="sm"
    >
      <h3 slot="title">
        {{ __('Bygger Word-dokument') }}
      </h3>
      <div
        slot="body"
        class="text-align"
      >
        <exam-loader />
        <h4 class="text-center">
          {{ __('Word-filen kommer strax laddas ner.') }}
        </h4>
      </div>
    </exam-modal>

    <exam-modal
      ref="pdfModal"
      size="sm"
    >
      <h3 slot="title">
        {{ __('Bygger PDF-dokument') }}
      </h3>
      <div
        slot="body"
        class="text-align"
      >
        <exam-loader />
      </div>
    </exam-modal>

    <exam-modal
      ref="throwingOutModal"
      size="sm"
    >
      <h3 slot="title">
        {{ __('Avslutar elevens prov') }}
      </h3>
      <div
        slot="body"
        class="text-align"
      >
        <exam-loader />
      </div>
    </exam-modal>

    <exam-modal
      ref="unusedImagesModal"
      size="sm"
    >
      <h3
        slot="title"
        class="modal-title"
      >
        {{ __('Oanvända bilder') }}
      </h3>
      <div
        slot="body"
        class="text-center"
      >
        <div style="margin-bottom: 10px">
          {{
            __('Följande bilder är skapade av eleven men inte bifogade elevsvaret. Klicka för att öppna och se dessa.')
          }}
        </div>
        <div
          v-for="(unusedImage, index) in unusedImages"
          :key="unusedImage.url"
        >
          <a
            target="_blank"
            :href="unusedImage.url"
          >{{ __('Bild') }} {{ index + 1 }}</a>
          <span style="margin-left: 10px;">(<FormatDate :datetime="unusedImage.created_at" />)</span>
        </div>
      </div>
      <div
        slot="footer"
        class="popup-footer"
      >
        <text-button
          mode="secondary"
          data-dismiss="modal"
          :title="__('Stäng')"
        />
      </div>
    </exam-modal>

    <h1 style="text-align: left; margin-top: 0">
      {{ Student.displayName(student) }}
    </h1>
    <div
      v-if="isExam"
      style="margin-bottom: 10px"
    >
      <div v-if="studentTeacher">
        <b>{{ __('Undervisande lärare') }}:</b> {{ studentTeacher }}
        <br>
      </div>
      <div v-if="studentClass">
        <b>{{ __('Klass') }}:</b> {{ studentClass }}
        <br>
      </div>
      <div v-if="studentPhone">
        <b>{{ __('Telefonnummer') }}:</b> {{ studentPhone }}
        <br>
      </div>
      <div v-if="student && (examAsksForEmail || studentEmail) && (exam.anonymous == 0 || surveillanceMode)">
        <div
          v-if="editEmail"
          style="display: flex; align-items: center"
        >
          <el-input
            v-model="newEmail"
            style="width: 200px; margin: 0; margin-right: 10px"
          />
          <text-button
            :title="__('Spara')"
            @click="saveStudentEmail"
          />
        </div>
        <div v-else>
          {{ studentEmail || __('Ingen epost angiven') }}
          <span
            v-if="student.is_demo == 0"
            style="margin-left: 50px; cursor: pointer"
          >
            <i
              v-tooltip="__('Redigera elevens epost.')"
              class="fa fa-pencil"
              @click="
                editEmail = true
                newEmail = student.email
              "
            />&nbsp;
            <i
              v-if="studentEmail"
              v-tooltip="__('Skicka individuell provnyckel via epost.')"
              class="fa fa-paper-plane-o"
              @click="Modal.show($refs.sendOneIndividualModal)"
            />
          </span>
        </div>
      </div>
    </div>

    <exam-loader v-show="status === 'fetching'" />
    <el-tab
      v-show="status === 'fetched'"
      @input="handleTabClick"
    >
      <el-tab-item :title="__('Status och åtgärder')">
        <div style="display: flex">
          <div style="flex: 2; margin-right: 10px; margin-top: 10px">
            <div
              v-if="site === 'km' && hasDoneParts()"
              style="margin-bottom: 10px"
            >
              <span
                v-for="(part, index) in partsDone"
                :key="index"
                style="padding: 2px"
              >
                <text-button
                  v-if="index === partsDone.length - 1"
                  :title="`Eleven är på del ${partLetter(index)}`"
                  mode="secondary"
                  disabled
                />
                <text-button
                  v-else
                  :title="`Öppna upp från del ${partLetter(index)}`"
                  mode="secondary"
                  @click="openPartConfirm(index)"
                />
              </span>
            </div>

            <status-card
              v-if="student && Student.isHandedIn(student) && !Student.isHandedInOnlyByTeacher(student)"
              success
              icon="check-circle-o"
            >
              {{ __('Eleven har lämnat in provet') }}
            </status-card>
            <status-card
              v-else-if="student && Student.isHandedInOnlyByTeacher(student)"
              v-tooltip="{
                content:__(
                  'Detta prov har inte lämnats in på ordinarie sätt. Detta kan bero på att eleven var offline när provet avslutades av läraren. Kontrollera i loggen till höger att inlämning gjorts, eller att senaste elevprestationen är synkroniserad.',
                ),
                theme: 'tooltipClickable',
              }"
              warning
              icon="check-circle-o"
            >
              {{ __('Provet är avslutat') }}.
            </status-card>
            <div
              v-else-if="student && Student.hasConnectionError(student)"
              class="notification-banner-danger"
              style="padding: 10px; cursor: pointer"
              @click="Modal.show($refs.missingConnectionInfoModal)"
            >
              {{ __('Eleven saknar anslutning eller har avbrutit provet.') }}
            </div>
            <div
              v-else-if="student && Student.isLockedOut(student) && !studentCanUnlockThemselves"
              class="notification-banner-danger"
              style="padding: 10px; cursor: pointer"
              @click="unlockStudent"
            >
              <span v-if="sendingUnlock">{{ __('Skickar upplåsning ...') }}</span>
              <span v-else>{{ __('Eleven är utelåst. Klicka här för att låsa upp.') }}</span>
            </div>
            <div
              v-else-if="student && Student.isAborted(student) && !isExam"
              class="notification-banner-warning"
              style="padding: 10px"
            >
              <span>{{
                __(
                  'Elevdatan är sparad men provet är inte slutinlämnat och rättat. Du kan lämna in åt eleven med knappen nedan, förutsatt att provet är klart. Om eleven ska fortsätt vid nästa tillfälle ska du inte lämna in provet.',
                )
              }}</span>
            </div>
            <div
              v-else-if="student"
              class="notification-banner-success"
              style="padding: 10px"
            >
              {{ __('Eleven skriver och har anslutning till provet') }}
            </div>

            <div style="margin: 20px 0">
              <div
                v-if="student && isExam"
                style="margin-bottom: 15px"
              >
                <b>{{ exam.examkey }}#{{ student.resume_pin }}</b><br>
                <i style="font-size: 12px; margin-bottom: 15px">{{ __('Individuell provnyckel för återupptag') }}</i>
              </div>

              <el-inline>
                <div
                  v-if="ip"
                  style="margin-bottom: 15px; width: 50%"
                >
                  <b>{{ ip }}</b><br>
                  <i style="font-size: 12px">{{ __('IP-adress') }}</i>
                </div>

                <div
                  v-if="os"
                  style="margin-bottom: 15px; width: 50%"
                >
                  <b>{{ os }}</b><br>
                  <i style="font-size: 12px">{{ __('Operativsystem') }}</i>
                </div>
                <status-card
                  v-if="showSEBIOSWarning"
                  danger
                  icon="exclamation-triangle"
                >
                  For iOS devices we recommend to use our iOS app instead of Safe Exam Browser. During 2022, the support
                  for SEB for iOS at Exam.net will end. Please visit
                  <a
                    target="_blank"
                    href="https://support.exam.net/#/high-security"
                  >
                    this link to read more and install
                  </a>
                  the Exam.net iOS app.
                </status-card>

                <status-card
                  v-if="showSEBMacOSWarning"
                  danger
                  icon="exclamation-triangle"
                >
                  For macOS devices, we recommend to use our native app instead of Safe Exam Browser.
                  During 2022, the support for SEB for macOS at Exam.net will end. Please visit
                  <a
                    target="_blank"
                    href="https://support.exam.net/#/high-security?id=macos"
                  >
                    this link to read more and install
                  </a>
                  the Exam.net native app.
                </status-card>

                <status-card
                  v-if="showOldSEBWarning"
                  danger
                  icon="exclamation-triangle"
                >
                  This student is using an old version of Safe Exam Browser. Please update to the latest version.
                  Support for SEB 2.x will end on 2022-06-17.
                  <a
                    target="_blank"
                    href="https://support.exam.net/#/high-security?id=windows"
                  >
                    See installation instructions.
                  </a>
                </status-card>
              </el-inline>
            </div>
          </div>
          <div style="flex: 1">
            <ExamStudentLog :studentLogs="student ? student.student_logs : []" />
          </div>
        </div>

        <div style="padding: 20px; padding-top: 0">
          <div
            v-if="isExam && !hiddenAnonymousMode"
            style="display: flex; margin-top: 20px"
          >
            <action-card
              :description="__('Skriv ut elevsvar')"
              icon="print"
              @click="print"
            />
            <action-card
              :description="__('Hämta Word-fil')"
              icon="file-word-o"
              @click="Modal.show('wordIndividualModal')"
            />
            <action-card
              :description="__('Hämta PDF-fil')"
              icon="file-pdf-o"
              @click="headlessPDF"
            />
          </div>

          <div
            v-if="isExam && !hiddenAnonymousMode"
            style="display: flex; margin-top: 10px"
          >
            <action-card
              description="Google Drive"
              @click="googleDrive"
            >
              <img
                class="print-icon"
                src="https://static.exam.net/myicons/drive_icon.png"
                alt="Google Drive logo"
              >
            </action-card>

            <action-card
              description="OneDrive"
              @click="oneDrive"
            >
              <img
                class="print-icon"
                src="https://static.exam.net/myicons/onedrive.gif"
                alt="Onedrive logo"
              >
            </action-card>

            <action-card
              v-if="!isBuilder"
              :description="__('Ladda ner elevsvar')"
              icon="download"
              @click="downloadArchive"
            />

            <action-card
              v-if="student && student.is_demo == 0 && (isBuilder || student.email)"
              :description="__('Skicka kopia till elev')"
              icon="copy"
              @click="Modal.show($refs.shareViaEmail)"
            />
          </div>

          <div style="display: flex; margin-top: 10px">
            <action-card
              :danger="true"
              :description="__('Avsluta provet för denna elev')"
              @click="Modal.show($refs.throwOutStudentModal)"
            >
              <img
                class="print-icon"
                src="https://static.exam.net/myicons/exit.svg"
              >
            </action-card>

            <action-card
              v-if="isExam && !isMarked && student"
              :description="__('Ge eleven en markering')"
              icon="flag-checkered"
              @click="mark"
            />
            <action-card
              v-if="isExam && isMarked && student"
              :danger="true"
              :description="__('Ta bort markering')"
              icon="flag-checkered"
              @click="unmark"
            />

            <action-card
              v-if="isExam"
              :danger="true"
              :description="__('Radera eleven')"
              icon="trash-o"
              @click="Modal.show($refs.deleteStudentModal)"
            />
          </div>
        </div>
      </el-tab-item>

      <el-tab-item
        v-if="isExam"
        :title="__('Elevsvar och historik')"
      >
        <div
          v-if="hiddenAnonymousMode"
          style="text-align: center"
        >
          <h1>{{ __('Provet är avidentifierat') }}</h1>
          <div v-if="!isBuilder">
            <h3 v-if="wordCount === 0">
              {{ __('Eleven har inte skrivit något.') }}
            </h3>
            <h3 v-else-if="wordCount < 20">
              {{ __('Eleven har skrivit färre än 20 ord.') }}
            </h3>
            <h3 v-else-if="wordCount < 50">
              {{ __('Eleven har skrivit 20 – 50 ord.') }}
            </h3>
            <h3 v-else-if="wordCount < 100">
              {{ __('Eleven har skrivit 50 – 100 ord.') }}
            </h3>
            <h3 v-else-if="wordCount < 200">
              {{ __('Eleven har skrivit 100 – 200 ord.') }}
            </h3>
            <h3 v-else-if="wordCount < 400">
              {{ __('Eleven har skrivit 200 – 400 ord.') }}
            </h3>
            <h3 v-else-if="wordCount < 800">
              {{ __('Eleven har skrivit 400 – 800 ord.') }}
            </h3>
            <h3 v-else-if="wordCount < 1500">
              {{ __('Eleven har skrivit 800 – 1500 ord.') }}
            </h3>
            <h3 v-else>
              {{ __('Eleven har skrivit mer än 1500 ord.') }}
            </h3>
          </div>
        </div>
        <div v-else>
          <div class="previews">
            <div
              v-for="attachment in geogebras"
              :key="attachment.identifier"
            >
              <attachment-preview
                type="GEOGEBRA"
                :name="attachment.name"
                :readonly="true"
                @click="studyAttachment('GEOGEBRA', attachment)"
              />
            </div>

            <div
              v-for="attachment in desmosConstructions"
              :key="attachment.identifier"
            >
              <attachment-preview
                type="DESMOS"
                :name="attachment.name"
                :readonly="true"
                @click="studyAttachment('DESMOS', attachment)"
              />
            </div>

            <div
              v-for="attachment in programmingPrograms"
              :key="attachment.identifier"
            >
              <attachment-preview
                type="PROGRAMMING"
                :name="attachment.name"
                :readonly="true"
                @click="studyAttachment('PROGRAMMING', attachment)"
              />
            </div>

            <div
              v-for="audioRecording in audioRecordings"
              :key="audioRecording.id"
            >
              <attachment-preview
                type="AUDIO"
                :name="audioRecording.data.title"
                :readonly="true"
                @click="openAudio(audioRecording)"
              />
            </div>
          </div>

          <div
            style="display: flex"
            class="fr-view student-answer student-answer-area"
          >
            <ExamBuilderGrading
              v-if="examContentChoice === 'builder' && student"
              class="student-answer__content"
              :content="exam.content"
              :student="currentBuilderStudent"
              :studentName="Student.displayName(student)"
            />
            <div
              v-else
              class="student-answer__content student-answer__content--overflow-auto"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-show="backupAnswer(chosenBackup).length === 0"
                ref="studentAnswer"
                v-html="sanitizedAnswer"
              />
              <!-- eslint-enable vue/no-v-html -->
              <div v-show="backupAnswer(chosenBackup).length > 0">
                <text-button
                  :title="__('Återställ elevtexten till denna tidpukt')"
                  @click="Modal.show('overrideAnswerModal')"
                />
                <h4 style="color: red; margin-bottom: 20px">
                  {{ __('Visar backup från') }} <FormatDate :datetime="chosenBackup.created_at" />
                </h4>
                <!-- eslint-disable vue/no-v-html -->
                <div
                  ref="backupAnswer"
                  v-html="backupAnswer(chosenBackup)"
                />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <div style="width: 140px; text-align: right; margin-right: -170px">
              <div
                class="backupPoint"
                :class="{ selected: backupAnswer(chosenBackup).length === 0 }"
                @click="clearChosenBackup"
              >
                <div class="backupPoint__highlight" />
                <div v-if="!isBuilder">
                  {{ wordCount }} {{ __('ord') }}
                </div>
                <div>
                  <b>{{ __('Senast') }}</b>
                </div>
              </div>
              <div v-if="backup.status === 'fetched'">
                <div v-if="backup.backups.length">
                  <div
                    v-for="studentBackup in backup.backups"
                    :key="studentBackup.id"
                    class="backupPoint"
                    :class="{ selected: chosenBackup === studentBackup }"
                    @click="showBackup(studentBackup)"
                  >
                    <div class="backupPoint__highlight" />
                    <div v-if="!isBuilder">
                      {{ wordCounter(backupAnswer(studentBackup)) }} {{ __('ord') }}
                    </div>
                    <div style="font-size: 10px">
                      <FormatDate :datetime="studentBackup.created_at" />
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ __('Inga') }}
                </div>
              </div>
              <div
                v-else
                class="backupPoint__showMore"
              >
                <text-button
                  mode="secondary"
                  :title="__('Historik')"
                  :loading="backup.status === 'fetching'"
                  @click="readBackups"
                />
              </div>
            </div>
          </div>

          <div class="gradingComments__wrapper">
            <div
              v-for="gradingComment in gradingComments"
              :key="gradingComment.id"
              class="gradingComments__comment"
            >
              <status-card info>
                <div class="gradingComments__title">
                  Lärarkommentar <FormatDate :datetime="gradingComment.updated_at" />
                </div>
                <div>
                  {{ gradingComment.text }}
                </div>
              </status-card>
            </div>
            <div
              v-show="gradingCommentsBeta"
              class="gradingComments__create"
            >
              <textarea
                v-show="showExamGradingCommentBox"
                v-model="examGradingComment"
                class="gradingComments__input"
                :placeholder="__('Skriv en kommentar och tryck sedan på Lägg till')"
              />
              <text-button
                v-show="!showExamGradingCommentBox"
                mode="secondary"
                :title="__('Skriv rättningskommentar')"
                @click="showExamGradingCommentBox = true"
              />
              <text-button
                v-show="showExamGradingCommentBox"
                :title="__('Lägg till kommentar')"
                @click="addExamGradingComment"
              />
            </div>
          </div>

          <!-- The following section intentionally doesn't use the <text-button> component which would
          solve the loading and disabling part automatically. The reason is that we currently want
          this to be a fairly hidden feature as it is very rarely used, and mostly by us in support
          cases/responses. -->
          <div
            class="unusedImages"
            @click="readImages"
          >
            <div v-if="imageSearchStatus !== 'none'">
              {{ __('Sök efter oanvända bilder') }}
              <i
                v-if="imageSearchStatus === 'searching'"
                class="fa fa-spinner"
              />
            </div>
            <div
              v-else
              class="unusedImages--none"
            >
              {{ __('Inga nya bilder funna') }}
            </div>
          </div>
        </div>
      </el-tab-item>

      <el-tab-item :title="__('Inställningar')">
        <individual-settings
          v-if="student"
          v-show="surveillanceMode"
          :site="site"
          :student="student"
          :exam="exam"
        />
        <div v-show="!surveillanceMode">
          <h1>{{ __('Provet är anonymiserat') }}</h1>
          <h3>{{ __('Aktivera övervakningsläge för att justera individuella inställningar') }}.</h3>
        </div>
      </el-tab-item>
    </el-tab>
  </div>
</template>

<script>
import AttachmentPreview from 'VueComponents/student/exam/AttachmentPreview.vue'
import ProgrammingExporter from 'Modules/ProgrammingExporter.js'
import ExamStudentLog from 'VueTeacher/admin/ExamStudentLog.vue'
import { wordCount as wordCounter } from '@teachiq/utils/text'
import FirebaseFileUpload from 'Modules/FirebaseFileUpload.js'
import GeoGebraExporter from 'Modules/GeoGebraExporter.js'
import FormatDate from 'VueDesignElements/FormatDate.vue'
import IndividualSettings from './IndividualSettings.vue'
import ExamBuilderGrading from './ExamBuilderGrading.vue'
import ThrowOutHandler from 'Modules/ThrowOutHandler.js'
import ElTabItem from 'VueDesignElements/ElTabItem.vue'
import DesmosExporter from 'Modules/DesmosExporter.js'
import StudyAttachments from './StudyAttachments.vue'
import { useStudent } from 'Modules/stores/student.ts'
import WordExporter from 'Modules/WordExporter.js'
import ExamArchiver from 'Modules/ExamArchiver.js'
import HTMLExporter from 'Modules/HTMLExporter.js'
import HeadlessPDF from 'Modules/HeadlessPDF.js'
import ElTab from 'VueDesignElements/ElTab.vue'
import TypeTrigger from '@krisell/type-trigger'
import Firestore from 'Modules/Firestore.js'
import Anonymous from 'Modules/Anonymous.ts'
import ActionCard from './ActionCard.vue'
import StatusCard from './StatusCard.vue'
import FastApi from 'Modules/FastApi.js'
import Student from 'Modules/Student'
import Katex from 'Modules/Katex.js'
import Parser from '@krisell/parser'
import File from 'Modules/File.ts'
import Modal from 'Modules/Modal'
import Exam from 'Models/Exam.js'
import axios from 'axios'

const UPDATE_INTERVAL = 15000

export default {
  components: {
    ElTab,
    ElTabItem,
    ExamStudentLog,
    ActionCard,
    StatusCard,
    IndividualSettings,
    StudyAttachments,
    ExamBuilderGrading,
    AttachmentPreview,
    FormatDate,
  },
  props: {
    studentId: {
      type: [Number, String],
      default: '',
    },
    studentJwt: {
      type: String,
      default: null,
    },
    exam: {
      type: Object,
      required: true,
    },
    surveillanceMode: Boolean,
    site: {
      type: String,
      required: true,
    },
    isBuilder: Boolean,
  },
  setup() {
    const studentStore = useStudent()

    return { studentStore }
  },
  data() {
    return {
      wordCounter,
      newEmail: '',
      student: null,
      useFileBackups: false,
      editEmail: false,
      loadInterval: null,
      openPartIndex: 0,
      chosenType: 0,
      status: 'fetching',
      loadingStatusTimeout: null,
      Modal,
      Student,
      studentLogs: [],
      answer: '',
      chosenBackup: {
        answer: '',
      },
      backup: {
        status: '',
        backups: [],
      },
      examModel: new Exam(this.exam),
      sendingUnlock: false,
      deleteConfirmation: '',
      link: {
        expirationNumber: 6,
        expirationUnit: 'hours',
        expirationUnitOptions: [
          { value: 'minutes', label: this.__('minuter') },
          { value: 'hours', label: this.__('timmar') },
          { value: 'days', label: this.__('dagar') },
          { value: 'months', label: this.__('månader') },
        ],
      },
      showDomDump: false,
      unusedImages: [],
      imageSearchStatus: '',
      showExamGradingCommentBox: false,
      examGradingComment: '',
      gradingComments: [],
      gradingCommentsBeta: false,
    }
  },
  computed: {
    isAnonymous() {
      return Number(this.exam.anonymous) === 1
    },
    ua() {
      if (!this.student) {
        return ''
      }

      const ua = Parser.json(this.student.extra).ua

      if (!ua) {
        return ''
      }

      return ua
    },
    showOldSEBWarning() {
      if (!this.ua.match('Windows')) {
        return false
      }

      const SEB = this.ua.match(/SEB\/(.*?)\s/)

      if (!SEB) {
        return false
      }

      // Show warning for SEB 2.x
      if (SEB[1] && SEB[1][0] === '2') {
        return true
      }

      return false
    },
    showSEBIOSWarning() {
      if (!this.student) {
        return false
      }

      return this.student.security === 'SEB_ios'
    },
    showSEBMacOSWarning() {
      return this.ua.match('Mac') && this.ua.match('SEB')
    },
    os() {
      if (!this.student) {
        return false
      }

      if (this.student.security === 'SEB_ios') {
        return 'Safe Exam Browser – iOS'
      }

      if (this.ua.match('examnet-iOS app')) {
        return 'iOS (Exam.net app)'
      }

      if (this.ua.match('examnet-macOS app')) {
        return 'macOS (Exam.net app)'
      }

      if (this.ua.match('examnet-Windows app')) {
        return 'Windows (Exam.net app)'
      }

      if (this.ua.match('Windows')) {
        const SEB = this.ua.match(/SEB\/(.*?)\s/)

        if (SEB) {
          return `Windows (SEB ${SEB[1]})`
        }

        return 'Windows'
      }

      if (this.ua.match('iPad')) {
        return 'iOS (iPad)'
      }

      if (this.ua.match('iPhone')) {
        return 'iOS (iPhone)'
      }

      if (this.ua.match('CrOS')) {
        return 'Chrome OS'
      }

      if (this.ua.match('Mac')) {
        const SEB = this.ua.match(/SEB\/(.*?)\s/)

        if (SEB) {
          return `macOS (SEB ${SEB[1]})`
        }

        return 'macOS'
      }

      if (this.ua.match('Linux')) {
        return 'Linux'
      }

      return this.__('Okänt')
    },
    ip() {
      if (!this.student) {
        return false
      }

      return Parser.json(this.student.extra).ip
    },
    currentBuilderStudent() {
      if (this.chosenBackup && this.chosenBackup.student_answer) {
        return {
          answer: this.chosenBackup.student_answer,
          is_handed_in: 0,
        }
      }

      if (this.chosenBackup && this.chosenBackup.answer) {
        return {
          answer: this.chosenBackup.answer,
          is_handed_in: 0,
        }
      }

      return this.student
    },
    sanitizedAnswer() {
      if (!this.student) {
        return ''
      }

      return this.sanitize(this.student.answer)
    },
    examContentChoice() {
      return JSON.parse(this.exam.content).choice
    },
    geogebras() {
      return this.studentStore.attachments.GEOGEBRA
    },
    desmosConstructions() {
      return this.studentStore.attachments.DESMOS
    },
    programmingPrograms() {
      return this.studentStore.attachments.PROGRAMMING
    },
    audioRecordings() {
      return this.studentStore.attachments.RECORDING
    },
    partsDone() {
      if (!this.student) {
        return
      }

      const answer = JSON.parse(this.student.answer)
      return Array(answer.part + 1).fill(0)
    },
    hiddenAnonymousMode() {
      return this.isAnonymous && this.surveillanceMode
    },
    isMarked() {
      if (!this.student) {
        return false
      }

      return !!Parser.json(this.student.info).mark
    },
    isExam() {
      return this.site === 'exam'
    },
    studentEmail() {
      if (!this.student) {
        return false
      }

      return this.student.email
    },
    examAsksForEmail() {
      return this.exam.requirements?.includes('email')
    },
    studentPhone() {
      return Student.phone(this.student)
    },
    studentTeacher() {
      if (!this.student) {
        return false
      }

      return this.student.teacher
    },
    studentClass() {
      if (!this.student) {
        return false
      }

      return this.student.class
    },
    wordCount() {
      if (!this.student || typeof this.student.answer !== 'string') {
        return 0
      }

      return wordCounter(this.student.answer)
    },
    studentCanUnlockThemselves() {
      if (!this.isExam) {
        return this.exam.kmLowestSecuritySetting
      }

      return Number(this.exam.browserSecurityMode) === 1
    },
    geogebraType() {
      const resources = this.exam?.resources

      if (!resources) {
        return 'classic'
      }

      if (typeof resources === 'string') {
        return JSON.parse(resources).geogebra || 'classic'
      }

      return resources.geogebra || 'classic'
    },
  },
  watch: {
    studentId() {
      this.loadStudent()

      window.clearInterval(this.loadInterval)
      this.loadInterval = setInterval(() => this.loadStudent(), UPDATE_INTERVAL)

      if (this.isExam && this.chosenType === 1) {
        this.loadAttachments()
      }

      this.loadGradingComments()

      this.backup = {
        status: '',
        backups: [],
      }

      this.chosenBackup = {
        answer: '',
      }

      this.imageSearchStatus = ''

      this.loadingStatusTimeout = window.setTimeout(() => {
        this.status = 'fetching'
      }, 150)
    },
  },
  mounted() {
    TypeTrigger.register('domdump', () => {
      this.showDomDump = !this.showDomDump
    })

    TypeTrigger.register('gradingcomment', () => {
      this.gradingCommentsBeta = true
    })

    window.K$.events.$on('student-realtime-message', (options = {}) => {
      if (this.student && Number(options.studentId) === Number(this.student.id)) {
        this.loadStudent()
      }
    })

    this.loadInterval = setInterval(() => this.loadStudent(), UPDATE_INTERVAL)

    window.K$.events.$on('word-download-started', () => {
      Modal.hide(this.$refs.wordModal)
    })

    window.$('.contentArea').on('click', '.student-answer-area img', function (event) {
      if (event.target.parentElement.classList.contains('AttachmentPreview__thumbnail')) {
        return
      }

      window.K$.events.$emit('image-display', this.src)
    })
  },
  methods: {
    partLetter(index) {
      return 'ABCDEFGHIJKLMNOPQRSTUVXYZÅÄÖ'[index] || ''
    },
    openAudio(audio) {
      window.open(audio.data.url)
    },
    studyAttachment(type, attachment) {
      window.K$.events.$emit('study-attachment', Student.displayName(this.student), type, attachment)
    },
    backupAnswer(backup) {
      const field = this.showDomDump ? 'domDump' : 'student_answer'

      if (backup[field]) {
        return this.sanitize(backup[field])
      }

      return this.sanitize(backup.answer || '')
    },
    showBackup(backup) {
      console.log(backup) // Keep this in production, it's a simple way to look at raw data in support cases
      this.chosenBackup = backup
      Katex.render()
    },
    async openLink() {
      if (typeof this.link.expirationNumber === 'string' && !this.link.expirationNumber.match(/^\d+$/)) {
        return
      }

      const expiration = `${this.link.expirationNumber} ${this.link.expirationUnit}`
      const response = await axios.get(
        `/print-builder/students/${this.student.id}?pdf-link=true&expiration=${expiration}`,
      )

      window.open(response.data)
    },
    async shareViaEmail() {
      if (typeof this.link.expirationNumber === 'string' && !this.link.expirationNumber.match(/^\d+$/)) {
        return
      }

      Modal.hide(this.$refs.shareViaEmail)

      if (this.isBuilder) {
        const expiration = `${this.link.expirationNumber} ${this.link.expirationUnit}`
        const response = await axios.get(
          `/print-builder/students/${this.student.id}?pdf-link=true&expiration=${expiration}`,
        )

        axios.post('/api/email/answer', { students: [{ email: this.student.email, link: response.data }] })
        return
      }

      const name = Student.displayName(this.student)
      this.loadAttachments().then(async () => {
        const files = []

        if (this.student.answer.length) {
          files.push({
            name: `${Student.displayName(this.student)}.html`,
            content: await HTMLExporter.exportOne(this.student, this.exam, true),
          })
        }

        this.geogebras.forEach((geogebra, index) => {
          files.push({
            name: `${name} GEOGEBRA–${index + 1}.html`,
            content: GeoGebraExporter.export(geogebra.data.base64),
          })
        })

        this.desmosConstructions.forEach((desmos, index) => {
          files.push({
            name: `${name} DESMOS–${index + 1}.html`,
            content: DesmosExporter.export(desmos.data.construction),
          })
        })

        this.programmingPrograms.forEach((program, index) => {
          files.push({
            name: `${name} PROGRAMMING–${index + 1}.html`,
            content: ProgrammingExporter.export(program.data),
          })
        })

        axios.post('/api/email/answer', { students: [{ email: this.student.email, files }] })
      })
    },
    googleDrive() {
      window.K$.events.$emit('google-drive-show')
      window.K$.events.$emit('google-drive-data', [this.student], this.exam, this.examModel, this.isBuilder)
    },
    oneDrive() {
      window.K$.events.$emit('one-drive-show')
      window.K$.events.$emit('one-drive-data', [this.student], this.exam, this.isBuilder)
    },
    sendIndividualResumeEmail() {
      window.$.ajax({
        method: 'post',
        url: `/api/resume/students/${this.student.id}`,
      })
    },
    saveStudentEmail() {
      window.$.ajax({
        method: 'put',
        url: `/api/students/${this.student.id}`,
        data: { email: this.newEmail },
        success: () => {
          this.student.email = this.newEmail
          this.editEmail = false
        },
        error: () => {
          this.editEmail = false
        },
      })
    },
    openPartConfirm(index) {
      this.openPartIndex = index
      Modal.show(this.$refs.openPartModal)
    },
    openPart() {
      Firestore.sendToStudent(this.student.id, window.K$.bucket, {
        type: 'open_part',
        part: this.openPartIndex,
      })

      window.K$.events.$emit('student-realtime-message')
    },
    hasDoneParts() {
      let answer
      try {
        answer = JSON.parse(this.student.answer)
      } catch (e) {
        answer = {}
      }

      if (!answer.part || answer.part === 0 || Number(this.student.is_handed_in) === 1) {
        return false
      }

      return true
    },
    handleTabClick(type) {
      this.chosenType = type
      if (this.isExam && type === 1) {
        // Second option, "Student answer"
        Katex.render()
        this.loadAttachments()
        this.loadGradingComments()
      }
    },
    async loadGradingComments() {
      if (!this.studentId) {
        return
      }

      if (!this.gradingCommentsBeta) {
        this.gradingComments = []
        return
      }

      this.gradingComments = (await axios.get(`/api/grading-comments/${this.studentId}`)).data
    },
    loadAttachments() {
      if (!this.student || !this.studentId) {
        return
      }

      return new Promise((resolve) => {
        window.$.ajax({
          url: '/api/attachments',
          data: { audience: 'teacher' },
          headers: { Authorization: `Bearer ${this.studentJwt}` },
          success: (attachments) => {
            this.student.attachments = attachments
            ;['GEOGEBRA', 'DESMOS', 'PROGRAMMING', 'RECORDING'].forEach((type) => {
              const attachmentsByType = attachments.filter((attachment) => attachment.type === type)
                .map((entry) => {
                  const data = JSON.parse(entry.data)
                  return { id: entry.id, data, name: data.name, created_at: entry.created_at }
                })

              this.studentStore.setAttachmentsByType(type, attachmentsByType)
            })

            resolve()
          },
        })
      })
    },
    mark() {
      window.$.ajax({
        method: 'post',
        url: `/api/students/${this.student.id}/mark`,
        success: () => {
          this.loadStudent({ force: true })
          window.K$.events.$emit('student-realtime-message', { force: true, studentId: this.student.id })
        },
      })
    },
    unmark() {
      window.$.ajax({
        method: 'delete',
        url: `/api/students/${this.student.id}/mark`,
        success: () => {
          this.loadStudent({ force: true })
          window.K$.events.$emit('student-realtime-message', { force: true, studentId: this.student.id })
        },
      })
    },
    deleteStudent() {
      if (this.deleteConfirmation.toLowerCase() !== this.__('radera').toLowerCase()) {
        return
      }

      this.deleteConfirmation = ''
      Modal.hide(this.$refs.deleteStudentModal)
      this.$emit('deletedStudent')

      window.$.ajax({
        method: 'delete',
        url: `/api/students/${this.student.id}`,
        success: () => {
          window.K$.events.$emit('student-realtime-message')
        },
      })
    },
    throwOutStudent() {
      Modal.static(this.$refs.throwingOutModal)
      ThrowOutHandler.perform(ThrowOutHandler.student(this.student)).then(() => {
        setTimeout(() => window.K$.events.$emit('student-realtime-message', { studentId: this.student.id }), 10)
        Modal.hide(this.$refs.throwingOutModal)
      })
    },
    unlockStudent() {
      this.sendingUnlock = true
      window.$.ajax({
        method: 'post',
        url: `/api/students/${this.student.id}/unlock`,
        data: { token: this.student.jwt },
        context: this,
      }).always(() => {
        this.sendingUnlock = false
        window.K$.events.$emit('student-realtime-message', { studentId: this.student.id })
      })
    },
    overrideAnswer() {
      window.$.ajax({
        method: 'post',
        url: `/api/students/${this.student.id}/rollback`,
        data: { answer: this.backupAnswer(this.chosenBackup) },
        success: (response) => {
          this.backup.backups = response
          this.student.answer = this.backupAnswer(this.chosenBackup)
          this.clearChosenBackup()
        },
      })
    },
    print() {
      if (Parser.json(this.exam.content).choice === 'builder') {
        window.open(`/print-builder/students/${this.student.id}?autoprint=true`)
        return
      }

      window.K$.events.$emit('print-show')

      this.examModel
        .fetchResults(this.student)
        .then((students) => {
          const studentsWithWordCount = students.map((student) => {
            student.wordCount = wordCounter(student.answer)
            return student
          })

          window.K$.events.$emit('print-data', {
            type: 'students',
            students: studentsWithWordCount,
            examName: this.exam.name,
            anonymous: false,
            noDouble: true,
          })
        })
        .catch(() => {
          window.K$.events.$emit('print-error')
        })
    },
    downloadWord() {
      Modal.show(this.$refs.wordModal)
      WordExporter.exportMany([this.student], this.examModel)
    },
    async headlessPDF() {
      Modal.static(this.$refs.pdfModal)

      try {
        if (Parser.json(this.exam.content).choice === 'builder') {
          const url = await axios.get(`/print-builder/students/${this.student.id}?pdf-link=true`)

          const response = await HeadlessPDF.url(url.data, null, [this.student], this.isAnonymous)

          Modal.hide(this.$refs.pdfModal)
          File.save(
            `${Student.displayName(this.student)} --ID${btoa(this.student.id)}ID-- (${
              this.exam.name
            }) ${Date.now()}.pdf`,
            response.data,
            'Document',
          )
          return
        }

        HeadlessPDF.pdf([this.student], this.exam).then((response) => {
          Modal.hide(this.$refs.pdfModal)
          File.save(
            `${Student.displayName(this.student)} --ID${btoa(this.student.id)}ID-- (${
              this.exam.name
            }) ${Date.now()}.pdf`,
            response.data,
            'Document',
          )
        })
      } catch (error) {
        Modal.hide(this.$refs.pdfModal)
      }
    },
    async downloadArchive() {
      await this.loadAttachments()
      ExamArchiver.download([this.student], this.exam)
    },
    clearChosenBackup() {
      this.chosenBackup = {
        answer: '',
      }
    },
    async readImages() {
      if (this.imageSearchStatus !== '') {
        return
      }

      this.imageSearchStatus = 'searching'

      const images = await FirebaseFileUpload.studentImages(this.student.id, window.K$.bucket)

      this.unusedImages = []
      images.forEach((image) => {
        if (typeof this.student.answer === 'string' && !this.student.answer.match(image.meta.name)) {
          this.unusedImages.push({
            url: image.url,
            created_at: image.meta.timeCreated,
          })
        }
      })

      if (this.unusedImages.length > 0) {
        Modal.show(this.$refs.unusedImagesModal)
        this.imageSearchStatus = ''
        return
      }

      this.imageSearchStatus = 'none'
    },
    async readBackups() {
      this.backup.status = 'fetching'

      const fileBackups = (await FirebaseFileUpload.studentBackups(this.student.id, window.K$.bucket))
        .map((entry) => {
          return {
            id: entry.url,
            created_at: entry.timeCreated,
            student_answer: entry.data && entry.data.answer,
            domDump: entry.data && entry.data.domDump,
          }
        })
        .filter((entry) => entry.student_answer)

      window.$.ajax({
        url: `/api/student/${this.student.id}/backups`,
        context: this,
        success(response) {
          this.backup.status = 'fetched'
          this.backup.backups = [...response, ...fileBackups].sort((a, b) =>
            window.moment.utc(b.created_at).diff(window.moment.utc(a.created_at)),
          )
        },
      })
    },
    loadStudent(options = {}) {
      if (!this.studentId) {
        this.student = null
        return
      }

      if (
        this.student &&
        Number(this.student.id) === Number(this.studentId) &&
        Number(this.student.is_handed_in) === 1 &&
        !options.force
      ) {
        return // No need to fetch again and it also disturbs ongoing grading
      }

      FastApi.post({
        url: 'result',
        data: { token: this.studentJwt },
      }).then(({ student }) => {
        if (!this.studentId || Number(student.id) !== Number(this.studentId)) {
          return // Got a delayed result, not valid anymore
        }

        student.jwt = this.studentJwt
        this.student = student

        if (this.student && this.isAnonymous && !this.surveillanceMode) {
          Anonymous.replaceName(this.student, this.exam, true)
        }

        window.clearTimeout(this.loadingStatusTimeout)
        this.status = 'fetched'

        Katex.render()
      })
    },
    async addExamGradingComment() {
      await axios.post(`/api/grading-comments/${this.student.id}`, {
        text: this.examGradingComment,
      })

      this.examGradingComment = ''
      this.showExamGradingCommentBox = false
      this.loadGradingComments()
    },
  },
}
</script>

<style>
/** Global style to target images within HTML created in the text-editor  */
.student-answer img {
  cursor: pointer;
}
</style>

<style scoped lang="less">
.openPartModal {
  &__footer {
    display: flex;
    justify-content: center;
  }

  &__button {
    margin: 4px;
  }
}

.backupPoint {
  position: relative;
  width: 140px;
  margin-left: auto;
  margin-bottom: 15px;
  border-radius: 3px 0 0 3px;
  padding: 10px 15px;
  padding-right: 20px;
  text-align: left;
  cursor: pointer;
  color: #888;
  white-space: nowrap;
  background: white;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #613c00;
  }

  &__showMore {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__highlight {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #ffe9cd;
    opacity: 0;
    z-index: -1;
    border-radius: 0;
    transition: all 0.2s ease-out;
  }

  &:hover .backupPoint__highlight {
    width: 100%;
    opacity: 1;
    border-radius: 3px 0 0 3px;
  }

  &.selected {
    background-color: #ffe9cd;
    color: #613c00;
    cursor: auto;

    &:hover {
      color: #613c00;
    }
  }
}

.notification-banner-danger {
  background: #eca69d;
  border: 1px solid #9a1818;
  color: #9a1818;
  border-radius: 5px;
  margin-right: 10px;
}

.notification-banner-success {
  background: #dff0d8;
  border: 1px solid rgb(40, 126, 65);
  color: rgb(23, 165, 82);
  border-radius: 5px;
  margin-right: 10px;
}

.notification-banner-warning {
  background: #ffdaac;
  border: 1px solid #eff3f8;
  color: black;
  border-radius: 5px;
  margin-right: 10px;
}

.popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

img.print-icon {
  margin-top: 8px;
  height: 50px;
}

.previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.student-answer__content {
  flex: 1;
  margin-right: 30px;

  &--overflow-auto {
    overflow: auto;
  }
}

.unusedImages {
  position: absolute;
  bottom: -80px;
  right: 0;
  font-size: 12px;
  color: #ababab;
  cursor: pointer;

  &--none {
    cursor: auto;
  }
}

.gradingComments {
  &__wrapper {
    margin-top: 20px;
  }

  &__comment {
    margin-bottom: 5px;
  }

  &__title {
    font-weight: bold;
  }

  &__create {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__input {
    border-radius: 5px;
    margin-right: 10px;
    flex: 1;
    padding: 10px;
  }
}
</style>
