

























































































































































































































































































































































































































































































































































































































































































































































































































































































// @import '~style/main.less';

.student-answer__content {
  flex: 1;
  margin-right: 30px;

  &--overflow-auto {
    overflow: auto;
  }
}

.information-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;

  & .events-wrapper {
    display: flex;
    // @media (max-width: @tablet-width) {
    flex-direction: column;
    // }
    & .events-header {
      margin: 10px 0;
    }

    & .events-and-load-more {
      display: flex;
      flex-direction: column;
      // margin: 5px;
      & .load-more {
        color: @navy-blue;
        cursor: pointer;
      }

      & .events {
        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;

        & > div {
          align-items: flex-start;
        }

        & .events-time-type {
          display: flex;
          flex-direction: row;
          // width: 100px;
          width: 100%;
          margin-right: 5px;

          @media (max-width: 800px) {
            flex-direction: column;
          }

          & > div {
            height: initial;
          }

          & .event-time {
            color: @lightGreyDark;
            margin-right: 5px;
          }

          & .event-type.event-type {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            // width: 100px;
            // min-width: 100px;
          }
        }
      }
    }
  }
}

.two-column-container {
  .body2;

  display: flex;
  align-items: center;

  & div {
    display: flex;
    align-items: center;
  }

  & > .column1 {
    width: 120px;
    margin-right: 20px;

    & div {
      display: flex;
      align-items: center;
    }

    & > div {
      height: @height;
      .caption();

      color: @blackish;
    }
  }

  & > .column2 {
    flex: 1;
    .body2;
    // border: 1px solid red;
    & > div {
      display: flex;
      align-items: center;
      height: @height;
      .caption();

      color: @blackish;
    }
  }
}
////////////////************ */

@height: 30px;
@tablet-width: 1024px;
@mobile-width: 480px;
@show-only-icon-width: 1300px;
.mulish() {
  font-family: Mulish, sans-serif;
  font-style: normal;
}

.headline3() {
  .mulish;

  font-weight: normal;
  font-size: 25px;
  line-height: 31px;
}

.headline3 {
  .headline3;
}

.headline1 {
  .mulish;

  font-weight: 100;
  font-size: 50px;
  line-height: 63px;

  @media (max-width: @mobile-width) {
    // headline3
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
  }
}
.headline2() {
  .mulish;

  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.25px;
}

.headline2 {
  .headline2();
}

.headline4 {
  .mulish;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

/* MULISH REGULAR 17/23px, SPACING 0.15px */
.subtitle1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
}

.subtitle1 {
  .subtitle1();
}

.subtitle2() {
  .mulish;

  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.subtitle2 {
  .subtitle2;
}

.body1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.body1 {
  .body1();
}
.body2() {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.body2 {
  .body2();
}

.button {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 1.25px;
}

.caption() {
  .mulish;

  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.caption {
  .caption;
}

.overline {
  .mulish;

  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}
@blackish: #373f41;
@blueberry: #2c415a;
@midnight-blue: #1b304a;

@navy-blue: #1395be;
@lime: #92df2f;
@lightGreen: #f3fbdd;
@peach: #ff9292;
@sun-bleached: #fbeda0;
@greyish: #e1e5e8;
@lightGreyDark: #737b7d;
@lightGreyBetween: #c3cbcd;
@lightGrey: #dbdde0;
@light: #efefef;
@backgroundLight: #f5f7f9;
