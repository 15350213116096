


















































































































































































































































































































































































































































@import 'Less/variables.less';

.km-conversion {
  min-height: 100vh;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #222;
  font-size: 18px;
  padding-bottom: 100px;
}

.page {
  display: block;
  position: relative;
  width: 900px;
  max-width: 100%;
  background-color: #fff;
  height: auto;
  margin: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 20%);
  margin-top: 30px;
  box-sizing: content-box;
  overflow: auto;

  &--blue {
    background-color: @theme-blue;
    color: #fff;

    h2 {
      position: relative;
      display: block;
      margin: 0;
      margin-top: 15px;
      padding: 0 20px;
      width: auto;
      text-align: center;
      box-sizing: border-box;
    }

    p {
      position: relative;
      display: block;
      margin: 0;
      margin-bottom: 15px;
      padding: 0 20px;
      width: auto;
      text-align: center;
      box-sizing: border-box;
    }
  }
}

.custom.vue-tags-input {
  max-width: 600px;
}

.vue-tags-input .input {
  border-radius: 10px;
}

.vue-tags-input .tag.valid {
  background: @theme-primary;
}

.vue-tags-input .item.valid.selected-item {
  background: @theme-primary;
}
