<template>
  <div
    v-sanitized-html="icon"
    class="text-center"
    @click.stop="$emit('click')"
  />
</template>

<script>
import Icons from 'Modules/Icons.js'

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator: (type) => {
        return Object.keys(Icons).includes(type)
      },
    },
  },

  computed: {
    icon() {
      return Icons[this.name]
    },
  },
}
</script>

<style scoped>
div {
  cursor: pointer;
}
</style>
