<template>
  <div
    class="wrapper"
    :class="{ blur: status === 'loading' }"
  >
    <div style="display: flex">
      <div style="flex: 1">
        <h3>Sök skola</h3>

        <div class="form-group">
          <vue-simple-suggest
            v-model="chosenSchoolDisplay"
            :maxSuggestions="20"
            style="font-size: 14px"
            :list="schoolList"
            displayAttribute="display"
            :placeholder="__('Ange skola')"
            :filterByQuery="true"
            @select="fetchSchoolStats"
          />
        </div>
      </div>

      <div style="flex: 1">
        <h3>Sök ort</h3>

        <div class="form-group">
          <!-- <div style="width: 600px; margin: auto;"> -->
          <input
            v-model="ortToSearch"
            style="height: 34px"
            type="text"
            class="form-control input-lg"
            placeholder="Ort"
            tabindex="1"
            autocomplete="off"
            @keydown.enter.prevent="fetchOrtStats(ortToSearch)"
          >
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="stat">
      <div class="teachers">
        <table
          class="table table-striped"
          style="font-size: 10px"
        >
          <tr>
            <th>Namn & logga in som</th>
            <th @click="sort('latest')">
              Senaste inlogg
            </th>
            <th @click="sort('logins')">
              Inlogg
            </th>
            <th @click="sort('logins')">
              Inlogg (60 d.)
            </th>
            <th @click="sort('exams')">
              Prov
            </th>
            <th @click="sort('exams')">
              Prov (60 d.)
            </th>
            <th @click="sort('students')">
              Elevstarter
            </th>
            <th @click="sort('students')">
              Elevstarter (60 d.)
            </th>
          </tr>
          <tr
            v-for="teacher in teachers"
            :key="teacher.id"
          >
            <td>
              <a :href="loginLink(teacher)">{{ teacher.firstName }} {{ teacher.lastName }}</a>
            </td>
            <td :class="loginColor(teacher.login_at || teacher.created_at)">
              {{ teacher.time.toString() }}
            </td>
            <td style="text-align: center">
              {{ num('logins', teacher) }}
            </td>
            <td style="text-align: center">
              {{ num60('logins', teacher) }}
            </td>
            <td style="text-align: center">
              {{ num('exams', teacher) }}
            </td>
            <td style="text-align: center">
              {{ num60('exams', teacher) }}
            </td>
            <td style="text-align: center">
              {{ num('students', teacher) }}
            </td>
            <td style="text-align: center">
              {{ num60('students', teacher) }}
            </td>
          </tr>
        </table>
      </div>

      <div class="schoolstat">
        <div v-if="school">
          <h3>{{ school.namn }}</h3>
          <h5
            class="clickable"
            @click="fetchOrtStats(school.ort)"
          >
            {{ school.ort }}
          </h5>
          <h5
            class="clickable"
            @click="fetchOrgStats(school.orgnummer)"
          >
            {{ school.orgnummer }}
          </h5>
          <div class="schoolInfo">
            <b>{{ teachers.length }} registrerade lärare</b>
            <br>({{ teachers_last_60 }} nya senaste 60 dagarna)
          </div>
          <div class="schoolInfo">
            <b>{{ numExams60Days }} skapade prov senaste 60 dagarna</b>
            <br>({{ numExamsTotal }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ numStudents60Days }} elevstarter senaste 60 dagarna</b>
            <br>({{ numStudentsTotal }} totalt)
          </div>
        </div>

        <div v-if="ort">
          <h3>{{ ort }}</h3>
          <!-- <h5
            style="text-decoration: underline; cursor: pointer;"
            @click="fetchOrtStats(school.ort)"
          >
            {{ school.ort }}
          </h5> -->
          <div class="schoolInfo">
            <b>{{ teachers.length }} registrerade lärare</b>
            <br>({{ teachers_last_60 }} nya senaste 60 dagarna)
          </div>
          <div class="schoolInfo">
            <b>{{ numExams60Days }} skapade prov senaste 60 dagarna</b>
            <br>({{ numExamsTotal }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ numStudents60Days }} elevstarter senaste 60 dagarna</b>
            <br>({{ numStudentsTotal }} totalt)
          </div>
          <div>
            <div
              v-for="ortSchool in ortSchoolsByTeachers"
              :key="ortSchool.id"
              class="clickable"
              @click="fetchSchoolStats(ortSchool.id)"
            >
              {{ ortSchool.namn }} ({{ ortSchool.teachers.length }})
            </div>
          </div>
        </div>

        <div v-if="orgnummer">
          <h3>{{ orgnummer }}</h3>
          <!-- <h5
            style="text-decoration: underline; cursor: pointer;"
            @click="fetchOrtStats(school.ort)"
          >
            {{ school.ort }}
          </h5> -->
          <div class="schoolInfo">
            <b>{{ teachers.length }} registrerade lärare</b>
            <br>({{ teachers_last_60 }} nya senaste 60 dagarna)
          </div>
          <div class="schoolInfo">
            <b>{{ numExams60Days }} skapade prov senaste 60 dagarna</b>
            <br>({{ numExamsTotal }} totalt)
          </div>
          <div class="schoolInfo">
            <b>{{ numStudents60Days }} elevstarter senaste 60 dagarna</b>
            <br>({{ numStudentsTotal }} totalt)
          </div>
          <div>
            <div
              v-for="orgSchool in orgSchoolsByTeachers"
              :key="orgSchool.id"
              class="clickable"
              @click="fetchSchoolStats(orgSchool.id)"
            >
              {{ orgSchool.namn }} ({{ orgSchool.teachers.length }})
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="status === 'loading'"
      style="z-index: 9999; position: fixed; top: 300px; left: 40%"
    >
      <i
        style="font-size: 300px"
        class="fa fa-spin fa-spinner"
      />
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'

export default {
  components: { VueSimpleSuggest },
  data() {
    return {
      chosenSchoolDisplay: '',
      schoolList: [],
      ortToSearch: '',
      schools: [],
      ortSchools: [],
      orgSchools: [],
      school: null,
      ort: null,
      orgnummer: null,
      teachers: [],
      status: 'done',
    }
  },
  computed: {
    teachers_last_60() {
      return this.teachers.filter((teacher) => {
        return window.moment().diff(window.moment(teacher.created_at), 'days') < 60
      }).length
    },

    numStudents60Days() {
      return this.teachers.reduce((acc, teacher) => {
        if (teacher.stat.length === 0) {
          return acc
        }

        const currentValue = +teacher.stat[0].num_students
        const st = teacher.stat.find((st) => window.moment().diff(window.moment(st.week, 'YYWW'), 'days') > 60)
        console.log(st)
        const oldValue = (st && st.num_students) || 0

        return acc + (currentValue - oldValue)
      }, 0)
    },

    numExams60Days() {
      return this.teachers.reduce((acc, teacher) => {
        if (teacher.stat.length === 0) {
          return acc
        }

        const currentValue = +teacher.stat[0].num_exams
        const st = teacher.stat.find((st) => window.moment().diff(window.moment(st.week, 'YYWW'), 'days') > 60)
        console.log(st)
        const oldValue = (st && st.num_exams) || 0

        return acc + (currentValue - oldValue)
      }, 0)
    },

    numStudentsTotal() {
      return this.teachers.reduce((acc, teacher) => acc + this.num('students', teacher), 0)
    },

    numExamsTotal() {
      return this.teachers.reduce((acc, teacher) => acc + this.num('exams', teacher), 0)
    },

    ortSchoolsByTeachers() {
      return this.ortSchools.slice().sort((a, b) => b.teachers.length - a.teachers.length)
    },

    orgSchoolsByTeachers() {
      return this.orgSchools.slice().sort((a, b) => b.teachers.length - a.teachers.length)
    },
  },

  mounted() {
    this.preloadSchools()
  },

  methods: {
    preloadSchools() {
      window.$.ajax({
        url: '/api/site-admin/schools',
        success: (data) => {
          data.forEach((school) => {
            school.display = `${school.namn} (${school.ort})`
          })

          this.schoolList = data
        },
      })
    },
    sort(column) {
      const intSorts = ['logins', 'exams', 'students']

      if (intSorts.includes(column)) {
        column = `num_${column}`

        this.teachers.sort((a, b) => {
          if (a.stat.length === 0) {
            return 1
          }

          if (b.stat.length === 0) {
            return -1
          }

          if (b.stat[0][column] - a.stat[0][column] === 0) {
            return b.firstName.localeCompare(a.firstName)
          }

          return b.stat[0][column] - a.stat[0][column]
        })
      }

      if (column === 'latest') {
        this.teachers.sort((a, b) => (a.login_at === b.login_at ? 0 : a.login_at < b.login_at ? 1 : -1))
      }
    },

    num(column, teacher) {
      if (teacher.stat.length === 0) {
        return 0
      }

      return +teacher.stat[0][`num_${column}`]
    },

    num60(column, teacher) {
      if (teacher.stat.length === 0) {
        return 0
      }

      const currentValue = +teacher.stat[0][`num_${column}`]
      const now = window.moment()

      const st = teacher.stat.find((st) => now.diff(window.moment(st.week, 'YYWW'), 'days') > 60)
      const oldValue = +(st && st[`num_${column}`]) || 0

      return currentValue - oldValue
    },

    loginColor(datetime) {
      const time = this.getTime(datetime)

      if (time.daysAgo < 30) {
        return 'login-green'
      }

      if (time.daysAgo < 90) {
        return 'login-orange'
      }

      return 'login-red'
    },

    getTime(datetime) {
      console.log('DATETIME', datetime)
      const date = new Date(`${datetime.substring(0, 19).replace(/-/g, '/')} UTC`)
      const localeString = new Date(`${datetime.substring(0, 19).replace(/-/g, '/')} UTC`).toLocaleString()

      const diff = new Date() - date
      const secondsAgo = Math.floor(diff / 1000)
      const minutesAgo = Math.floor(secondsAgo / 60)
      const hoursAgo = Math.floor(minutesAgo / 60)
      const daysAgo = Math.floor(hoursAgo / 24)

      return {
        toString() {
          return localeString
        },
        secondsAgo,
        minutesAgo,
        hoursAgo,
        daysAgo,
      }
    },

    loginLink(teacher) {
      return `/admin/impersonate/${teacher.email}`
    },

    fetchOrtStats(ort) {
      this.status = 'loading'
      fetch(`/api/adminlog/ort/${ort}`, { credentials: 'include' })
        .then((data) => data.json())
        .then((data) => {
          this.status = 'done'
          this.teachers = []
          this.school = null
          this.orgnummer = null
          this.ort = ort
          this.ortSchools = data.schools.filter((school) => school.teachers.length > 0)
          data.schools.forEach((school) => {
            this.teachers = this.teachers.concat(school.teachers)
          })

          this.teachers.sort((a, b) => a.firstName.localeCompare(b.firstName))

          this.teachers.forEach((teacher) => {
            teacher.time = this.getTime(teacher.login_at || teacher.created_at)
            teacher.stat.sort((a, b) => b.week - a.week)
          })
        })
    },

    fetchOrgStats(orgnummer) {
      this.status = 'loading'
      fetch(`/api/adminlog/orgnummer/${orgnummer}`, { credentials: 'include' })
        .then((data) => data.json())
        .then((data) => {
          this.status = 'done'
          this.teachers = []
          this.school = null
          this.ort = null
          this.orgnummer = orgnummer
          this.orgSchools = data.schools.filter((school) => school.teachers.length > 0)
          data.schools.forEach((school) => {
            this.teachers = this.teachers.concat(school.teachers)
          })

          this.teachers.sort((a, b) => a.firstName.localeCompare(b.firstName))

          this.teachers.forEach((teacher) => {
            teacher.time = this.getTime(teacher.login_at || teacher.created_at)
            teacher.stat.sort((a, b) => b.week - a.week)
          })
        })
    },

    fetchSchoolStats(skolid) {
      window.setTimeout(() => {
        if (typeof skolid === 'object') {
          skolid = skolid.id
        }

        if (!skolid || typeof skolid !== 'number') {
          console.log(this.chosenSchoolDisplay)
          skolid = this.schoolList.find((school) => this.chosenSchoolDisplay === `${school.namn} (${school.ort})`).id
        }

        if (!skolid) {
          return
        }

        this.status = 'loading'
        fetch(`/api/adminlog/school/${skolid}`, { credentials: 'include' })
          .then((data) => data.json())
          .then((data) => {
            this.status = 'done'
            this.ort = null
            this.orgnummer = null
            this.school = data.school
            this.teachers = data.teachers
            this.teachers.sort((a, b) => a.firstName.localeCompare(b.firstName))
            this.teachers.forEach((teacher) => {
              teacher.time = this.getTime(teacher.login_at || teacher.created_at)
              teacher.stat.sort((a, b) => b.week - a.week)
            })
          })
      }, 100)
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  text-align: center;
}

.stat {
  display: flex;
  text-align: left;
}

.teachers {
  flex: 2;
}

.schoolstat {
  flex: 1;
  border: 1px solid black;
  border-radius: 20px;
  text-align: center;
  box-shadow: 5px 5px 5px #00000085;
  margin: 10px;
}

td.login-green {
  color: green;
}

td.login-orange {
  color: orange;
}

td.login-red {
  color: red;
}

.schoolInfo {
  margin: 20px;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.blur {
  filter: blur(10px);
}
</style>
