
















































































































































































































































































































































































































































































































































































































































.box {
  position: relative;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 20%);
  border-radius: 5px;
}

.wrapper {
  position: relative;
  width: 100%;
  min-height: 200px;
  height: auto;
  margin-top: 8px;

  &.km {
    margin-top: -90px;
  }
}

.left {
  position: fixed;
  width: 220px;
  margin-right: 10px;
  height: auto;
  top: 100px;
  bottom: 70px;

  &--top {
    top: 60px;
    bottom: 100px;
  }

  &--km {
    top: 10px;
  }
}

.right {
  flex: 1;
  padding: 10px;
  max-width: 800px;
}

.selectExam {
  margin-bottom: 5px;
}

.clickable {
  cursor: pointer;
  transition: all 0.1s;
}

.search {
  margin: 10px;
  width: ~'Calc(100% - 20px)';
  min-height: 30px;
}

.studentNavigationPanel {
  position: relative;
  height: auto;
  min-height: 200px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;

  &__list {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: auto;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px;

    &--blurred {
      filter: blur(10px);
    }
  }
}

.overviewBody {
  position: relative;
  padding: 15px 20px;
  height: auto;
  left: 230px;
  width: ~'Calc(100% - 230px)';
}

.studentButton {
  line-height: 1.6;
  min-height: 22px;
  display: flex;
  padding: 0 10px;
  border-radius: 10px;

  &:hover &__title {
    padding-left: 3px;
  }

  .studentButton__title {
    white-space: nowrap;
  }

  &--selected {
    background: rgba(255, 158, 2, 57%);
    border-left: 5px solid #ff9e02;
    color: #613c00;
    border-radius: 0;
    padding: 5px;

    .studentButton__title {
      padding-left: 3px;
    }
  }
}

.pretty-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.pretty-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
  background-color: #eee;
}

.pretty-scrollbar::-webkit-scrollbar-thumb {
  background-color: #1d2b51;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
}

.sort-list-move {
  transition: transform 0.1s;
}

.animation {
  transition: all 0.2s ease-in-out;
}

.exam-chat-wrapper {
  position: fixed;
  bottom: 5px;
  left: 5px;
  z-index: 999;
}

.student-icons {
  display: flex;
  align-items: center;

  i {
    margin-right: 3px;
  }
}
