






































































































































































.StudentExamStatus {
  &__info {
    padding: var(--spacing-2) var(--spacing-4);
    border-radius: var(--radius-default);
    background: var(--green-900);
    color: white;
    font-weight: var(--font-normal);
    font-size: var(--font-size-xs);

    i {
      margin-right: var(--spacing-1);
      font-size: var(--font-size-sm);
    }

    &--danger {
      background: var(--red-900);
    }
  }

  &__action {
    color: white;
    border: var(--divider-border);
    border-radius: 300px;
    padding: 0 var(--spacing-3);
    margin-left: var(--spacing-3);

    span {
      font-size: 10px;
    }
  }
}
