



























































































































































































































































































.saved {
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 1px 1px 5px #e4e4e4;
  margin-left: 10px;
}

.loadInfo {
  margin: auto;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  margin-top: -10px;
  padding-bottom: 5px;
}

.saveEntry {
  cursor: pointer;
  margin: 10px 0;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 20%);
  }

  h1,
  h2 {
    margin: 8px;
    font-size: 12px;
  }

  h1 {
    font-weight: 600;
  }
}

.saveEntryInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete {
  color: #bc3838;
  font-size: 22px;
  margin-right: 20px;
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}

.save-error-message {
  color: red;
  font-size: 12px;
  padding: 5px;
}
