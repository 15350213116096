





































































































@import 'Less/variables.less';

.group-actions {
  font-size: @font-size-medium;
  padding: @spacing-2;
  cursor: pointer;

  &--wrapper {
    color: white;
    position: absolute;
    right: @spacing-3;
    display: flex;
    top: 0;
    cursor: pointer;
    align-items: center;
  }
}
