





























































































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.ExamBuilderPrint {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f9f9f9;
  align-content: center;

  @media print {
    background-color: #fff;
  }
}

.print-settings-wrapper {
  position: fixed;
  box-shadow: 5px 3px 7px #bdbdbd;
  z-index: 1;
  padding: 0 10px 10px;
  background: #fff;
  border-radius: 0.6em;
  max-width: 220px;

  @media print {
    display: none;
  }

  &--minimized {
    position: fixed;
    box-shadow: 5px 3px 7px #bdbdbd;
    z-index: 1;
    background: #fff;
    border-radius: 0.6em;
    padding: 0 10px;
  }
}

.print-settings {
  &--minimized {
    display: none;
  }

  &__title {
    margin-top: 1em;
  }

  &__button-print {
    display: flex;
    justify-content: center;
    width: 200px;
    margin-top: 10px;
  }

  &__button-minimize {
    width: 200px;
    border: none;
    color: @theme-blue;
    background: #fff;
  }

  &__toggle {
    display: flex;
    align-items: flex-end;
    margin: 5px;
    justify-content: space-between;
  }

  &__double-sided-checkbox {
    margin-top: @spacing-3;
  }

  &__double-sided-warning {
    text-align: center;
    margin-top: @spacing-3;
    font-size: @font-size-small;
  }
}

.print-summary {
  margin: auto;

  &__menu {
    max-width: 800px;
    margin: auto;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media print {
      display: none;
    }
  }

  &__button-print {
    margin: 5px;
  }

  &__button {
    margin: 5px;
  }

  &__select {
    margin: 5px;
    margin-left: auto;
  }

  &__print-warning {
    position: absolute;
    width: unset;
  }
}

.print-students-wrapper {
  margin: auto;
  max-width: 80%;
  border: antiquewhite;
  background-color: #f9f9f9;

  @media (max-width: 1660px) {
    max-width: 100%;
  }

  @media print {
    background-color: white;
  }
}

.print-students {
  margin: auto;
  min-height: 100%;

  &__item {
    position: relative;
    box-sizing: border-box;
    max-width: 800px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 20%);
    margin: 20px auto;
    break-after: page;
    width: 210mm; /* A4 width, same as PDF generator service */

    @media print {
      print-color-adjust: exact;
      box-shadow: none;
      margin: 0 auto;
    }
  }

  &__select {
    margin: 5px;
    margin-left: auto;
  }

  &__button {
    margin: 5px;
  }
}

.print-empty {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 20%);
  margin: 20px auto;
  break-after: page;

  @media print {
    print-color-adjust: exact;
    box-shadow: none;
    margin: 0 auto;
  }
}

.anonymous-qr-code {
  position: absolute;
  top: 30px;
  right: 30px;
}

.performDate {
  font-size: 12px;
}
