

















































































































































































































































































































































h1 {
  color: black;
}

.startButtons {
  opacity: 0;
}

.endButtons {
  opacity: 1;
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.logoStart {
  opacity: 0;
}

.logoEnd {
  opacity: 1;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.endMargin {
  margin-right: 50px;
}

.imageMarginEnd {
  margin-top: 0;
}

.update {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 10px;
  padding: 5px;
  text-align: right;
}

.box {
  background: white;
  width: 400px;
  margin-top: -200px;
  text-align: center;
  border: 1 px solid black;
  padding: 10px 30px;
  max-width: 1000px;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 20%);
  border-radius: 5px;
  color: black;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
