










































































































































































































@import 'Less/variables.less';

.audio-player {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  &__slider {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: @spacing-2;
  }

  &__icon {
    height: @spacing-10;
    width: @spacing-10;
    background: @theme-blue;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    cursor: pointer;

    &--rounded {
      border-radius: 50%;
    }
  }

  &__audio {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}
