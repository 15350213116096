































































































































































































































































































































































































@grey: #eeeeee;
@success: #5bde44;
@warning: #f5a623;
@animation-duration: 0.5s;

.status-icon {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: @grey;
  padding: 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 2%);

  &--success {
    background-color: @success;
    box-shadow: none;
  }

  &--warning {
    background-color: @warning;
    box-shadow: none;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

.animation {
  transition: all @animation-duration ease-in-out;
}
