











































































































































































































































































































































// @import '~style/main.less';
@tablet-width: 1024px;
@mobile-width: 480px;

.wrapper-settings {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  border: 1px solid @lightGrey;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  color: @lightGreyDark;
  max-height: 630px;
  overflow-y: auto;
  overflow-x: hidden;

  .header {
    color: @blackish;
    height: 40px;
    border-bottom: 1px solid @lightGreyDark;
    padding-left: 10px;
    display: flex;
    align-items: center;

    .input-container {
      border-radius: 4px;
      height: 30px;
      margin-left: auto;

      .input-wrapper {
        height: 100%;
        padding: 3px 3px 3px 5px;
        display: flex;

        input {
          position: relative;
          text-align: left;
          height: 100%;
          min-height: 100%;
          background-color: white;
          border: none;
          box-sizing: border-box;

          &:focus {
            border: none;
            outline: none;
          }
        }
      }
    }
  }

  .student-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    border-bottom: 1px solid @lightGrey;
    // &:not(.warning).active {
    //   background-color: #f2f2f2;
    // }
    &.active {
      background-color: #f2f2f2;
    }

    &.active::before {
      position: absolute;
      content: '';
      min-height: 40px;
      border-left: 6px solid @lightGrey;
      background-color: #f2f2f2;
      opacity: 0.5;
    }
    // &.warning.active::before {
    //   border-left: 2px solid @lightGrey;
    // }
    &:hover {
      background-color: #f5f5f5;
    }

    .student-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: @lightGreyDark;
      height: 31px;
      padding: 4px;

      &.active {
        background-color: #f5f5f5;
      }

      &.warning {
        background-color: #ffd3d3;
        border-radius: 8px;
      }

      & .name-wrapper {
        flex: 1;
        // min-width: max-content;
        min-width: 180px;
        padding-right: 20px;
        cursor: pointer;

        & > div {
          display: flex;
          align-items: center;
          padding-left: 5px;
          max-width: 190px;
          white-space: nowrap;
          height: 100%;

          & span.name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      & .progress-wrapper,
      .icons-right {
        & div {
          display: flex;
          align-items: center;
        }

        & > div {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }

      & .progress-wrapper {
        min-width: 60px;
        width: 60px;
        margin-right: 10px;
      }

      & .icons-right {
        display: flex;
        justify-content: center;
        min-width: 20px;
        width: 20px;
      }
    }
  }
}

.progress::-webkit-progress-bar {
  // background-color: #eee;
  background-color: #fff;
  border-radius: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: inset 0 0 0 1px #e1e5e8;
  margin-bottom: 0;
}

.progress {
  height: 20px;
  margin-bottom: 0;
}

progress::-webkit-progress-value {
  background-color: @greyish;
  // background-color: #92df2f;
}

////////////////************ */
.mulish() {
  font-family: Mulish, sans-serif;
  font-style: normal;
}

.headline3() {
  .mulish;

  font-weight: normal;
  font-size: 25px;
  line-height: 31px;
}

.headline3 {
  .headline3;
}

.headline1 {
  .mulish;

  font-weight: 100;
  font-size: 50px;
  line-height: 63px;

  @media (max-width: @mobile-width) {
    // headline3
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
  }
}
.headline2() {
  .mulish;

  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.25px;
}

.headline2 {
  .headline2();
}

.headline4 {
  .mulish;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

/* MULISH REGULAR 17/23px, SPACING 0.15px */
.subtitle1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
}

.subtitle1 {
  .subtitle1();
}

.subtitle2() {
  .mulish;

  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.subtitle2 {
  .subtitle2;
}

.body1() {
  .mulish;

  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.body1 {
  .body1();
}
.body2() {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.body2 {
  .body2();
}

.button {
  .mulish;

  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 1.25px;
}

.caption() {
  .mulish;

  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.caption {
  .caption;
}

.overline {
  .mulish;

  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}
@blackish: #373f41;
@blueberry: #2c415a;
@midnight-blue: #1b304a;
@navy-blue: #1395be;
@lime: #92df2f;
@lightGreen: #f3fbdd;
@peach: #ff9292;
@sun-bleached: #fbeda0;
@greyish: #e1e5e8;
@lightGreyDark: #737b7d;
@lightGreyBetween: #c3cbcd;
@lightGrey: #dbdde0;
@light: #efefef;
@backgroundLight: #f5f7f9;
