




















































































































































































































.StudentMonitoringHistory {
  display: flex;
  justify-items: center;

  &__dropdown--text-based {
    width: var(--width-60);
  }

  &__reset-button {
    margin-left: var(--spacing-1);
  }
}
