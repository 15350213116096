





























































































































































































.StudentImageHandlerImageList {
  border-top: var(--divider-border);
  border-bottom: var(--divider-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    display: flex;
    align-items: baseline;
    width: 100%;
    padding: 0 var(--spacing-4);

    &--collapsable {
      cursor: pointer
    }

    &__text {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-md);
      flex: 1;
    }

    &__icon {
      font-size: var(--font-size-small);
    }
  }

  &__list {
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);

    &__image {
      cursor: grabbing;

      &--ghost {
        opacity: 0.4;
      }
    }

    &__image-wrapper {
      margin: auto;
      width: fit-content;
      margin-bottom: var(--spacing-4);
      position: relative;
      max-width: 100%;
      max-height: 300px;
    }
  }
}

.show-if-hovered {
  opacity: 0;
  transition: var(--transition-all);
  pointer-events: none;

  &:hover {
    opacity: 1;
  }
}

.group-list-move {
  transition: var(--transition-all);
}

