







































































































/* greek */
@font-face {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 100;
  src: url('https://cdn2.exam.net/static/fonts/roboto/v29/KFOkCnqEu92Fr1MmgVxLIzIXKMnyrYk.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */
@font-face {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 100;
  src: url('https://cdn2.exam.net/static/fonts/roboto/v29/KFOkCnqEu92Fr1MmgVxGIzIXKMnyrYk.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 100;
  src: url('https://cdn2.exam.net/static/fonts/roboto/v29/KFOkCnqEu92Fr1MmgVxIIzIXKMny.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}

.calculator-wrapper-div {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  .js-calc-panel,
  .js-calc-panel > div {
    width: 100%;
    height: 100%;
  }

  body {
    position: absolute;
    background-color: #fff;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0%);
    -webkit-tap-highlight-color: transparent;
  }

  body.clear * {
    color: #000 !important;
    background-color: #000 !important;
  }

  .js-calc {
    background-color: #fff;
    color: #000;
    font-family: monospace;
    font-size: 48px;
  }

  .js-calc-panel,
  .js-calc-display {
    width: 100%;
    line-height: 1;
  }

  .js-calc-display {
    user-select: text;
    padding-top: 6px;
    line-height: 35px;
    margin-top: 25px;
    font-size: 24px;
    padding-left: 24px;
    padding-right: 24px;
    height: 2em;
    text-align: right;
  }

  .js-calc-fsize,
  .js-calc-display,
  .js-calc-mode,
  .js-calc-advanced {
    position: absolute;
    top: 0;
    left: 0;
  }

  .js-calc-fsize {
    visibility: hidden;
    z-index: -1;
    width: auto;
    color: transparent;
    color: rgba(0, 0, 0, 0%);
  }

  .js-calc-mode {
    font-size: 0.33em;
    padding: 10px 12px;
    color: rgba(0, 0, 0, 40%);
  }

  .js-calc-advanced,
  .js-calc-display {
    border-bottom: solid 3px;
  }

  .js-calc-panel {
    padding-top: 2em;
  }

  .js-calc-panel > div {
    position: relative;
  }

  .js-calc-basic {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70%;
  }

  .js-calc-advanced,
  .js-calc-basic {
    width: 100%;
    font-size: 0.7em;
  }

  .js-calc-advanced {
    height: 30%;
  }

  .js-calc-basic .js-calc-btn-container {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
  }

  .js-calc-numpad {
    left: 0;
  }

  .js-calc-opad {
    right: 0;
    position: absolute;
    top: 0;
    width: 40%;
  }

  .js-calc-btn-container {
    height: 100%;
    line-height: 1;
  }

  .js-calc-btn-container > div {
    position: relative;
    text-align: center;
    float: left;
    cursor: pointer;
    touch-action: manipulation;
  }

  .js-calc-btn-container > div > span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -0.5em;
  }

  .js-calc-basic .js-calc-btn-container > div {
    height: 25%;
    width: 33.3333%;
  }

  .js-calc-advanced .js-calc-btn-container > div {
    height: 50%;
    width: 16.6667%;
  }

  .js-calc-advanced .js-calc-primary,
  .js-calc-advanced.shifted .js-calc-shift {
    display: block;
  }

  .js-calc-advanced .js-calc-shift,
  .js-calc-advanced.shifted .js-calc-primary {
    display: none;
  }

  .js-calc-advanced .js-calc-btn-container,
  .js-calc-btn-container > div.l {
    font-size: 0.7em;
  }

  .js-calc-basic .js-calc-btn-container .js-calc-eq {
    width: 66.6667%;
    background: #ffb202;
    color: black;
    font-size: 1.5em;
  }

  .js-calc-eq {
    /* Fix equal signning showing too low */
    line-height: 0.7;
  }

  .js-calc-btn-container::after {
    content: '\0020';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .minimal-js-calc {
    font-family: Roboto, sans-serif;
    font-weight: 100;
  }

  .minimal-js-calc .js-calc-btn-container > div {
    transition: color 0.3s, background-color 0.3s;
  }

  .minimal-js-calc .js-calc-numpad > div:hover,
  .minimal-js-calc .js-calc-opad > div:hover {
    color: #fff;
  }

  .minimal-js-calc .js-calc-numpad,
  .minimal-js-calc .js-calc-opad {
    color: rgba(255, 255, 255, 75%);
  }

  .minimal-js-calc .js-calc-opad {
    background-color: #666;
  }

  .minimal-js-calc .js-calc-numpad {
    background-color: #333;
  }

  .minimal-js-calc .js-calc-advanced,
  .minimal-js-calc .js-calc-display {
    border: 0;
    width: 100%;
  }

  .minimal-js-calc .js-calc-display {
    color: rgba(0, 0, 0, 40%);
  }

  .minimal-js-calc .js-calc-advanced {
    font-weight: 300;
    background-color: #ffb202;
    color: rgba(0, 0, 0, 100%);
  }

  .minimal-js-calc .js-calc-advanced .js-calc-btn-container > div:hover {
    color: #111;
  }
}
