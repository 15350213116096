














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.exam-container {
  display: flex;
  flex-direction: row;
}

.resources {
  flex: 1 2 0;
  display: flex;
  width: 100%;
  max-height: 100vh;
  overflow: auto;

  &__item {
    flex: 1 2 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    &--border-right {
      border-right: solid @border-thickness #d1d1d1;
    }

    &--first {
      order: -1;
    }
  }
}

.exam-scroller {
  padding: 0;
  height: 100vh;
  display: flex;
  flex: 1;
  order: 1;
  overflow: hidden;
  background: white;
}

.exam-area {
  padding: 0;
  height: 100vh;
  display: flex;
  flex: 1;
  order: 1;
  overflow: hidden;
}

.connection-problem {
  position: absolute;
  top: @spacing-10;
  right: @spacing-5;
  font-size: @font-size-xx-large;
  color: orange;
  z-index: 999;
  cursor: pointer;
}

.smaller-info p {
  font-size: 14px;
}
