





































































































































































































































































































































































































































































































































@import 'Less/variables.less';

.handleShareExam {
  color: @color-font-dark;
  font-weight: normal;

  &__help-icon {
    position: absolute;
    top: @spacing-4;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    &__button {
      margin: @spacing-1;
    }
  }

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &__teacher {
      display: flex;
      flex-direction: column;
      width: 40%;
      padding: @spacing-5;
      padding-bottom: @spacing-2;
      padding-top: 0;

      &--column {
        display: flex;
        flex-direction: column;
      }

      &__input {
        margin-top: 0;
        border-radius: @radius-xs;
      }

      &__error {
        min-height: 2em;
        color: @color-danger-dark;
        flex: 1;
      }
    }

    &__others {
      display: flex;
      flex-direction: column;
      width: 40%;
      padding: @spacing-5;
      padding-bottom: @spacing-2;
      padding-top: 0;

      &__input {
        margin-top: 0;
        border-radius: 0;
        border-start-start-radius: @radius-xs;
        border-end-start-radius: @radius-xs;
        border-right: none;
      }

      &__button {
        background: @theme-blue;
        color: white;
        border: none;
        width: 50px;
      }

      &__error {
        min-height: 2em;
        color: @color-danger-dark;
        flex: 1;
      }

      &--flex {
        display: flex;
      }
    }
  }

  &__list {
    &--info {
      margin-bottom: @spacing-1;
      text-align: center;
    }

    &__container {
      height: 60vh;
      overflow: auto;
    }

    &__teacher-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: @spacing-1 @spacing-2;
      transition: all 0.2s ease-in-out;
      border-top: @border-thickness solid #eee;
      border-bottom: @border-thickness solid #eee;

      &__choices {
        user-select: none;
        -webkit-user-drag: none;
      }

      &__chip {
        font-size: @font-size-default;
        margin-left: @spacing-5;
      }

      &__name {
        flex: 1;
        font-size: @font-size-default;
      }

      &__email {
        flex: 1;
      }

      &--column {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &:hover {
        background: #f5f5f5;
      }
    }
  }
}

.scroll-shadow {
  background:
    linear-gradient(white 30%, hsla(0deg, 0%, 100%, 0%)),
    linear-gradient(hsla(0deg, 0%, 100%, 0%) 10px, white 70%) bottom,
    radial-gradient(at top, rgba(0, 0, 0, 20%), transparent 70%),
    radial-gradient(at bottom, rgba(0, 0, 0, 20%), transparent 70%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
  animation: scale 6s ease 3 normal;
}

.sort-list-move {
  transition: all var(--duration-500) ease-in-out;
}
