























































































































.ExamResults__overview {
  margin-top: var(--spacing-10);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--divider-border);
    margin-bottom: var(--spacing-3);
    padding: var(--spacing-2) 0;

    &__heading {
      display: flex;
      align-items: baseline;

      h3 {
        font-size: var(--font-size-lg);
        font-weight: var(--font-semibold);
        margin: 0;
        margin-right: var(--spacing-5);
      }
    }
  }

  &__table {
    width: 100%;

    tr {
      height: var(--height-7);

      td:first-of-type {
        width: var(--width-half);
        padding-left: var(--spacing-1);
      }

      td:nth-of-type(2) {
        width: var(--width-40);
      }

      td:last-of-type {
        vertical-align: middle;

        & > div {
          display: flex;
          justify-content: flex-end;
        }

        button {
          display: inline-flex;
        }

        i {
          font-size: var(--font-size-default);
          margin-right: var(--spacing-2);
        }
      }
    }

    tr:hover {
      background-color: var(--gray-200);
    }
  }
}
