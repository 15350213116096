

















































































@import 'Less/variables.less';

.range-slider {
  &--wrapper {
    position: relative;
  }
}

input[type='range'] {
  appearance: none;
  width: 100%;
  margin: @spacing-1 0;
  background-color: transparent;

  --webkitProgressPercent: 0%;
  --progressColor: @theme-blue;
  --progressBorder: 0.1px solid rgba(10, 15, 17, 80%);
  --progressBorderRadius: 4px;
  --trackColor: #f9f9f9;
  --trackShadow: inset @shadow-md;
  --trackCursor: pointer;
  --trackHeight: 5.4px;
  --trackBorderRadius: 25px;
  --thumbShadow: @shadow-md, inset @shadow-md;
  --thumbBackground: #f9f9f9;
  --thumbSize: 18px;
  --thumbCursor: pointer;
}

input[type='range']:focus {
  --thumbBackground: var(--yellow-100);

  outline: none;
}

//Range track
input[type='range']::-ms-track {
  background-color: var(--trackColor);
  box-shadow: var(--trackShadow);
  border-radius: var(--trackBorderRadius);
  width: 100%;
  height: var(--trackHeight);
  cursor: var(--trackCursor);
}

input[type='range']::-webkit-slider-runnable-track {
  box-shadow: var(--trackShadow);
  border-radius: var(--trackBorderRadius);
  width: 100%;
  height: var(--trackHeight);
  cursor: var(--trackCursor);
  background-image:
    linear-gradient(
      90deg,
      var(--progressColor) var(--webkitProgressPercent),
      var(--trackColor) var(--webkitProgressPercent)
    ); //webkit hack for progress style
}

input[type='range']::-moz-range-track {
  background-color: var(--trackColor);
  box-shadow: var(--trackShadow);
  border-radius: var(--trackBorderRadius);
  width: 100%;
  height: var(--trackHeight);
  cursor: var(--trackCursor);
}

//Range track before thumb
input[type='range']::-ms-fill-lower {
  background-color: var(--progressColor);
  border: var(--progressBorder);
  border-radius: var(--progressBorderRadius);
}

input[type='range']::-moz-range-progress {
  background-color: var(--progressColor);
  border: var(--progressBorder);
  border-radius: var(--progressBorderRadius);
}

//Thumb
input[type='range']::-ms-thumb {
  width: var(--thumbSize);
  height: var(--thumbSize);
  background: var(--thumbBackground);
  box-shadow: var(--thumbShadow);
  border: none;
  border-radius: 50%;
  cursor: var(--thumbCursor);
  margin-top: 0;
}

input[type='range']::-moz-range-thumb {
  width: var(--thumbSize);
  height: var(--thumbSize);
  background: var(--thumbBackground);
  border: none;
  box-shadow: var(--thumbShadow);
  border-radius: 50%;
  cursor: var(--thumbCursor);
}

input[type='range']::-webkit-slider-thumb {
  margin-top: calc((var(--thumbSize) - var(--trackHeight)) / 2 * -1);
  width: var(--thumbSize);
  height: var(--thumbSize);
  background: var(--thumbBackground);
  box-shadow: var(--thumbShadow);
  border-radius: 50%;
  border: none;
  cursor: var(--thumbCursor);
  appearance: none;
}
