



































































.question-wrapper {
  width: 98%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 2;
  font-size: 12pt;
  font-family: 'Times New Roman', Times, serif;

  & .question-number {
    align-self: flex-start;
    font-weight: 700;
    z-index: 2;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 20px;
    max-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
