



































































































































































































































































































































































.ExamOverview {
  transition: var(--transition-all);
  display: grid;
  grid-template-areas:
    'header header  header'
    'left   content right';
  grid-template-columns: 2fr 6fr 2fr;
  grid-template-rows: auto 1fr;
  row-gap: var(--spacing-3);
  margin: var(--spacing-3) auto;
  min-height: var(--height-192);
  max-width: var(--width-320);

  &__chat-placeholder {
    font-size: var(--font-size-md);
    font-weight: var(--font-semibold);
  }

  &__sticky {
    position: sticky;
    top: var(--spacing-20);
    max-height: var(--height-192);
  }

  .header {
    grid-area: header;
  }

  .left-sidebar {
    grid-area: left;
    margin-right: var(--spacing-3);
  }

  .content {
    grid-area: content;
    background: white;
    height: 100%;
    border-radius: var(--radius-lg);
    padding: 0 var(--spacing-4);
    min-height: var(--height-192);
  }

  .right-sidebar {
    grid-area: right;
    max-height: 100%;
    min-height: var(--height-80);
    margin-left: var(--spacing-3);
  }
}
