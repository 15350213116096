














































































































































































.GradingCommentsList {
  display: flex;
  flex-direction: column;

  &__comments {
    margin-bottom: var(--spacing-6);
  }

  &__menu {
    align-self: end;
  }
}
