






























































































































































@import 'Less/variables.less';

.exam-window {
  --header-height: @spacing-8;

  z-index: 1000;
  position: fixed;
  box-shadow: @shadow-2xl;
  overflow: hidden;
  border-radius: @radius-xs;

  &__content {
    max-width: 100%;
    height: calc(100% - var(--header-height));

    > * {
      height: 100%;
    }
  }

  &__header {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: @theme-primary;
    padding: @spacing-1 @spacing-2;
    cursor: move;
    user-select: none;
    touch-action: none;
    border-radius: @radius-xs @radius-xs 0 0;

    &__title {
      display: flex;
      align-items: baseline;
      font-weight: 600;
    }

    &__title-icon {
      font-size: @font-size-default;
      margin-right: @spacing-2;
    }

    &__close-icon {
      margin-top: 0;
      cursor: pointer;
    }
  }
}
