



























































































































































@import 'Less/variables.less';

@divider-border: 1px solid @color-grey-light;
@blue-main: #1d2c52;

.chat-window {
  width: 640px;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;

  &__messages {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    padding: @spacing-2;
  }

  &__input {
    min-height: @spacing-10;
    width: 100%;
    padding: @spacing-1 @spacing-2;
    box-sizing: border-box;
    resize: none;
    border: none;
    border-top: @divider-border;
  }
}

.message {
  position: relative;
  padding: @spacing-1 @spacing-3;
  background-color: @color-grey-light;
  align-self: flex-start;
  width: auto;
  max-width: 60%;
  border-radius: @spacing-2;
  border-bottom-left-radius: 0;
  margin: @spacing-1 0;
  word-break: break-word;

  &--from-user {
    background-color: @blue-main;
    color: #fff;
    align-self: flex-end;
    border-radius: @spacing-2;
    border-bottom-right-radius: 0;
  }
}
