















































@import 'Less/variables.less';

.chartDataCard {
  margin: @spacing-1;
  width: 200px;
  height: 76px;

  &__secondary {
    font-weight: var(--font-bold);
    font-size: @font-size-small;
    margin-bottom: -@spacing-2;
    margin-top: -@spacing-1;
  }

  &__primary {
    font-weight: var(--font-bold);
    font-size: @font-size-medium;
    margin-bottom: -@spacing-2;
  }

  &__info {
    color: grey;
  }
}
