




































































.exam-dropdown {
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px;
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 20%);
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;

  &--active {
    background-color: #ffc886;
    border-left: 10px solid #ff9e01;
  }

  &__target {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 30px;
    z-index: 2;
    background: transparent;
    background-color: transparent;
    opacity: 0;
    border-radius: 5px;
    cursor: pointer;

    &:hover .exam-dropdown__dropdown {
      padding-left: 3px;
    }
  }

  &__dropdown {
    white-space: pre-wrap;
    overflow: hidden;
    appearance: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 100% center;
    width: 100%;
    height: 100%;
    margin: 0 10px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #fff;
    color: #222;
    z-index: 1;
    transition: all 0.2s ease-in-out;

    &::-ms-expand {
      display: none;
    }

    &--active {
      background-color: #ffc886;
      color: #613c00;
    }
  }
}
