



























































































































































.TeacherChat {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    text-align: center;
    padding: var(--spacing-4);
    display: flex;
    justify-content: center;
    cursor: default;
    border-top-right-radius: var(--radius-lg);
    border-bottom-left-radius: 50% 20%;
    background: var(--yellow-500);

    &__icon {
      color: white;
      font-size: var(--font-size-xl);
      margin-right: var(--spacing-2);
    }

    &__selectedStudent {
      color: white;
      font-weight: var(--font-semibold);
      font-size: var(--font-size-md);
      width: var(--width-40);
    }
  }

  &__info {
    color: var(--gray-700);
    padding: var(--spacing-2);
    font-size: var(--font-size-xs);
    text-align: center;
    cursor: default;
  }

  &__chatWindow {
    flex: 1;
    width: 100%;
    min-height: 50%;
    max-height: 100%;
    overflow-y: auto;
    background: white;
    border-radius: var(--radius-lg);
  }
}
