










































































































.GradingComment {
  p {
    margin: 0;
  }

  &__content {
    padding: var(--spacing-3);
    margin-bottom: var(--spacing-1);
    background-color: var(--gray-100);
    border-radius: var(--radius-sm);

    &__text {
      color: var(--gray-900);
      padding: var(--spacing-4)  var(--spacing-2);
      padding-bottom: var(--spacing-2);
      white-space: pre-wrap;
    }

    &__type-label {
      display: flex;
      align-items: center;
      font-weight: var(--font-bold);
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-xs);

    &__info {
      color: var(--gray-700);
      font-size: var(--font-size-xs);
    }

    &__error {
      color: var(--red-700);
      font-size: var(--font-size-xs);
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      &__date,
      &__button {
        margin: 0;
        padding: 0 var(--spacing-1);
      }

      &__date {
        margin-right: var(--spacing-2);
        color: var(--gray-800);
      }

      &__button {
        color: var(--gray-700);
      }
    }
  }
}
