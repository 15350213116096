<template>
  <div
    v-show="show"
    id="endPageWrapper"
  >
    <logotype-light />
    <exam-finished />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    window.K$.events.$on('show-end-page', () => {
      this.show = true
    })
  },
}
</script>

<style scoped>
#endPageWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url('https://static.exam.net/img/2021/back-image-min.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
